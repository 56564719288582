import { NoResultsDataTable } from '@/components/Menus/NoResultsDataTable/NoResultsDataTable';
import { RetargetingPixelModal } from '@/components/Modals/RetargetingPixelModal/RetargetingPixelModal';
import { PixelService } from '@/services/PixelService';
import { usePixelsStore } from '@/stores/usePixelsStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { IPixel } from '@/types/pixels';
import { getRelativeTime } from '@/utils/DateUtility';
import { faPencil, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Table,
  Title,
  Text,
  ActionIcon,
  Space,
  Tooltip,
  ScrollArea,
} from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useEffect } from 'react';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isUserCollaborator } from '@/utils/CommonUtility';
import { useUserStore } from '@/stores/useUserStore';
  
export function RetargetingPixelsPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);  
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [user] = useUserStore((state) => [state.user]);
  useDocumentTitle('Retargeting Pixels | ' + defaultWorkspace?.agency?.name);

  const [pixelId, setPixelId, pixels, setPixels, setSetupModal, fetchPixels] = usePixelsStore(
    (state) => [
      state.id,
      state.setId,
      state.pixels,
      state.setPixels,
      state.setSetupModal,
      state.fetchPixels,
    ]
  );

  const handlePixelDelete = async (id: string) => {
    await new PixelService()
      .delete(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          // Remove the item from the list
          const newList = pixels.filter((item: any) => item._id !== id);
          setPixels(newList);
          notifications.show({
            color: 'green',
            message: 'Retargeting pixel has been deleted.',
          });
        } else {
          notifications.show({
            color: 'red',
            message: 'Something went wrong. Please contact support',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   *  Function to confirm the deletion of a report.
   * @param id - The id of the report to be deleted.
   */
  const confirmPixelDelete = async (id: string) => {
    modals.openConfirmModal({
      title: <Title order={5}>Delete Retargeting Pixel</Title>,
      children: (
        <>
          <Text size="sm">Are you sure? This process can not be undone.</Text>
        </>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handlePixelDelete(id),
    });
  };

  useEffect(() => {
    fetchPixels(defaultWorkspace?._id || '');
  }, []);
  return (
    <>
      <RetargetingPixelModal id={pixelId} />
      <Box
        style={{
          backgroundColor: 'var(--mantine-color-white)',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          height: '100%',
          flexGrow: 1,
          overflow: 'auto',
        }}
        ml={16}
        mr={16}
      >
        <Grid p={16}>
          <Grid.Col span={5}>
          {defaultPermissions && checkPermission(defaultPermissions, ['add_pixels']) && <Button
              size="xs"
              onClick={() => {
                setPixelId(null);
                setSetupModal(true);
              }}
            >
              Add Pixel
            </Button>}
          </Grid.Col>
          <Grid.Col span={2}>
            <Title pt={2} order={5}>
              Retargeting Pixels
            </Title>
          </Grid.Col>
          <Grid.Col span={5}>
            <></>
          </Grid.Col>
        </Grid>
        <Divider color="gray.2" />
        {/* <ScrollArea h={'85vh'}> */}
        <Table stickyHeader verticalSpacing={'md'}>
          <Table.Thead h={20} bg={'gray.1'} c="gray.7" fz="xs">
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>Network</Table.Th>
              <Table.Th>Pixel ID</Table.Th>
              <Table.Th>Created at</Table.Th>
              {defaultPermissions && checkPermission(defaultPermissions, ['edit_pixels', 'delete_pixels']) && <Table.Th ta={'center'}>Actions</Table.Th>}
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            {pixels.length === 0 && (
              <>
                <NoResultsDataTable
                  data={pixels}
                  noResultsMessage="You do not have any retargeting pixels created yet."
                  colSpan={5}
                />
              </>
            )}
            {pixels.map((pixel: IPixel, index) => (
              <>
                <Table.Tr key={index}>
                  <Table.Td>
                    <Text pt={4} fz={13}>
                      {pixel.name}
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    <Text fz={13} tt="capitalize">
                      {pixel?.type}
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    <Text fz={13}>{pixel.pixel}</Text>
                  </Table.Td>

                  <Table.Td>
                    <Text fz={13}>{getRelativeTime(pixel.created_at)}</Text>
                  </Table.Td>

                  {defaultPermissions && checkPermission(defaultPermissions, ['edit_pixels', 'delete_pixels']) && isUserCollaborator(user, defaultWorkspace?._id, pixel, 'pixel') && <Table.Td ta="center">
                    <Flex justify={'center'}>
                    {defaultPermissions && checkPermission(defaultPermissions, ['edit_pixels']) && <Tooltip label="Edit">
                        <ActionIcon
                          variant="subtle"
                          color="gray"
                          onClick={() => {
                            setPixelId(pixel._id);
                            setSetupModal(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faPencil} />
                        </ActionIcon>
                      </Tooltip>}
                      <Space w={8} />
                      {defaultPermissions && checkPermission(defaultPermissions, ['delete_pixels']) && <Tooltip label="Delete">
                        <ActionIcon
                          variant="subtle"
                          color="red"
                          onClick={() => confirmPixelDelete(pixel._id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </ActionIcon>
                      </Tooltip>}
                    </Flex>
                  </Table.Td>}
                </Table.Tr>
              </>
            ))}
          </Table.Tbody>
        </Table>
        {/* </ScrollArea> */}
      </Box>
    </>
  );
}
