import {
  Anchor,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Image,
  PinInput,
  Text,
  Title,
} from '@mantine/core';
import { useUserStore } from '@/stores/useUserStore';
import { useEffect, useState } from 'react';
import { UserService } from '@/services/UserService';
import { useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { getAppLogo } from '@/utils/CommonUtility';

export const EmailVerificationPage = () => {
  const [emailVerifyingLoader, setEmailVerifyingLoader] = useState(false);
  const [input, setInput] = useState('');
  const [user, setUser] = useUserStore((state) => [state.user, state.setUser]);
  const navigate = useNavigate();
  const handleVerification = async () => {
    setEmailVerifyingLoader(true);
    await new UserService()
      .verifyEmail(input)
      .then((response) => {
        if (response.data.status) {
          notifications.show({
            color: 'green',
            message: response.data.message,
          });
          navigate('/');
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          notifications.show({
            color: 'red',
            message: error.response.data.message,
          });
        }
      });
    setEmailVerifyingLoader(false);
  };

  const handleResendEmail = async () => {
    await new UserService()
      .resendVerificationEmail()
      .then((response) => {
        if (response.data.status) {
          notifications.show({
            color: 'green',
            message: response.data.message,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          notifications.show({
            color: 'red',
            message: error.response.data.message,
          });
        }
      });
  };
  const handleLogout = () => {};

  const fetchUser = async () => {
    await new UserService()
      .getProfile()
      .then((response) => {
        if (response.data.status) {
          setUser(response.data.data.user);
          return true;
        }
        return false;
      })
      .catch((error) => {
        console.debug(error);
        if (error.response.status === 401) {
          navigate('/login');
        }

        return false;
      });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (input.length === 6) {
      handleVerification();
    }
  }, [input]);

  return (
    <>
      <Container>
        <Flex direction={'column'}>
          <Box
            mt={16}
            p={24}
            px={32}
            bg="primary.9"
            style={{
              borderRadius: '0.25rem',
            }}
          >
            <Center>
              <Image w={122} h={36} src={getAppLogo()} />
            </Center>
          </Box>
        </Flex>
        <Flex direction={'column'} align={'center'} py={32}>
          <Title order={2} mb={32}>
            Please verify your email address.
          </Title>
          <PinInput
            type={'number'}
            disabled={emailVerifyingLoader}
            value={input}
            pb={32}
            length={6}
            size="xl"
            // onComplete={handleVerification}
            onChange={setInput}
          />

          <Button
            disabled={input.length <= 5}
            mb={32}
            loading={emailVerifyingLoader}
            onClick={handleVerification}
          >
            Verify Email
          </Button>

          <Text size="sm" mb={32} ta="center" px={32}>
            Please check your email{' '}
            <span
              style={{
                color: 'var(--mantine-color-blue-9)',
              }}
            >
              ({user?.email})
            </span>{' '}
            for a 6 digit verification code.
            <br />
            <br />
            If you don't see the email, check your spam folder or have us{' '}
            <Anchor onClick={handleResendEmail} fw={700}>
              resend
            </Anchor>{' '}
            it.
            <br />
            <br />
            Or{' '}
            <Anchor onClick={() => navigate('/login')} fw={700}>
              Logout
            </Anchor>
          </Text>
        </Flex>
      </Container>
    </>
  );
};
