import { Carousel } from '@mantine/carousel';
import { Anchor, Flex, Image, Text, Title, Tooltip, Button } from '@mantine/core';
import { NavLink } from 'react-router-dom';
import classes from './dashboard.module.css';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import Mobile from '../../assets/images/common/mobile.png';
import { checkPermission, isUserCollaborator } from '@/utils/CommonUtility';
import { usePermissionsStore } from '@/stores/usePermissionsStore';

export function DashboardBioLinkCard() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);

  return (
    <>
      <Carousel
        height={200}
        withIndicators
        withControls={false}
        classNames={{ indicator: classes.indicator }}
      >
        <Carousel.Slide>
          <Flex p={16}>
            <Flex direction={'column'} pr={8}>
              <Title c="primary" pb="sm" order={4}>
                Bio Link
              </Title>
              <Text size="xs" pb="sm">
              A single URL that houses all your key links in one place.
              </Text>
              {(defaultPermissions && checkPermission(defaultPermissions, ['add_bio_link'])) ?
                  <NavLink to={`/bio-links/${defaultWorkspace?._id}/setup`}>
                    <Anchor mb={8} fw={700} size="sm" style={{
                      backgroundColor: '#0045be',
                      color: 'white',
                      padding: '8px 16px',
                      borderRadius: '4px',
                      textDecoration: 'none',
                      fontSize: '0.7vw'
                    }}>
                      Create Now
                    </Anchor>
                  </NavLink> : <Tooltip label="You don't have permission to create bio links, please contact admin.">
                  <Button size="sm" variant="primary" disabled style={{
                      padding: '8px 16px',
                      borderRadius: '4px',
                      textDecoration: 'none',
                      fontSize: '0.7vw',
                      width: '10em'
                    }}>Create Now</Button>
                </Tooltip>}
            </Flex>
            <Image src={Mobile} h={150} w={96} />
          </Flex>
        </Carousel.Slide>
      </Carousel>
    </>
  );
}
