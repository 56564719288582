import {
  Box,
  Flex,
  Group,
  ScrollArea,
  Space,
  TextInput,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { LinksTable } from '../Links/LinksTable';
import { DateMenu } from '@/components/Menus/DateMenu/DateMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { useLinksStore } from '@/stores/useLinksStore';
import { useEffect } from 'react';
import { useDocumentTitle } from '@mantine/hooks';

export function DashboardLinks() {
  const theme = useMantineTheme();
  const [search, setSearch, setDate] = useLinksStore((state) => [
    state.search,
    state.setSearch,
    state.setDate,
  ]);

  return (
    <>
      <Box
        h={'100%'}
        style={{
          //   border: '2px dashed var(--mantine-color-gray-4)',
          //   borderSpacing: '8px',
          backgroundColor: 'var(--mantine-color-white)',
          borderRadius: '0.25rem',
        }}
      >
        <Group p={'sm'} grow>
          <Title order={5}>Recent Links</Title>
          <Flex direction={'row-reverse'}>
            {/* <DateMenu onChange={setDate} /> */}
            <Space w={8} />
            <TextInput
              w={160}
              size="xs"
              ml={8}
              value={search}
              placeholder="Search Links"
              onChange={(event) => setSearch(event.currentTarget.value)}
              leftSection={
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{
                    color: theme.colors.gray[5],
                  }}
                />
              }
            />
          </Flex>
        </Group>
        <ScrollArea h={600}>
          <LinksTable theadBg={'white'} excludeColumns />
        </ScrollArea>
      </Box>
    </>
  );
}
