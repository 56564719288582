import { ILink } from '@/types/links';
import { getRelativeTime } from '@/utils/DateUtility';
import { getShortenedURL } from '@/utils/LinkUtility';
import { faCheck, faCopy } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import {
  ActionIcon,
  CopyButton,
  Flex,
  Text,
  Image,
  Space,
  Tooltip,
  useMantineTheme,
  Anchor,
} from '@mantine/core';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';

export function LinkComponent({ link }: { link: ILink }) {
  const theme = useMantineTheme();
  // Workspace store
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  useEffect(() => {
    // console.log('link 123', link, defaultWorkspace?.append_original_url);
  }, [link])

  return (
    <>
      <Flex direction={'column'}>
        <Flex mb={4} align={'center'}>
          <Anchor fw={600} fz={13} href={getShortenedURL(link)} target="_blank">
            {getShortenedURL(link)}
          </Anchor>
          <Space w={8} />
          <CopyButton value={getShortenedURL(link)} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip fz="xs" label={copied ? 'Copied' : 'Copy Link'} withArrow position="right">
                <ActionIcon
                  size="sm"
                  color={copied ? 'green' : 'gray'}
                  variant="subtle"
                  onClick={copy}
                >
                  {copied ? (
                    <FontAwesomeIcon
                      style={{
                        fontSize: 13,
                        marginTop: 4,
                        color: theme.colors.green[5],
                      }}
                      icon={faCheck}
                    />
                  ) : (
                    <FontAwesomeIcon
                      style={{
                        fontSize: 13,
                        marginTop: 4,
                        color: theme.colors.gray[5],
                      }}
                      icon={faCopy}
                    />
                  )}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </Flex>

        <Flex direction={'column'}>
          <Flex align={'center'}>
            <Image
              mr={4}
              h={16}
              w={16}
              radius={'xl'}
              src={link?.link_preview_attributes?.favicon_url}
            // src={`https://favicon.usermaven.com/?url=${link?.information?.meta?.url}`}
            />
            <Tooltip label={link?.url}>
              <Text lineClamp={1} fz={13}>
                {link?.url}
              </Text>
            </Tooltip>
          </Flex>
          <Tooltip label={link?.link_preview_attributes?.meta?.title}>
            <Text lineClamp={1} c="dimmed" pt={2} fz={12}>
              {link?.link_preview_attributes?.meta?.title}
            </Text>
          </Tooltip>
        </Flex>
        <Flex align={'center'}>
          <Text pt={4} c="dimmed" size="xs">
            {getRelativeTime(link?.created_at)}
          </Text>
        </Flex>
      </Flex>
    </>
  );
}
