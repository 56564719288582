import { DateMenu } from '@/components/Menus/DateMenu/DateMenu';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { Box, Divider, Flex, Grid, Loader, Select, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BioAnalyticsByBrowser } from './Components/BioLinks/BioAnalyticsByBrowser';
import { BioAnalyticsByLocation } from './Components/BioLinks/BioAnalyticsByLocation';
import { BioAnalyticsByReferral } from './Components/BioLinks/BioAnalyticsByReferral';
import { BioAnalyticsHistogram } from './Components/BioLinks/BioAnalyticsHistogram';
import { BioAnalyticsOverview } from './Components/BioLinks/BioAnalyticsOverview';
import { BioAnalyticsByDevice } from './Components/BioLinks/BioiAnalyticsByDevice';
import { endOfDay, startOfDay } from 'date-fns';
import { BioLinksService } from '@/services/BioLinksService';
import { BioLinkService } from '@/services/BioLinkService';
import CustomDateMenu from '@/components/Menus/DateMenu/CustomDateMenu';

export const AnalyticsBioLinksPage = () => {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [linkSuggestionLoader, setLinkSuggestionLoader] = useState(false);
  const [selectedLink, setSelectedLink] = useState<string | null>(queryParams.get('link_id'));
  const [linksSuggestions, setLinksSuggestions] = useState<any[]>([]);

  const navigate = useNavigate();
  /**
   * Handle date change
   * @param value
   */
  const handleDateChange = (startDate: string | null, endDate: string | null, granularity: string) => {
    if (!startDate || !endDate) {
      // Handle "All time" case
      queryParams.set(
        'from_date',
        startOfDay(new Date(defaultWorkspace?.created_at || '')).toISOString()
      );
      queryParams.set('to_date', endOfDay(new Date()).toISOString());
    } else {
      queryParams.set('from_date', startDate);
      queryParams.set('to_date', endDate);
    }
    const newSearch = `?${queryParams.toString()}`;
    navigate(newSearch);
  };

  const handleLinkChange = (value: string | null) => {
    setSelectedLink(value);
    queryParams.set('link_id', value || '');
    const newSearch = `?${queryParams.toString()}`;
    navigate(newSearch);
  };

  const fetchBioLinks = async () => {
    setLinkSuggestionLoader(true);
    await new BioLinkService()
      .getLinks(defaultWorkspace?._id || '', '')
      .then((res) => {
        if (res.data.status) {
          setLinksSuggestions(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLinkSuggestionLoader(false);
  };

  useEffect(() => {
    // search for the query params
    const linkId = queryParams.get('link_id');
    setSelectedLink(linkId);
    fetchBioLinks();
  }, []);

  return (
    <>
      <Divider />
      <Flex
        mt={16}
        // p={16}
        // px={24}
        align={'center'}
        style={
          {
            //   background: 'var(--mantine-color-white)',
          }
        }
      >
        <Flex
          style={{
            flexGrow: 1,
          }}
        >
          <Select
            data={linksSuggestions}
            placeholder="Filter by a bio link"
            size="sm"
            radius="sm"
            limit={25}
            searchable
            style={{ minWidth: 300 }}
            value={selectedLink}
            onChange={handleLinkChange}
            disabled={linkSuggestionLoader}
            rightSection={linkSuggestionLoader ? <Loader size={14} /> : null}
          />
        </Flex>
        {/* <DateMenu onChange={handleDateChange} labelValue="Last 30 days" /> */}
        <CustomDateMenu
          showButtons={{
            all_time: true,
            last_24_hours: true,
            last_7_days: true,
            last_14_days: true,
            last_30_days: true,
            last_90_days: false,
            last_6_months: true,
            last_1_year: true,
            this_month: true,
            last_month: true,
            "7D": false,
            "14D": false,
            "30D": false,
            "3M": false,
            "6M": false,
            "12M": false,
            "no-range": false,
            custom: true
          }}
          onDateChange={handleDateChange}
          value={[
            queryParams.get('from_date') || null,
            queryParams.get('to_date') || null
          ]}
          withGranularity={false}
        />
      </Flex>
      <Box my={16}>
        <Grid>
          <Grid.Col span={12}>
            <BioAnalyticsOverview />
          </Grid.Col>
          <Grid.Col span={12}>
            <Box
              h={306}
              bg={'white'}
              style={{
                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
                border: '1px solid var(--mantine-color-gray-2)',
                borderRadius: 4,
                padding: 16,
              }}
            >
              <Flex direction={'column'}>
                <Text fw={600} c="dimmed" mb={12}>
                  Views vs Unique Views vs Clicks
                </Text>
                <BioAnalyticsHistogram />
              </Flex>
            </Box>
          </Grid.Col>
        </Grid>
      </Box>
      <Grid>
        <Grid.Col span={6}>
          <BioAnalyticsByReferral />
        </Grid.Col>
        <Grid.Col span={6}>
          <BioAnalyticsByLocation />
        </Grid.Col>
        <Grid.Col span={6}>
          <BioAnalyticsByBrowser />
        </Grid.Col>
        <Grid.Col span={6}>
          <BioAnalyticsByDevice />
        </Grid.Col>
      </Grid>
    </>
  );
};
