import { useState, useEffect } from 'react';

function useUrlChange() {
    const [pathname, setPathname] = useState(window.location.pathname);

    useEffect(() => {
        const handleUrlChange = () => {
            setPathname(window.location.pathname);
        };

        window.addEventListener('popstate', handleUrlChange);

        // For handling pushState and replaceState
        const originalPushState = window.history.pushState;
        const originalReplaceState = window.history.replaceState;

        window.history.pushState = function () {
            originalPushState.apply(this, arguments as any);
            handleUrlChange();
        };

        window.history.replaceState = function () {
            originalReplaceState.apply(this, arguments as any);
            handleUrlChange();
        };

        return () => {
            window.removeEventListener('popstate', handleUrlChange);
            window.history.pushState = originalPushState;
            window.history.replaceState = originalReplaceState;
        };
    }, []);

    return pathname;
}

export default useUrlChange