import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Loader,
  Switch,
  Text,
  TextInput,
  Textarea,
  Tooltip,
} from '@mantine/core';

import { UtilsService } from '@/services/UtilsService';
import { faImage } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import classes from '../../BioLinks.module.css';
import { BioAppearance } from '../Themes/BioAppearance';
import { BioThemes } from '../Themes/BioThemes';
import { useScrollIntoView } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';

export const BioBrandingSection = ({
  form,
  scrollIntoView,
  targetRef,
}: {
  form: any;
  scrollIntoView: any;
  targetRef: any;
}) => {
  // Create a ref for the file input element
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const openFileInput = () => {
    // Trigger the click event on the file input element
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      const formData = new FormData();
      formData.append('input_file', files[0]);
      setLoadingImage(true);
      await new UtilsService()
        .uploadImage(formData)
        .then((res) => {
          console.log(res.data);
          if (res.data.status) {
            form.setFieldValue('profile_image', res.data.url);
          }
        })
        .catch((err) => {
          if (err.response.status === 400 || err.response.status === 422) {
            notifications.show({
              message: err.response.data.message,
              color: 'red',
            });
          }
        });

      setLoadingImage(false);
      // You can now use formData to send the file data to your server or perform any other actions.
    }
  };

  const [loadingImage, setLoadingImage] = useState(false);

  return (
    <>
      <Grid p={'md'} mb={'md'}>
        <Grid.Col span={4}>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={uploadImage}
          />
          <Flex justify={'center'} align={'center'} mb="sm" direction={'column'}>
            <Tooltip
              label="Upload Image"
              //</Flex> disabled={form.values.logo || false}
            >
              <Avatar
                className={classes.avatar}
                w={96}
                h={96}
                size={'xl'}
                src={form.values.profile_image}
                style={{
                  cursor: 'pointer',
                }}
                onClick={openFileInput}
              >
                {loadingImage ? (
                  <>
                    <Loader size="xs" />
                  </>
                ) : (
                  <FontAwesomeIcon icon={faImage} color="gray" size="sm" />
                )}
              </Avatar>
            </Tooltip>
            <Text fz={10} ta="center" pt="sm">
              Recommended size: Less than 2MB (90 x 90)
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={8}>
          <TextInput
            mb={'md'}
            label="Profile title"
            required
            placeholder="Profile title"
            {...form.getInputProps('profile_title')}
          />
          <Textarea
            mb="md"
            label="Bio"
            placeholder="Bio description"
            {...form.getInputProps('bio_description')}
          />
          <Flex
            direction={'row'}
            style={{
              flexGrow: 1,
            }}
          >
            <Flex direction={'column'}>
              <Text fw={500} mb="sm" fz={14}>
                Verified
              </Text>
              <Text fz={13}>
                A verified account badge helps people be sure that your account is yours and doesn't
                represent anyone else.
              </Text>
            </Flex>
            <Switch
              mt={6}
              size="xs"
              checked={form.values.is_verified}
              onChange={() => {
                form.setFieldValue('is_verified', !form.values.is_verified);
              }}
            />
          </Flex>
        </Grid.Col>
      </Grid>
      <Divider />
      <Box p="md">
        <Text fw={500} fz={14} mb="lg">
          Personalize your bio profile to your heart's content. Switch up the background using a
          variety of colors, gradients, and images. Select your preferred button style, adjust the
          font, and much more.
        </Text>
        <BioThemes form={form} scrollIntoView={scrollIntoView} />
      </Box>

      <Box p="md" ref={targetRef}>
        <BioAppearance form={form} />
      </Box>
    </>
  );
};
