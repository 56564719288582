import axios from './JWTService';

export class ReportService {
  get = (
    workspace_id: string,
    search: string,
    page: number,
    limit: number,
    archived: boolean
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `workspace/${workspace_id}/report/get?page=${page}&limit=${limit}&search=${search}&is_archived=${archived}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  delete = (workspace_id: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`workspace/${workspace_id}/report/delete/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  create = (workspace_id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`workspace/${workspace_id}/report/create`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  update = (workspace_id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`workspace/${workspace_id}/report/update/${data._id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  dispatch = (workspace_id: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`workspace/${workspace_id}/report/send_report/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  activity = (workspace_id: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`workspace/${workspace_id}/report/activity/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
