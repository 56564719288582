import { CallToActionTypeEnum, CallToActionVariantsEnum } from '@replug/utils';
import { Button, Center, Divider, Stack, TextInput, Textarea, Title, Checkbox } from '@mantine/core';

export const CallToActionMessage = ({ form, nextStep }: { form: any; nextStep: () => void }) => {
  return (
    <>
      {' '}
      <Title order={5} px={24} py={16}>
        Message
      </Title>
      <Divider color="gray.2" />
      <Stack p={24}>
        <TextInput
          label="Headline"
          variant="filled"
          placeholder="Your message headline"
          {...form.getInputProps('call_to_action.message_headline')}
        />
        {form.values.call_to_action.variant !== CallToActionVariantsEnum.Banner && (
          <>
            <Textarea
              label="Message"
              variant="filled"
              placeholder="Your message goes here"
              {...form.getInputProps('call_to_action.message')}
            />
          </>
        )}

        <TextInput
          label={
            form.values.call_to_action.type === CallToActionTypeEnum.Link
              ? 'Link Text'
              : 'Button Text'
          }
          variant="filled"
          placeholder="Button URL"
          {...form.getInputProps('call_to_action.button_text')}
        />

        {form.values.call_to_action.type == CallToActionTypeEnum.Form && (
          <Checkbox
            label="Enable redirection after form submission"
            checked={form.values.call_to_action.redirect_after_submission}
            {...form.getInputProps('call_to_action.redirect_after_submission')}
          />
        )}

        {(form.values.call_to_action.type !== CallToActionTypeEnum.Form || (form.values.call_to_action.type === CallToActionTypeEnum.Form && form.values.call_to_action.redirect_after_submission)) && (
          <>
            <TextInput
              label={`${CallToActionTypeEnum.Link ? 'Link' : 'Button'
                } URL (or phone number for click to call) `}
              description={`This is the URL that the ${CallToActionTypeEnum.Link ? 'Link' : 'Button'
                } will redirect to.`}
              variant="filled"
              placeholder="e.g https://mywebsite.com or tel:+05437321244"
              {...form.getInputProps('call_to_action.button_url')}
            />
          </>
        )}

        <Center>
          <Button
            w={'200'}
            onClick={() => {
              form.validate();
              console.log(form.errors);
              if (form.isValid()) {
                nextStep();
              }
            }}
          >
            Next
          </Button>
        </Center>
      </Stack>
    </>
  );
};
