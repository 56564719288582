import { NoResultsDataTable } from '@/components/Menus/NoResultsDataTable/NoResultsDataTable';
import { UTMPresetModal } from '@/components/Modals/UTMPresetModal/UTMPresetModal';
import { UTMService } from '@/services/UTMService';
import { useUTMStore } from '@/stores/useUTMStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { IUTM } from '@/types/utms';
import { faCircleExclamation, faPencil, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Table,
  Title,
  Text,
  ActionIcon,
  Space,
  Tooltip,
} from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isUserCollaborator } from '@/utils/CommonUtility';
import { useUserStore } from '@/stores/useUserStore';

export function UTMTrackingPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  useDocumentTitle('UTM Tracking | ' + defaultWorkspace?.agency?.name);

  const [utmId, setUtmId, UTMs, setUTMs, setSetupModal] = useUTMStore((state) => [
    state.id,
    state.setId,
    state.UTMs,
    state.setUTMs,
    state.setSetupModal,
  ]);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [user] = useUserStore((state) => [state.user]);

  const handleUTMDelete = async (id: string) => {
    await new UTMService()
      .delete(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          // Remove the item from the list
          const newList = UTMs.filter((item) => item._id !== id);
          setUTMs(newList);
          notifications.show({
            color: 'green',
            message: 'UTM preset has been deleted.',
          });
        } else {
          notifications.show({
            color: 'red',
            message: 'Something went wrong. Please contact support',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   *  Function to confirm the deletion of a UTM.
   * @param id - The id of the UTM to be deleted.
   */
  const confirmDeleteUTM = async (id: string) => {
    modals.openConfirmModal({
      title: <Title order={5}>Delete UTM</Title>,
      children: (
        <>
          <Text size="sm">Are you sure? This process can not be undone.</Text>
        </>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleUTMDelete(id),
    });
  };

  return (
    <>
      <UTMPresetModal id={utmId} />
      <Box
        style={{
          backgroundColor: 'var(--mantine-color-white)',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          height: '100%',
          flexGrow: 1,
        }}
        ml={16}
        mr={16}
      >
        <Grid p={16}>
          <Grid.Col span={5}>
          {defaultPermissions && checkPermission(defaultPermissions, ['add_utms']) && <Button
              size="xs"
              onClick={() => {
                setSetupModal(true);
                setUtmId(null);
              }}
            >
              Add UTM Preset
            </Button>}
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex align={'center'}>
              <Title pt={2} order={5} mr={8}>
                UTM Tracking
              </Title>
              <Tooltip
                multiline
                w={450}
                fz="13"
                label="With UTM parameters attached to your links, you will have the ability to track your campaign's performance in Google Analytics or Usermaven.

This will also allow you to see how much traffic are you receiving from the links you shared on different social channels. "
              >
                <FontAwesomeIcon
                  style={{
                    color: 'var(--mantine-color-gray-6)',
                  }}
                  icon={faCircleExclamation}
                />
              </Tooltip>
            </Flex>
          </Grid.Col>
          <Grid.Col span={5}>
            <></>
          </Grid.Col>
        </Grid>
        <Divider color="gray.2" />
        <Table stickyHeader verticalSpacing={'md'}>
          <Table.Thead h={20} bg={'gray.1'} c="gray.7" fz="xs">
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>UTM Campaign</Table.Th>
              <Table.Th>UTM Medium</Table.Th>
              <Table.Th>UTM Source</Table.Th>
              <Table.Th>UTM Term</Table.Th>
              <Table.Th>UTM Content</Table.Th>
              {defaultPermissions && checkPermission(defaultPermissions, ['edit_utms', 'delete_utms']) && <Table.Th ta={'center'}>Actions</Table.Th>}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {UTMs.length === 0 && (
              <>
                <NoResultsDataTable
                  data={UTMs}
                  colSpan={6}
                  noResultsMessage="You do not have any UTM tags created"
                />
              </>
            )}
            {UTMs.map((utm: IUTM, index: number) => (
              <>
                <Table.Tr key={index}>
                  <Table.Td>
                    <Text pt={4} fz={13}>
                      {utm.name}
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    <Text pt={4} fz={13}>
                      {utm.utm_campaign}
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    <Text pt={4} fz={13}>
                      {utm.utm_medium}
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    <Text pt={4} fz={13}>
                      {utm.utm_source}
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    <Text pt={4} fz={13}>
                      {utm.utm_term}
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    <Text pt={4} fz={13}>
                      {utm.utm_content}
                    </Text>
                  </Table.Td>

                  {defaultPermissions && checkPermission(defaultPermissions, ['edit_utms', 'delete_utms']) && isUserCollaborator(user, defaultWorkspace?._id, utm, 'utm') && <Table.Td ta="center">
                    <Flex justify={'center'}>
                    {defaultPermissions && checkPermission(defaultPermissions, ['edit_utms']) && <Tooltip label="Edit">
                        <ActionIcon
                          variant="subtle"
                          color="gray"
                          onClick={() => {
                            setUtmId(utm._id);
                            setSetupModal(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faPencil} />
                        </ActionIcon>
                      </Tooltip>}
                      <Space w={8} />
                      {defaultPermissions && checkPermission(defaultPermissions, ['delete_utms']) && <Tooltip label="Delete">
                        <ActionIcon
                          variant="subtle"
                          color="red"
                          onClick={() => confirmDeleteUTM(utm._id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </ActionIcon>
                      </Tooltip>}
                    </Flex>
                  </Table.Td>}
                </Table.Tr>
              </>
            ))}
          </Table.Tbody>
        </Table>
      </Box>
    </>
  );
}
