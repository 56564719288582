import axios from './JWTService';

export class AnalyticsService {
  overview = (
    workspace_id: string,
    campaign_id: string | null,
    link_id: string | null,
    from_date: string,
    to_date: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post('/metrics/overview', {
          workspace_id,
          campaign_id,
          from_date,
          to_date,
          link_id,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  histogram = (
    workspace_id: string,
    campaign_id: string | null,
    link_id: string | null,

    from_date: string,
    to_date: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post('/metrics/histogram', {
          workspace_id,
          campaign_id,
          link_id,
          from_date,
          to_date,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  referrer = (
    workspace_id: string,
    campaign_id: string | null,
    link_id: string | null,

    from_date: string,
    to_date: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post('/metrics/referrer', {
          workspace_id,
          campaign_id,
          link_id,
          from_date,
          to_date,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  location = (
    workspace_id: string,
    type: string,
    campaign_id: string | null,
    link_id: string | null,

    from_date: string,
    to_date: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/metrics/location/${type}`, {
          workspace_id,
          campaign_id,
          link_id,
          from_date,
          to_date,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  browser = (
    workspace_id: string,
    campaign_id: string | null,
    link_id: string | null,

    from_date: string,
    to_date: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/metrics/browser/list`, {
          workspace_id,
          campaign_id,
          link_id,
          from_date,
          to_date,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  device = (
    workspace_id: string,
    type: string,
    campaign_id: string | null,
    link_id: string | null,
    from_date: string,
    to_date: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/metrics/device/${type}`, {
          workspace_id,
          campaign_id,
          link_id,
          from_date,
          to_date,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
