import { useEffect, useState } from 'react';
import classes from './MultiSelectCreatable.module.css';

import {
  CheckIcon,
  Combobox,
  Flex,
  Group,
  Pill,
  PillsInput,
  useCombobox,
  Text,
} from '@mantine/core';

export function MultiSelectCreatable({
  dataItems,
  valueItems = [],
  onChange,
  onCreate,
  label,
  width,
  placeholder = 'Search values',
  size,
  error = false,
}: {
  width?: number;
  placeholder?: string;
  label?: string;
  size: string;
  error?: boolean;
  valueItems: string[];
  dataItems: {
    label: string;
    value: string;
  }[];
  onChange: (value: string[]) => void; // Function to call when selection changes
  onCreate: (item: { label: string; value: string }) => void; // Function to call when a new item is created
}) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });
  
  const [search, setSearch] = useState('');
  const [errorFlag, setErrorFlag] = useState<boolean>(false);
  const [data, setData] = useState(dataItems);
  const [value, setValue] = useState<string[]>(valueItems);

  const exactOptionMatch = data.some(
    (item) => item?.label && item.label.toLowerCase() === search.toLowerCase()
  );

  const handleValueSelect = (val: string) => {
    setSearch('');

    if (val === '$create') {
      const newItem = { label: search, value: search.toLowerCase() };
      //   setData((current) => [...current, newItem]);
      //   setValue((current) => [...current, newItem.value]);
      onCreate(newItem); // Call onCreate prop with the new item
    } else {
      setValue((current) => {
        const newValue = current.includes(val)
          ? current.filter((v) => v !== val)
          : [...current, val];
        onChange(newValue); // Call onChange prop with the new value array
        return newValue;
      });
    }
  };

  const handleValueRemove = (val: string) => {
    setValue((current) => {
      const newValue = current.filter((v) => v !== val);
      onChange(newValue); // Call onChange prop with the updated value array
      return newValue;
    });
  };

  const values = value.map((val) => {
    const item = data.find((item) => item.value === val);
    return (
      <Pill key={val} withRemoveButton onRemove={() => handleValueRemove(val)}>
        {item && item?.label ? item.label : val}
      </Pill>
    );
  });

  const options = data
    .filter(
      (item) => item?.label && item?.label.toLowerCase().includes(search.trim().toLowerCase())
    )
    .map((item) => (
      <Combobox.Option value={item.value} key={item.value} active={value.includes(item.value)}>
        <Group gap="sm">
          {value.includes(item.value) ? <CheckIcon size={12} /> : null}
          <span>{item?.label || ''}</span>
        </Group>
      </Combobox.Option>
    ));

  useEffect(() => {
    setData(dataItems);
  }, [dataItems]);

  useEffect(() => {
    setErrorFlag(error);
  }, [error]);

  return (
    <>
      <Flex direction={'column'}>
        {label && (
          <Text mb={2} mt={2} fz={13} fw={500}>
            {label}
          </Text>
        )}
        <Combobox
          size={size || 'sm'}
          store={combobox}
          onOptionSubmit={handleValueSelect}
          withinPortal={false}
          //@ts-ignore
          style={errorFlag ? { border: '1px solid red', borderRadius: '4px' } : null}
        >
          <Combobox.DropdownTarget>
            <PillsInput
              w={width || undefined}
              size={size || 'sm'}
              onClick={() => combobox.openDropdown()}
            >
              <Pill.Group>
                {values}
                <Combobox.EventsTarget>
                  <PillsInput.Field
                    onFocus={() => combobox.openDropdown()}
                    onBlur={() => combobox.closeDropdown()}
                    value={search}
                    placeholder={placeholder}
                    onChange={(event) => {
                      combobox.updateSelectedOptionIndex();
                      setSearch(event.currentTarget.value);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Backspace' && search.length === 0) {
                        event.preventDefault();
                        handleValueRemove(value[value.length - 1]);
                      }
                    }}
                  />
                </Combobox.EventsTarget>
              </Pill.Group>
            </PillsInput>
          </Combobox.DropdownTarget>

          <Combobox.Dropdown style={{
            maxHeight: '300px', // Set max height to make it scrollable
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            scrollbarColor: '#888 #e0e0e0',
          }}>
            <Combobox.Options>
              {options}

              {!exactOptionMatch && search.trim().length > 0 && (
                <Combobox.Option value="$create">+ Create {search}</Combobox.Option>
              )}

              {exactOptionMatch && search.trim().length > 0 && options.length === 0 && (
                <Combobox.Empty>Nothing found</Combobox.Empty>
              )}
              {options.length === 0 && search.trim().length === 0 && (
                <Combobox.Empty>You do not have any tag, type to create a tag</Combobox.Empty>
              )}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
      </Flex>
    </>
  );
}
