import React from 'react';
import { Box, ActionIcon, Text } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { TrafficRoutingCondition } from './TrafficRoutingCondition'; 
import { TrafficRoutingFilter } from './TrafficRoutingFilter';

export const TrafficRoutingBlock = ({
  form,
  ruleIndex,
  blockIndex,
  block,
  selectedValue,
  handleFilterTypeChange,
  removeCondition,
  removeBlock,
  blockCount
}: {
  form: any,
  ruleIndex: any,
  blockIndex: any,
  block: any,
  selectedValue: any,
  handleFilterTypeChange: any,
  removeCondition: any,
  removeBlock: any,
  blockCount: any
}) => {
  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };
  const blockPath = `rules.${ruleIndex}.blocks.${blockIndex}`;
  const blockError = getNestedValue(form.errors, blockPath);

  return (
    <Box
      style={{
        border: '1px solid #ccc',
        padding: '20px',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
        marginBottom: '10px',
        marginTop: '10px',
        position: 'relative', // Ensure position relative for absolute positioning of ActionIcon
      }}
      key={blockIndex}
    >
      {block.conditions.map((condition: any, conditionIndex: any) => (
        <Box
          key={conditionIndex}
          style={{
            position: 'relative',
          }}
        >
          <TrafficRoutingCondition
            form={form} // Pass the form instance
            ruleIndex={ruleIndex}
            blockIndex={blockIndex}
            conditionIndex={conditionIndex}
            //@ts-ignore
            type={condition.filterType} // Pass the filterType to your component
          />
          <ActionIcon
            onClick={() => removeCondition(ruleIndex, blockIndex, conditionIndex)}
            style={{
              position: 'absolute',
              top: '50%',
              right: '5px',
              transform: 'translateY(-50%)',
            }}
            size="xs"
            variant="subtle"
            color="primary.3"
          >
            <FontAwesomeIcon
              size="sm"
              icon={faTimes}
              style={{
                color: 'gray',
              }}
            />
          </ActionIcon>
        </Box>
      ))}
      <TrafficRoutingFilter
        blockIndex={blockIndex}
        selectedValue={selectedValue}
        handleFilterTypeChange={handleFilterTypeChange}
      />
      {blockCount > 1 && <ActionIcon
        onClick={() => removeBlock(ruleIndex, blockIndex)}
        style={{
          position: 'absolute',
          top: '5px',
          right: '5px',
        }}
        size="xs"
        variant="subtle"
        color="primary.3"
      >
        <FontAwesomeIcon
          size="sm"
          icon={faTimes}
          style={{
            color: 'gray',
          }}
        />
      </ActionIcon>}
      {(blockError && typeof blockError !== 'object') && (
        <Text color="#F03E3E" size="sm" mt="xs">
          <small>{blockError}</small>
        </Text>
      )}
    </Box>
  );
};
