import { Select } from '@mantine/core';
import styles from './TrafficRouting.module.css'

export const TrafficRoutingFilter = ({ blockIndex, selectedValue, handleFilterTypeChange }: { blockIndex: any, selectedValue: any, handleFilterTypeChange: any }) => {
  return (
    <Select
      data={['Country', 'Device', 'Browser', 'OS', 'Date', 'DaysOfWeek', 'IP']}
      placeholder="+ Add a filter"
      value={selectedValue}
      onChange={(value) => handleFilterTypeChange(blockIndex, value)}
      classNames={{
        input: styles.input,
        dropdown: styles.dropdown,
      }}
    />
  );
};
