import { ActionIcon, Flex, Group, Text, Image, Tooltip } from '@mantine/core';
import CardTitleOnlyIcon from '../../../../assets/images/common//card-title-only-icon.svg';
import CardTitleDescriptionIcon from '../../../../assets/images/common/card-title-and-description-icon.svg';
import CardTitleOnlyActiveIcon from '../../../../assets/images/common//card-title-only-active-icon.svg';
import CardTitleDescriptionActiveIcon from '../../../../assets/images/common/card-title-and-description-active-icon.svg';
import { BIO_CARD_STYLES } from '@replug/utils';
import card_1 from '../../../../assets/images/common/card-1.png';
import card_2 from '../../../../assets/images/common/card-2.png';
import thumb_1 from '../../../../assets/images/common/thumb-1.png';
import thumb_2 from '../../../../assets/images/common/thumb-2.png';
import strip from '../../../../assets/images/common/strip.png';
import card_1_black from '../../../../assets/images/common/card_1_black.png';
import card_2_black from '../../../../assets/images/common/card_2_black.png';
import thumb_1_black from '../../../../assets/images/common/thumb_1_black.png';
import thumb_2_black from '../../../../assets/images/common/thumb_2_black.png';
import strip_black from '../../../../assets/images/common/strip_black.png';

export const BioStyle = ({
  onChange,
  activeStyle,
}: {
  onChange: (value: string) => void;
  activeStyle: string;
}) => {
  return (
    <>
      <Flex direction={'column'}>
        <Flex align={'center'} mb={'sm'}>
          <Text fw={500} fz={14} mb={4}>
            Style
          </Text>
        </Flex>
        <Group>
          <Tooltip label="Top Image Card">
            <ActionIcon
              variant="subtle"
              color={
                activeStyle === BIO_CARD_STYLES.CARD_1 ? 'primary.6' : 'gray.6'
              }
              mr={'xs'}
              onClick={() => {
                onChange(BIO_CARD_STYLES.CARD_1);
              }}
            >
              <Image
                src={
                  activeStyle === BIO_CARD_STYLES.CARD_1
                    ? card_1
                    : card_1_black
                }
                alt="Card Title Only Icon"
              />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Centered Image Card">
            <ActionIcon
              variant="subtle"
              color={
                activeStyle === BIO_CARD_STYLES.CARD_2 ? 'primary.6' : 'gray.6'
              }
              mr={'xs'}
              onClick={() => {
                onChange(BIO_CARD_STYLES.CARD_2);
              }}
            >
              <Image
                src={
                  activeStyle === BIO_CARD_STYLES.CARD_2
                    ? card_2
                    : card_2_black
                }
                alt="Card Title Only Icon"
              />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Left Thumbnail Card">
            <ActionIcon
              variant="subtle"
              color={
                activeStyle === BIO_CARD_STYLES.THUMB_1 ? 'primary.6' : 'gray.6'
              }
              mr={'xs'}
              onClick={() => {
                onChange(BIO_CARD_STYLES.THUMB_1);
              }}
            >
              <Image
                src={
                  activeStyle === BIO_CARD_STYLES.THUMB_1
                    ? thumb_1
                    : thumb_1_black
                }
                alt="Card Title Only Icon"
              />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Left Circular Thumbnail Card">
            <ActionIcon
              variant="subtle"
              color={
                activeStyle === BIO_CARD_STYLES.THUMB_2 ? 'primary.6' : 'gray.6'
              }
              mr={'xs'}
              onClick={() => {
                onChange(BIO_CARD_STYLES.THUMB_2);
              }}
            >
              <Image
                src={
                  activeStyle === BIO_CARD_STYLES.THUMB_2
                    ? thumb_2
                    : thumb_2_black
                }
                alt="Card Title Only Icon"

              />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Left Banner Thumbnail Card">
            <ActionIcon
              variant="subtle"
              color={
                activeStyle === BIO_CARD_STYLES.STRIP ? 'primary.6' : 'gray.6'
              }
              mr={'xs'}
              onClick={() => {
                onChange(BIO_CARD_STYLES.STRIP);
              }}
            >
              <Image
                src={
                  activeStyle === BIO_CARD_STYLES.STRIP
                    ? strip
                    : strip_black
                }
                alt="Card Title Only Icon"
              />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Flex>
    </>
  );
};
