import {
  faBlock,
  faLink,
  faPlay,
  faRss,
  faShareNodes,
  faText,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Center, Divider, Flex, Group, Text } from '@mantine/core';
import classes from '../BioLinks.module.css';
import { BIO_SECTION_NAMES } from '@replug/utils';
import { useBioLinkSetupStore } from '@/stores/Bio/useBioLinkSetupStore';
export const BioAddBlocks = ({ handleSectionChange }: { handleSectionChange: any }) => {
  const [bioLink] = useBioLinkSetupStore((state) => [state.bioLink]);
  return (
    <>
      <Text fz={14} mb="md" ta="center" ml={8}>
        {bioLink?.blocks.length > 0 ? 'Add a new block' : 'Add your first block'}
      </Text>
      <Center>
        <Group>
          <Flex
            onClick={() => {
              handleSectionChange(BIO_SECTION_NAMES.SOCIAL);
            }}
            className={`${classes.navItem}`}
            direction={'column'}
            justify={'center'}
            align={'center'}
          >
            <FontAwesomeIcon icon={faShareNodes} />
            <Text mt={4} fz={11}>
              Social
            </Text>
          </Flex>

          <Flex
            onClick={() => {
              handleSectionChange(BIO_SECTION_NAMES.LINKS);
            }}
            className={`${classes.navItem} `}
            direction={'column'}
            justify={'center'}
            align={'center'}
          >
            <FontAwesomeIcon icon={faLink} />
            <Text mt={4} fz={11}>
              Links
            </Text>
          </Flex>

          <Flex
            onClick={() => {
              handleSectionChange(BIO_SECTION_NAMES.RSS);
            }}
            className={`${classes.navItem} `}
            direction={'column'}
            justify={'center'}
            align={'center'}
          >
            <FontAwesomeIcon icon={faRss} />
            <Text mt={4} fz={11}>
              RSS
            </Text>
          </Flex>

          <Flex
            onClick={() => {
              handleSectionChange(BIO_SECTION_NAMES.VIDEO);
            }}
            className={`${classes.navItem} `}
            direction={'column'}
            justify={'center'}
            align={'center'}
          >
            <FontAwesomeIcon icon={faPlay} />
            <Text mt={4} fz={11}>
              Video
            </Text>
          </Flex>
          <Flex
            onClick={() => {
              handleSectionChange(BIO_SECTION_NAMES.CARD);
            }}
            className={`${classes.navItem} `}
            direction={'column'}
            justify={'center'}
            align={'center'}
          >
            <FontAwesomeIcon icon={faBlock} />
            <Text mt={4} fz={11}>
              Card
            </Text>
          </Flex>
          <Flex
            onClick={() => {
              handleSectionChange(BIO_SECTION_NAMES.TEXT);
            }}
            className={`${classes.navItem} `}
            direction={'column'}
            justify={'center'}
            align={'center'}
          >
            <FontAwesomeIcon icon={faText} />
            <Text mt={4} fz={11}>
              Text
            </Text>
          </Flex>
        </Group>
      </Center>
    </>
  );
};
