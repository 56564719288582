import { DEFAULT_RSS_BLOCK_VALUES, IBioRSSBlock } from '@replug/utils';
import { create } from 'zustand';

interface IBioRSSBlockStore {
  bioRSSBlock: IBioRSSBlock;
  setBioRSSBlock: (bioRSSBlock: IBioRSSBlock) => void;
  loader: boolean;
  setLoader: (loader: boolean) => void;
  reset: () => void;
}

export const useBioRSSBlockStore = create<IBioRSSBlockStore>((set, get) => ({
  bioRSSBlock: DEFAULT_RSS_BLOCK_VALUES,
  setBioRSSBlock: (bioRSSBlock: IBioRSSBlock) => {
    set({ bioRSSBlock });
  },
  loader: false,
  setLoader: (loader: boolean) => {
    set({ loader });
  },
  reset: () => {
    set({ bioRSSBlock: DEFAULT_RSS_BLOCK_VALUES });
  },
}));
