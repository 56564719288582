import { DeveloperAppService } from '@/services/DeveloperAppsService';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { Box, Button, Center, Modal, TextInput, Title } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useEffect } from 'react';
import { z } from 'zod';
import { ensureHttps } from '@/utils/LinkUtility';

interface IDeveloperAppModalProps {
  isOpen: boolean;
  onClose: () => void;
  reload: () => void;
  app: any;
}

const schema = z.object({
  name: z.string().min(2, { message: 'Please enter a valid app name' }),
  redirect: z.string().url('Invalid URL (i.e https://contentstudio.io/replug/callback)'),
});

export const DeveloperAppModal = ({ isOpen, onClose, app, reload }: IDeveloperAppModalProps) => {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const form = useForm({
    initialValues: {
      _id: app ? app._id : '',
      name: app ? app.name : '',
      redirect: app ? app.redirect : '',
    },
    validate: zodResolver(schema),
  });

  const handleSubmit = () => {
    form.validate();
    if (form.isValid()) {
      new DeveloperAppService()
        .create(defaultWorkspace?._id || '', form.values)
        .then((res) => {
          if (res.status === 201) {
            notifications.show({
              color: 'green',
              message: 'Developer app created successfully',
            });
            reload();
            handleClose();
          }
        })
        .catch((err) => {
          notifications.show({
            color: 'red',
            message: err.response?.data?.message || 'An error occurred',
          });
        });
    }
  };

  const handleClose = () => {
    form.reset();
    onClose();
  };

  useEffect(() => {
    form.setValues({
      _id: app?._id || '',
      name: app?.name || '',
      redirect: app?.redirect || '',
    });
  }, [app]);

  return (
    <>
      <Modal
        size={600}
        opened={isOpen}
        onClose={onClose}
        title={<Title order={5}>Developer App</Title>}
      >
        <Box p={'md'}>
          <TextInput
            mb="md"
            label="App Name"
            variant="filled"
            placeholder="i.e ContentStudio"
            {...form.getInputProps('name')}
          />
          <TextInput
            mb="md"
            label="Redirect URL"
            variant="filled"
            placeholder="i.e https://contentstudio.io/replug/callback"
            value={form.values.redirect}
            onChange={(event) => {
              const url = ensureHttps(event.currentTarget.value);
              form.setFieldValue('redirect', url);
            }}
          />

          <Center>
            <Button variant="outline" color="gray" onClick={handleClose}>
              Cancel
            </Button>
            <Button ml={'sm'} color="primary" onClick={handleSubmit}>
              Save
            </Button>
          </Center>
        </Box>
      </Modal>
    </>
  );
};
