import { UserService } from '@/services/UserService';
import { useUserStore } from '@/stores/useUserStore';
import { faEnvelope, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Alert,
  Box,
  Button,
  Container,
  Flex,
  Modal,
  Space,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';

export function ProfileSave() {
  const [changeEmailLoading, setChangeEmailLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  const [user, setProfile, setChangeEmail] = useUserStore((state) => [
    state.user,
    state.setProfile,
    state.setChangeEmail,
  ]);
  const form = useForm({
    initialValues: {
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      email: user?.email || '',
    },

    validate: {
      first_name: (value) => (value.trim().length > 0 ? null : 'First name is required'),
      last_name: (value) => (value.trim().length > 0 ? null : 'Last name is required'),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const changeEmailForm = useForm({
    initialValues: {
      email: '',
      confirm_email: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      confirm_email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const saveProfile = async (formValues: any) => {
    setLoading(true);
    await new UserService()
      .updateProfileInformation({
        first_name: formValues.first_name,
        last_name: formValues.last_name,
      })
      .then((res) => {
        if (res.data.status) {
          if (formValues.first_name && formValues.last_name) {
            setProfile(formValues.first_name, formValues.last_name);
          }
          notifications.show({
            color: 'green',
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        notifications.show({
          color: 'red',
          message: err.response.data.message,
        });
      });

    setLoading(false);
  };

  const changeEmail = async (formValues: any) => {
    if (formValues.email !== formValues.confirm_email) {
      notifications.show({
        color: 'red',
        message: 'Email addresses do not match',
      });
      return;
    }
    setChangeEmailLoading(true);

    await new UserService()
      .changeEmail({
        email: formValues.email,
        workspace_id: defaultWorkspace?._id,
      })
      .then((res) => {
        if (res.data.status) {
          setChangeEmail(formValues.email);
          notifications.show({
            color: 'green',
            message: res.data.message,
          });
          close();
        }
      })
      .catch((err) => {
        notifications.show({
          color: 'red',
          message: err.response.data.message,
        });
      });
    setChangeEmailLoading(false);
  };

  const cancelChangeEmail = async () => {
    setCancelLoading(true);
    await new UserService()
      .cancelChangeEmailRequest()
      .then((res) => {
        if (res.data.status) {
          setChangeEmail('');
          notifications.show({
            color: 'green',
            message: res.data.message,
          });
          close();
        }
      })
      .catch((err) => {
        notifications.show({
          color: 'red',
          message: err.response.data.message,
        });
      });
    setCancelLoading(false);
  };

  const resendChangeEmail = async () => {
    setResendLoading(true);
    await new UserService()
      .resendChangeEmail(user?.change_email, defaultWorkspace?._id)
      .then((res) => {
        if (res.data.status) {
          notifications.show({
            color: 'green',
            message: res.data.message,
          });
          close();
        }
      })
      .catch((err) => {
        notifications.show({
          color: 'red',
          message: err.response.data.message,
        });
      });
    setResendLoading(false);
  };

  const closeHandle = () => {
    close()
    changeEmailForm.reset()
  }

  const theme = useMantineTheme();
  return (
    <>
      <Modal opened={opened} onClose={closeHandle} size={'lg'}>
        <Flex direction="column">
          <FontAwesomeIcon color={theme.colors.primary[9]} icon={faEnvelope} fontSize={'3rem'} />
          <Title order={4} px={16} mt={16} ta="center">
            Change email address
          </Title>
          <Text mt={8} px={64} size="sm" mb={16} ta="center">
            Enter your new email address below and we will send you a confirmation email.
          </Text>
          <Container pb={32} size="md">
            <form onSubmit={changeEmailForm.onSubmit(changeEmail)}>
              <TextInput
                variant="filled"
                mb={16}
                label="New email address"
                placeholder="Enter your new email address"
                {...changeEmailForm.getInputProps('email')}
              />
              <TextInput
                variant="filled"
                mb={16}
                label="Confirm email address"
                placeholder="Enter your new email address"
                {...changeEmailForm.getInputProps('confirm_email')}
              />
              <Button
                disabled={changeEmailLoading}
                loading={changeEmailLoading}
                type="submit"
                w={160}
                mr={10}
              >
                Change Email
              </Button>
              <Button onClick={close} w={160} variant="light">
                Cancel
              </Button>
            </form>
          </Container>
        </Flex>
      </Modal>

      <Box mr={16}>
        <form onSubmit={form.onSubmit(saveProfile)}>
          <TextInput
            mb={12}
            label="First Name"
            placeholder="e.g John"
            variant="filled"
            {...form.getInputProps('first_name')}
          />
          <TextInput
            mb={12}
            label="Last Name"
            placeholder="e.g Doe"
            variant="filled"
            {...form.getInputProps('last_name')}
          />
          <TextInput
            disabled
            mb={12}
            label="Email"
            placeholder="e.g Email"
            variant="filled"
            {...form.getInputProps('email')}
            rightSection={
              <ActionIcon variant="subtle" onClick={open}>
                <FontAwesomeIcon icon={faPencil} />
              </ActionIcon>
            }
          />
          <Button fullWidth type="submit" disabled={loading}>
            Save
          </Button>
        </form>
        {user?.change_email && (
          <Alert mt={16} color="blue" title="Email address not verified">
            <Text size="sm">
              We have sent a verification email to <strong>{user?.change_email}</strong>. Please
              check your inbox and verify your email address.
            </Text>
            <Flex pt={'sm'}>
              <Button disabled={resendLoading} loading={resendLoading} onClick={resendChangeEmail}>
                Resend email
              </Button>
              <Space w={8} />
              <Button
                disabled={cancelLoading}
                loading={cancelLoading}
                onClick={cancelChangeEmail}
                variant="outline"
                color="red"
              >
                Cancel
              </Button>
            </Flex>
          </Alert>
        )}
      </Box>
    </>
  );
}
