import {
  Box,
  Button,
  Center,
  Divider,
  Fieldset,
  Group,
  Image,
  MultiSelect,
  Stack,
  Text,
  TextInput,
  rem,
} from '@mantine/core';

import { RetargetingPixelModal } from '@/components/Modals/RetargetingPixelModal/RetargetingPixelModal';
import { UtilsService } from '@/services/UtilsService';
import { usePixelsStore } from '@/stores/usePixelsStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { faImage, faTimes, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';

export const BioSettingsSection = ({ form }: { form: any }) => {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  const [pixlels, fetchPixels, setOpened] = usePixelsStore((state) => [
    state.pixels,
    state.fetchPixels,
    state.setSetupModal,
  ]);
  const handleFileDrop = async (files: any, type: any) => {
    if (files && files.length > 0) {
      const formData = new FormData();
      formData.append('input_file', files[0]);
      setLoadingImage(true);
      await new UtilsService()
        .uploadImage(formData)
        .then((res) => {
          console.log(res.data);
          if (res.data.status) {
            if (type === 'favicon') {
              form.setFieldValue('favicon_url', res.data.url);
            } else {
              form.setFieldValue('meta.image', res.data.url);
            }
          }
        })
        .catch((err) => {
          if ((err.response.status === 400 ||  err.response.status ===  422) && err?.response?.data?.message) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
        });

      setLoadingImage(false);
      // You can now use formData to send the file data to your server or perform any other actions.
    }
  };

  const [loadingImage, setLoadingImage] = useState(false);

  useEffect(() => {
    fetchPixels(defaultWorkspace?._id || '');
  }, []);

  return (
    <>
      <RetargetingPixelModal />
      <Box p="md">
        <Fieldset
          mb={'md'}
          styles={{
            legend: {
              fontWeight: 600,
            },
          }}
          legend="Customize SEO and Social Media Preview"
        >
          <Text fz={14}>
            Add your custom meta title, description and image to improve the link presence in search
            engine and social media channels.
          </Text>
          <TextInput
            label="Title"
            variant="filled"
            mt={12}
            placeholder="Title"
            {...form.getInputProps('meta.title')}
          />
          <TextInput
            label="Description"
            variant="filled"
            mt={12}
            placeholder="Description"
            {...form.getInputProps('meta.description')}
          />
          {form.values.meta.image?.startsWith('http') && (
            <Center>
              <Image
                w={480}
                h={255}
                mt={12}
                fit="contain"
                style={{
                  borderRadius: rem(4),
                  border: `1px solid var(--mantine-color-gray-3)`,
                }}
                src={form.values.meta.image || ''}
                alt="Link Preview Image"
              />
            </Center>
          )}

          {form.values.meta.image?.startsWith('http') ? (
            <>
              <Center>
                <Button
                  variant="outline"
                  mt={12}
                  color="red"
                  onClick={() => {
                    form.setFieldValue('meta.image', '');
                  }}
                >
                  Remove Image
                </Button>
              </Center>
            </>
          ) : (
            <>
              <Dropzone
                mt={12}
                onDrop={(files) => handleFileDrop(files, 'meta_image')}
                onReject={(files) => console.log('rejected files', files)}
                maxSize={5 * 1024 ** 2}
                accept={IMAGE_MIME_TYPE}
              >
                <Group justify="center" gap="xl" mih={100} style={{ pointerEvents: 'none' }}>
                  <Dropzone.Accept>
                    <FontAwesomeIcon
                      color="gray.7"
                      size="sm"
                      icon={faUpload}
                      style={{
                        width: rem(32),
                        height: rem(32),
                        color: 'var(--mantine-color-blue-6)',
                      }}
                    />
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <FontAwesomeIcon
                      color="gray.7"
                      size="sm"
                      icon={faTimes}
                      style={{
                        width: rem(32),
                        height: rem(32),
                        color: 'var(--mantine-color-red-6)',
                      }}
                    />
                  </Dropzone.Reject>
                  <Dropzone.Idle>
                    <FontAwesomeIcon
                      color="gray.7"
                      size="sm"
                      icon={faImage}
                      style={{
                        width: rem(32),
                        height: rem(32),
                        color: 'var(--mantine-color-gray-4)',
                      }}
                    />
                  </Dropzone.Idle>

                  <div>
                    <Text size="sm" inline>
                      Drag image here or click to select file.
                    </Text>
                  </div>
                </Group>
              </Dropzone>
            </>
          )}
        </Fieldset>

        <Fieldset
          styles={{
            legend: {
              fontWeight: 600,
            },
          }}
          mb={'md'}
          legend={'Add your custom favicon image.'}
        >
          <>
            {form.values.favicon_url?.startsWith('http') && (
              <Center>
                <Image
                  h={16}
                  w={16}
                  mt={12}
                  fit="contain"
                  style={{
                    borderRadius: rem(4),
                    border: `1px solid var(--mantine-color-gray-3)`,
                  }}
                  src={form.values.favicon_url || ''}
                  alt="Link Preview Image"
                />
              </Center>
            )}

            {form.values.favicon_url?.startsWith('http') ? (
              <>
                <Center>
                  <Button
                    variant="outline"
                    mt={12}
                    color="red"
                    onClick={() => {
                      form.setFieldValue('favicon_url', '');
                    }}
                  >
                    Remove Image
                  </Button>
                </Center>
              </>
            ) : (
              <>
                <Dropzone
                  mt={12}
                  onDrop={(files) => handleFileDrop(files, 'favicon')}
                  onReject={(files) => console.log('rejected files', files)}
                  maxSize={5 * 1024 ** 2}
                  accept={IMAGE_MIME_TYPE}
                >
                  <Group justify="center" gap="xl" mih={100} style={{ pointerEvents: 'none' }}>
                    <Dropzone.Accept>
                      <FontAwesomeIcon
                        color="gray.7"
                        size="sm"
                        icon={faUpload}
                        style={{
                          width: rem(32),
                          height: rem(32),
                          color: 'var(--mantine-color-blue-6)',
                        }}
                      />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                      <FontAwesomeIcon
                        color="gray.7"
                        size="sm"
                        icon={faTimes}
                        style={{
                          width: rem(32),
                          height: rem(32),
                          color: 'var(--mantine-color-red-6)',
                        }}
                      />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                      <FontAwesomeIcon
                        color="gray.7"
                        size="sm"
                        icon={faImage}
                        style={{
                          width: rem(32),
                          height: rem(32),
                          color: 'var(--mantine-color-gray-4)',
                        }}
                      />
                    </Dropzone.Idle>

                    <div>
                      <Text size="sm" inline>
                        Drag image here or click to select file.
                      </Text>
                    </div>
                  </Group>
                </Dropzone>
              </>
            )}
          </>
        </Fieldset>

        <Fieldset
          styles={{
            legend: {
              fontWeight: 600,
            },
          }}
          legend={'Tracking pixels'}
        >
          <Text fz={14}>
            Tracking pixels makes conversion tracking, optimization and remarketing easier than
            ever. Once placed on your page, you will be able to measure cross device conversions,
            create lookalike audiences, optimize delivery to people that likely to take action and
            much more.
          </Text>
          <Stack p={24}>
            <MultiSelect
              data={pixlels.map((pixel) => {
                return { label: pixel.name, value: pixel._id };
              })}
              placeholder="Select a retargeting pixel"
              {...form.getInputProps('retargeting_ids')}
            />
            <Divider color="gray.5" label="OR" />
            <Button variant="outline" color="primary" onClick={() => setOpened(true)}>
              Create a new Retargeting Pixel
            </Button>
          </Stack>
        </Fieldset>
      </Box>
    </>
  );
};
