import { IPermissions } from '@/types/permissions';
import axios from '../services/JWTService';
import { create } from 'zustand';


interface IPermissionsStore {
  defaultPermissions: IPermissions | null;
  setDefaultPermissions: (workspaceId: string) => void;
}

export const usePermissionsStore = create<IPermissionsStore>((set) => ({
  defaultPermissions: null,
  setDefaultPermissions: (workspaceId: string) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/workspace/${workspaceId}/miscellaneous/get_permissions`)
        .then((response: any) => {
          let permissions = response?.data?.permissions
          set({ defaultPermissions: permissions })
        })
        .catch((error: any) => {
          console.log(error);
          reject(error);
        });
    });
  },
}));
