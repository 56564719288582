import { Box, Divider, Grid, Space } from '@mantine/core';
import { DashboardAnalytics } from './DashboardAnalytics';
import { DashboardChromeExtension } from './DashboardChromeExtension';
import { DashboardContact } from './DashboardContact';
import { DashboardInfoCarousel } from './DashboardInfoCarousel';
import { DashboardBioLinkCard } from './DashboardBioLinkCard';
import { DashboardLinkCreation } from './DashboardLinkCreation';
import { DashboardLinks } from './DashboardLinks';
import { DashboardVideos } from './DashboardVideos';
import { useDocumentTitle } from '@mantine/hooks';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { useUserStore } from '@/stores/useUserStore';
import { useEffect, useState } from 'react';
import { UserService } from '@/services/UserService';
import { ConfettiComponent } from './confeti';
import { WelcomeModal } from "@/components/Modals/WelcomeModal/WelcomeModal";

export function DashboardPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [user] = useUserStore((state) => [state.user]);
  const [setUser, updateUser] = useUserStore((state) => [state.setUser, state.updateUser]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [welcomeModal, setWelcomeModal] = useState(false)

  useEffect(() => {
    async function fetchUser() {
      if (user) {
        console.log('user', user.v2_onboarding);
        if (user.v2_onboarding === false || user.v2_onboarding === undefined) {
          setShowConfetti(true);
          setWelcomeModal(true);
          try {
            const response = await new UserService().setUserOnBoardingTrue(user._id);
            if (response.data.status) {
              updateUser({ v2_onboarding: true });
            } else {
              console.log('Error setting new user to false');
            }
          } catch (error) {
            console.debug(error);
          }
        }
      }
    }
    fetchUser();
  }, [user]);
  useDocumentTitle('Dashboard | ' + defaultWorkspace?.agency?.name);

  return (
    <>
      <Grid gutter={'md'} px={'sm'}>
        <Grid.Col span={9}>
          <Grid>
            <Grid.Col span={9}>
              <DashboardLinkCreation />
            </Grid.Col>
            <Grid.Col span={3}>
              <Box
                h={200}
                style={{
                  borderRadius: '0.25rem',
                  backgroundColor: 'var(--mantine-color-white)',
                }}
              >
                <DashboardBioLinkCard />
              </Box>
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={3}>
          <Box
            h={200}
            style={{
              borderRadius: '0.25rem',
              backgroundColor: 'var(--mantine-color-white)',
            }}
          >
            {showConfetti && <><ConfettiComponent />
              <WelcomeModal
                opened={welcomeModal}
                setOpened={setWelcomeModal}
              />
              </>}
            <DashboardInfoCarousel />
          </Box>
        </Grid.Col>
        <Grid.Col span={9}>
          <DashboardLinks />
        </Grid.Col>
        <Grid.Col span={3}>
          <DashboardAnalytics />
          <Space my={16} c="transparent" />
          {!defaultWorkspace?.agency?.is_whitelabeled && <DashboardContact />}
        </Grid.Col>
        {/* <Grid.Col span={3}>
          <DashboardChromeExtension />
        </Grid.Col>
        <Grid.Col span={6}>
          <DashboardVideos />
        </Grid.Col> */}
      </Grid>
    </>
  );
}
