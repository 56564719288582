import { DEFAULT_CARD_BLOCK_VALUES, IBioCardBlock } from '@replug/utils';
import { create } from 'zustand';

interface IBioCardBlockStore {
  bioCardBlock: IBioCardBlock;
  setBioCardBlock: (bioCardBlock: IBioCardBlock) => void;
  loader: boolean;
  setLoader: (loader: boolean) => void;
  reset: () => void;
}

export const useBioCardBlockStore = create<IBioCardBlockStore>((set, get) => ({
  bioCardBlock: DEFAULT_CARD_BLOCK_VALUES,
  setBioCardBlock: (bioCardBlock: IBioCardBlock) => {
    set({ bioCardBlock });
  },
  loader: false,
  setLoader: (loader: boolean) => {
    set({ loader });
  },
  reset: () => {
    set({ bioCardBlock: DEFAULT_CARD_BLOCK_VALUES });
  },
}));
