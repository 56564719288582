import {
  Banner,
  BridgePage,
  ExitIntent,
  Popup,
  ScrollBox,
  SocialClassic,
  SocialModern,
} from '@replug/ui';

import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { Box, Flex, Grid, Skeleton } from '@mantine/core';
import { CallToActionVariantsEnum } from '@replug/utils';
import { useForm } from '@mantine/form';

const child = (
  <Skeleton
    styles={{
      root: {
        background: '#fafafa',
      },
    }}
    style={{}}
    height={95}
    radius="md"
    animate={false}
  />
);
export const CampaignPreview = ({ form }: { form: any }) => {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  const optinForm = useForm({
    initialValues: {
      full_name: '',
      email: '',
      agreements: [],
    },

    validate: {
      full_name: (value) => (!form.values.call_to_action?.name_field || value ? null : 'Please enter your full name'),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Please enter a valid email address')
    },
  });

  const handleFormSubmit = (values: any) => {
    console.log(values);
  };

  const CALL_TO_ACTIONS_PREVIEWS = [
    {
      type: CallToActionVariantsEnum.SocialClassic,
      content: <SocialClassic values={form.values} workspace={defaultWorkspace} optinForm={optinForm} />,
    },
    {
      type: CallToActionVariantsEnum.SocialModern,
      content: <SocialModern values={form.values} workspace={defaultWorkspace} optinForm={optinForm} />,
    },
    {
      type: CallToActionVariantsEnum.Banner,
      content: <Banner values={form.values} workspace={defaultWorkspace} optinForm={optinForm} />,
    },
    {
      type: CallToActionVariantsEnum.ExitIntent,
      content: <ExitIntent values={form.values} workspace={defaultWorkspace} optinForm={optinForm} />,
    },
    {
      type: CallToActionVariantsEnum.Popup,
      content: <Popup values={form.values} workspace={defaultWorkspace} optinForm={optinForm} />,
    },
    {
      type: CallToActionVariantsEnum.ScrollBox,
      content: <ScrollBox values={form.values} workspace={defaultWorkspace} optinForm={optinForm} />,
    },
    {
      type: CallToActionVariantsEnum.BridgePage,
      content: <BridgePage values={form.values} workspace={defaultWorkspace} optinForm={optinForm} />,
    },
  ];

  const getActiveCTAPreview = () => {
    if (!form.values.is_call_to_action_enabled) {
      return <></>;
    }
    const activeCTAPreview = CALL_TO_ACTIONS_PREVIEWS.find(
      (cta) => cta.type === form.values.call_to_action.variant
    );
    console.log(activeCTAPreview);
    return activeCTAPreview?.content || null;
  };

  if (form.values.call_to_action.variant === CallToActionVariantsEnum.BridgePage) {
    return (
      <>
        <Box
          component="div"
          style={{
            position: 'relative',
            height: '100%',
            width: '100%',
            background: '#fafafa',
          }}
        >
          <form onSubmit={optinForm.onSubmit(handleFormSubmit)}>
            {getActiveCTAPreview()}
          </form>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box
        p={'md'}
        style={{
          borderRadius: '0.5rem',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          position: 'relative',
        }}
        bg={'white'}
      >
        <Flex>
          <Box
            h={12}
            w={12}
            bg="red.5"
            style={{
              borderRadius: '50%',
            }}
          />
          <Box
            ml={8}
            h={12}
            w={12}
            bg="orange.5"
            style={{
              borderRadius: '50%',
            }}
          />
          <Box
            ml={8}
            h={12}
            w={12}
            bg="green.5"
            style={{
              borderRadius: '50%',
            }}
          />
        </Flex>
        <Grid py={'24'}>
          <Grid.Col span={{ base: 12, xs: 4 }}>{child}</Grid.Col>
          <Grid.Col span={{ base: 12, xs: 8 }}>{child}</Grid.Col>
          <Grid.Col span={{ base: 12, xs: 8 }}>{child}</Grid.Col>
          <Grid.Col span={{ base: 12, xs: 4 }}>{child}</Grid.Col>
          <Grid.Col span={{ base: 12, xs: 3 }}>{child}</Grid.Col>
          <Grid.Col span={{ base: 12, xs: 3 }}>{child}</Grid.Col>
          <Grid.Col span={{ base: 12, xs: 6 }}>{child}</Grid.Col>
          <Grid.Col span={{ base: 12, xs: 8 }}>{child}</Grid.Col>
          <Grid.Col span={{ base: 12, xs: 4 }}>{child}</Grid.Col>
          <Grid.Col span={{ base: 12, xs: 12 }}>{child}</Grid.Col>
        </Grid>

        <form onSubmit={optinForm.onSubmit(handleFormSubmit)}>
          {getActiveCTAPreview()}
        </form>
      </Box>
    </>
  );
};
