import { IpAddressService } from '@/services/IpAddressService';
import { useIpAddressStore } from '@/stores/useIpAddressStore';
import { faPencil, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Box,
  Button,
  Chip,
  Divider,
  Flex,
  Grid,
  Space,
  Title,
  Text,
  Alert,
  List,
  ListItem,
  Popover,
  Group,
  TextInput,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import classes from './WorkspaceSettings.module.css';
import { modals } from '@mantine/modals';
import { useState } from 'react';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { useForm } from '@mantine/form';
import { useDocumentTitle } from '@mantine/hooks';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isUserCollaborator } from '@/utils/CommonUtility';
import { useUserStore } from '@/stores/useUserStore';

const EditIp = ({ id, value }: { id: string; value: string }) => {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [ipAddresses, setIpAddress] = useIpAddressStore((state) => [state.ipAddresses, state.setIpAddress]);
  const [opened, setOpened] = useState(false);
  const [ipAddress, setAddress] = useState(value);
  const [ipAddressIds, setIpAddressIds] = useState(id);
  const [loading, setLoading] = useState(false);
  const [user] = useUserStore((state) => [state.user]);
  const form = useForm({
    initialValues: {
      _id: ipAddressIds,
      ip_address: ipAddress,
    },
    validate: {
      ip_address: (v: string) => (v.trim().length > 0 ? null : 'Ip address is required'),
    },
  });

  const handleAdressEdit = async (formValues: any) => {
    const payload = {
      ...formValues,
      workspace_id: defaultWorkspace?._id,
    };
    await new IpAddressService()
      .update(defaultWorkspace?._id || '', payload)
      .then((res) => {
        if (res.data.status) {
          // Remove the item from the list
          notifications.show({
            color: 'green',
            message: 'Ip Address has been updated.',
          });

          // find and update the tag in the list
          const newList = ipAddresses.map((item: any) => {
            if (item._id === ipAddressIds) {
              return res.data.data;
            }
            return item;
          });
          setIpAddress(newList);
          setOpened(false);
        } else {
          notifications.show({
            color: 'red',
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 422 || 400) {
          if (err.response?.data?.errors['ip_address'] && err.response.data.errors['ip_address'].length > 0) {
            err.response.data.errors['ip_address'].forEach((error : string, key : string) => {
              notifications.show({
                color: 'red',
                message: error,
              });
            })
          }
        }
        console.log(err);
      });
  };

  return (
    <>
      <Popover opened={opened} onClose={() => setOpened(false)}>
        <Popover.Target>
          <ActionIcon variant="subtle" size="xs" ml={8} onClick={() => setOpened((o) => !o)}>
            <FontAwesomeIcon color="gray" size="xs" icon={faPencil} />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <form onSubmit={form.onSubmit(handleAdressEdit)}>
            <Group>
              <TextInput size="xs" {...form.getInputProps('ip_address')} placeholder="Ip Address" />

              <Button loading={loading} disabled={loading} size="xs" type="submit">
                Update
              </Button>
            </Group>
          </form>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};

export function IpAddressPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  useDocumentTitle('Tags | ' + defaultWorkspace?.agency?.name);

  const [ipAddresses, setIpAddress] = useIpAddressStore((state) => [state.ipAddresses, state.setIpAddress]);
  const [ipAddessSelected, setIpAddessSelected] = useState<any[]>([]);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [user] = useUserStore((state) => [state.user]);
  const createForm = useForm({
    initialValues: {
      _id: null,
      ip_address: '',
      workspace_id: defaultWorkspace?._id,
    },
    validate: {
      ip_address: (v: string) => (v.trim().length > 0 ? null : 'Ip address is required'),
    },
  });

  const handleDeleteAddress = async (id: string) => {
    await new IpAddressService()
      .delete(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          // Remove the item from the list
          const newList = ipAddresses.filter((item: any) => item._id !== id);
          setIpAddress(newList);
          notifications.show({
            color: 'green',
            message: 'Ip address has been deleted.',
          });
        } else {
          notifications.show({
            color: 'red',
            message: 'Something went wrong. Please contact support',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   *  Function to confirm the deletion of a Tag.
   * @param id - The id of the Tag to be deleted.
   */
  const confirmAddressDelete = async (id: string) => {
    modals.openConfirmModal({
      title: <Title order={5}>Delete Ip Address</Title>,
      children: (
        <>
          <Text size="sm">Are you sure? This process can not be undone.</Text>
        </>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleDeleteAddress(id),
    });
  };

  // handle tag creation

  const handleIpAddressCreation = async (formValues: any) => {
    setLoading(true);
    await new IpAddressService()
      .create(defaultWorkspace?._id || '', formValues)
      .then((res) => {
        if (res.data.status) {
          // Remove the item from the list
          const newList = [...ipAddresses, res.data.data];
          setIpAddress(newList);
          notifications.show({
            color: 'green',
            message: 'Ip Address has been created.',
          });
          createForm.reset();
          setOpened(false);
        } else {
          notifications.show({
            color: 'red',
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 422 || 400) {
          if (err.response?.data?.errors['ip_address'] && err.response.data.errors['ip_address'].length > 0) {
            err.response.data.errors['ip_address'].forEach((error : string, key : string) => {
              notifications.show({
                color: 'red',
                message: error,
              });
            })
          }
        }
        console.log(err);
      });
    setLoading(false);
  };

  return (
    <Box
      style={{
        backgroundColor: 'var(--mantine-color-white)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        height: '100%',
        flexGrow: 1,
      }}
      ml={16}
      mr={16}
    >
      <Grid p={16}>
        <Grid.Col span={5}>
          <Popover
            shadow="sm"
            opened={opened}
            onClose={() => setOpened(false)}
            position="bottom"
            withArrow
          >
            {defaultPermissions && checkPermission(defaultPermissions, ['add_ip']) && <Popover.Target>
              <Button size="xs" onClick={() => setOpened((o) => !o)}>
                Add Ip Address
              </Button>
            </Popover.Target>}
            <Popover.Dropdown>
              <form onSubmit={createForm.onSubmit(handleIpAddressCreation)}>
                <Group>
                  <TextInput
                    size="xs"
                    {...createForm.getInputProps('ip_address')}
                    placeholder="Ip Address"
                  />

                  <Button loading={loading} disabled={loading} size="xs" type="submit">
                    Create
                  </Button>
                </Group>
              </form>
            </Popover.Dropdown>
          </Popover>
        </Grid.Col>
        <Grid.Col span={2}>
          <Flex align={'center'}>
            <Title pt={2} order={5} mr={8}>
              Ip Address
            </Title>
          </Flex>
        </Grid.Col>
        <Grid.Col span={5}>
        </Grid.Col>
      </Grid>
      <Divider color="gray.2" />
      <Box p={16}>
        {ipAddresses.length === 0 ? (
          <Text fz={14} mt={32} ta="center" c="dimmed">
            You do not have created any ip addresses.
          </Text>
        ) : (
          <>
            <Flex wrap={'wrap'}>
              {ipAddresses.map((Ip_address: any, index) => (
                <>
                  <Chip
                    mb={12}
                    className={classes.tag}
                    color={'gray.1'}
                    size="sm"
                    key={index}
                  >
                    <Flex align={'center'} className={classes.tagActions}>
                      {Ip_address.ip_address}
                      {defaultPermissions && checkPermission(defaultPermissions, ['edit_ip']) && isUserCollaborator(user, defaultWorkspace?._id, Ip_address, 'IpAddress') && <EditIp id={Ip_address._id} value={Ip_address.ip_address} />}
                      <Space w={2} />
                      {defaultPermissions && checkPermission(defaultPermissions, ['delete_ip']) && isUserCollaborator(user, defaultWorkspace?._id, Ip_address, 'IpAddresstag') && <ActionIcon
                        onClick={() => confirmAddressDelete(Ip_address._id)}
                        variant="subtle"
                        size="xs"
                      >
                        <FontAwesomeIcon color={'red'} size="xs" icon={faTrash} />
                      </ActionIcon>}
                    </Flex>
                  </Chip>
                </>
              ))}
            </Flex>
          </>
        )}
      </Box>
    </Box>
  );
}
