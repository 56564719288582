import axios from './JWTService';

export class CampaignsService {
  getAll = (workspace_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`workspace/${workspace_id}/campaign/get?limit=100`)
        .then((response) => {
          const data = response?.data?.data?.data;
          const res: any = data.map((item: any) => {
            return {
              _id: item._id,
              name: item.name,
              is_default: item.is_default,
              type: item.type,
              is_call_to_action_enabled: item.is_call_to_action_enabled,
              is_retargeting_enabled: item.is_retargeting_enabled,
              is_custom_widget_enabled: item.is_custom_widget_enabled,
            };
          });
          resolve(res);
        })
        .catch((error) => reject(error));
    });
  };

  get = (
    workspace_id: string,
    search: string,
    date: string,
    archive: boolean,
    page: number,
    limit: number = 10
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `workspace/${workspace_id}/campaign/get?search=${search}&date=${date}&sort=created_at:desc&archive=${archive}&page=${page}&limit=${limit}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  getById = (workspace_id: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`workspace/${workspace_id}/campaign/${id}/get_by_id`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  delete = (workspace_id: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`workspace/${workspace_id}/campaign/${id}/delete`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  archive = (workspace_id: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`workspace/${workspace_id}/campaign/${id}/archive`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  unarchive = (workspace_id: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`workspace/${workspace_id}/campaign/${id}/unarchive`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  store = (workspace_id: string, form: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`workspace/${workspace_id}/campaign/store`, form)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  getLinksbyCampaignId = (workspace_id: string, campaign_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`workspace/${workspace_id}/campaign/${campaign_id}/get_links`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  setDefault = (workspace_id: string, campaign_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`workspace/${workspace_id}/campaign/${campaign_id}/set_default`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  duplicate = (workspace_id: string, campaign_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`workspace/${workspace_id}/campaign/${campaign_id}/duplicate`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
