import { CAMPAIGN_COLUMNS } from '@/constants/Constants';
import { useCampaignColumnsStore } from '@/stores/useCampaignColumnsStore';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, Flex, Menu, Popover } from '@mantine/core';

export function CampaignsColumnsMenu() {
  const { visibleColumns, toggleColumnVisibility } = useCampaignColumnsStore();

  return (
    <>
      <Popover withArrow>
        <Popover.Target>
          <Button
            variant="outline"
            color="gray"
            size="xs"
            rightSection={<FontAwesomeIcon icon={faChevronDown} />}
          >
            Hide/Show Columns
          </Button>
        </Popover.Target>
        <Popover.Dropdown>
          {CAMPAIGN_COLUMNS.filter((c) => !c.is_disabled).map((column) => (
            <Flex
              key={column.accessor}
              align={'center'} // Make sure to have a unique key for each item
              fz={'xs'}
              style={{
                cursor: 'pointer',
              }}
              // onClick={(e) => {
              //   e.preventDefault();
              //   e.stopPropagation();
              //   toggleColumnVisibility(column.accessor);
              // }}
              py={6}
            >
              <Checkbox
                mb={2}
                size="xs"
                styles={{
                  input: {
                    cursor: 'pointer',
                  },
                  label: {
                    cursor: 'pointer',
                  },
                }}
                label={column.label}
                checked={visibleColumns.includes(column.accessor)}
                onChange={(e) => {
                  // e.stopPropagation();
                  // e.preventDefault();
                  toggleColumnVisibility(column.accessor);
                }}
                // Prevent menu from closing on click
                // onClick={(event) => event.stopPropagation()}
              />
            </Flex>
          ))}
        </Popover.Dropdown>
      </Popover>
    </>
  );
}
