import React, { useState } from 'react';
import { Box, Flex, Text, Button, Badge, Title, Container, Space, SegmentedControl, Modal, LoadingOverlay, Select } from '@mantine/core';
import { getPaddlePlanId, getPlanTrialName } from '@/utils/CommonUtility';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { usePlanStore } from '@/stores/usePlanStore';
import { useUserStore } from '@/stores/useUserStore';
import { notifications } from '@mantine/notifications';
import { PlanService } from '@/services/PlanService';
import { modals } from '@mantine/modals';


interface IPlans {
  isOpen: boolean;
  primarySubscription: any;
  showTrialPlans: boolean;
  currentPlanId: string | undefined;
  disabledAccountMessage?: string;
  isLifetimeUser?: boolean;
  onClose: () => void;
  fetchSubscriptions: (workspaceId: string, organizationId: string) => void;
}


export const PlansModal = ({ isOpen, onClose, currentPlanId, primarySubscription, showTrialPlans, disabledAccountMessage, isLifetimeUser, fetchSubscriptions }: IPlans) => {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [plan, fetchPlan] = usePlanStore((state) => [state.plan, state.fetchPlan]);
  console.log('currentPlanId', plan);
  
  const [user] = useUserStore((state) => [state.user]);
  const [upgradeLoading, setUpgradeLoading] = useState(false);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState('10');

  const getAgencyPricing = (workspaces: string, isAnnual: boolean, originalPrce: boolean = false) => {
    let price: Number = 79;
    switch (workspaces) {
      case '20':
        price = 149;
        break;
      case '50':
        price = 299;
        break;
      case '100':
        price = 499;
        break;
      default:
        price = 79;
        break;
    }
    if (originalPrce) return price
    //@ts-ignore
    return isAnnual ? Math.round((price * 0.8).toFixed(1)) : price
  };

  const monthlyPlans = [
    {
      title: 'Basic',
      trial_name: 'basic-trial', // trial name
      name: 'basic-monthly',
      price: '$19',
      basis_price: 19,
      period: '/mo',
      credits: [
        '10,000 clicks per month',
        '1 workspace',
        '5 bio links',
        '5 campaigns',
        '1 retargeting pixel'
      ],
      features: [
        'Unlimited links',
        'Call-to-actions (CTAs)',
        'UTM builder',
        'QR code generation',
        'Custom slug / Short link',
        'Bulk link shortening'
      ]
    },
    {
      title: 'Professional',
      trial_name: 'professional-trial',
      name: 'professional-monthly',
      price: '$49',
      basis_price: 49,
      period: '/mo',
      credits: [
        '50,000 clicks per month',
        '5 workspaces',
        '25 bio links',
        'Unlimited campaigns',
        'Unlimited retargeting pixels'
      ],
      features: [
        'Everything in Basic, plus:',
        'Team access',
        'Branded link shortening',
        'Custom domains',
        'A/B testing',
        'Traffic routing / Link rotation',
        'Remove Replug Branding'
      ]
    },
    {
      title: 'Agency',
      trial_name: 'agency-trial',
      name: 'agency-monthly',
      price: (workspaces: string) => `$${getAgencyPricing(workspaces, false)}`,
      basis_price: (workspaces: string) => getAgencyPricing(workspaces, false),
      period: '/mo',
      credits: (workspaces: number) => [
        'Unlimited clicks per month',
        `${workspaces} workspaces`,
        `${workspaces * 5} bio links`,
        'Unlimited campaigns',
        'Unlimited retargeting pixels'
      ],
      features: [
        'Everything in Professional, plus:',
        'Deep-linking',
        'White-label',
        'API access',
        'Dedicated support',
        'Onboarding & call support'
      ]
    }
  ]


  const annualPlans = [
    {
      title: 'Basic',
      name: 'basic-yearly',
      price: '$15',
      basis_price: 19,
      period: '/mo',
      credits: [
        '10,000 clicks per month',
        '1 workspace',
        '5 bio links',
        '5 campaigns',
        '1 retargeting pixel'
      ],
      features: [
        'Unlimited links',
        'Call-to-actions (CTAs)',
        'UTM builder',
        'QR code generation',
        'Custom slug / Short link',
        'Bulk link shortening'
      ]
    },
    {
      title: 'Professional',
      name: 'professional-yearly',
      price: '$39',
      basis_price: 49,
      period: '/mo',
      credits: [
        '50,000 clicks per month',
        '5 workspaces',
        '25 bio links',
        'Unlimited campaigns',
        'Unlimited retargeting pixels'
      ],
      features: [
        'Everything in Basic, plus:',
        'Team access',
        'Branded link shortening',
        'Custom domains',
        'A/B testing',
        'Traffic routing / Link rotation',
        'Remove Replug Branding'
      ]
    },
    {
      title: 'Agency',
      name: 'agency-yearly',
      price: (workspaces: string) => `$${getAgencyPricing(workspaces, true)}`,
      basis_price: (workspaces: string) => getAgencyPricing(workspaces, true, true),
      period: '/mo',
      credits: (workspaces: number) => [
        'Unlimited clicks per month',
        `${workspaces} workspaces`,
        `${workspaces * 5} bio links`,
        'Unlimited campaigns',
        'Unlimited retargeting pixels'
      ],
      features: [
        'Everything in Professional, plus:',
        'Deep-linking',
        'White-label',
        'API access',
        'Dedicated support',
        'Onboarding & call support'
      ]
    }
  ];

  const [planType, setPlanType] = useState('monthly');
  const plans = showTrialPlans
    ? monthlyPlans
    : (planType === 'monthly' ? monthlyPlans : annualPlans);


  // Modified to handle dynamic pricing for Agency plan
  const getPrice = (plan: any) => {
    if (typeof plan.price === 'function') {
      return plan.price(selectedWorkspaces);
    }
    return plan.price;
  };

  const getBasisPrice = (plan: any) => {
    if (typeof plan.basis_price === 'function') {
      return plan.basis_price(selectedWorkspaces);
    }
    return plan.basis_price;
  };


  const getCredits = (plan: any) => {
    if (typeof plan.credits === 'function') {
      return plan.credits(selectedWorkspaces);
    }
    return plan.credits;
  };

  const isCurrentPlan = (subscriptionPlan: any) => {
    if (showTrialPlans) {
      const planName = getPlanTrialName(subscriptionPlan, selectedWorkspaces);
      console.log(planName, subscriptionPlan.trial_name, plan?.name, subscriptionPlan.trial_name == plan?.name);
      
      return planName == plan?.name
    }
    return Number(currentPlanId) === getPaddlePlanId(subscriptionPlan, selectedWorkspaces);
  }

  /**
   * Check if current usage exceeds the limits of the selected plan
   * @param workspace 
   * @param plan_name  selected plan name
   * @returns 
   */
  const hasExceededPlanLimits = async (workspace: any, plan_name: string) => {

    // check plan limits before upgrading
    return await new PlanService().checkPlanLimits(workspace._id, workspace.organization_id, plan_name).then((response) => {
      if (response.data?.status && response.data?.message && (response.data?.whitelabel_error || response.data?.is_exceeding_limits)) {
        notifications.show({
          color: 'red',
          message: response.data?.message,
        });
        return true;
      }
    }).catch((error) => {
      console.error('Error checking plan limits', error)
      return false;
    })

    return false;
  }


  const changeTrialPlan = async (plan: any) => {
    if (!defaultWorkspace || !user) {
      alert("Something went wrong. Please try again later.")
      return;
    }

    // check plan limits before upgrading
    if (await hasExceededPlanLimits(defaultWorkspace, plan.trial_name)) {
      setUpgradeLoading(false);
      return;
    }

    const planName = getPlanTrialName(plan, selectedWorkspaces);

    new PlanService().changeTrialPlan(defaultWorkspace?._id, defaultWorkspace?.organization_id, planName).then((response) => {
      if (response.data?.status) {
        notifications.show({
          color: 'green',
          message: 'Trial plan has been changed successfully.',
        });
        fetchPlan(defaultWorkspace._id, defaultWorkspace.organization_id);
        onClose();
      }
    }).catch((error) => {
      console.error('Error changing trial plan', error)
    });
  }

  const showLTDUpgradeConfirmModal = () => {
    return new Promise((resolve, reject) => {
      modals.openConfirmModal({
        title: <Title order={5}>Confirm Lifetime Plan Upgrade</Title>,
        children: (
          <>
            <Text size="sm">By upgrading to this plan, Your current lifetime plan will be replaced with this plan.</Text>
          </>
        ),
        labels: { confirm: 'Confirm', cancel: 'Cancel' },
        confirmProps: { color: 'red' },
        onCancel: () => {
          setUpgradeLoading(false);
          reject(false);
        },
        onConfirm: () => {
          resolve(true);
        }
      });
    });
  };

  /**
   * Upgrade plan
   * @param plan 
   */
  const onPlanSelect = async (plan: any) => {
    console.log('plan', plan);
    
    if (showTrialPlans) {
      changeTrialPlan(plan);
      return;
    }

    const planId = getPaddlePlanId(plan, selectedWorkspaces);
    if (!defaultWorkspace || !user) {
      alert("Something went wrong. Please try again later.")
      return;
    }

    if (primarySubscription?.status == 'paused') {
      notifications.show({
        color: 'red',
        message: 'Subscription is paused. Please resume subscription to upgrade plan.',
      });
      return;
    }


    setUpgradeLoading(true);

    // check plan limits before upgrading
    if (await hasExceededPlanLimits(defaultWorkspace, plan.name)) {
      setUpgradeLoading(false);
      return false;
    }


    // upgrade plan incase of active subscription
    if (primarySubscription?.status == 'active') {
      await new PlanService().upgradePlan(defaultWorkspace._id, defaultWorkspace.organization_id, planId, primarySubscription.subscription_id).then((response) => {
        if (response.data?.status) {
          notifications.show({
            color: 'green',
            message: 'Plan has been upgraded successfully.',
          });
          setTimeout(() => {
            fetchPlan(defaultWorkspace._id, defaultWorkspace.organization_id);
            fetchSubscriptions(defaultWorkspace._id, defaultWorkspace.organization_id);
          }, 3000);
        } else {
          notifications.show({
            color: 'red',
            message: response.data?.message || 'Error upgrading plan. Please try again later.',
          });
        }
      }).catch((error) => {
        console.error('Error upgrading plan', error)
        notifications.show({
          color: 'red',
          message: error?.response?.data?.message || 'Error upgrading plan. Please try again later.',
        });
      });
      setUpgradeLoading(false);
      onClose();
      return;
    }

    if (isLifetimeUser) {
      try {
        await showLTDUpgradeConfirmModal();
        // User confirmed, proceed with the upgrade
      } catch {
        // User canceled, stop the flow
        return;
      }
    }


    // Upgrade plan for new subscription
    const passthrough = {
      organization_id: defaultWorkspace.organization_id,
      workspace_id: defaultWorkspace._id,
      user_id: user._id,
    };

    // @ts-ignore open paddle checkout
    Paddle.Checkout.open({
      product: planId,
      email: user?.email,
      disableLogout: true,
      coupon: isLifetimeUser ? 'LTDUPGRADE50%OFF' : (plan?.name?.includes('yearly') ? '20OFFANNUAL' : ''),
      passthrough: JSON.stringify(passthrough),
      successCallback: function (data: any) {
        notifications.show({
          color: 'green',
          message: 'Plan has been upgraded successfully.',
        });
        setTimeout(() => {
          fetchPlan(defaultWorkspace._id, defaultWorkspace.organization_id);
          fetchSubscriptions(defaultWorkspace._id, defaultWorkspace.organization_id);
        }, 3000);
        onClose();
      },
      closeCallback: function () {
        onClose();
      }
    });
    setUpgradeLoading(false);
  };


  const getAnnualTotal = (monthlyPrice: string) => {
    const monthly = parseInt(monthlyPrice.replace('$', ''));
    return Math.round(monthly * 12);
  };

  const getDiscountedPrice = (plan: any) => {
    if (!isLifetimeUser) return getPrice(plan);
    const numericPrice = getBasisPrice(plan);
    const discountedPrice = Math.floor(numericPrice * 0.5);
    return `$${Math.floor(discountedPrice)}`;
  };

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      size={1200}
      centered
    >
      <Container>
        <LoadingOverlay visible={upgradeLoading} />
        {disabledAccountMessage && (
          <Flex justify="center" align="center" mb={30}>
            <Text size="lg" ta="center" color="red" fw={500}>
              {disabledAccountMessage}
            </Text>
          </Flex>
        )}
        <Flex direction="column" align="center" mb="xl">
          {isLifetimeUser && (
            <Text color="green" size="xl" fw="bold" mb={20}>
              🎉 Exclusive: 50% discount on all plans for lifetime users
            </Text>
          )}
          {showTrialPlans ? (
            <Title order={2} ta="center">Trial Plans</Title>
          ) : (
            <>
              <Flex w={300} align="center" justify={'center'}>
                <SegmentedControl
                  radius="xl"
                  size="md"
                  color="#003aa9"
                  value={planType}
                  onChange={setPlanType}

                  styles={{
                    label: {
                      fontSize: '14px',
                      padding: '8px 16px',
                    }
                  }}
                  data={[
                    { label: 'Monthly', value: 'monthly' },
                    { label: isLifetimeUser ? 'Yearly' : 'Annual (Save 20%)', value: 'annual' },
                  ]}
                />
              </Flex>
                {isLifetimeUser && (
                  <Text size="sm" ta="center" mt="md" color="gray" style={{ fontStyle: 'italic' }}>
                    Note: Upon canceling your monthly/annual subscription, you'll automatically return to your current LTD plan with existing limits.
                  </Text>
                )}
            </>
          )}
        </Flex>
        <Flex justify="space-between" align="stretch" wrap="wrap" gap="md" className="min-h-full">
          {plans.map((plan, index) => (
            <Box key={index} style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', border: '1px solid #e0e0e0', borderRadius: '8px', padding: '1.5rem', height: '100%' }}>
              <Title order={2} ta="center">{plan.title}</Title>
              {showTrialPlans ? (
                <>
                <Text ta="center" size="sm" fw={700} mt="md">14 days free trial</Text>
                <Flex direction="column" align="center" mt="md">
                    {plan.title === 'Agency' && (
                      <Select
                        value={selectedWorkspaces}
                        onChange={(value) => setSelectedWorkspaces((value as string) ? value as string : '10')}
                        data={[
                          { label: '10 Workspaces', value: '10' },
                          { label: '20 Workspaces', value: '20' },
                          { label: '50 Workspaces', value: '50' },
                          { label: '100 Workspaces', value: '100' },
                        ]}
                        placeholder="Select workspace count"
                        size="sm"
                        mt="md"
                      />
                    )}
                  </Flex>
                </>
              ) : (
                <>
                  <Flex direction="column" align="center" mt="md">
                    <Text ta="center" size="xl" fw={700} mt="md">
                      {getDiscountedPrice(plan)}
                      <span>{plan.period}</span>
                    </Text>
                    {planType === 'annual' && (
                      <Text size="xs" fw={500}>
                        ${getAnnualTotal(isLifetimeUser ? getDiscountedPrice(plan) : getPrice(plan))} billed annually
                      </Text>
                    )}
                  <Flex direction="column" align="center" mt="md">
                    {(planType === 'annual' || isLifetimeUser) && (
                      <Text size="sm" color="red" td="line-through">
                        Regular: ${getBasisPrice(plan)}{plan.period}
                      </Text>
                    )}
                    {planType === 'annual' && (
                      <Text color='green' size="sm" fw={500} mt={8}>
                        ${(getBasisPrice(plan) * 12) - (getAnnualTotal(isLifetimeUser ? getDiscountedPrice(plan) : getPrice(plan)))} save annually
                      </Text>
                    )}
                  </Flex>
                    {plan.title === 'Agency' && (
                      <Select
                        value={selectedWorkspaces}
                        onChange={(value) => setSelectedWorkspaces((value as string) ? value as string : '10')}
                        data={[
                          { label: '10 Workspaces', value: '10' },
                          { label: '20 Workspaces', value: '20' },
                          { label: '50 Workspaces', value: '50' },
                          { label: '100 Workspaces', value: '100' },
                        ]}
                        placeholder="Select workspace count"
                        size="sm"
                        mt="md"
                      />
                    )}
                  </Flex>
                </>
              )}
              <ul>
                {getCredits(plan).map((credit: string, index: number) => (
                  <li key={index}><Text size="sm">{credit}</Text></li>
                ))}
              </ul>
              <Text fw="bold" ta="center" size="sm" mt="md">FEATURES</Text>
              <ul>
                {plan.features.map((feature: string, index: number) => (
                  <li key={index}><Text size="sm">{feature}</Text></li>
                ))}
              </ul>
              <Flex justify="center" mt="auto" pt="md">
                {isCurrentPlan(plan) ? (
                  (primarySubscription?.status === 'deleted' || primarySubscription?.status === 'cancelled') ? (
                    <Button color="green" onClick={() => onPlanSelect(plan)}>
                      Renew Plan
                    </Button>
                  ) : (
                    <Badge color="green" variant="filled" size="lg">Current Plan</Badge>
                  )
                ) : (
                  <Button onClick={() => onPlanSelect(plan)}>
                    {showTrialPlans ? 'Start Trial' : 'Subscribe'}
                  </Button>
                )}
              </Flex>
            </Box>
          ))}
        </Flex>
      </Container>
    </Modal>
  );
};