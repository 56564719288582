import { LoaderDataTable } from '@/components/Menus/LoaderDataTable/LoaderDataTable';
import { NoResultsDataTable } from '@/components/Menus/NoResultsDataTable/NoResultsDataTable';
import { TotalResultsMenu } from '@/components/Menus/TotalResultsMenu.tsx/TotalResultsMenu';
import { CampaignsService } from '@/services/CampaignsService';
import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import {
  findCampaignType,
  getCTAType,
  getCampaignDisplayText,
  getCampaignMessage,
} from '@/utils/CampaignUtility';
import { getRelativeTime } from '@/utils/DateUtility';
import { faCircleInfo, faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Alert,
  Badge,
  Box,
  Center,
  Flex,
  Group,
  List,
  Menu,
  Pagination,
  Table,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { useDebouncedValue, useDocumentTitle } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CampaignsHeader } from './CampaignsHeader';
import { notifications } from '@mantine/notifications';
import { ICampaign } from '@/types/campaigns';
import { modals } from '@mantine/modals';
import { useUserStore } from '@/stores/useUserStore';
import { toDate } from 'date-fns';
import { useCampaignColumnsStore } from '@/stores/useCampaignColumnsStore';
import { CAMPAIGN_COLUMNS } from '@/constants/Constants';
import { numberToCommas } from '@/utils/StringUtility';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isUserCollaborator } from '@/utils/CommonUtility';
import { useSearchParams } from 'react-router-dom';

export function CampaignsPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  useDocumentTitle('Campaigns | ' + defaultWorkspace?.agency?.name);
  const { workspaceId } = useParams();

  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);

  const [
    storeCampaigns,
    setStoreCampaigns,
    search,
    page,
    setPage,
    lastPage,
    setLastPage,
    date,
    totalResults,
    setTotalResults,
    archive,
  ] = useCampaignsStore((state) => [
    state.campaigns,
    state.setCampaigns,
    state.search,
    state.page,
    state.setPage,
    state.lastPage,
    state.setLastPage,
    state.date,
    state.totalResults,
    state.setTotalResults,
    state.archive,
  ]);
  const { visibleColumns } = useCampaignColumnsStore();
  const [debounced] = useDebouncedValue(search, 200);
  const [user] = useUserStore((state) => [state.user]);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  //@ts-ignore
  const workspace_member = user.workspace_member.find(member => member.workspace_id === defaultWorkspace?._id);
  const [searchParams] = useSearchParams();
  const action = searchParams.get('action');
  console.log('action: ', action);
  
  const fetchCampaigns = async () => {
    setLoading(true);
    await new CampaignsService()
      .get(defaultWorkspace?._id || '', search, date.value, archive, page, totalResults)
      .then((res) => {
        if (res.data.status) {
          const paginatedData = res.data.data;
          setCampaigns(paginatedData.data);
          setLastPage(paginatedData.last_page);
        }
      })
      .catch((res) => {
        console.log(res);
      });
    setLoading(false);
  };

  /**
   * Handles the deletion of a campaign.
   * @param id - The id of the campaign to be deleted.
   */
  const handleDeleteCampaign = async (id: string) => {
    await new CampaignsService()
      .delete(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          const updatedCampaigns = campaigns.filter((item: any) => item._id !== id);
          setCampaigns(updatedCampaigns);

          // Remove the campaign from the store
          const updatedStoreCampaigns = storeCampaigns.filter((item: any) => item._id !== id);
          setStoreCampaigns(updatedStoreCampaigns);

          notifications.show({
            color: 'green',
            message: 'Campaign deleted successfully.',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   *  Function to confirm the deletion of a campaign.
   * @param id - The id of the campaign to be deleted.
   */
  const confirmCampaignDelete = async (id: string) => {
    modals.openConfirmModal({
      title: <Title order={5}>Delete Campaign</Title>,
      children: (
        <>
          <Text size="sm">Are you sure? This process can not be undone.</Text>
          <Alert color="yellow" my={16} p={16}>
            <List>
              <List.Item>Links will be removed.</List.Item>
              <List.Item>RSS automation will be removed.</List.Item>
              <List.Item>RSS feed links will be removed.</List.Item>
            </List>
          </Alert>
        </>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleDeleteCampaign(id),
    });
  };

  /**
   * Sends an API call to the backend to archive a campaign.
   * @param id - The id of the campaign to be archived.
   */
  const handleArchiveCampaign = async (id: string) => {
    await new CampaignsService()
      .archive(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          const updatedCampaigns = campaigns.filter((item: any) => item._id !== id);
          setCampaigns(updatedCampaigns);

          // TODO: Update the store and remove the campaign back.

          notifications.show({
            color: 'green',
            message: 'Campaign archived successfully.',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Sends an API call to the backend to unarchive a campaign.
   * @param id - The id of the campaign to be unarchived.
   */
  const handleUnarchiveCampaign = async (id: string) => {
    await new CampaignsService()
      .unarchive(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          const updatedCampaigns = campaigns.filter((item: any) => item._id !== id);
          setCampaigns(updatedCampaigns);

          // TODO: Update the store and add the campaign back.

          notifications.show({
            color: 'green',
            message: 'Campaign unarchived successfully.',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Confirms the archiving of a campaign.
   * @param id - The id of the campaign to be archived.
   */
  const confirmArchiveCampaign = async (id: string) => {
    modals.openConfirmModal({
      title: <Title order={5}>Archive Campaign</Title>,
      children: (
        <>
          <Text size="sm">
            Are you sure? Your campaign will be archived but it will still be functional.
          </Text>
        </>
      ),
      labels: { confirm: 'Archive', cancel: 'Cancel' },
      confirmProps: { color: 'orange' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleArchiveCampaign(id),
    });
  };

  const handleSetDefaultCampaign = async (id: string) => {
    await new CampaignsService()
      .setDefault(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          fetchCampaigns();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDuplicate = async (id: string) => {
    await new CampaignsService()
      .duplicate(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          fetchCampaigns();
          notifications.show({
            color: 'green',
            message: 'Campaign duplicated successfully.',
          });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 400 || err.response.status === 422) {
          notifications.show({
            message: err.response.data.message,
            color: 'red',
          });
        }
      });
  };

  const navigate = useNavigate();
  useEffect(() => {
    console.log('this is user form campiagns page' , user);
    
    fetchCampaigns();
  }, [debounced, page, date, totalResults, archive, workspaceId]);

  const renderCampaignBody = (campaign: ICampaign, accessor: string) => {
    switch (accessor) {
      case 'name':
        return (
          <>
            <Flex direction={'column'}>
              <Flex align={'center'}>
                <Text fz={13}>{campaign.name}</Text>
                {campaign.is_default && (
                  <>
                    <Badge variant="outline" ml={8} size="xs">
                      Default
                    </Badge>
                  </>
                )}
              </Flex>
              <Text pt={4} c="dimmed" fz={10}>
                Created {getRelativeTime(campaign.created_at)} | Last modified{' '}
                {getRelativeTime(campaign.updated_at)}
              </Text>
              {/* <Text>Custom domain: {campaign?.domain?.url}</Text> */}
            </Flex>
          </>
        );
      case 'campaign_type':
        return (
          <>
            <Text fz={13}>{findCampaignType(campaign)}</Text>
          </>
        );
      case 'cta_type':
        return (
          <>
            <Text fz={13} tt="capitalize">
              {getCTAType(campaign)}
            </Text>
          </>
        );
      case 'headline':
        return (
          <>
            <Text fz={13}>{getCampaignMessage(campaign)}</Text>
          </>
        );
      case 'total_links':
        return (
          <>
            <Text fw={500} fz={14}>
              {campaign?.total_links || 0}
            </Text>
          </>
        );
      case 'total_clicks':
        return (
          <>
            <Text fw={500} fz={14}>
              {numberToCommas(campaign?.clicks) || 0}
            </Text>
          </>
        );
      case 'unique_clicks':
        return (
          <>
            <Text fw={500} fz={14}>
              {numberToCommas(campaign?.unique_clicks) || 0}
            </Text>
          </>
        );
      case 'qr_scans':
        return (
          <>
            <Text fw={500} fz={14}>
              {numberToCommas(campaign?.qr_scans) || 0}
            </Text>
          </>
        );
      case 'actions':
        return (
          <>
            <Menu withArrow withinPortal>
              <Menu.Target>
                <Center>
                  <ActionIcon c="gray" variant="subtle">
                    <FontAwesomeIcon icon={faEllipsis} />
                  </ActionIcon>
                </Center>
              </Menu.Target>
              <Menu.Dropdown w="140">
              {(defaultPermissions && checkPermission(defaultPermissions, ['edit_campaign']) && isUserCollaborator(user, defaultWorkspace?._id, campaign, 'campaign')) && <Menu.Item
                  onClick={() => {
                    navigate(`/campaigns/${defaultWorkspace?._id}/setup/${campaign._id}`);
                  }}
                >
                  Edit
                </Menu.Item>}

                {(!campaign?.is_default && (workspace_member?.role !== 'collaborator') && defaultPermissions && checkPermission(defaultPermissions, ['edit_campaign'])) && (
                  <Menu.Item
                    onClick={() => {
                      handleSetDefaultCampaign(campaign._id || '');
                    }}
                  >
                    Set as Default
                  </Menu.Item>
                )}

                <Menu.Item
                  onClick={() => {
                    const url = `/analytics/${defaultWorkspace?._id}/short-links?campaign_id=${campaign._id}`;
                    navigate(url);
                  }}
                >
                  View Stats
                </Menu.Item>

                {(defaultPermissions && checkPermission(defaultPermissions, ['add_campaign']) && isUserCollaborator(user, defaultWorkspace?._id, campaign, 'campaign')) && <Menu.Item
                  onClick={() => {
                    handleDuplicate(campaign._id || '');
                  }}
                >
                  Duplicate
                </Menu.Item>}
                {!campaign?.is_default && (
                  <>
                    {(defaultPermissions && checkPermission(defaultPermissions, ['edit_campaign']) && isUserCollaborator(user, defaultWorkspace?._id, campaign, 'campaign')) && <Menu.Item
                      onClick={() => {
                        if (campaign?.is_archived) {
                          handleUnarchiveCampaign(campaign._id || '');
                        } else {
                          confirmArchiveCampaign(campaign._id || '');
                        }
                      }}
                    >
                      {campaign?.is_archived ? 'Unarchive' : 'Archive'}
                    </Menu.Item>}
                    {(defaultPermissions && checkPermission(defaultPermissions, ['edit_campaign']) && isUserCollaborator(user, defaultWorkspace?._id, campaign, 'campaign')) && <Menu.Divider />}
                    {(defaultPermissions && checkPermission(defaultPermissions, ['delete_campaign']) && isUserCollaborator(user, defaultWorkspace?._id, campaign, 'campaign')) && <Menu.Item onClick={() => confirmCampaignDelete(campaign._id || '')} c={'red'}>
                      Delete
                    </Menu.Item>}
                  </>
                )}
              </Menu.Dropdown>
            </Menu>
          </>
        );
    }
    return <></>;
  };

  return (
    <>
      <Flex align={'center'} pl={'lg'} pt={'xs'}>
        <Text size="xl" fw="bold" mr="sm">
          Campaigns
        </Text>
        <Tooltip
          position="bottom"
          multiline
          w={450}
          fz="xs"
          label=" A campaign allows you to create widgets for your brand advertisement. You can create campaigns to promote your branded message and collect leads for each of your brands."
        >
          <FontAwesomeIcon
            style={{
              color: 'var(--mantine-color-gray-6)',
            }}
            icon={faCircleInfo}
          />
        </Tooltip>
      </Flex>
      <CampaignsHeader />
      <Flex
        direction={'column'}
        mx={'md'}
        style={{
          background: 'var(--mantine-color-white)',
        }}
      >
        <Box
          style={{
            flexGrow: 1,
          }}
        >
          <Table stickyHeader verticalSpacing={'md'}>
            <Table.Thead h={20} bg={'gray.1'} c="gray.7" fz="xs">
              <Table.Tr>
                {CAMPAIGN_COLUMNS.filter((column) => visibleColumns.includes(column.accessor)).map(
                  (column) => (
                    <Table.Th key={column.accessor} ta={column.ta as any}>
                      {column.label}
                    </Table.Th>
                  )
                )}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {loading ? (
                <>
                  <LoaderDataTable colSpan={9} />
                </>
              ) : (
                <>
                  {campaigns.length === 0 && (
                    <>
                      <NoResultsDataTable data={campaigns} colSpan={9} />
                    </>
                  )}

                  {campaigns.map((campaign: any) => (
                    <Table.Tr
                      key={campaign._id}
                      style={{
                        opacity: campaign?.is_archived ? 0.5 : 1,
                      }}
                    >
                      {CAMPAIGN_COLUMNS.filter((column) =>
                        visibleColumns.includes(column.accessor)
                      ).map((column: any, index: number) => (
                        <Table.Td
                          key={`${campaign._id}-${column.accessor}-${index}`}
                          ta={column.ta as any}
                          style={{
                            opacity: campaign?.is_archived ? 0.4 : 1,
                          }}
                        >
                          {renderCampaignBody(campaign, column.accessor)}
                        </Table.Td>
                      ))}
                    </Table.Tr>
                  ))}
                </>
              )}
            </Table.Tbody>
          </Table>
        </Box>
      </Flex>
      <Box mx={'sm'} pb={'lg'} mt={'sm'}>
        <Group justify="space-between">
          <Pagination size="xs" onChange={setPage} total={lastPage} />
          {/* <TotalResultsMenu value={20} onChange={setTotalResults} /> */}
        </Group>
      </Box>
    </>
  );
}
