import React from 'react';
import { Box, Text, Group, ActionIcon, ScrollArea } from '@mantine/core';
import { IconTrash, IconEdit } from '@tabler/icons-react';
import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { useEffect, useState } from 'react'

export const TrafficRoutingGroup = ({ onEditRule, onDeleteRule }: { onEditRule: any; onDeleteRule: any }) => {
  //variables
  const items = Array(10).fill(null);
  const [link, setLink] = useLinkSetupStore((state) => [state.link, state.setLink]);
  const [filterTypesByKey, setFilterTypesByKey] = useState<any>({});

  const getFilterNames = (rule: any) => {
    const uniqueFilterTypes = new Set();
    rule.blocks.forEach((block: any) => {
      block.conditions.forEach((condition: any) => {
        uniqueFilterTypes.add(condition.filterType);
      });
    });
    return Array.from(uniqueFilterTypes);
  };

  const arrayToCommaSeparatedString = (obj: any) => {
    // Check if the input is an object
    if (typeof obj !== 'undefined' || obj !== undefined) {
      // Collect all values from the object into a single array
      const allValues = Object.values(obj).flat();
      // Ensure all items are strings and join them with a comma
      const result = allValues
        .map(item => {
          if (typeof item !== 'string') {
            throw new Error('All items in the arrays must be strings');
          }
          return item;
        })
        .join(', ');
      return result;
    }
  };

  useEffect(() => {
    const filterTypes: any = {};
    link.routing_attributes.forEach((rulesArray, index) => {
      filterTypes[index] = rulesArray.flatMap((rule: any) => getFilterNames(rule));
    });
    setFilterTypesByKey(filterTypes);
  }, []);

  console.log('filter types', filterTypesByKey);

  return (
    <ScrollArea style={{ height: '100%', maxHeight: '300px' }}>
      {(link.routing_attributes.length > 0) ? link.routing_attributes.map((rule, index) => (
        <div key={index}>
          <Group
            key={index}
            style={{
              padding: '10px',
              borderRadius: '5px',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              backgroundColor: '#f5f5f5',
              margin: '10px 0px',
              width: '100%' // Ensure the Group takes full width
            }}
          >
            <div style={{ width: '65%' }}>
              <Text size="sm" color="dimmed" fz={13}>
                On specific <Text component="span" color="blue" fw={600} size="xs">{arrayToCommaSeparatedString(filterTypesByKey[index])}</Text> go to this url
              </Text>
              <Text size="md" fw={400} fz={15} color="gray">
                {rule[0].destination}
              </Text>
            </div>
            <Group
              style={{ width: '30%' }}
              justify="flex-end"
              gap="md"
            >
              <ActionIcon variant="transparent" onClick={() => onEditRule(index)}>
                <IconEdit size={24} />
              </ActionIcon>
              <ActionIcon color="red" variant="transparent" onClick={() => onDeleteRule(index)}>
                <IconTrash size={24} />
              </ActionIcon>
            </Group>
          </Group>
          {(index !== link.routing_attributes.length - 1) && <Text fw={300} fz={13}>Otherwise</Text>}
        </div>
      )) : <Text fw={300} fz={14}>Please add a rule</Text>}
    </ScrollArea>
  );
};
