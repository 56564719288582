import { MultiSelectCreatable } from '@/components/MultiSelectCreatable/MultiSelectCreatable';
import { LinksService } from '@/services/LinksService';
import { useTagsStore } from '@/stores/useTagsStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { faCheck, faPlus, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Button, Flex, Pill, Tooltip, Space } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';

export const LinkTagsComponent = ({
  link,
  onChange,
  show
}: {
  link: any;
  onChange: (tags: []) => void;
  show: boolean;
}) => {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [addTags, setAddTags] = useState(false);
  const [tags, createTag] = useTagsStore((state) => [state.tags, state.createTag]);
  // Function to find the tag name from the tags store
  const findTagName = (tagId: string) => {
    const find: any = tags.find((tag: any) => tag._id === tagId);
    return find?.name;
  };

  const [selectedTags, setSelectedTags] = useState(link.tags);

  const handleUpdateTags = () => {
    new LinksService()
      .updateTags(defaultWorkspace?._id || '', link._id, selectedTags)
      .then((res) => {
        if (res.data.status) {
          notifications.show({
            color: 'green',
            message: 'Tags updated for the link successfully.',
          });
          onChange(selectedTags);
          setAddTags(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleTagCreation = async (value: string) => {
    const resp = await createTag(defaultWorkspace?._id || '', {
      _id: null,
      name: value,
      workspace_id: defaultWorkspace?._id || '',
    });
  };

  if (addTags) {
    return (
      <Flex align={'center'}>
        <MultiSelectCreatable
          size="xs"
          dataItems={tags.map((tag: any) => ({
            label: tag.name,
            value: tag._id,
          }))}
          width={140}
          valueItems={link.tags}
          onChange={setSelectedTags}
          onCreate={(item) => {
            handleTagCreation(item.value);
          }}
        />

        <Tooltip label="Save" withArrow>
          <ActionIcon
            onClick={() => handleUpdateTags()}
            ml={2}
            size="xs"
            variant={'subtle'}
            color="primary.3"
          >
            <FontAwesomeIcon
              size="sm"
              icon={faCheck}
              style={{
                color: 'var(--mantine-color-green-6)',
              }}
            />
          </ActionIcon>
        </Tooltip>

        <Tooltip label="Cancel" withArrow>
          <ActionIcon
            onClick={() => setAddTags(false)}
            ml={2}
            size="xs"
            variant={'subtle'}
            color="primary.3"
          >
            <FontAwesomeIcon
              size="sm"
              icon={faTimes}
              style={{
                color: 'var(--mantine-color-red-6)',
              }}
            />
          </ActionIcon>
        </Tooltip>
      </Flex>
    );
  }

  return (
    <>
      {link.tags.length > 0 ? (show ?
        <Flex align={'center'} wrap="wrap">
          {link.tags.map((tag: any, tagIndex: number) => (
            <>
              {findTagName(tag) && (
                <>
                  <Pill
                    mb={4}
                    mr={4}
                    fz={11}
                    key={`${link._id}:${tag}:${tagIndex}`}
                    size="xs"
                    color="gray"
                  // variant="outline"
                  >
                    {findTagName(tag)}
                  </Pill>
                </>
              )}
            </>
          ))}
          <ActionIcon
            onClick={() => setAddTags(true)}
            ml={2}
            size="xs"
            variant={'subtle'}
            color="primary.3"
          >
            <FontAwesomeIcon size="xs" icon={faPlus} />
          </ActionIcon>
        </Flex>
        : <Flex align={'center'} wrap="wrap">
          {link.tags.map((tag: any, tagIndex: number) => (
            <>
              {findTagName(tag) && (
                <>
                  <Pill
                    mb={4}
                    mr={4}
                    fz={11}
                    key={`${link._id}:${tag}:${tagIndex}`}
                    size="xs"
                    color="gray"
                  // variant="outline"
                  >
                    {findTagName(tag)}
                  </Pill>
                </>
              )}
            </>
          ))}
        </Flex>) : (show ?
        <>
          <Button
            size="xs"
            variant={'subtle'}
            color="primary.3"
            leftSection={
              <>
                <FontAwesomeIcon icon={faPlus} />
              </>
            }
            onClick={() => setAddTags(true)}
          >
            Add tag
          </Button>
        </>
      : <Space h="md" />)}
    </>
  );
};
