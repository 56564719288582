import { create } from 'zustand';

interface IQRModalStore {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose: () => void;
  url: string;
  setURL: (url: string) => void;
  props: any;
  setProps: (props: any) => void;
  fgColor: string;
  setFgColor: (fgColor: string) => void;
  bgColor: string;
  setBgColor: (bgColor: string) => void;
}

export const useQRModalStore = create<IQRModalStore>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen: boolean) => set({ isOpen }),
  onClose: () => set({ isOpen: false }),
  url: '',
  setURL: (url: string) => set({ url }),
  props: {},
  setProps: (props: any) => set({ props }),
  fgColor: '#242424',
  setFgColor: (fgColor: string) => set({ fgColor }),
  bgColor: '#FFFFFF',
  setBgColor: (bgColor: string) => set({ bgColor }),
}));
