import { Box, Button, Center, Container, Divider, Grid, Image, Text, Title } from '@mantine/core';
import { useUserStore } from '@/stores/useUserStore';
import { useDocumentTitle } from '@mantine/hooks';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { useState } from "react";
import styles from './ReferAndEarn.module.css'; // Import the CSS module
import { UserService } from '@/services/UserService';
import { notifications } from '@mantine/notifications';
import { useEffect } from 'react';
import first_step  from '../../assets/images/common/first_step.png';
import second_step  from '../../assets/images/common/second_step.png';
import third_step  from '../../assets/images/common/third_step.png';

export function ReferAndEarnPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [user, setUser] = useUserStore<any>((state) => [state.user, state.setUser]);
  const [loading, setLoading] = useState(false);

  const isFirstPromoterAuthTokenAvailable : any = user?.first_promoter?.auth_token;
  const firstPromoterIframeSrc = isFirstPromoterAuthTokenAvailable
    ? `https://replug.firstpromoter.com//iframe?at=${user.first_promoter.auth_token}`
    : '';

  useDocumentTitle('Earn 30% Recurring Commissions | ' + defaultWorkspace?.agency?.name);

  const becomeAffiliate = async () => {
    await new UserService()
      .createPromoter()
      .then((res) => {
        if (res?.data?.status) {
          notifications.show({
            color: 'green',
            message: res?.data?.message,
          });
          getUser()
        } else {
          notifications.show({
            color: 'red',
            message: res?.data?.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        notifications.show({
          color: 'red',
          message: err?.response?.data?.message,
        });
      });
  };

  const getUser = async () => {
    await new UserService()
      .getProfile()
      .then((res) => {
        setUser(res?.data?.data?.user)
      }).catch((err) => {
        console.log(err.response);
      })
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.boxTitle}>
        <Center>
          <Title order={3}>Earn 30% Recurring Commissions!</Title>
        </Center>
        <Text style={{ textAlign: 'center' }}>Here’s how our affiliate program works:</Text>
      </Box>
      <Divider color="gray.2" />

      <Container mt={32}>
        {isFirstPromoterAuthTokenAvailable ? (
          <Box p={4}>
            <iframe
              src={firstPromoterIframeSrc}
              height="850px"
              width="100%"
              frameBorder="0"
              onError={() => notifications.show({
                color: 'red',
                message: 'Failed to load the affiliate program details.'
              })}
            />
          </Box>
        ) : (
          <Grid justify="center">
            <Grid.Col span={4} >
              <div className={`${styles.border} ${styles.rounded} ${styles.textCenter} ${styles.p4}`} style={{padding: '30px'}}>
                <Image src={first_step} width="100%" />
                <Title order={5} style={{ textAlign: 'center' }}>1. Join</Title>
                <Text mt={2} style={{ textAlign: 'center' }} size='sm'>Sign up and unlock the benefits of the Replug affiliate program.</Text>
              </div>
            </Grid.Col>
            <Grid.Col span={4}>
              <div className={`${styles.border} ${styles.rounded} ${styles.textCenter} ${styles.p4}`} style={{padding: '20px'}}>
                <Image src={second_step} width="100%" />
                <Title order={5} style={{ textAlign: 'center' }}>2. Refer</Title>
                <Text mt={2} style={{ textAlign: 'center' }} size='sm'>Share your unique affiliate link with your friends and followers to. </Text>
              </div>
            </Grid.Col>
            <Grid.Col span={4}>
              <div className={`${styles.border} ${styles.rounded} ${styles.textCenter} ${styles.p4}`}>
                <Image src={third_step} width="100%" />
                <Title order={5} style={{ textAlign: 'center' }}>3. Earn</Title>
                <Text mt={2} style={{ textAlign: 'center' }} size='sm'>Receive 30% commission on every sale we get from your affiliate link.</Text>
              </div>
            </Grid.Col>
          </Grid>
        )}

        {!isFirstPromoterAuthTokenAvailable && (
          <Container className={styles.note} pt={40}>
            <Text style={{ textAlign: 'center' }} size='sm'><strong>Note:</strong> It’s not just that one payment when they sign up. That 30% commission will be paid to you every month for as long as their Replug account is active.</Text>
            <Text style={{ textAlign: 'center' }} mt={30} size='sm'>Through our affiliate program you’re not only earning extra money for you and your family, you’re also helping others streamline their social media and content marketing with Replug.</Text>
            <Center>
              <Button
                onClick={becomeAffiliate}
                className={`${styles.btnCta} ${styles.withShadow} ${styles.btnRound} ${styles.btnBold}`}
                loading={loading}
                mt={40}
              >
                Become Affiliate
              </Button>
            </Center>
          </Container>
        )}
      </Container>
    </Box>
  );
}
