import { Box, Button, Center, Divider, Group, Text } from '@mantine/core';
import { Link, RichTextEditor } from '@mantine/tiptap';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import { Underline } from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { BioAnimation } from '../Themes/BioAnimation';
import { useBioTextBlockStore } from '@/stores/Bio/useBioTextBlockStore';
import { notifications } from '@mantine/notifications';
import { BioLinkBlockService } from '@/services/BioLinkBlockService';
import { useParams } from 'react-router-dom';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { DEFAULT_TEXT_BLOCK_VALUES } from '@replug/utils';
import { useBioLinkSetupStore } from '@/stores/Bio/useBioLinkSetupStore';
import { useEffect } from 'react';

export const BioTextSection = ({ reload }: { reload: () => void }) => {
  const { id } = useParams();
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  const [bioTextBlock, setBioTextBlock] = useBioTextBlockStore((state) => [
    state.bioTextBlock,
    state.setBioTextBlock,
  ]);

  const [bioLink] = useBioLinkSetupStore((state) => [state.bioLink]);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      //   Superscript,
      //   SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content: bioTextBlock.text || '<p></p>',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    onUpdate: ({ editor }: any) => {
      setBioTextBlock({
        ...bioTextBlock,
        text: editor.getHTML(),
      });
    },
  });

  const handleAddBlock = () => {
    if (bioTextBlock.text.length === 0) {
      notifications.show({
        color: 'red',
        message: 'Please add some text',
      });
      return;
    }

    let payload = {
      ...bioTextBlock,
      bio_link_id: id,
      workspace_id: defaultWorkspace?._id,
    };

    if (bioTextBlock._id) {
      new BioLinkBlockService()
        .update(defaultWorkspace?._id || '', payload)
        .then((res) => {
          if (res.data.data) {
            notifications.show({
              color: 'green',
              message: res.data.message,
            });

            setBioTextBlock(DEFAULT_TEXT_BLOCK_VALUES);
            reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      payload.order = bioLink?.blocks.length + 1;

      if (!bioLink?._id) {
        notifications.show({
          color: 'red',
          message: 'Please first publish your bio link.',
        });
        return;
      }
      new BioLinkBlockService()
        .create(defaultWorkspace?._id || '', payload)
        .then((res) => {
          if (res.data.data) {
            notifications.show({
              color: 'green',
              message: res.data.message,
            });

            setBioTextBlock(DEFAULT_TEXT_BLOCK_VALUES);
            reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (bioTextBlock.text && editor) {
      editor.commands.setContent(bioTextBlock.text);
    }
  }, []);
  return (
    <>
      <Text ta="center" px={'md'} pb={'md'} fz={14}>
        Add text, title, and description to your profile with the Text Block. Add engaging text to
        your Bio-Link.
      </Text>
      <Divider color="gray.2" />
      <Box p="md">
        <RichTextEditor fz={13} editor={editor}>
          <RichTextEditor.Toolbar sticky stickyOffset={60}>
            <RichTextEditor.ControlsGroup fz={11}>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
              <RichTextEditor.Strikethrough />
              <RichTextEditor.ClearFormatting />
              <RichTextEditor.BulletList />
              <RichTextEditor.OrderedList />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Link />
              <RichTextEditor.Unlink />
            </RichTextEditor.ControlsGroup>
          </RichTextEditor.Toolbar>

          <RichTextEditor.Content
          // {...form.getInputProps(`call_to_action.agreements.${index}.text`)}
          />
        </RichTextEditor>
        {/* <Box
          my="lg"
          p={16}
          style={{
            borderRadius: 4,
            border: '1px solid var(--mantine-color-gray-2)',
          }}
        >
          <BioAnimation />
        </Box> */}

        <Divider my={'md'} />
        <Center>
          <Group>
            <Button onClick={handleAddBlock}>
              {bioTextBlock._id ? 'Update Block' : 'Add Block'}
            </Button>
          </Group>
        </Center>
      </Box>
    </>
  );
};
