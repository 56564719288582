import { CustomWidgetModal } from '@/components/Modals/CustomWidgetModal/CustomWidgetModal';
import { CustomWidgetService } from '@/services/CustomWidgetService';
import { useCampaignSetupStore } from '@/stores/useCampaignSetupStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { IWidget } from '@/types/widgets';
import { Button, Center, Divider, MultiSelect, Stack, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { useUsermaven } from "@usermaven/react";

export const CampaignSetupWidgets = ({ form, nextStep }: { form: any; nextStep: () => void }) => {
  const [storeCampaign, createLoading] = useCampaignSetupStore((state) => [
    state.storeCampaign,
    state.createLoading,
  ]);
  const navigate = useNavigate();
  const {track} = useUsermaven()
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any>([]);
  const [widgetModalOpened, setWidgetModalOpened] = useState(false);
  const [ setPage ] = useCampaignsStore((state) => [ state.setPage]);

  const fetchCustomWidgets = async () => {
    setLoading(true);
    await new CustomWidgetService()
      .get(defaultWorkspace?._id || '')
      .then((res) => {
        if (res.data.status) {
          setList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const handleSaveCampaign = async () => {
    if (!form.values.custom_widget_ids || form.values.custom_widget_ids.length === 0) {
      form.setFieldError('custom_widget_ids', 'Please select a custom widget');
      return;
    }

    const res = await storeCampaign(defaultWorkspace?._id || '', form.values);
    if (res) {
      track('campaign_created', { campaign: form.values.name, type: 'third_party_custom_widget' })
      setPage(1)
      navigate(`/campaigns/${defaultWorkspace?._id}`);
    }
  };

  useEffect(() => {
    fetchCustomWidgets();
  }, []);

  return (
    <>
      {' '}
      <CustomWidgetModal
        opened={widgetModalOpened}
        setOpened={setWidgetModalOpened}
        onChange={(storedWidget) => {
          console.log('list of widgets', list, storedWidget);
          const newList = [...list, storedWidget];
          setList(newList);
        }}
      />
      <Title order={5} px={24} py={16}>
        3rd Party / Custom Widgets
      </Title>
      <Divider color="gray.2" />
      <Stack p={24}>
        <MultiSelect
          data={list.map((widget: IWidget) => {
            return { label: widget.name, value: widget._id };
          })}
          placeholder="Select a 3rd party/custom widget"
          {...form.getInputProps('custom_widget_ids')}
        />
        <Divider color="gray.5" label="OR" />
        <Button variant="outline" color="blue" onClick={() => setWidgetModalOpened(true)}>
          Create a new custom widget
        </Button>

        <Center>
          {form.values.is_retargeting_enabled ? (
            <>
              <Button w={'200'} onClick={() => nextStep()}>
                Next
              </Button>
            </>
          ) : (
            <>
              <Button
                loading={createLoading}
                disabled={createLoading}
                w={'200'}
                onClick={() => handleSaveCampaign()}
              >
                Save Campaign
              </Button>
            </>
          )}
        </Center>
      </Stack>
    </>
  );
};
