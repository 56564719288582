import { CAMPAIGN_COLUMNS } from '@/constants/Constants';
import superjson from 'superjson'; //  can use anything: serialize-javascript, devalue, etc.
import { create } from 'zustand';
import { PersistStorage, persist } from 'zustand/middleware';

interface TableState {
  visibleColumns: string[];
  setVisibleColumns: (visibleColumns: string[]) => void;
  toggleColumnVisibility: (accessor: string) => void;
}

const storage: PersistStorage<TableState> = {
  getItem: (name) => {
    const str = localStorage.getItem(name);
    if (!str) return null;
    return superjson.parse(str);
  },
  setItem: (name, value) => {
    localStorage.setItem(name, superjson.stringify(value));
  },
  removeItem: (name) => localStorage.removeItem(name),
};

const initialVisibleColumns = CAMPAIGN_COLUMNS.map((column) => column.accessor);

export const useCampaignColumnsStore = create<TableState>()(
  persist(
    (set) => ({
      visibleColumns: initialVisibleColumns,
      setVisibleColumns: (visibleColumns: string[]) => set({ visibleColumns }),
      toggleColumnVisibility: (accessor: string) =>
        set((state) => {
          const isVisible = state.visibleColumns.includes(accessor);
          return {
            visibleColumns: isVisible
              ? state.visibleColumns.filter((col) => col !== accessor)
              : [...state.visibleColumns, accessor],
          };
        }),
    }),
    {
      name: 'campaign-columns-storage',
      storage,
    }
  )
);
