import {
  Container,
  Paper,
  Flex,
  Box,
  Center,
  Title,
  Button,
  Text,
  Image,
  TextInput,
  Grid,
  PasswordInput,
  Anchor,
  Loader,
  Group,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { WorkspaceMemberService } from '@/services/WorkspaceMemberService';
import { useParams, useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';
import { useUserStore } from '@/stores/useUserStore';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import { WORKSPACE_MEMBER_STATES } from '@/constants/Constants';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { UserService } from '@/services/UserService';
import { getAppLogo, getAppPrivacyPolicy, getAppTermsOfService } from '@/utils/CommonUtility';

const schema = z.object({
  member_id: z.string(),
  first_name: z.string().min(2, { message: 'First name should be at least 2 characters long' }),
  last_name: z.string().min(2, { message: 'Last name should be at least 2 characters long' }),
  password: z.string().min(6, { message: 'Password should be at least 6 characters long' }),
});
export const JoinWorkspacePage = () => {
  const [loading, setLoading] = useState(true);
  const [invalidToken, setInvalidToken] = useState(false);
  const [memberData, setMemberData] = useState<any>();
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [user, setUser] = useUserStore((state) => [state.user, state.setUser]);
  const navigate = useNavigate();

  console.log('default workspace', defaultWorkspace);

  const form = useForm({
    initialValues: {
      member_id: '',
      first_name: '',
      last_name: '',
      password: '',
    },
    validate: zodResolver(schema),
  });
  const { token } = useParams();
  const findMemberByToken = async () => {
    setLoading(true);
    await axios
      .get(`/workspace_members/find_by_token/${token}`)
      .then((res) => {
        if (res.data.status) {
          setMemberData(res.data.data);
          form.setFieldValue('member_id', res.data.data._id);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setInvalidToken(true);
          notifications.show({
            color: 'red',
            message: 'Invitation token is invalid. Please contact the workspace owner.',
          });
        }
      });

    setLoading(false);
  };

  const handleAcceptInvitation = async () => {
    setLoading(true);
    await new WorkspaceMemberService()
      .acceptInvite(token || '')
      .then((res) => {
        if (res.data.status) {
          notifications.show({
            color: 'green',
            message: res.data.message,
          });
          window.location.href = '/';
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
        } else if(err.response.status === 401){
          notifications.show({
            color: 'yellow',
            message: 'Please log in to accept invitation.',
          });
          setTimeout(() => {
            // redirect to login page with redirect url including current path and query params
            const redirect = window.location.pathname + window.location.search;
            navigate('/login?redirect=' + redirect);
          }, 2000);
        } else {
          notifications.show({
            color: 'red',
            message: 'Something went wrong. Please try again.',
          });
        }
      });

    setLoading(false);
  };

  const handleSetupAccount = async () => {
    form.validate();
    if (form.isValid()) {
      await axios.post('/workspace_members/setup_account', {
        ...form.values,
        token,
      });
      window.location.href = '/';
    }
  };

  const fetchProfile = async () => {
    const res = await new UserService()
      .getProfile()
      .then((response) => {
        if (response.data.status) {
          setUser(response.data.data.user);
          return true;
        }
        return false;
      })
      .catch((error) => {
        console.debug(error);
        return false;
      });
    setLoading(false);
  };
  
  useEffect(() => {
    const fetchData = async () => {
      await findMemberByToken();
      await fetchProfile();
    };
    fetchData();
  }, []);

  const renderHeader = () => {
    return (
      <>
        <Flex direction={'column'}>
          <Box
            mt={16}
            p={24}
            px={32}
            bg="primary.9"
            style={{
              borderRadius: '0.25rem',
            }}
          >
            <Center>
              <Image w={122} h={36} src={getAppLogo()} />
            </Center>
          </Box>
        </Flex>
      </>
    );
  };

  // If state is loading

  if (loading) {
    return (
      <Container>
        {renderHeader()}
        <Flex direction={'column'} align={'center'} py={32}>
          <Loader mt={'xl'} size={'sm'} />
        </Flex>
      </Container>
    );
  }http://localhost:3001/workspace/6673df5f5cefc74987028e15/basic

  // if token is invalid

  if (invalidToken) {
    return (
      <Container>
        {renderHeader()}
        <Flex direction={'column'} align={'center'} py={32}>
          <FontAwesomeIcon icon={faTimesCircle} size="3x" color="red" />
          <Title order={2} mb={'md'} mt="md">
            Invalid Invitation Token
          </Title>
          <Text pb={16} size="sm" ta="center" c="dimmed">
            Invitation token is invalid. Please contact the workspace owner.
          </Text>

          <Button
            my={'md'}
            onClick={() => {
              window.location.href = '/';
            }}
          >
            Go Back
          </Button>
        </Flex>
      </Container>
    );
  }

  // If the user has already joined the workspace

  if (memberData.state === WORKSPACE_MEMBER_STATES.JOINED) {
    return (
      <Container>
        {renderHeader()}
        <Flex direction={'column'} align={'center'} py={32}>
          <Title order={2} mb={'md'} mt="md">
            Workspace already joined
          </Title>
          <Text pb={16} size="sm" ta="center" c="dimmed">
            You have already joined the workspace.
          </Text>

          <Button
            my={'md'}
            onClick={() => {
              window.location.href = '/';
            }}
          >
            Go to Dashboard
          </Button>
        </Flex>
      </Container>
    );
  }

  // if token is valid, allow the user to accept the invitation

  if (memberData && memberData?.user && memberData?.user.first_name && memberData?.user.last_name) {
    return (
      // If the user has account already, then give an option to accept invitation.
      <Container>
        {renderHeader()}
        <Flex direction={'column'} align={'center'} py={32}>
          <Title order={2} mb={'md'} mt="md">
            Join Workspace Invitation
          </Title>
          <Text pb={16} size="sm" ta="center" c="dimmed">
            Please accept the invitation to join the workspace.
          </Text>

          <Center>
            <Group>
              <Button
                my={'md'}
                variant="outline"
                color="gray"
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                Cancel
              </Button>
              <Button my={'md'} onClick={handleAcceptInvitation}>
                Accept Invitation
              </Button>
            </Group>
          </Center>
        </Flex>
      </Container>
    );
  }

  return (
    <>
      <Container>
        {renderHeader()}
        <Flex direction={'column'} align={'center'} py={32}>
          <Title order={2} mb={'md'}>
            Join Workspace
          </Title>

          <Box
            bg={'white'}
            w={600}
            style={{
              padding: 16,
              borderRadius: '0.25rem',
              margin: '0 auto',
              border: '1px solid var(--mantine-color-gray-2)',
            }}
          >
            <Grid>
              <Grid.Col span={6}>
                <TextInput
                  label="First Name"
                  placeholder="John"
                  {...form.getInputProps('first_name')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="Last Name"
                  placeholder="Carl"
                  {...form.getInputProps('last_name')}
                />
              </Grid.Col>
              <Grid.Col>
                <TextInput label="Email Address" disabled value={memberData.user.email} />
              </Grid.Col>
              <Grid.Col>
                <PasswordInput
                  label="Password"
                  placeholder="Your Password"
                  {...form.getInputProps('password')}
                />
              </Grid.Col>
            </Grid>
          </Box>

          <Button
            my={'md'}
            onClick={handleSetupAccount}
            // loading={emailVerifyingLoader}
            // onClick={handleVerification}
          >
            Create Account
          </Button>

          <Center>
            <Text pb={16} size="sm" ta="center" c="dimmed">
              By signing up, You agree to our{' '}
              <Anchor onClick={() => window.open(getAppTermsOfService(), '_blank')}>
                terms of service
              </Anchor>{' '}
              and{' '}
              <Anchor onClick={() => window.open(getAppPrivacyPolicy(), '_blank')}>
                privacy policy
              </Anchor>
              .
            </Text>
          </Center>
        </Flex>
      </Container>
    </>
  );
};
