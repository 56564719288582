import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_URL } from '@/constants/Constants';
import { getAuthTokenLocalStorage } from '@/utils/Storage';

const onRequest = (
  config: AxiosRequestConfig
): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
  console.debug(`[request] [${JSON.stringify(config)}]`);
  const token = getAuthTokenLocalStorage();

  if (config.headers) {
    // Using type assertion to ensure TypeScript understands the correct type
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    } as AxiosRequestConfig['headers'];
  }

  // eslint-disable-next-line no-param-reassign
  config.baseURL = API_URL;
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  // console.debug(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.debug(`[response] [${JSON.stringify(response)}]`);
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  // console.debug(`[response error] [${JSON.stringify(error)}]`);
  // Error handling logic remains the same
  // ...

  return Promise.reject(error);
};

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  //@ts-ignore
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}

setupInterceptorsTo(axios);

export default axios;
