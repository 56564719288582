import { BioLinkService } from '@/services/BioLinkService';
import { BioLinksService } from '@/services/BioLinksService';
import { useUserStore } from '@/stores/useUserStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { getRelativeTime } from '@/utils/DateUtility';
import {
  faArrowPointer,
  faArrowUpRightFromSquare,
  faChartLine,
  faCircleExclamation,
  faClipboard,
  faClipboardCheck,
  faEye,
  faMagnifyingGlass,
  faPencil,
  faQrcode,
  faTrash,
  faCircleInfo
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Anchor,
  Badge,
  Box,
  Button,
  Center,
  CopyButton,
  Divider,
  Flex,
  Grid,
  Group,
  Loader,
  SegmentedControl,
  Text,
  TextInput,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useDebouncedValue, useDocumentTitle } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BioLinkPreviewContainer } from './Components/Preview/BioLinkPreviewContainer';
import { useBioLinkSetupStore } from '@/stores/Bio/useBioLinkSetupStore';
import { DEFUALT_BIO_LINK_VALUES } from '@replug/utils';
import { getShortenedURL } from '@/utils/LinkUtility';
import { numberToCommas } from '@/utils/StringUtility';
import { useQRModalStore } from '@/stores/useQRModalStore';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isUserCollaborator } from '@/utils/CommonUtility';

export function BioLinksPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  useDocumentTitle('Bio Links | ' + defaultWorkspace?.agency?.name);

  const [setQRModalOpened, setQRModalURL, setQRModalProps, setFgColor, setBgColor] =
    useQRModalStore((state) => [
      state.setIsOpen,
      state.setURL,
      state.setProps,
      state.setFgColor,
      state.setBgColor,
    ]);

  const [setBioLink] = useBioLinkSetupStore((state) => [state.setBioLink]);
  const [search, setSearch] = useState('');
  const [debounced] = useDebouncedValue(search, 200);
  const [linkType, setLinkType] = useState<string>('active');
  const [loading, setLoading] = useState<boolean>(true);
  const [list, setList] = useState<any[]>([]);
  const theme = useMantineTheme();
  const [user] = useUserStore((state) => [state.user]);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const navigate = useNavigate();
  const fetchBioLinks = async () => {
    setLoading(true);
    await new BioLinkService()
      .get(defaultWorkspace?._id || '', debounced)
      .then((res) => {
        if (res.data.status) {
          setList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const handleDeleteBioLink = async (id: string) => {
    await new BioLinkService()
      .delete(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          // Remove the item from the list
          const newList = list.filter((item) => item._id !== id);
          setList(newList);
          notifications.show({
            color: 'green',
            message: 'Bio link has been removed.',
          });
        } else {
          notifications.show({
            color: 'red',
            message: 'Something went wrong. Please contact support',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   *  Function to confirm the deletion of a rss.
   * @param id - The id of the rss to be deleted.
   */
  const confirmBioDelete = async (id: string) => {
    modals.openConfirmModal({
      title: <Title order={5}>Delete Bio Link</Title>,
      children: (
        <>
          <Text size="sm">Are you sure? This process can not be undone.</Text>
        </>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleDeleteBioLink(id),
    });
  };

  useEffect(() => {
    fetchBioLinks();
  }, [debounced, linkType]);
  return (
    <>
      <Flex align={'center'} pl={'lg'} pt={'xs'}>
        <Text size="xl" fw="bold" mr="sm">
          Bio Links
        </Text>
        <Tooltip
          position="bottom"
          multiline
          w={450}
          fz="xs"
          label=" Optimize your internet presence, whether you’re a blogger, an artist or run a content platform. You get one link to house all the content you’re driving followers to. Share that link anywhere, like your Instagram bio, Facebook posts or Twitch profile. ."
        >
          <FontAwesomeIcon
            style={{
              color: 'var(--mantine-color-gray-6)',
            }}
            icon={faCircleInfo}
          />
        </Tooltip>
      </Flex>
      <Grid my="md" px={'lg'} grow>
        <Grid.Col span={5}>
          <Flex align={'center'}>
            {(defaultPermissions && checkPermission(defaultPermissions, ['add_bio_link'])) && <Button
              size="xs"
              onClick={() => {
                setBioLink(DEFUALT_BIO_LINK_VALUES);
                navigate(`/bio-links/${defaultWorkspace?._id}/setup`);
              }}
            >
              Create new
            </Button>}
            <TextInput
              w={160}
              size="xs"
              ml={8}
              value={search}
              onChange={(event) => setSearch(event.currentTarget.value)}
              placeholder="Search for a link"
              leftSection={
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{
                    color: theme.colors.gray[5],
                  }}
                />
              }
            />
          </Flex>
        </Grid.Col>

        <Grid.Col span={7}></Grid.Col>
      </Grid>
      <Flex
        direction={'column'}
        mx={'md'}
        style={{
          background: 'var(--mantine-color-white)',
          justifyContent: 'space-between',
        }}
      >
        <Box
          style={{
            flexGrow: 1,
          }}
          p={'md'}
          mx="md"
        >
          <>
            {list.length === 0 && !loading ? (
              <>
                <Flex justify={'center'} align={'center'} direction={'column'} p={'lg'}>
                  <Text mb={'md'} fz={14} ta="center">
                    {debounced.length > 0 ? (
                      <>
                        No bio links found for <strong>{debounced}</strong>.
                      </>
                    ) : (
                      <>You have not created any bio links.</>
                    )}
                  </Text>
                  {(defaultPermissions && checkPermission(defaultPermissions, ['add_bio_link'])) && <Button
                    onClick={() => {
                      setBioLink(DEFUALT_BIO_LINK_VALUES);
                      navigate(`/bio-links/${defaultWorkspace?._id}/setup`);
                    }}
                  >
                    Create Bio Link
                  </Button>}
                </Flex>
              </>
            ) : (
              <>
                {loading ? (
                  <Flex justify={'center'} align={'center'} p={'lg'}>
                    <Loader size={'sm'} />
                  </Flex>
                ) : (
                  <>
                    <Group style={{
                      background: 'var(--mantine-color-white)',
                      justifyContent: 'center',
                      gap: "50px"
                    }}>
                      {list.map((bio, index) => (
                        <Flex direction={'column'} w={350}>
                          <Box
                            bg="gray.1"
                            style={{
                              borderRadius: 8,
                              marginBottom: 8,
                            }}
                            mx="lg"
                          >
                            <Flex align={'center'} justify={'center'} p="sm">
                              <Anchor href={getShortenedURL(bio)} target="_blank">
                                <Flex align={'center'} justify={'center'}>
                                  <Text fz={13} fw={500} c={'primary'} mr={8}>
                                    {getShortenedURL(bio)}
                                  </Text>
                                  <FontAwesomeIcon size="xs" icon={faArrowUpRightFromSquare} />
                                </Flex>
                              </Anchor>
                              <Tooltip label="Copy link">
                                <CopyButton value={getShortenedURL(bio)}>
                                  {({ copied, copy }) => (
                                    <ActionIcon
                                      ml={8}
                                      variant="subtle"
                                      color={copied ? 'teal' : 'primary'}
                                      onClick={copy}
                                    >
                                      {copied ? (
                                        <FontAwesomeIcon size="sm" icon={faClipboardCheck} />
                                      ) : (
                                        <FontAwesomeIcon size="sm" icon={faClipboard} />
                                      )}
                                    </ActionIcon>
                                  )}
                                </CopyButton>
                              </Tooltip>
                            </Flex>
                            <Divider />
                            <Group justify="center" mt={'sm'} grow>
                              <Tooltip label="Total number of views on your bio-link.">
                                <Flex direction={'column'} align={'center'} justify={'center'}>
                                  <FontAwesomeIcon icon={faEye} />
                                  <Text my="xs" fz={14}>
                                    {numberToCommas(bio.views)}
                                  </Text>
                                </Flex>
                              </Tooltip>

                              <Tooltip label="Total number of actions (clicks) on your bio-link.">
                                <Flex direction={'column'} align={'center'} justify={'center'}>
                                  <FontAwesomeIcon icon={faArrowPointer} />
                                  <Text my="xs" fz={14}>
                                    {numberToCommas(bio.clicks)}
                                  </Text>
                                </Flex>
                              </Tooltip>
                              <Tooltip label="Total number of QR-scans for your bio-link.">
                                <Flex direction={'column'} align={'center'} justify={'center'}>
                                  <FontAwesomeIcon icon={faQrcode} />
                                  <Text my="xs" fz={14}>
                                    {numberToCommas(bio.qr_scans)}
                                  </Text>
                                </Flex>
                              </Tooltip>
                            </Group>
                          </Box>

                          <BioLinkPreviewContainer
                            values={bio}
                            isListing={true}
                            blocks={bio.blocks}
                          />
                          <Flex
                            mt={'md'}
                            direction={'column'}
                            justify={'center'}
                            align={'center'}
                            py={'sm'}
                            style={{
                              borderRadius: 4,
                              border: '1px solid var(--mantine-color-gray-3)',
                            }}
                          >
                            <Group mb="sm" mt="sm">
                              <Tooltip label="View detailed analytics">
                                <ActionIcon
                                  onClick={() => {
                                    navigate(
                                      `/analytics/${defaultWorkspace?._id}/bio-links?link_id=${bio._id}`
                                    );
                                  }}
                                  variant="outline"
                                  color="gray"
                                  mr={'sm'}
                                >
                                  <FontAwesomeIcon icon={faChartLine} />
                                </ActionIcon>
                              </Tooltip>
                              <Tooltip label="Download QR Code">
                                <ActionIcon
                                  variant="outline"
                                  color="gray"
                                  mr={'sm'}
                                  onClick={() => {
                                    setQRModalURL(getShortenedURL(bio));
                                    setQRModalProps(bio);
                                    setFgColor('#242424');
                                    setBgColor('#FFFFFF');
                                    setQRModalOpened(true);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faQrcode} />
                                </ActionIcon>
                              </Tooltip>
                              {(defaultPermissions && checkPermission(defaultPermissions, ['edit_bio_link'])) && isUserCollaborator(user, defaultWorkspace?._id, bio, 'bio_link') && <Tooltip label="Edit bio link">
                                <ActionIcon
                                  onClick={() => {
                                    navigate(
                                      `/bio-links/${defaultWorkspace?._id}/setup/${bio._id}`
                                    );
                                  }}
                                  variant="outline"
                                  color="gray"
                                  mr={'sm'}
                                >
                                  <FontAwesomeIcon icon={faPencil} />
                                </ActionIcon>
                              </Tooltip>}

                              {(defaultPermissions && checkPermission(defaultPermissions, ['delete_bio_link'])) && isUserCollaborator(user, defaultWorkspace?._id, bio, 'bio_link') && <Tooltip label="Delete">
                                <ActionIcon
                                  variant="outline"
                                  color="red"
                                  mr={'sm'}
                                  onClick={() => {
                                    confirmBioDelete(bio._id);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </ActionIcon>
                              </Tooltip>}
                            </Group>
                            <Flex>
                              <Text fz={13} fw={500} c="dimmed">
                                Created {getRelativeTime(bio.created_at)}
                              </Text>
                            </Flex>
                          </Flex>
                        </Flex>
                      ))}
                    </Group>
                  </>
                )}
              </>
            )}
          </>
        </Box>
      </Flex>
    </>
  );
}
