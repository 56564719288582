import { DEFAULT_SOCIAL_BLOCK_VALUES, IBioSocialBlock } from '@replug/utils';
import { create } from 'zustand';

interface IBioSocialBlockStore {
  bioSocialBlock: IBioSocialBlock;
  setBioSocialBlock: (bioSocialBlock: IBioSocialBlock) => void;
  loader: boolean;
  setLoader: (loader: boolean) => void;
  reset: () => void;
}

export const useBioSocialBlockStore = create<IBioSocialBlockStore>((set, get) => ({
  bioSocialBlock: DEFAULT_SOCIAL_BLOCK_VALUES,
  setBioSocialBlock: (bioSocialBlock: IBioSocialBlock) => {
    set({ bioSocialBlock });
  },
  loader: false,
  setLoader: (loader: boolean) => {
    set({ loader });
  },
  reset: () => {
    set({ bioSocialBlock: DEFAULT_SOCIAL_BLOCK_VALUES });
  },
}));
