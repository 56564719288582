export const addUtmParametersToUrl = (baseUrl: string, utmParams: any) => {
  if (!baseUrl) return '';
  if (!baseUrl.startsWith('http')) baseUrl = `https://${baseUrl}`;

  // @ts-ignore
  let urlWithParams = new URL(baseUrl);

  Object.keys(utmParams).forEach((key) => {
    // Check if the value is 'none'; if not, append the parameter
    if (utmParams[key] && utmParams[key].toLowerCase() !== 'none' && key.startsWith('utm_')) {
      urlWithParams.searchParams.set(key, utmParams[key]);
    }
  });

  return urlWithParams.href;
};
