import { DEFAULT_BIO_VIDEO_BLOCK_VALUES, IBioVideoBlock } from '@replug/utils';
import { create } from 'zustand';

interface IBioVideoBlockStore {
  bioVideoBlock: IBioVideoBlock;
  setBioVideoBlock: (bioVideoBlock: IBioVideoBlock) => void;
  loader: boolean;
  setLoader: (loader: boolean) => void;
  reset: () => void;
}

export const useBioVideoBlockStore = create<IBioVideoBlockStore>((set, get) => ({
  bioVideoBlock: DEFAULT_BIO_VIDEO_BLOCK_VALUES,
  setBioVideoBlock: (bioVideoBlock: IBioVideoBlock) => {
    set({ bioVideoBlock: bioVideoBlock });
  },
  loader: false,
  setLoader: (loader: boolean) => {
    set({ loader });
  },
  reset: () => {
    set({ bioVideoBlock: DEFAULT_BIO_VIDEO_BLOCK_VALUES });
  },
}));
