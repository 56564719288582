import React, { useState, useEffect } from 'react';
import { MultiSelect, Box, Text } from '@mantine/core';


interface CustomMultiSelectProps {
    data: { value: string; label: string }[];
    form: any; // Pass the form instance
    path: string; // Path for the form input
}

const CustomValueComponent = ({ value, label, onRemove, ...others }: { value: any, label: any, onRemove: any }) => {
    return (
        <Box
            style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#f0f0f0',
                borderRadius: 4,
                padding: '2px 8px',
                margin: '2px 4px',
            }}
            {...others}
        >
            <Text size="sm">{label}</Text>
        </Box>
    );
};

export const CustomMultiSelect: React.FC<CustomMultiSelectProps> = ({ data, form, path }) => {
    const [selectOpened, setSelectOpened] = useState(false);
    const selectedValues = form.getInputProps(path).value || [];
    const error = form.getInputProps(path).error;

    const handleSelectAll = () => {
        if (selectedValues.length === data.length) {
            form.setFieldValue(path, []);
        } else {
            form.setFieldValue(path, data.map(item => item.value));
        }
    };

    const selectedCount = selectedValues.length;
    const displayValue =
        selectedCount > 1
            ? `${data.find(item => item.value === selectedValues[0])?.label} and ${selectedCount - 1} others`
            : selectedCount === 1
                ? data.find(item => item.value === selectedValues[0])?.label
                : 'Choose a value';

    const allSelected = selectedValues.length === data.length;

    return (
        <Box>
            {selectOpened ? (
                <MultiSelect
                    data={[
                        { value: 'select_all', label: allSelected ? 'Deselect All' : 'Select All' },
                        ...data,
                    ]}
                    {...form.getInputProps(path)}
                    placeholder="Choose a value"
                    searchable
                    valueComponent={CustomValueComponent}
                    onChange={(values) => {
                        if (values.includes('select_all')) {
                            handleSelectAll();
                        } else {
                            form.setFieldValue(path, values);
                        }
                    }}
                    onDropdownClose={() => setSelectOpened(false)}
                    styles={{
                        input: {
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            '&::placeholder': {
                                color: 'blue',
                            },
                            '&:focus': {
                                borderColor: 'transparent',
                                boxShadow: 'none',
                            },
                        },
                        value: {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: '100%',
                        },
                    }}
                />
            ) : (
                <Text
                    size="sm"
                    mt="6"
                    onClick={() => setSelectOpened(true)}
                    style={{ cursor: 'pointer', color: selectedCount > 0 ? '#003aa9' : 'black' }}
                >
                    {displayValue}
                </Text>
            )}
            {error && (
                <Text color="red" size="sm">
                    {error}
                </Text>
            )}
        </Box>
    );
};
