import { NoResultsDataTable } from '@/components/Menus/NoResultsDataTable/NoResultsDataTable';
import { Modal, Table, Title } from '@mantine/core';

interface IDNSRecordsModal {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  records: [];
}

export const DNSRecordsModal = ({ opened, setOpened, records }: IDNSRecordsModal) => {
  return (
    <>
      <Modal
        opened={opened}
        size="lg"
        onClose={() => setOpened(false)}
        title={<Title order={5}>DNS Records</Title>}
      >
        <Table mb={16}>
          <Table.Thead h={20} bg={'gray.1'} c="gray.7" fz="xs">
            <Table.Tr>
              <Table.Th>Record Type</Table.Th>
              <Table.Th>Record Class</Table.Th>
              <Table.Th>Hostname </Table.Th>
              <Table.Th>Points to</Table.Th>
              <Table.Th>TTL</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <NoResultsDataTable
              colSpan={5}
              noResultsMessage="No DNS records found"
              data={records}
            />
            {records.map((record: any, index: number) => (
              <Table.Tr key={index}>
                <Table.Td>{record.type}</Table.Td>
                <Table.Td>{record.class}</Table.Td>
                <Table.Td>{record.host}</Table.Td>
                <Table.Td>{record.type === 'CNAME' ? record.target : record.ip}</Table.Td>
                <Table.Td>{record.ttl}</Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Modal>
    </>
  );
};
