import { useUserStore } from '@/stores/useUserStore';
import { Avatar, Box, Button, Center, Divider, Flex, Grid, Loader, Title, Text } from '@mantine/core';
import { ProfileChangePassword } from './ProfileChangePassword';
import { ProfileSave } from './ProfileSave';
import { useDocumentTitle } from '@mantine/hooks';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { useEffect, useRef, useState } from 'react';
import { UtilsService } from '@/services/UtilsService';
import { notifications } from '@mantine/notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faPencil, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { UserService } from '@/services/UserService';

export function ProfilePage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [loading, setLoading] = useState(false);

  useDocumentTitle('Account Profile | ' + defaultWorkspace?.agency?.name);

  const [user, setUser] = useUserStore((state) => [state.user, state.setUser]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [loadingImage, setLoadingImage] = useState(false);

  const openFileInput = () => {
    // Trigger the click event on the file input element
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const getAvatarInitials = () => {
    if (user) {
      // @ts-ignore
      return user.first_name[0] + user.last_name[0];
    }
    return '';
  };

  const uploadImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      const formData = new FormData();
      formData.append('input_file', files[0]);
      setLoadingImage(true);
      await new UtilsService()
        .uploadImage(formData)
        .then((res) => {
          if (res.data.status) {
            // @ts-ignore
            setUser({ ...user, profile_image: res.data.url });
            const updatedUser = {
              first_name: user?.first_name,
              last_name: user?.last_name,
              profile_image: res.data.url
            };
            saveProfile(updatedUser)
          }
        })
        .catch((err) => {
          if (err.response.status === 400 || err.response.status === 422) {
            notifications.show({
              message: err.response.data.message,
              color: 'red',
            });
          }
        });
      setLoadingImage(false);
      // You can now use formData to send the file data to your server or perform any other actions.
    }
  };

  const saveProfile = async (formValues: any) => {
    setLoading(true);
    await new UserService()
      .updateProfileInformation({
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        profile_image: formValues.profile_image,
      })
      .then((res) => {
        if (res.data.status) {
          notifications.show({
            color: 'green',
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        notifications.show({
          color: 'red',
          message: err.response.data.message,
        });
      });
    setLoading(false);
  };

  const removeProfileImage = () => {
    if (user) {
      const updatedUser = {
        ...user,
        profile_image: '',
      };
      setUser(updatedUser);
      saveProfile({
        first_name: updatedUser.first_name,
        last_name: updatedUser.last_name,
        profile_image: '',
      });
    }
  };

  return (
    <Box
      style={{
        backgroundColor: 'var(--mantine-color-white)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        height: '100%',
        flexGrow: 1,
      }}
      ml={16}
      mr={16}
      pb={100}
    >
      <Box p={16}>
        <Center>
          <Title order={5}>Profile Settings</Title>
        </Center>
      </Box>
      <Divider color="gray.2" />

      <Grid gutter={32} mt={64}>
        <Grid.Col span={3}>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={uploadImage}
          />
          <Flex direction="column" align="center" justify="center" mt={64}>
          <Box style={{ position: 'relative' }}>
            <Avatar
              color={'primary'}
              src={user?.profile_image}
              styles={{
                placeholder: {
                  textTransform: 'uppercase',
                },
                root: {
                  cursor: 'pointer',
                  transition: 'all ease-in-out',
                  opacity: 0.7,
                },
              }}
              w={128}
              h={128}
              size={'xl'}
              onClick={openFileInput}
            >
              {loadingImage ? (
                <>
                  <Loader size="xs" />
                </>
              ) : (
                getAvatarInitials()
              )}
            </Avatar>
            {user?.profile_image && (
              <Box
                style={{
                  position: 'absolute',
                  top: -8,
                  right: -8,
                  background: 'white',
                  borderRadius: '50%',
                  padding: 4,
                  cursor: 'pointer',
                  color: 'red',
                }}
                onClick={removeProfileImage}
              >
                <FontAwesomeIcon icon={faTimes} size="xs" />
              </Box>
            )}
          </Box>
            <Text size="xs" color="gray" mt={8}>
              Recommended size: Less than 2MB (90 x 90)
            </Text>
            {user?.profile_image ? (
              <Button
                variant="outline"
                size="xs"
                leftSection={<FontAwesomeIcon icon={faPencil} />}
                onClick={openFileInput}
                mt={8}
              >
                Edit Image
              </Button>
            ) : (
              <Button
                variant="outline"
                size="xs"
                leftSection={<FontAwesomeIcon icon={faImage} />}
                onClick={openFileInput}
                mt={8}
              >
                Upload Image
              </Button>
            )}
            
          </Flex>
        </Grid.Col>
        <Grid.Col span={4}>
          <ProfileSave />
        </Grid.Col>
        <Grid.Col span={4}>
          <ProfileChangePassword />
        </Grid.Col>
      </Grid>
    </Box>
  );
}
