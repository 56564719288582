import { DEFAULT_LINK_BLOCK_VALUES, IBioLinkBlock } from '@replug/utils';
import { create } from 'zustand';

interface IBioLinkBlockStore {
  bioLinkBlock: IBioLinkBlock;
  setBioLinkBlock: (bioSocialBlock: IBioLinkBlock) => void;
  loader: boolean;
  setLoader: (loader: boolean) => void;
  reset: () => void;
}

export const useBioLinkBlockStore = create<IBioLinkBlockStore>((set, get) => ({
  bioLinkBlock: DEFAULT_LINK_BLOCK_VALUES,
  setBioLinkBlock: (bioLinkBlock: IBioLinkBlock) => {
    set({ bioLinkBlock });
  },
  loader: false,
  setLoader: (loader: boolean) => {
    set({ loader });
  },
  reset: () => {
    set({ bioLinkBlock: DEFAULT_LINK_BLOCK_VALUES });
  },
}));
