import { IntegrationServie } from '@/services/IntegrationService';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { Box, Button, Center, Modal, TextInput, Title } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { z } from 'zod';

interface IConnectIntegrationModalProps {
  isOpen: boolean;
  integration: any;
  onClose: () => void;
}

export const ConnectIntegrationModal = ({ isOpen, integration, onClose }: IConnectIntegrationModalProps) => {

  const [isConnecting, setIsConnecting] = useState(false);
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  const schema = z.object({
    key: integration?.fields?.includes('key') ? z.string().min(3, { message: 'Please enter a key' }) : z.string().optional(),
    url: integration?.fields?.includes('url') ? z.string().url({ message: 'Please enter a valid url' }) : z.string().optional(),
    name: integration?.fields?.includes('name') ? z.string().min(3, { message: 'Please enter a valid name' }) : z.string().optional(),
    secret: integration?.fields?.includes('secret') ? z.string().min(3, { message: 'Please enter a valid secret' }) : z.string().optional(),
  });

  const form = useForm({
    initialValues: {
      key: '',
      url: '',
      name: '',
      secret: '',
    },
    validate: zodResolver(schema),
  });

  const handleSubmit = async () => {
    form.validate();
    if (form.isValid()) {
      setIsConnecting(true); // Indicate connection attempt in progress

      const payload = { ...form.values, workspace_id: defaultWorkspace?._id, integration_name: integration.name };
      new IntegrationServie().connect(defaultWorkspace?._id || '', payload).then((response) => {
        response = response.data;
        if (response.status) {
          notifications.show({
            color: 'green',
            message: response.message || 'Integration connected successfully',
          });
          form.reset();
          onClose();
        } else {
          notifications.show({
            color: 'red',
            message: response.message || 'Connection failed. Please check your credentials.',
          });
        }
      }).catch((error) => {
        console.error('Error connecting to Integration:', error);
        notifications.show({
          color: 'red',
          message: error.response?.data?.message || 'Connection failed. Please check your credentials.',
        });
      }).finally(() => {
        setIsConnecting(false); // Reset connection state
      });
    };
  };

  const handleClose = () => {
    form.reset();
    onClose();
  };


  return (
    <>
      <Modal
        size={600}
        opened={isOpen}
        onClose={handleClose}
        title={<Title order={5}>Connect {integration?.label} to Your {defaultWorkspace?.agency?.name} Account</Title>}
      >
        {integration ? (
          <Box p={'md'}>
            {integration.fields.includes('name') && (
              <TextInput
                mb="md"
                label={integration.label + ' Account Name'}
                variant="filled"
                placeholder={"Your " + integration.label + " Account Name"}
                {...form.getInputProps('name')}
              />
            )}

            {integration.fields.includes('url') && (
              <TextInput
                mb="md"
                label={integration.label + ' API URL'}
                variant="filled"
                placeholder={"Your " + integration.label + " API URL"}
                {...form.getInputProps('url')}
              />
            )}
            {integration.fields.includes('key') && (
              <TextInput
                mb="md"
                label={integration.label + ' API Key'}
                variant="filled"
                placeholder={"Your " + integration.label + " API Key"}
                {...form.getInputProps('key')}
              />
            )}
            {integration.fields.includes('secret') && (
              <TextInput
                mb="md"
                label={integration.label + ' API Secret'}
                variant="filled"
                placeholder={"Your " + integration.label + " API Secret"}
                {...form.getInputProps('secret')}
              />
            )}

            <Center>
              <Button variant="outline" color="gray" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                ml={'sm'}
                color="primary"
                onClick={handleSubmit}
                loading={isConnecting}
                disabled={isConnecting}
              >
                Connect
              </Button>
            </Center>
          </Box>
        ) : (
            <Button variant="outline" color="gray" onClick={handleClose}>
            Cancel
          </Button>
        )}
      </Modal >
    </>
  );
};