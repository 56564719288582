import axios from './JWTService';

export class DeveloperAppService {
  get = (workspace_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`workspace/${workspace_id}/oauth/clients`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  create = (workspace_id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`workspace/${workspace_id}/oauth/clients`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  delete = (workspace_id: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`workspace/${workspace_id}/oauth/clients/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  update = (workspace_id: string, id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`workspace/${workspace_id}/oauth/clients/${id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  verifyClient = (clientId: string, redirectUri: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post('client/verify', { client_id: clientId, redirect_uri: redirectUri })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * Aprrve authorization request
   * @param clientId
   */
  approveClient = (clientId: string, state:string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`client/approve?client_id=${clientId}&response_type=code&state=${state}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * Reject authorization request
   * @param clientId
   */
  rejectClient = (clientId: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`client/deny?client_id=${clientId}&response_type=code`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }
}
