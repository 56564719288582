import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { Box, Text, Button, Divider, Flex, Group, ActionIcon, Title } from '@mantine/core';
import classes from '../LinkCreationModal.module.css';
import { TrafficRoutingModal } from '../TrafficRoutingModal';
import { TrafficRoutingGroup } from './TrafficRoutingGroup';
import { useState, useEffect } from 'react'
import { DEFAULT_ROUTING_RULES } from '@/constants/Constants';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';

export const TrafficRouting = () => {
  //variables
  const [link, setLink, routingRules, setRoutingRules] = useLinkSetupStore((state) => [state.link, state.setLink, state.routingRules, state.setRoutingRules]);
  const [trafficRoutingModalOpened, setTrafficRoutingModalOpened] = useState(false);
  const [editRuleIndex, setEditRuleIndex] = useState(null);

  //functions
  const handleTrafficRoutingModalShow = () => {
    if (link?.routing_attributes?.length < 10) {
      setTrafficRoutingModalOpened((prevState) => !prevState);
    } else {
      showNotification({
        title: 'Error',
        message: 'You can not add more than 10 rules.',
        color: 'red',
      });
    }
  };
  const handleClose = () => {
    setTrafficRoutingModalOpened(false);
    setEditRuleIndex(null);
  };
  const handleEditRule = (index: any) => {
    setEditRuleIndex(index);
    setTrafficRoutingModalOpened(true); // Open the modal for editing
  };
  const handleDeleteRule = (index: any) => {
    modals.openConfirmModal({
      title: <Title order={5}>Delete Rule</Title>,
      children: (
        <>
          <Text size="sm">
            Are you sure you want to delete the rule? This action is irrervrsible.
          </Text>
        </>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => {
        const updatedRoutingAttributes = [...link.routing_attributes];
        updatedRoutingAttributes.splice(index, 1); // Remove the rule at the specified index
        setLink({ ...link, routing_attributes: updatedRoutingAttributes });
      },
    });
  };

  return (
    <Box p={16} className={!link.is_routing_enabled ? classes.disabled : ''}>
      <Text fw={600} fz={14}>
        Traffic Routing Rules
      </Text>
      <TrafficRoutingModal
        opened={trafficRoutingModalOpened}
        onClose={handleClose}
        ruleIndex={editRuleIndex}
      />
      <Box style={{ height: '300px' }}> {/* Adjust height as needed */}
        <TrafficRoutingGroup
          onEditRule={handleEditRule}
          onDeleteRule={handleDeleteRule}
        />
      </Box>
      <Flex justify={'space-between'}>
        <Button
          variant="subtle"
          color="primary"
          size="xs"
          onClick={handleTrafficRoutingModalShow}
        >
          + Add new rule
        </Button>
      </Flex>
      <Divider mt={5} mb={5} />
      <Text size="sm" color="gray" mb={10} mt={10} fw={500}>
        Otherwise point to default destination URL.
      </Text>
    </Box>
  );
};
