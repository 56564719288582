import { AnalyticsBioService } from '@/services/AnalyticsBioService';
import { AnalyticsService } from '@/services/AnalyticsService';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex, Loader, Text } from '@mantine/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
// @ts-ignore
import * as echarts from 'echarts/dist/echarts.esm';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';

const DEFAULT_SERIES = [
  {
    name: 'Views',
    type: 'bar',
    stack: 'total',

    smooth: true,
    showSymbol: false,
    data: [],
  },
  {
    name: 'Clicks',
    type: 'bar',
    stack: 'total',

    smooth: true,
    showSymbol: false,
    data: [],
  },
  {
    name: 'QR Scans',
    type: 'bar',
    stack: 'total',

    smooth: true,
    showSymbol: false,
    data: [],
  },
];
export const BioAnalyticsHistogram = () => {
  // Get Values from Query Params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fromDate =
    queryParams.get('from_date') ||
    format(startOfDay(subDays(new Date(), 30)), "yyyy-MM-dd'T'HH:mm:ss");
  const toDate =
    queryParams.get('to_date') || format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss");
  const linkId = queryParams.get('link_id');

  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [option, setOption] = useState<any>({
    color: ['#054dd4', '#F9BC4D', '#96f2d7'],
    title: {
      text: '',
    },
    notMerge: true,
    tooltip: {
      //   borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2],
      //   backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : '#fff',
      //   textStyle: {
      //     color: theme.colorScheme === 'dark' ? '#A6A7AB' : '#595c5f',
      //   },
      trigger: 'axis',
      axisPointer: {
        type: 'line',
      },
      //   formatter: (params: any) => GenericTooltip(params, false),
    },
    legend: {
      show: false,
      data: [],
    },
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      top: '7%',
      left: '1%',
      right: '3%',
      bottom: '5%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'time',
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: '{MMM} {dd}',
          fontSize: 10,
          axisPointer: {
            show: false,
          },
          margin: 14,
        },
        splitLine: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        min: 0,
        type: 'value',
        splitLine: true,
        alignTicks: true,
        axisTick: {
          show: true,
          splitNumber: 4,
        },
      },
    ],
    series: DEFAULT_SERIES,
    // replaceMerge: ['series'],
  });

  const [loading, setLoading] = useState(true);

  const fetchHistogram = async () => {
    setLoading(true);
    await new AnalyticsBioService()
      .histogram(defaultWorkspace?._id || '', linkId, fromDate, toDate)
      .then((res) => {
        if (res.data.status && res.data.data.length > 0) {
          const viewsData = res.data.data.map((item: any) => [item.date, item.views]);
          const clicksData = res.data.data.map((item: any) => [item.date, item.clicks]);
          const qrScansData = res.data.data.map((item: any) => [item.date, item.qr_scans]);

          const eChartSeries = [
            {
              name: 'Views',
              type: 'bar',
              stack: 'total',

              smooth: true,
              showSymbol: false,
              data: viewsData,
            },
            {
              name: 'Clicks',
              type: 'bar',
              stack: 'total',

              smooth: true,
              showSymbol: false,
              data: clicksData,
            },
            {
              name: 'QR Scans',
              type: 'bar',
              stack: 'total',
              smooth: true,
              showSymbol: false,
              data: qrScansData,
            },
          ];

          const newOption = { ...option, series: eChartSeries };
          setOption(newOption);
        } else {
          const newOption = { ...option, series: DEFAULT_SERIES };
          setOption(newOption);
        }
      })
      .catch((err) => {
        console.log(err);
        const newOption = { ...option, series: DEFAULT_SERIES };
        setOption(newOption);
      });
    setLoading(false);
  };

  const checkIfSeriesAreEmpty = () => {
    return (
      option.series[0].data.length === 0 &&
      option.series[1].data.length === 0 &&
      option.series[2].data.length === 0
    );
  };

  const checkIfSeriesDataPointsAreZero = () => {
    return (
      option.series[0].data.every((item: any) => parseInt(item[1], 10) === 0) &&
      option.series[1].data.every((item: any) => parseInt(item[1], 10) === 0) &&
      option.series[2].data.every((item: any) => parseInt(item[1], 10) === 0)
    );
  };

  useEffect(() => {
    fetchHistogram();
  }, [linkId, fromDate, toDate]);
  return (
    <>
      {loading ? (
        <Box h={240}>
          <Flex mt={64} justify={'center'} align={'center'}>
            <Loader size={'sm'} />
          </Flex>
        </Box>
      ) : (
        <>
          {checkIfSeriesAreEmpty() || checkIfSeriesDataPointsAreZero() ? (
            <>
              <Box h={240}>
                <Flex mt={64} justify={'center'} align={'center'} direction={'column'}>
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    size="2x"
                    style={{ color: 'var(--mantine-color-gray-5)', marginBottom: 9 }}
                  />
                  <Text fz={14} c="dimmed">
                    No data available
                  </Text>
                </Flex>
              </Box>
            </>
          ) : (
            <>
              <ReactEChartsCore
                style={{ height: '240px' }}
                echarts={echarts}
                option={option}
                notMerge
              />
            </>
          )}
        </>
      )}
    </>
  );
};
