import React, { Dispatch, SetStateAction } from 'react';
import { Modal, Flex, Box, Image, Text, Card, BackgroundImage, Button, Anchor } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { useMediaQuery } from '@mantine/hooks';
import ModalBG from '../../../assets/images/common/bg.svg';
import { IconExternalLink, IconPlayerSkipForward } from '@tabler/icons-react';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';

type Props = {
    opened: boolean;
    setOpened: Dispatch<SetStateAction<boolean>>;
    imageSource?: string;
};

export const WelcomeModal = ({
    opened,
    setOpened,
    imageSource = '/images/tools/youtube-keyword/limit.svg',
}: Props) => {
    const matches = useMediaQuery('(max-width: 768px)');
    const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
    // Define arrays for cards
    const cards = [
        {
            id: 1,
            title: '🎨 Sleek, Intuitive Dashboard',
            description: 'Navigate effortlessly through a user-friendly interface designed for productivity.',
            image: 'https://storage.googleapis.com/replug-assets/web_app/6d06501a-b78e-4ac4-83c2-57c51eba8c6a.jpg',
            imageHeight: 160,
        },
        {
            id: 2,
            title: '🔗 Enhanced Bio Links',
            description: 'Create stunning link pages with our revamped, ready-to-use templates.',
            image: 'https://storage.googleapis.com/replug-assets/web_app/ed7a4b96-3748-4db6-932a-f8633cc36cf2.jpg',
            imageHeight: 160,
        },
        {
            id: 3,
            title: '🏢 Powerful Workspaces',
            description: 'Formerly brands, our new workspaces provide dedicated environments to oversee project performance.',
            image: 'https://storage.googleapis.com/replug-assets/web_app/d4b62251-1103-474a-8bb3-8fcbe4c615c1.jpg',
            imageHeight: 140,
        },
        {
            id: 4,
            title: '📊 Advanced Analytics',
            description: 'Gain quick insights into key metrics, empowering you to make data-driven decisions.',
            image: 'https://storage.googleapis.com/replug-assets/web_app/9ed9ae76-c33d-4217-b014-e2665fc99db1.jpg',
            imageHeight: 140,
        },
    ];

    return (
        <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            centered
            radius={16}
            size="64rem"
            closeOnClickOutside={false}
            overlayProps={{
                opacity: 0.3,
                color: '#000000',
            }}
        >
            <BackgroundImage src={ModalBG}>
                <Box p={16}>
                    <Flex justify="center" direction="column" align="center">
                        <Text fw={600} mb={8} fz={24} pt={8}>
                            {!defaultWorkspace?.agency?.is_whitelabel_enabled ? 'Introducing Replug 2.0: Your Link Management Suite, Reimagined 🚀' : ' Your Link Management Suite, Reimagined 🚀'}
                        </Text>
                        <Text fw={600} mb={8} fz={20} pt={8}>
                            {!defaultWorkspace?.agency?.is_whitelabel_enabled ? "What's New in Replug 2.0?" : "What's New?"}
                        </Text>
                        <Carousel
                            my={24}
                            slideGap="lg"
                            align="center"
                            slideSize={matches ? '100%' : '45%'}
                            slidesToScroll={1}
                            loop

                            nextControlProps={{
                                style: {
                                    backgroundColor: '#003aa9',
                                    color: '#FFFFFF',
                                    height: '36px',
                                    width: '36px',
                                },
                            }}
                            previousControlProps={{
                                style: {
                                    backgroundColor: '#003aa9',
                                    color: '#FFFFFF',
                                    height: '36px',
                                    width: '36px',
                                },
                            }}
                        >
                            {cards.map((card) => (
                                <Carousel.Slide key={card.id}>
                                    <Card withBorder shadow="md" radius="md" style={{ maxHeight: '298px' }}>
                                        <Card.Section style={{ padding: '16px' }}>
                                            <Image
                                                src={card.image}
                                                height="auto"
                                                width="100%"
                                                fit="contain"
                                                alt=""
                                                style={{ objectFit: 'contain', maxHeight: `${card.imageHeight}px` }}
                                            />
                                        </Card.Section>
                                        <Text ta="center" fw={700} mt="md" px="lg">
                                            {card.title}
                                        </Text>
                                        <Text ta="center" px="lg">
                                            {card.description}
                                        </Text>
                                    </Card>
                                </Carousel.Slide>
                            ))}
                        </Carousel>
                        <Flex justify="center" my={24} align="center" direction="row" gap={12}>
                            <Button
                                onClick={() => setOpened(false)}
                                size="md"
                                fullWidth
                                variant="outline"
                            >
                                Skip and try <IconPlayerSkipForward style={{
                                    marginLeft: '5px',
                                }} size={16} />
                            </Button>
                            <Anchor target='_blank' rel="noreferrer" href='https://updates.replug.io/announcements'>
                                <Button
                                    variant="filled"
                                    size="md"
                                    fullWidth
                                >
                                    Read full announcement  <IconExternalLink style={{
                                        marginLeft: '5px',
                                    }} size={18} />
                                </Button>
                            </Anchor>
                        </Flex>
                    </Flex>
                </Box>
            </BackgroundImage>
        </Modal>
    );
};
function createStyles(arg0: (theme: any) => {
    control: {
        backgroundColor: string; // Set your desired blue color here
        color: string; // Set text/icon color if necessary
        '&:hover': { backgroundColor: string; };
    };
}) {
    throw new Error('Function not implemented.');
}

