// src/components/Auth/GoogleCallback.jsx
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '@/stores/useUserStore';
import { setAuthTokenLocalStorage } from '@/utils/Storage';
import { notifications } from '@mantine/notifications';
import { Title, Center, Loader } from '@mantine/core';
import axios from 'axios';
import { API_URL } from '@/constants/Constants';

export function GoogleCallback() {
    const navigate = useNavigate();
    const [setUser] = useUserStore((state) => [state.setUser]);

    useEffect(() => {
        console.log('entered google callback function');
        
        const handleCallback = () => {
            try {
                const params = new URLSearchParams(window.location.search);
                const token = params.get('token');
                const userStr = params.get('user');
                const isWorkspaceMember = params.get('is_workspace_member') === 'true' || params.get('is_workspace_member') === '1';
                const error = params.get('error');

                if (error) {
                    throw new Error(error);
                }

                if (!token || !userStr) {
                    throw new Error('Invalid response');
                }

                const user = JSON.parse(userStr);

                // Save token
                setAuthTokenLocalStorage(token);

                // Set user in store
                setUser(user);

                // Check workspace membership
                if (!isWorkspaceMember) {
                    notifications.show({
                        color: 'red',
                        message: 'You are not added to any Workspace, Please contact admin.',
                    });
                    navigate('/login');
                    return;
                }

                notifications.show({
                    color: 'green',
                    message: 'Successfully signed in with Google!'
                });

                // Navigate based on email verification
                if (!user.email_verified_at) {
                    navigate('/verification');
                } else {
                    navigate('/dashboard');
                }
            } catch (error: any) {
                console.error('Google auth error:', error);
                notifications.show({
                    color: 'red',
                    message: error.message || 'Authentication failed'
                });
                navigate('/login');
            }
        };

        handleCallback();
    }, [navigate, setUser]);

    return (
        <Center h="100vh" bg="#f2f6f7">
            <div className="text-center">
                <Loader size="md" mb={16} />
                <Title order={3}>Completing sign in...</Title>
            </div>
        </Center>
    );
}