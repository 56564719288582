import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Image,
  ScrollArea,
  SegmentedControl,
  Table,
  Text,
  Title,
} from '@mantine/core';
import React, { Key, useEffect, useState } from 'react';
import { useDocumentTitle } from '@mantine/hooks';
import { ConnectIntegrationModal } from '@/components/Modals/ConnectIntegrationModal/ConnectIntegrationModal';
import { IntegrationServie } from '@/services/IntegrationService';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { notifications } from '@mantine/notifications';
import { useSearchParams } from 'react-router-dom';
import { modals } from '@mantine/modals';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission } from '@/utils/CommonUtility';
import { useUserStore } from '@/stores/useUserStore';

export function IntegrationsPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const name = defaultWorkspace?.agency?.name;

  useDocumentTitle('Integrations | ' + name);
  const [searchParams, setSearchParams] = useSearchParams();
  const [integrationsList, setIntegrationsList] = useState<any>([]);
  const [connectedIntegrations, setConnectedIntegrations] = useState<any>({});

  const [opened, setOpened] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);

  const [value, setValue] = useState('available_integrations'); // ['replug_links', 'bio_links'
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [user] = useUserStore((state) => [state.user]);
  

  /**
   * Get available integrations from the API
   * Uses the IntegrationService to fetch the available integrations
   */
  const getIntegrationsList = async () => {
    await new IntegrationServie()
      .getList(defaultWorkspace?._id || '')
      .then((res) => {
        if (res.data.status) {
          setIntegrationsList(res.data?.data || []);
        } else {
          notifications.show({
            color: 'red',
            message: res.data?.message || 'Error fetching integrations',
          });
        }
      })
      .catch((err) => {
        console.error(err);
        notifications.show({
          color: 'red',
          message: err.response?.data?.message || 'Error fetching integrations',
        });
      });
  };

  /**
   * Get connected integrations for the default workspace from the API
   * Uses the IntegrationService to fetch the connected integrations
   */
  const getConnectedIntegrations = async () => {
    await new IntegrationServie()
      .getConnected(defaultWorkspace?._id || '')
      .then((res) => {
        if (res.data.status) {
          setConnectedIntegrations(res.data?.data || []);
        } else {
          notifications.show({
            color: 'red',
            message: res.data?.message || 'Error fetching connected integrations',
          });
        }
      })
      .catch((err) => {
        console.error(err);
        notifications.show({
          color: 'red',
          message: err.response?.data?.message || 'Error fetching connected integrations',
        });
      });
  };

  /**
   * Connect to an integration
   * @param integration
   */
  const connectIntegration = async (integration: any) => {
    if (integration.auth_type === 'oauth2' && integration.auth_url) {

      // save the integration connection details to the local storage
      localStorage.setItem('integrationDetails', JSON.stringify({
        integrationName: integration.name,
        workspaceId: defaultWorkspace?._id,
      }));

      // Redirect to the auth URL
      window.location.href = integration.auth_url;
    } else {
      setOpened(true);
      setSelectedIntegration(integration);
    }
  };


  const handleConfirmDisconnect = (id: string) => {
    modals.openConfirmModal({
      title: <Title order={5}>Disconnect Integration</Title>,
      children: (
        <>
          <Text size="sm">Are you sure? Your associated campaigns for this account will be paused.</Text>
        </>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => disconnectIntegration(id),
    });
  };

  /**
   * Disconnect from an integration
   * @param integrationId
   */
  const disconnectIntegration = async (integrationId: string) => {
    await new IntegrationServie().disconnect(defaultWorkspace?._id || '', integrationId)
      .then((res) => {
        if (res.data.status) {
          notifications.show({
            color: 'green',
            message: 'Integration disconnected successfully',
          });
          getConnectedIntegrations();
        } else {
          notifications.show({
            color: 'red',
            message: res.data.message || 'Error disconnecting integration',
          });
        }
      })
      .catch((err) => {
        console.error(err);
        notifications.show({
          color: 'red',
          message: err.response?.data?.message || 'Error disconnecting integration',
        });
      });
  };

  /**
   * Handle the connection callback from the integration
   * @param integrationName
   * @param code
   * @param state
   */
  const handleConnectionCallback = async (integrationName: string, code: string, state: string | null) => {
    // connect the integration
    new IntegrationServie()
      .connect(defaultWorkspace?._id || '', {
        code,
        state,
        integration_name: integrationName,
        workspace_id: defaultWorkspace?._id,
      })
      .then((response) => {
        response = response.data;
        if (response.status) {
          notifications.show({
            color: 'green',
            message: response.message || 'Integration connected successfully',
          });
        } else {
          notifications.show({
            color: 'red',
            message: response.message || 'Connection failed. Please check your credentials.',
          });
        }
      })
      .catch((error) => {
        console.error('Error connecting to Integration:', error);
        notifications.show({
          color: 'red',
          message: error.response?.data?.message || 'Connection failed. Please check your credentials.',
        });
      }).finally(() => {
        // fetch the connected integrations
        getConnectedIntegrations();
      });
  };

  /**
   * Get the number of connections for an integration
   * @param integration
   * @returns
   */
  const getConnectionCount = (integration: any) => {
    return connectedIntegrations[integration.name]?.length || 0;
  };

  const getIntegrationLogo = (integration: any) => {
    return `https://storage.googleapis.com/replug-assets/integrations/${integration.logo}`;
  };

  /**
   * Get connected integrations for the integration
   * @param integration
   * @returns {Array}
   */
  const getConnectedIntegrationsForIntegration = (integration: any): Array<any> => {
    return connectedIntegrations[integration.name] || [];
  };

  useEffect(() => {
    // check if the user is redirected from an oauth2 integration
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    const integrationName = searchParams.get('integrationName');

    // remove the integration connection details from query params
    setSearchParams({});

    // clear the integration connection details from the local storage
    localStorage.removeItem('integrationDetails');

    if (code && integrationName) {
      handleConnectionCallback(integrationName, code, state);
    } else {
      getConnectedIntegrations();
    }

    getIntegrationsList();
  }, []);

  return (
    <>
      <ConnectIntegrationModal
        isOpen={opened}
        onClose={() => {
          setOpened(false);
          setSelectedIntegration(null);
          getConnectedIntegrations();
        }}
        integration={selectedIntegration}
      />
      <Box
        style={{
          backgroundColor: 'var(--mantine-color-white)',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        }}
        ml={16}
        mr={16}
      >
        <Grid p={16}>
          <Grid.Col span={5}>
            <></>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex align={'center'}>
              <Title pt={2} order={5} mr={8}>
                Integrations
              </Title>
            </Flex>
          </Grid.Col>
          <Grid.Col span={5}>
            <Flex justify={'flex-end'}>
              <SegmentedControl
                size="xs"
                color="primary"
                data={[
                  { label: 'Available Integrations', value: 'available_integrations' },
                  { label: 'Connected Integrations', value: 'connected_integrations' },
                ]}
                value={value}
                onChange={setValue}
              />
            </Flex>
          </Grid.Col>
        </Grid>
        <Divider color="gray.2" />
        {value === 'available_integrations' && (
        <Table stickyHeader verticalSpacing={'md'}>
          <Table.Thead h={20} bg={'gray.1'} c="gray.7" fz="xs">
            <Table.Tr>
              <Table.Th>Integration</Table.Th>
              {defaultPermissions && checkPermission(defaultPermissions, ['add_integrations']) && <Table.Th ta="right">Configuration</Table.Th>}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
              {integrationsList.map((item: { logo: any; label: string; auth_type: string }, index: Key) => (
                (!defaultWorkspace?.agency?.is_whitelabeled || item?.auth_type !== 'oauth2') &&
                <Table.Tr key={index}>
                  <Table.Td>
                    <Flex align={'center'}>
                      <Image
                        radius={'sm'}
                        mr={8}
                        h={48}
                        w={48}
                        src={getIntegrationLogo(item)}
                      />
                      <Flex direction={'column'}>
                        <Text pt={4} size="sm" fw={600}>
                          {item.label}
                        </Text>
                        <Text size="xs">Send subscribers directly to {item.label} from {name}</Text>
                      </Flex>
                    </Flex>
                  </Table.Td>
                  {defaultPermissions && checkPermission(defaultPermissions, ['add_integrations']) && <Table.Td ta="right">
                    <Flex justify={'flex-end'}>
                      <div style={{ position: 'relative', display: 'inline-block' }}>
                      <Button size="xs" onClick={() => connectIntegration(item)}>
                        Connect
                        </Button>
                        {getConnectionCount(item) > 0 && (
                          <Badge
                            size="sm"
                            circle
                            color="red"
                            style={{
                              position: 'absolute',
                              top: '-6px',
                              right: '-6px',
                            }}
                          >
                            {getConnectionCount(item)}
                          </Badge>
                        )}
                      </div>
                    </Flex>
                  </Table.Td>}
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        )}
        {value === 'connected_integrations' && (
          <Table stickyHeader verticalSpacing={'md'}>
            <Table.Thead h={20} bg={'gray.1'} c="gray.7" fz="xs">
              <Table.Tr>
                <Table.Th>Integration</Table.Th>
                <Table.Th>Account Name</Table.Th>
                <Table.Th>Status</Table.Th>
                {defaultPermissions && checkPermission(defaultPermissions, ['add_integrations']) && <Table.Th ta="right">Actions</Table.Th>}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {integrationsList.map((integration: any, index: Key) => (
                (!defaultWorkspace?.agency?.is_whitelabeled || integration?.auth_type !== 'oauth2') &&
                <React.Fragment key={index}>
                  {
                    getConnectedIntegrationsForIntegration(integration)?.map((item: any, index: Key) => (
                      <Table.Tr key={index}>
                        <Table.Td>
                          <Flex align={'center'}>
                            <Image
                              radius={'sm'}
                              mr={8}
                              h={48}
                              w={48}
                              src={getIntegrationLogo(integration)}
                            />
                            <Flex direction={'column'}>
                              <Text pt={4} size="sm" fw={600}>
                                {integration.label}
                              </Text>
                            </Flex>
                          </Flex>
                        </Table.Td>
                        <Table.Td>
                          <Text size="sm">{item.name}</Text>
                        </Table.Td>
                        <Table.Td>
                          {item.validity === 'valid' && (
                            <Badge color="green">Connected</Badge>
                          )}
                          {item.validity === 'invalid' && (
                            <Badge color="red">Invalid</Badge>
                          )}
                        </Table.Td>
                        {defaultPermissions && checkPermission(defaultPermissions, ['add_integrations']) && <Table.Td ta="right">
                          <Button size="xs" onClick={() => connectIntegration(integration)}>
                            Reconnect
                          </Button>
                          <Button size="xs" color="red" ml={8} onClick={() => handleConfirmDisconnect(item._id)}>
                            Disconnect
                          </Button>
                        </Table.Td>}
                      </Table.Tr>
                    ))
                  }
                </React.Fragment>
              ))}
            </Table.Tbody>
          </Table>
        )}

      </Box>
    </>
  );
}
