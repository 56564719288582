import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { Box, Text } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import classes from '../LinkCreationModal.module.css';
import { useEffect } from 'react';

export const LinkExpiry = () => {
  const [link, setLink] = useLinkSetupStore((state) => [state.link, state.setLink]);

  useEffect(() => {
    if (link.is_link_expiry_enabled) {
      if (!link.link_expiry_attributes.expiry_date) {
        setLink({
          ...link,
          link_expiry_attributes: {
            expiry_date: new Date(),
          },
        });
      }
    }
  }, [link.is_link_expiry_enabled])
  
  return (
    <Box p={16} className={!link.is_link_expiry_enabled ? classes.disabled : ''}>
      <Text fw={600} fz={14}>
        Link Expiry
      </Text>
      <DateTimePicker
        minDate={new Date()}
        value={
          link.link_expiry_attributes.expiry_date
            ? new Date(link.link_expiry_attributes.expiry_date)
            : new Date()
        }
        onChange={(value) => {
          if (value && value.toISOString()) {
            const newLink = { ...link };
            newLink.link_expiry_attributes.expiry_date = value.toISOString();
            setLink(newLink);
          }
        }}
        mt={12}
        variant="filled"
        placeholder="Pick date and time."
      />
    </Box>
  );
};
