import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Tooltip } from '@mantine/core';
import { useState } from 'react';
import { ExportLinksModal } from '../Modals/ExportLinksModal/ExportLinksModal';

export const ExportLinksButton = () => {
  const [opened, setOpened] = useState(false);
  return (
    <>
      <ExportLinksModal
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
      />
      <Tooltip label="Export to CSV">
        <ActionIcon
          variant="outline"
          color="gray"
          onClick={() => {
            setOpened(!opened);
          }}
        >
          <FontAwesomeIcon icon={faDownload} />
        </ActionIcon>
      </Tooltip>
    </>
  );
};
