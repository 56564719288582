import { Box, Button, Flex, Modal, MultiSelect, TagsInput, Title } from '@mantine/core';
import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { z } from 'zod';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { zodResolver } from 'mantine-form-zod-resolver';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { LinksService } from '@/services/LinksService';
import { notifications } from '@mantine/notifications';

const exportLinksSchema = z.object({
  workspace_id: z.string(),
  campaign_ids: z
    .array(z.string().min(1))
    .min(1, { message: 'At least one campaign must be selected' }),
  email_addresses: z.array(z.string()).min(1, { message: 'At least one email must be selected' }),
});

export const ExportLinksModal = ({ opened, onClose }: { opened: boolean; onClose: () => void }) => {
  const [campaigns, setCampaigns] = useCampaignsStore((state) => [
    state.campaigns,
    state.setCampaigns,
  ]);
  const [loading, setLoading] = useState(false);
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  const form = useForm({
    validate: zodResolver(exportLinksSchema),
    initialValues: {
      workspace_id: defaultWorkspace?._id,
      campaign_ids: campaigns.map((campaign) => campaign._id),
      email_addresses: [],
    },
  });

  const handleFormSubmit = async () => {
    form.validate();
    if (form.isValid()) {
      setLoading(true);
      await new LinksService()
        .export(defaultWorkspace?._id || '', form.values.campaign_ids, form.values.email_addresses)
        .then((res) => {
          if (res.data.status) {
            notifications.show({
              color: 'green',
              message: res.data.message,
            });
          }
          console.log(res);
          onClose();
        })
        .catch((err) => {
          console.error(err);
          notifications.show({
            color: 'red',
            message: err.response?.data?.message || 'Error exporting links',
          });
        });

      setLoading(false);
    }
  };

  useEffect(() => {
    if (opened) {
      form.setValues({
        workspace_id: defaultWorkspace?._id,
        campaign_ids: campaigns.map((campaign) => campaign._id),
        email_addresses: [],
      });
    }
  }, [opened]);

  return (
    <Modal
      size={600}
      opened={opened}
      onClose={onClose}
      title={<Title order={5}>Export Links</Title>}
    >
      <Box p={16}>
        <form onSubmit={form.onSubmit(handleFormSubmit)}>
          <MultiSelect
            searchable
            mb={12}
            variant="filled"
            label="Campaigns"
            placeholder="Select your campaigns"
            data={campaigns.map((campaign) => ({
              label: campaign.name,
              value: campaign._id,
            }))}
            {...form.getInputProps('campaign_ids')}
          />
          <TagsInput
            mb={12}
            label="Where should we send an email to?"
            variant="filled"
            placeholder="Enter your email address (Note: Press enter key to save email)"
            {...form.getInputProps('email_addresses')}
          />

          <Flex mt={32} align={'center'} justify={'center'}>
            <Button disabled={loading} loading={loading} type="submit" mr={12}>
              Export Links
            </Button>
            <Button onClick={onClose} variant="outline" color="gray">
              Cancel
            </Button>
          </Flex>
        </form>
      </Box>
    </Modal>
  );
};
