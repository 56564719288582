import { TagsService } from '@/services/TagsService';
import { create } from 'zustand';

interface ITagsStore {
  tags: string[];
  setTags: (tags: string[]) => void;
  selectedTags: string[];
  setSelectedTags: (tags: string[]) => void;
  fetchTags: (workspace_id: string) => void;
  createTag: (workspaceId: string, payload: { _id: null | string; name: string; workspace_id: string }) => void;
}

export const useTagsStore = create<ITagsStore>((set, get) => ({
  tags: [],
  setTags: (tags) => {
    set({ tags });
  },
  selectedTags: [],
  setSelectedTags: (tags) => {
    set({ selectedTags: tags });
  },
  fetchTags: (workspace_id) => {
    new TagsService()
      .getAll(workspace_id)
      .then((res) => {
        if (res.data.status) {
          set({ tags: res.data.data });
        } else {
          set({ tags: [] });
        }
      })
      .catch((err) => {
        console.log(err);
        set({ tags: [] });
      });
  },
  createTag: async (workspaceId, payload) => {
    const resp = await new TagsService()
      .create(workspaceId, payload)
      .then((res) => {
        console.log('createTag', res.data);
        if (res.data.status) {
          const prevTags = get().tags;
          prevTags.push(res.data.data);
          set({ tags: prevTags });
          return res.data.data;
        }
        return false;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
    return resp;
  },
}));
