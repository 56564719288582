import { UtilsService } from '@/services/UtilsService';
import { faImage, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Button, Flex, Loader, Tooltip, Text } from '@mantine/core';
import { useRef, useState } from 'react';
import classes from './WorkspaceLogoUpload.module.css';
import { notifications } from '@mantine/notifications';

export function WorkspaceLogoUpload({ form }: { form: any }) {
  const [loadingImage, setLoadingImage] = useState(false);
  // Create a ref for the file input element
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const openFileInput = () => {
    // Trigger the click event on the file input element
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      const formData = new FormData();
      formData.append('input_file', files[0]);
      setLoadingImage(true);
      await new UtilsService()
        .uploadImage(formData)
        .then((res) => {
          console.log(res.data);
          if (res.data.status) {
            form.setFieldValue('logo', res.data.url);
          }
        })
        .catch((err) => {
          if ((err.response.status === 400 ||  err.response.status ===  422) && err?.response?.data?.message) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }

          console.log(err);
        });

      setLoadingImage(false);
      // You can now use formData to send the file data to your server or perform any other actions.
    }
  };
  return (
    <>
      <Flex mr={32} ml={32} mt={32}>
        <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={uploadImage} />

        <Box
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        >
          <Flex justify={'center'} align={'center'} mb="sm" direction={'column'}>
            <Tooltip label="Upload Image" disabled={form.values.logo || false}>
              <Avatar
                className={classes.avatar}
                w={96}
                h={96}
                size={'xl'}
                src={form.values.logo}
                style={{
                  cursor: 'pointer',
                }}
                onClick={openFileInput}
              >
                {loadingImage ? (
                  <>
                    <Loader size="xs" />
                  </>
                ) : (
                  <FontAwesomeIcon icon={faImage} color="gray" size="sm" />
                )}
              </Avatar>
            </Tooltip>
            <Text fz={10} ta="center" pt="sm">
              Recommended size: Less than 2MB (90 x 90)
            </Text>
          </Flex>
          {form.values.logo && (
            <>
              <Flex className={classes.editAvatar} justify={'center'}>
                <Button
                  disabled={loadingImage}
                  loading={loadingImage}
                  onClick={openFileInput}
                  variant="outline"
                  color="gray"
                  size={'xs'}
                  leftSection={<FontAwesomeIcon icon={faPencil} color="gray" size={'xs'} />}
                >
                  Edit Image
                </Button>
              </Flex>
            </>
          )}
        </Box>
      </Flex>
    </>
  );
}
