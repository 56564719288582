import { OriginalURLInput } from '@/components/OriginalURLInput/OriginalURLInput';
import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { Modal, Title } from '@mantine/core';

export const CreateLinkURLModal = ({
  opened,
  setOpened,
}: {
  opened: boolean;
  setOpened: (opened: boolean) => void;
}) => {
  const [link, setLink] = useLinkSetupStore((state) => [state.link, state.setLink]);
  return (
    <Modal
      opened={opened}
      size={'lg'}
      onClose={() => setOpened(false)}
      title={<Title order={5}>Create Link</Title>}
    >
      <OriginalURLInput onLinkCreated={() => setOpened(false)} />
    </Modal>
  );
};
