import { TagsService } from '@/services/TagsService';
import { useTagsStore } from '@/stores/useTagsStore';
import { faPencil, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Box,
  Button,
  Chip,
  Divider,
  Flex,
  Grid,
  Space,
  Title,
  Text,
  Alert,
  List,
  ListItem,
  Popover,
  Group,
  TextInput,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import classes from './WorkspaceSettings.module.css';
import { modals } from '@mantine/modals';
import { useState } from 'react';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { useForm } from '@mantine/form';
import { useDocumentTitle } from '@mantine/hooks';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isUserCollaborator } from '@/utils/CommonUtility';
import { useUserStore } from '@/stores/useUserStore';

const EditTag = ({ id, value }: { id: string; value: string }) => {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [tags, setTags] = useTagsStore((state) => [state.tags, state.setTags]);
  const [opened, setOpened] = useState(false);
  const [tag, setTag] = useState(value);
  const [tagId, setTagId] = useState(id);
  const [loading, setLoading] = useState(false);
  const [user] = useUserStore((state) => [state.user]);
  const form = useForm({
    initialValues: {
      _id: tagId,
      name: tag,
    },
    validate: {
      name: (v: string) => (v.trim().length > 0 ? null : 'Tag name is required'),
    },
  });

  const handleTagEdit = async (formValues: any) => {
    const payload = {
      ...formValues,
      workspace_id: defaultWorkspace?._id,
    };
    await new TagsService()
      .update(defaultWorkspace?._id || '', payload)
      .then((res) => {
        if (res.data.status) {
          // Remove the item from the list
          notifications.show({
            color: 'green',
            message: 'Tag has been updated.',
          });

          // find and update the tag in the list
          const newList = tags.map((item: any) => {
            if (item._id === tagId) {
              return res.data.data;
            }
            return item;
          });
          setTags(newList);
          setOpened(false);
        } else {
          notifications.show({
            color: 'red',
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        if (err.response.data.message) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
        }
      });
  };

  return (
    <>
      <Popover opened={opened} onClose={() => setOpened(false)}>
        <Popover.Target>
          <ActionIcon variant="subtle" size="xs" ml={8} onClick={() => setOpened((o) => !o)}>
            <FontAwesomeIcon color="gray" size="xs" icon={faPencil} />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <form onSubmit={form.onSubmit(handleTagEdit)}>
            <Group>
              <TextInput size="xs" {...form.getInputProps('name')} placeholder="Tag name" />

              <Button loading={loading} disabled={loading} size="xs" type="submit">
                Update
              </Button>
            </Group>
          </form>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};

export function TagsPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  useDocumentTitle('Tags | ' + defaultWorkspace?.agency?.name);

  const [tags, setTags] = useTagsStore((state) => [state.tags, state.setTags]);
  const [tagsSelected, setTagsSelected] = useState<any[]>([]);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [user] = useUserStore((state) => [state.user]);
  const createForm = useForm({
    initialValues: {
      _id: null,
      name: '',
      workspace_id: defaultWorkspace?._id,
    },
    validate: {
      name: (v: string) => (v.trim().length > 0 ? null : 'Tag name is required'),
    },
  });

  const handleDeleteTag = async (id: string) => {
    await new TagsService()
      .bulkDelete(defaultWorkspace?._id || '', [id])
      .then((res) => {
        if (res.data.status) {
          // Remove the item from the list
          const newList = tags.filter((item: any) => item._id !== id);
          setTags(newList);
          notifications.show({
            color: 'green',
            message: 'Tag has been deleted.',
          });
        } else {
          notifications.show({
            color: 'red',
            message: 'Something went wrong. Please contact support',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   *  Function to confirm the deletion of a Tag.
   * @param id - The id of the Tag to be deleted.
   */
  const confirmTagDelete = async (id: string) => {
    modals.openConfirmModal({
      title: <Title order={5}>Delete Tag</Title>,
      children: (
        <>
          <Text size="sm">Are you sure? This process can not be undone.</Text>
        </>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleDeleteTag(id),
    });
  };

  // Bulk tags deletion

  const handleBulkTagsDelete = async () => {
    await new TagsService()
      .bulkDelete(defaultWorkspace?._id || '', tagsSelected)
      .then((res) => {
        if (res.data.status) {
          // Remove the item from the list
          const newList = tags.filter((item: any) => !tagsSelected.includes(item._id));
          setTags(newList);
          notifications.show({
            color: 'green',
            message: 'Tags have been deleted.',
          });
          setTagsSelected([]);
        } else {
          notifications.show({
            color: 'red',
            message: 'Something went wrong. Please contact support',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const findTagName = (id: string) => {
    const tag = tags.find((t: any) => t._id === id);
    if (tag) {
      // @ts-ignore
      return tag?.name;
    }
    return '';
  };

  /**
   *  Function to confirm the deletion of a Tag.
   * @param id - The id of the Tag to be deleted.
   */
  const confirmBulkTagsDelete = async () => {
    modals.openConfirmModal({
      title: <Title order={5}>Delete Tags</Title>,
      children: (
        <>
          <Text mb={8} size="sm">
            Are you sure you want to delete the following tags:
          </Text>
          <Alert color="primary.2">
            <List listStyleType="none">
              {tagsSelected.map((tagId: string) => (
                <>
                  <ListItem fw={600}>{findTagName(tagId)}</ListItem>
                </>
              ))}
            </List>
          </Alert>
        </>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleBulkTagsDelete(),
    });
  };

  // handle tags selection

  const handleTagsSelection = (tagId: string) => {
    const find = tagsSelected.find((tag) => tag === tagId);
    const selectedTag = tags.find((tag: any) => tag._id === tagId);
    //@ts-ignore
    let $workspace = user.workspace_member?.find((workspace) => workspace?.workspace_id === defaultWorkspace?._id);
    if (find) {
      setTagsSelected(tagsSelected.filter((tag) => tag !== tagId));
    } else {
      if ($workspace.role === 'collaborator') {
        if (isUserCollaborator(user, defaultWorkspace?._id, selectedTag, 'tag')) {   
          setTagsSelected([...tagsSelected, tagId]);
        }
      } else {
        setTagsSelected([...tagsSelected, tagId]);
      }
    }
  };

  // handle tag creation

  const handleTagCreation = async (formValues: any) => {
    setLoading(true);
    await new TagsService()
      .create(defaultWorkspace?._id || '', formValues)
      .then((res) => {
        if (res.data.status) {
          // Remove the item from the list
          const newList = [...tags, res.data.data];
          setTags(newList);
          notifications.show({
            color: 'green',
            message: 'Tag has been created.',
          });
          createForm.reset();
          setOpened(false);
        } else {
          notifications.show({
            color: 'red',
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 400 ||  err.response.status ===  422) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
        }
        console.log(err);
      });
    setLoading(false);
  };

  return (
    <Box
      style={{
        backgroundColor: 'var(--mantine-color-white)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        height: '100%',
        flexGrow: 1,
      }}
      ml={16}
      mr={16}
    >
      <Grid p={16}>
        <Grid.Col span={5}>
          <Popover
            shadow="sm"
            opened={opened}
            onClose={() => setOpened(false)}
            position="bottom"
            withArrow
          >
            {defaultPermissions && checkPermission(defaultPermissions, ['add_tags']) && <Popover.Target>
              <Button size="xs" onClick={() => setOpened((o) => !o)}>
                Add Tag
              </Button>
            </Popover.Target>}
            <Popover.Dropdown>
              <form onSubmit={createForm.onSubmit(handleTagCreation)}>
                <Group>
                  <TextInput
                    size="xs"
                    {...createForm.getInputProps('name')}
                    placeholder="Tag name"
                  />

                  <Button loading={loading} disabled={loading} size="xs" type="submit">
                    Create
                  </Button>
                </Group>
              </form>
            </Popover.Dropdown>
          </Popover>
        </Grid.Col>
        <Grid.Col span={2}>
          <Flex align={'center'}>
            <Title pt={2} order={5} mr={8}>
              Tags
            </Title>
          </Flex>
        </Grid.Col>
        <Grid.Col span={5}>
          {tagsSelected.length > 0 && (
            <Flex justify={'flex-end'}>
              {/* <Button variant="outline" size="xs">
                Merge Tags
              </Button>
              <Space w={8} /> */}
              {defaultPermissions && checkPermission(defaultPermissions, ['delete_tags']) && <Button
                onClick={() => confirmBulkTagsDelete()}
                variant="outline"
                color="red"
                size="xs"
              >
                Delete Tags
              </Button>}
            </Flex>
          )}
        </Grid.Col>
      </Grid>
      <Divider color="gray.2" />
      <Box p={16}>
        {tags.length === 0 ? (
          <Text fz={14} mt={32} ta="center" c="dimmed">
            You do not have created any tags.
          </Text>
        ) : (
          <>
            <Flex wrap={'wrap'}>
              {tags.map((tag: any, index) => (
                <>
                  <Chip
                    onChange={() => handleTagsSelection(tag._id)}
                    mb={12}
                    checked={tagsSelected.includes(tag._id)}
                    className={classes.tag}
                    color={'gray.1'}
                    size="sm"
                    key={index}
                  >
                    <Flex align={'center'} className={classes.tagActions}>
                      {tag.name}
                      {defaultPermissions && checkPermission(defaultPermissions, ['edit_tags']) && isUserCollaborator(user, defaultWorkspace?._id, tag, 'tag') && <EditTag id={tag._id} value={tag.name} />}
                      <Space w={2} />
                      {defaultPermissions && checkPermission(defaultPermissions, ['delete_tags']) && isUserCollaborator(user, defaultWorkspace?._id, tag, 'tag') && <ActionIcon
                        onClick={() => confirmTagDelete(tag._id)}
                        variant="subtle"
                        size="xs"
                      >
                        <FontAwesomeIcon color={'red'} size="xs" icon={faTrash} />
                      </ActionIcon>}
                    </Flex>
                  </Chip>
                </>
              ))}
            </Flex>
          </>
        )}
      </Box>
    </Box>
  );
}
