import { IWorkspace } from '@/types/workspace';
import axios from './JWTService';

export class WorkspaceService {
  getAll = ( ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/workspace/get`)
        .then((response) => {
          const data = response?.data?.data;
          const res: IWorkspace[] = data.map((item: any) => {
            const { workspace } = item;
            return {
              ...workspace,
              is_default: item.is_default,
              role: item.role,
            };
          });
          // console.log(res);
          resolve(res);
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        });
    });
  };

  create = (selectedWorkspaceId: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/workspace/${selectedWorkspaceId}/store`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  update = (selectedWorkspaceId: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/workspace/${selectedWorkspaceId}/update/${data._id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  setAsDefault = (selectedWorkspaceId: string, workspace_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/workspace/${selectedWorkspaceId}/set_default/${workspace_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  stats = (selectedWorkspaceId: string, ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/workspace/${selectedWorkspaceId}/stats`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  delete = (selectedWorkspaceId: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`/workspace/${selectedWorkspaceId}/delete/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  miscellaneous = (selectedWorkspaceId: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`/workspace/${selectedWorkspaceId}/miscellaneous`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
