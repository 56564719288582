import axios from './JWTService';

export class ExcludeTrafficService {
  get = (workspace_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`workspace/${workspace_id}/blacklist_ip/get/all`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  store = (workspace_id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`workspace/${workspace_id}/blacklist_ip/store`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  update = (workspace_id: string, id: any, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`workspace/${workspace_id}/blacklist_ip/update/${id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  delete = (workspace_id: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`workspace/${workspace_id}/blacklist_ip/delete/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
