import { faBullhorn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mantine/core';
import React from 'react';

const FrillWidget: React.FC = React.memo(() => {
    // Store widget reference
    const widgetRef = React.useRef<any>(null);
    // This effect will run once when the component has mounted
    React.useEffect(() => {
        if ('Frill' in window) {
            let widget: any;
            //@ts-ignore
            widget = window?.Frill.widget({
                key: "4b94f995-4620-4285-9f35-5a92325a0c82", // <-- Add Widget key here
            });
            return () => {
                widget.destroy();
            };
        }
    }, []);

    return (
        <Tooltip label="Announcements" position="bottom">
        <FontAwesomeIcon
            className='replug-frill-widget'
            icon={faBullhorn}
            color='#fff'
            style={{
                cursor: 'pointer',
            }}
        />
        </Tooltip>
    );
});

export default FrillWidget;