import {
  Anchor,
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Loader,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useNavigate, useParams } from 'react-router-dom';

import classes from './auth.module.css';
import { AuthCarousel } from '@/components/Auth/AuthCarousel';
import { UserService } from '@/services/UserService';
import { useEffect, useState } from 'react';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import { z } from 'zod';
import { getAppLogo } from '@/utils/CommonUtility';

const schema = z.object({
  password: z.string().min(8, 'Password must be at least 8 characters'),
  confirm_password: z.string().min(8, 'Password must be at least 8 characters'),
});

export function ResetPasswordPage() {
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [resetLoader, setResetLoader] = useState(false);

  const navigate = useNavigate();
  const form = useForm({
    initialValues: {
      password: '',
      confirm_password: '',
    },

    validate: zodResolver(schema),
  });

  const validateToken = async () => {
    setLoading(true);
    await axios
      .get(`/auth/validate_reset_token/${token}`)
      .then((res) => {
        if (res.data.status) {
          setInvalidToken(false);
        } else {
          setInvalidToken(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setInvalidToken(true);
        }
      });

    setLoading(false);
  };

  const handleResetPassword = async (formValues: any) => {
    if (formValues.password !== formValues.confirm_password) {
      notifications.show({
        color: 'red',
        message: 'New password and confirm password does not match',
      });
      return;
    }
    setResetLoader(true);
    await axios
      .post('/auth/reset_password', {
        ...formValues,
        token,
      })
      .then((res) => {
        if (res.data.status) {
          notifications.show({
            color: 'green',
            message: res.data.message,
          });
          navigate('/login');
        } else {
          notifications.show({
            color: 'red',
            message: res.data.message,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setInvalidToken(true);
        }
      });
    setResetLoader(false);
  };

  useEffect(() => {
    validateToken();
  }, []);

  return (
    <Paper
      style={{
        backgroundColor: '#f2f6f7',
      }}
    >
      <Grid>
        <Grid.Col
          span={3.5}
          style={{
            height: '100%',
          }}
        >
          <Flex mt={16} mx={16} direction="column" className={classes.authContainer}>
            <Box className={classes.authLogoContainer}>
              <Image w={122} h={36} src={getAppLogo()} />
            </Box>
            {loading ? (
              <>
                <Flex justify={'center'} align={'center'} direction={'column'}>
                  <Loader mt="xl" size={'sm'} />
                  <Text mt={'sm'} fz={14}>
                    Validating token...
                  </Text>
                </Flex>
              </>
            ) : (
              <>
                {invalidToken ? (
                  <>
                    <Flex justify={'center'} direction={'column'}>
                      <Title order={4} ta="center" py={32}>
                        Invalid Reset Password Token
                      </Title>
                      <Text fz={14} ta="center" px={8}>
                        The reset password token is invalid or has expired. Please request a new
                        one.
                      </Text>

                      <Text pb={8} size="sm" ta="center" mt={'md'}>
                        <Anchor size="xs" onClick={() => navigate('/login')} fw="bold">
                          Take me back to login
                        </Anchor>
                      </Text>
                    </Flex>
                  </>
                ) : (
                  <>
                    <Stack h="100%" justify="space-between">
                      <Box p={16} px={32}>
                        <Title order={4} ta="center" py={16}>
                          Reset Password
                        </Title>
                        <Text size="sm" ta="center" mb={16}>
                          Please enter your new password.
                        </Text>
                        <form onSubmit={form.onSubmit(handleResetPassword)}>
                          <PasswordInput
                            mb={32}
                            label="Password"
                            placeholder="e.g New password"
                            {...form.getInputProps('password')}
                          />

                          <PasswordInput
                            mb={32}
                            label="Confirm Password"
                            placeholder="e.g Confirm new password"
                            {...form.getInputProps('confirm_password')}
                          />

                          <Box
                            mb={16}
                            style={{
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Button
                              loading={resetLoader}
                              disabled={resetLoader}
                              type="submit"
                              w={160}
                            >
                              Reset Password
                            </Button>
                          </Box>

                          <Text pb={8} size="sm" ta="center">
                            <Anchor size="xs" onClick={() => navigate('/login')} fw="bold">
                              Take me back to login
                            </Anchor>
                          </Text>
                        </form>
                      </Box>
                    </Stack>
                  </>
                )}
              </>
            )}
          </Flex>
        </Grid.Col>
        <Grid.Col span={8.5}>
          <AuthCarousel />
        </Grid.Col>
      </Grid>
    </Paper>
  );
}
