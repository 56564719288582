import { Box, Flex, Group, Text, Title } from '@mantine/core';
import classes from '../../BioLinks.module.css';
import { BioThemeCard } from './BioThemeCard';
import { BIO_THEMES_VARIANTS, IThemeCard } from '@replug/utils';
import { useEffect, useState } from 'react';

export const BioThemes = ({ form, scrollIntoView }: { form: any; scrollIntoView: any }) => {
  const handleThemeChange = (theme: IThemeCard) => {
    const themeStore = {
      background_color: theme.backgroundColor,
      color: theme.textColor,
      background_style: theme.backgroundStyle,
      button: {
        background_color: theme.buttonStyles.backgroundColor,
        color: theme.buttonStyles.color,
        variant: theme.buttonStyles.variant,
        radius: theme.buttonStyles.radius,
        box_shadow: theme.buttonStyles.boxShadow,
      },
    };
    form.setFieldValue('theme', themeStore);
  };

  const handleThemeReset = () => {
    const themeStore = {
      background_color: '#ffffff',
      color: '#000000',
      background_style: '',
      button: {
        background_color: '#000000',
        color: '#ffffff',
        variant: 'filled',
        radius: 'xl',
        box_shadow: '0px',
      },
    };
    form.setFieldValue('theme', themeStore);
  };

  const [matches, setMatches] = useState<boolean>(false);

  const compareThemes = (theme1: any, theme2: any): boolean => {
    const results: boolean[] = [];

    // Compare background colors
    if (theme1?.backgroundColor && theme2?.background_color) {
      results.push(theme1.backgroundColor === theme2.background_color);
    }

    // Compare text colors
    if (theme1?.textColor && theme2?.color) {
      results.push(theme1.textColor === theme2.color);
    }

    // Compare button properties
    const button1 = theme1?.buttonStyles;
    const button2 = theme2?.button;

    if (button1 && button2) {
      // Compare button variant
      if (button1?.variant && button2?.variant) {
        results.push(button1.variant === button2.variant);
      }

      // Compare button colors
      if (button1?.color && button2?.color) {
        results.push(button1.color === button2.color);
      }

      // Compare button background colors
      if (button1?.backgroundColor && button2?.background_color) {
        results.push(button1.backgroundColor === button2.background_color);
      }

      // Compare button radius
      if (button1?.radius && button2?.radius) {
        results.push(button1.radius === button2.radius);
      }
    }

    return results.length > 0 && results.every(result => result === true);
  };

  useEffect(() => {
    if (form.values.theme) {
      // Check if any theme in BIO_THEMES_VARIANTS matches the current form theme
      const hasMatch = BIO_THEMES_VARIANTS.some(theme =>
        compareThemes(theme, form.values.theme)
      );
      setMatches(hasMatch);
      // console.log("Does any theme match?", hasMatch);
    }
  }, [form.values.theme]);

  return (
    <>
      <Title order={4} my={'md'}>
        Select Theme
      </Title>
      <Group>
        {BIO_THEMES_VARIANTS.map((theme) => (
          <Flex direction="column" key={theme.name}>
            <BioThemeCard
              theme={theme}
              onClick={handleThemeChange}
              currentTheme={form.values.theme}  // Pass current theme
            />
            <Title my={'sm'} fz={14} fw={600} ta="center">
              {theme.name}
            </Title>
          </Flex>
        ))}
        <Box
          className={classes.createTheme}
          mt={-40}
          style={{
            borderColor: !matches ? '#000' : undefined,
            borderWidth: !matches ? '2px' : undefined,
          }}
          onClick={() => {
            scrollIntoView({
              alignment: 'center',
            });
          }}
        >
          <Box className={classes.createThemeInner} onClick={handleThemeReset}>
            <Text ta={'center'} fw={500} tt={'uppercase'}>
              Create your own theme
            </Text>
          </Box>
        </Box>
      </Group>
    </>
  );
};
