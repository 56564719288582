import { LoaderDataTable } from '@/components/Menus/LoaderDataTable/LoaderDataTable';
import { NoResultsDataTable } from '@/components/Menus/NoResultsDataTable/NoResultsDataTable';
import { RssService } from '@/services/RssService';
import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { getRelativeTime } from '@/utils/DateUtility';
import { ensureHttps } from '@/utils/LinkUtility';
import {
  faCircleCheck,
  faCircleExclamation,
  faEllipsis,
  faHourglassStart,
  faMagnifyingGlass,
  faPause,
  faPencil,
  faPlay,
  faSpinner,
  faTrash,
  faEye,
  faCircleInfo
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Alert,
  Anchor,
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Group,
  Menu,
  Modal,
  Pagination,
  Select,
  Table,
  Text,
  TextInput,
  Title,
  Tooltip,
  useMantineTheme,
  Card,
  Image,
  Grid,
  CopyButton,
  Space
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useDebouncedValue, useDocumentTitle } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isUserCollaborator } from '@/utils/CommonUtility';
import { useUserStore } from '@/stores/useUserStore';
import { RSS_URL } from '@/constants/Constants';
// @ts-ignore
import xml2js from 'xml2js';
import post_placeholder from '../../assets/images/common/post_placeholder.png';
import { format, parseISO } from 'date-fns';
import { getShortenedURL } from '@/utils/LinkUtility';
import { faCheck, faCopy } from '@fortawesome/pro-regular-svg-icons';
import { useSearchParams } from 'react-router-dom';
import { useUsermaven } from "@usermaven/react";

export function RSSFeedPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  useDocumentTitle('RSS Feed | ' + defaultWorkspace?.agency?.name);

  const [modalOpened, setModalOpened] = useState(false);
  const workspaceId = useParams();
  const [campaigns, getDefaultCampaign] = useCampaignsStore((state) => [
    state.campaigns,
    state.getDefaultCampaign,
  ]);
  const theme = useMantineTheme();
  const [search, setSearch] = useState('');
  const [debounced] = useDebouncedValue(search, 200);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [user] = useUserStore((state) => [state.user]);
  const [previewModalOpened, setPreviewModalOpened] = useState(false);
  const [previewRssFeed, setPreviewRssFeed] = useState<any>(null);
  const [previewRssFeedMeta, setPreviewRssFeedMeta] = useState<any>(null);
  const [searchParams] = useSearchParams();
  const {track} = useUsermaven()

  const fetchRSS = async () => {
    setLoading(true);
    await new RssService()
      .get(defaultWorkspace?._id || '', search, page, 20)
      .then((res) => {
        if (res.data.status) {
          console.log('RSS...', res.data.data.data);
          setList(res.data.data.data);
          setLastPage(res.data.data.last_page);
        }
      })
      .catch((res) => {
        console.log(res);
      });
    setLoading(false);
  };

  useEffect(() => {
    const action = searchParams.get('action');
    if (action === 'create') {
      setModalOpened(true)
    }
  }, [searchParams]);

  const getRSSProcessState = (state: string) => {
    switch (state) {
      case 'success':
        return 'Successful';
      case 'running':
        return 'Processing';
      case 'already_in_queue':
      case 'in_queue':
        return 'In Queue';
      case 'rss_already_updated':
        return 'Already Updated';
      case 'unable_to_update':
        return 'Unable to Update';
      case 'unable_to_fetch':
        return 'Unable to fetch';
      case 'iframe':
        return 'Iframe error occured';
      case 'invalid_rss_feed_url':
        return 'Invalid RSS feed URL';
      default:
        return 'N/A';
    }
  };

  const getRSSProcessStateIcon = (state: string) => {
    switch (state) {
      case 'success':
        return faCircleCheck;
      case 'running':
        return faSpinner;
      case 'already_in_queue':
      case 'in_queue':
        return faHourglassStart;
      case 'rss_already_updated':
        return faCircleCheck;
      case 'unable_to_update':
        return faCircleExclamation;
      case 'unable_to_fetch':
        return faCircleExclamation;
      case 'iframe':
        return faCircleExclamation;
      case 'invalid_rss_feed_url':
        return faCircleExclamation;
      default:
        return faSpinner;
    }
  };

  const getRSSProcessStateColor = (state: string) => {
    switch (state) {
      case 'success':
        return 'green';
      case 'running':
        return 'orange';
      case 'already_in_queue':
        return 'orange';
      case 'rss_already_updated':
        return 'green';
      case 'unable_to_update':
        return 'red';
      case 'unable_to_fetch':
        return 'red';
      case 'iframe':
        return 'red';
      case 'invalid_rss_feed_url':
        return 'red';
      default:
        return 'orange';
    }
  };

  // Add / Update RSS Feed

  const schema = z.object({
    _id: z.string().nullable(),
    campaign_id: z.string().min(2).nullable(),
    url: z.string().url({ message: 'Invalid URL' }),
    workspace_id: z.string().min(2),
  });

  const form = useForm({
    initialValues: {
      _id: null,
      campaign_id: '',
      url: '',
      workspace_id: defaultWorkspace?._id,
    },
    validate: zodResolver(schema),
  });

  const handleFormSubmit = async (formValues: any) => {
    console.log(formValues);
    setStoreLoading(true);
    if (formValues._id) {
      await new RssService()
        .update(defaultWorkspace?._id || '', formValues)
        .then((res) => {
          if (res.data.status) {
            setModalOpened(false);
            fetchRSS();
            form.reset();
          }
        })
        .catch((err) => {
          if (err.response.status === 400 || err.response.status === 422) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
        });
    } else {
      await new RssService()
        .create(defaultWorkspace?._id || '', formValues)
        .then((res) => {
          if (res.data.status) {
            setModalOpened(false);
            fetchRSS();
            form.reset();
            track('rss_automation_created', {})
          }
        })
        .catch((err) => {
          if (err.response.status === 400 || err.response.status === 422) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
        });
    }

    setStoreLoading(false);
  };

  // Recrawl RSS feed

  const handleRecrawl = async (id: string) => {
    await new RssService()
      .recrawl(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          notifications.show({
            color: 'green',
            message: "Your RSS feed is being recrawled. It'll take a few minutes.",
          });

          // update the feed state to running.

          const updatedList: any = list.map((item: any) => {
            if (item._id === id) {
              item.last_crawled_state = 'running';
            }
            return item;
          });
          setList(updatedList);
        } else {
          notifications.show({
            color: 'red',
            message: 'Something went wrong. Please contact our support team.',
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 400 || err.response.status === 422) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
        }
      });
  };

  // Change RSS Status

  const handleChangeStatus = async (rss: any) => {
    await new RssService()
      .changeStatus(defaultWorkspace?._id || '', rss._id, rss?.is_active ? false : true)
      .then((res) => {
        if (res.data.status) {
          notifications.show({
            color: 'green',
            message: res.data.message,
          });

          // update the feed state to running.

          const updatedList: any = list.map((item: any) => {
            if (item._id === rss._id) {
              item.is_active = !rss.is_active;
            }
            return item;
          });
          setList(updatedList);
        } else {
          notifications.show({
            color: 'red',
            message: 'Something went wrong. Please contact our support team.',
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 400 || err.response.status === 422) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
        }
      });
  };

  // Handle Delete

  const handleDeleteRSS = async (id: string) => {
    await new RssService()
      .delete(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          notifications.show({
            color: 'green',
            message: 'RSS feed deleted successfully.',
          });

          // update the list

          const updatedList = list.filter((item: any) => item._id !== id);
          setList(updatedList);
        } else {
          notifications.show({
            color: 'red',
            message: 'Something went wrong. Please contact our support team.',
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 400 || err.response.status === 422) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
        }
      });
  };

  /**
   *  Function to confirm the deletion of a rss.
   * @param id - The id of the rss to be deleted.
   */
  const confirmRSSDelete = async (id: string) => {
    modals.openConfirmModal({
      title: <Title order={5}>Delete RSS Feed</Title>,
      children: (
        <>
          <Text size="sm">Are you sure? This process can not be undone.</Text>
        </>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleDeleteRSS(id),
    });
  };

  //rss preview
  const handleRssPreview = async (rss: any) => {
    try {
      const parsedFeed = await xml2js.parseStringPromise(rss.rss);
      console.log('parsedFeed', parsedFeed);
      const items = parsedFeed.rss.channel[0].item;
      console.log('items', items);

      setPreviewRssFeedMeta(rss)
      setPreviewRssFeed(items)
      setPreviewModalOpened(true)
    } catch (error) {
      console.error('Error fetching RSS feed:', error);
    }
  }

  //extract blog original url
  const getOriginalUrl = (content: any) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const firstLink = doc.querySelector('a');
    const firstLinkUrl = firstLink ? firstLink.href : null;
    return firstLinkUrl
  }

  //copy link function
  const copyToClipboard = (url: any) => {
    navigator.clipboard.writeText(url).then(() => {
      notifications.show({
        color: 'green',
        message: 'Shorten Link Copied.',
      });
    }).catch((err) => {
      console.error('Failed to copy link: ', err);
    });
  };

  useEffect(() => {
    fetchRSS();
  }, [workspaceId, debounced, page]);

  const FeedCard = ({ item }: { item: any }) => {
    const updated_date = previewRssFeedMeta?.updated_at ? format(parseISO(previewRssFeedMeta.updated_at), 'PPPpp') : null;
    // console.log('preview item', item);

    const title = item.title[0];
    const link = item.link[0];

    // Extract image URL and description
    const content = item.description[0].summary[0];
    const imgRegex = /<img.*?src="(.*?)".*?>/;
    const imgMatch = content.match(imgRegex);
    const imageUrl = imgMatch ? imgMatch[1] : post_placeholder; // Use fallback image if no match
    const description = content.replace(imgRegex, '').trim(); // Remove img tag and trim

    const original_url = getOriginalUrl(description) || ''

    return (
      <Card shadow="sm" p="lg" style={{ marginBottom: '20px' }}>
        <Grid>
          <Grid.Col
            span={4}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}  // Vertically center the image
          >
            <Image src={imageUrl} alt={title} width={400} height={200} style={{ width: '100%', height: 'auto' }} />
          </Grid.Col>
          <Grid.Col span={8}>
            <Text fw={500} size="lg">
              <Anchor href={link} target="_blank" rel="noopener noreferrer">
                {title}
              </Anchor>
            </Text>
            <Text size="xs" c="dimmed">{updated_date}</Text>
            <Text
              size="sm"
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2, // Limit to 2 lines
              }}
            >
              <Text size="xs" dangerouslySetInnerHTML={{ __html: description }} />
            </Text>
            <div style={{ marginTop: '10px' }}>
              <Button size='xs' component="a" href={original_url} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
                Original Link
              </Button>
              <Button
                size='xs'
                onClick={() => copyToClipboard(link)}
              >
                Copy Link
              </Button>
            </div>
          </Grid.Col>
        </Grid>
      </Card>
    );
  };

  return (
    <>
      <Modal
        size={600}
        opened={modalOpened}
        title={<Title order={5}>{form.values._id ? 'Update' : 'Add'} RSS Feed</Title>}
        onClose={() => setModalOpened(!modalOpened)}
      >
        <Box p={16}>
          <Box mx={32}>
            <form onSubmit={form.onSubmit(handleFormSubmit)}>
              <Select
                mb={8}
                variant="filled"
                placeholder="Select your campaign"
                label={'Campaign'}
                data={campaigns.map((campaign: any) => ({
                  value: campaign._id,
                  label: campaign.name,
                }))}
                {...form.getInputProps('campaign_id')}
              />

              <TextInput
                variant="filled"
                label={'RSS Feed'}
                placeholder="Enter RSS Feed URL i.e https://blog.contentstudio.io/feed"
                value={form.values.url}
                error={form.errors?.url}
                onChange={(event) => {
                  const value = event.currentTarget.value;
                  if (value) {
                    form.setFieldValue('url', ensureHttps(value));
                  } else {
                    form.setFieldValue('url', value);
                  }
                }}
              />

              <Alert mt={16} mb={16} color="blue" title="Note:">
                Your RSS feed content will be automatically pulled after every 30 minutes.
              </Alert>

              <Flex align={'center'} justify={'center'}>
                <Button disabled={storeLoading} loading={storeLoading} type="submit" mr={12}>
                  {form.values._id ? 'Update' : 'Create'}
                </Button>
                <Button onClick={() => setModalOpened(false)} variant="outline" color="gray">
                  Cancel
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Modal>
      <Modal
        size={800}
        opened={previewModalOpened}
        title={<Title order={5}>RSS Feed Preview</Title>}
        onClose={() => setPreviewModalOpened(!previewModalOpened)}
      >
        <Box p={16}>
          <Box mx={32}>
            <div>
              {previewRssFeed && previewRssFeed.map((item: any, index: any) => (
                <FeedCard key={index} item={item} />
              ))}
            </div>
          </Box>
        </Box>
      </Modal>
      <Flex align={'center'} pl={'lg'} pt={'xs'}>
        <Text size="xl" fw="bold" mr="sm">
          RSS Automation
        </Text>
        <Tooltip
          position="bottom"
          multiline
          w={450}
          fz="xs"
          label=" You can add RSS feeds to your campaigns and escalate your brand marketing. Your RSS feed content will be automatically pulled after every 30 minutes which you can share among your audience via your shortened link."
        >
          <FontAwesomeIcon
            style={{
              color: 'var(--mantine-color-gray-6)',
            }}
            icon={faCircleInfo}
          />
        </Tooltip>
      </Flex>
      <Flex mt="lg" mx="lg" align={'center'}>
        {(defaultPermissions && checkPermission(defaultPermissions, ['add_rss'])) && <Button
          size="xs"
          onClick={() => {
            form.reset();
            setModalOpened(true);
          }}
        >
          Create new
        </Button>}
        <TextInput
          w={200}
          size="xs"
          ml={8}
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder="Search RSS feed URL"
          leftSection={
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              style={{
                color: theme.colors.gray[5],
              }}
            />
          }
        />
      </Flex>
      <Flex
        direction={'column'}
        m={'md'}
        style={{
          background: 'var(--mantine-color-white)',
        }}
      >
        <Box
          style={{
            flexGrow: 1,
          }}
        >
          <Table stickyHeader verticalSpacing={'md'}>
            <Table.Thead h={20} bg={'gray.1'} c="gray.7" fz="xs">
              <Table.Tr>
                <Table.Th>Feed URL</Table.Th>
                <Table.Th>Status</Table.Th>
                <Table.Th>URL</Table.Th>
                <Table.Th>Campaign</Table.Th>
                <Table.Th ta="center">Total Links</Table.Th>
                <Table.Th ta={'center'}>Last Crawled Shortened Links</Table.Th>
                <Table.Th ta="center">Last Crawled </Table.Th>
                {(defaultPermissions && checkPermission(defaultPermissions, ['edit_rss'])) && <Table.Th ta={'center'}>Actions</Table.Th>}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {loading ? (
                <>
                  <LoaderDataTable colSpan={8} />
                </>
              ) : (
                <>
                  {list.length === 0 && (
                    <>
                      <NoResultsDataTable
                        data={list}
                        colSpan={8}
                        noResultsMessage="You do not have any RSS feed created yet."
                      />
                    </>
                  )}

                  {list.map((rss: any) => (
                    <>
                      <Table.Tr key={rss._id}>
                        <Table.Td>
                          <Flex direction={'column'}>
                            <Flex align={'center'}>
                              <Anchor
                                fw={600}
                                fz={13}
                                href={`${RSS_URL}/${rss.shorten_id}`}
                                target="_blank"
                              >
                                {RSS_URL}/{rss.shorten_id}
                              </Anchor>
                              <Space w={8} />
                              <CopyButton value={`${RSS_URL}/${rss.shorten_id}`} timeout={2000}>
                                {({ copied, copy }) => (
                                  <Tooltip fz="xs" label={copied ? 'Copied' : 'Copy Link'} withArrow position="right">
                                    <ActionIcon
                                      size="sm"
                                      color={copied ? 'green' : 'gray'}
                                      variant="subtle"
                                      onClick={copy}
                                    >
                                      {copied ? (
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: 13,
                                            marginTop: 4,
                                            color: theme.colors.green[5],
                                          }}
                                          icon={faCheck}
                                        />
                                      ) : (
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: 13,
                                            marginTop: 4,
                                            color: theme.colors.gray[5],
                                          }}
                                          icon={faCopy}
                                        />
                                      )}
                                    </ActionIcon>
                                  </Tooltip>
                                )}
                              </CopyButton>
                            </Flex>
                            <Text pt={4} c="dimmed" fz={11}>
                              Created {getRelativeTime(rss.created_at)} | Last modified{' '}
                              {getRelativeTime(rss.updated_at)}
                            </Text>
                            <Badge
                              color={!rss.is_active ? 'yellow' : 'primary'}
                              variant="outline"
                              size="sm"
                              mt={'xs'}
                              radius={'sm'}
                            >
                              {rss.is_active ? 'Active' : 'Paused'}
                            </Badge>
                          </Flex>
                        </Table.Td>
                        <Table.Td>
                          <Flex align={'center'}>
                            <FontAwesomeIcon
                              icon={getRSSProcessStateIcon(rss.last_crawled_state)}
                              color={getRSSProcessStateColor(rss.last_crawled_state)}
                            />
                            <Text ml={8} tt="capitalize" fz={13}>
                              {getRSSProcessState(rss.last_crawled_state)}
                            </Text>
                          </Flex>
                        </Table.Td>
                        <Table.Td>
                          <Text fz={13}>{rss.url}</Text>
                        </Table.Td>
                        <Table.Td>
                          <Text fz={13}>{rss?.campaign?.name}</Text>
                        </Table.Td>
                        <Table.Td ta="center">
                          <Text fz={13}>{rss.total_links}</Text>
                        </Table.Td>
                        <Table.Td ta="center">
                          <Text fz={13}>{rss.last_crawled_total_links}</Text>
                        </Table.Td>
                        <Table.Td ta="center">
                          <Text fz={13}>
                            {rss.last_crawled_at ? (
                              <>{getRelativeTime(rss.last_crawled_at)}</>
                            ) : (
                              <>-</>
                            )}
                          </Text>
                        </Table.Td>

                        {(defaultPermissions && checkPermission(defaultPermissions, ['edit_rss'])) && isUserCollaborator(user, defaultWorkspace?._id, rss, 'rss') && <Table.Td>
                          <Menu withArrow withinPortal>
                            <Menu.Target>
                              <Center>
                                <ActionIcon c="gray" variant="subtle">
                                  <FontAwesomeIcon icon={faEllipsis} />
                                </ActionIcon>
                              </Center>
                            </Menu.Target>
                            <Menu.Dropdown w="140">
                              <Menu.Item
                                leftSection={<FontAwesomeIcon icon={faPencil} />}
                                onClick={() => {
                                  form.setFieldValue('_id', rss._id);
                                  form.setFieldValue('campaign_id', rss.campaign_id);
                                  form.setFieldValue('url', rss.url);
                                  setModalOpened(true);
                                }}
                              >
                                Edit
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => handleRecrawl(rss._id)}
                                leftSection={<FontAwesomeIcon icon={faSpinner} />}
                              >
                                Recrawl
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => handleChangeStatus(rss)}
                                leftSection={
                                  <FontAwesomeIcon icon={rss?.is_active ? faPause : faPlay} />
                                }
                              >
                                {rss?.is_active ? 'Pause' : 'Resume'}
                              </Menu.Item>

                              <Menu.Item
                                leftSection={<FontAwesomeIcon icon={faEye} />}
                                onClick={() => handleRssPreview(rss)}
                              >
                                Preview
                              </Menu.Item>
                              <Menu.Divider />
                              <Menu.Item
                                onClick={() => confirmRSSDelete(rss._id)}
                                c={'red'}
                                leftSection={<FontAwesomeIcon icon={faTrash} />}
                              >
                                Delete
                              </Menu.Item>
                            </Menu.Dropdown>
                          </Menu>
                        </Table.Td>}
                      </Table.Tr>
                    </>
                  ))}
                </>
              )}
            </Table.Tbody>
          </Table>
        </Box>
      </Flex>
      <Box mx={'sm'} pb={'lg'} mt={'sm'}>
        <Group justify="space-between">
          <Pagination size="xs" total={lastPage} onChange={setPage} />
          {/* <TotalResultsMenu /> */}
        </Group>
      </Box>
    </>
  );
}
