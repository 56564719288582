import { MultiSelectCreatable } from '@/components/MultiSelectCreatable/MultiSelectCreatable';
import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { useTagsStore } from '@/stores/useTagsStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import {
  Box,
  Button,
  Flex,
  Modal,
  Select,
  Textarea,
  Title,
  Text,
  Notification,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { zodResolver } from 'mantine-form-zod-resolver';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { notifications } from '@mantine/notifications';
import classes from './BulkLinkImport.module.css';

const BULK_LINK_ERRORS = {
  CAMPAIGN_ERROR: 'Campaign not found.',
  INVALID_LINK: 'Some links are invalid.',
  TAG_ERROR: 'Some tags are invalid.',
};

const bulkImportLinksSchema = z.object({
  workspace_id: z.string(),
  campaign_id: z.string(),
  links: z.string().min(1, { message: 'At least one link must be selected' }),
  tags: z.array(z.string().nullable()),
});

export const BulkImportLinksModal = ({
  opened,
  onClose,
}: {
  opened: boolean;
  onClose: () => void;
}) => {
  const [campaigns, setCampaigns] = useCampaignsStore((state) => [
    state.campaigns,
    state.setCampaigns,
  ]);
  const [bulkCreateLinks] = useLinkSetupStore((state) => [state.bulkCreateLinks]);
  const [loading, setLoading] = useState(false);
  const [linkErrors, setLinkErrors] = useState<{
    campaign_id?: string;
    url?: string;
    tags?: string;
  }>({ campaign_id: '', url: '', tags: '' });
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [tags, createTag] = useTagsStore((state) => [state.tags, state.createTag]);
  const handleTagCreate = async (value: string) => {
    // console.log(value);
    const resp = await createTag(defaultWorkspace?._id || '', {
      _id: null,
      name: value,
      workspace_id: defaultWorkspace?._id || '',
    });
    // console.log(resp);
  };
  const form = useForm({
    validate: zodResolver(bulkImportLinksSchema),
    initialValues: {
      workspace_id: defaultWorkspace?._id,
      campaign_id: '',
      links: '',
      tags: [],
    },
  });

  const getCampaignsData = () => {
    const list = campaigns.map((campaign: any) => ({
      label: campaign?.name || 'No Campaign',
      value: campaign._id,
    }));
    if (!list.length) return [];
    // console.log('getCampaignsData', list);
    return list;
  };
  const handleFormSubmit = async (values: any) => {
    setLoading(true)
    const links = values.links.split('\n');
    const bulkLinkCreated = await bulkCreateLinks(defaultWorkspace?._id || '', values?.campaign_id, links, values?.tags);
    // const bulkLinkCreated = await bulkCreateLinks('kjhsdflkjhds', links, ['123', '321', '34dfsg'])
    if (
      typeof bulkLinkCreated === 'object' &&
      bulkLinkCreated !== null &&
      !Array.isArray(bulkLinkCreated)
    ) {
      handleLinkError(bulkLinkCreated);
      setLoading(false)
    } else {
      setLoading(false)
      onClose();
    }
  };

  const handleLinkError = (err: any) => {
    let errorObject = {};
    Object.entries(err).forEach(([key, value]) => {
      if (key.includes('url')) {
        notifications.show({
          color: 'red',
          message: BULK_LINK_ERRORS.INVALID_LINK,
        });
        errorObject = { ...errorObject, ['url']: BULK_LINK_ERRORS.INVALID_LINK };
      }
      if (key.includes('campaign_id')) {
        notifications.show({
          color: 'red',
          message: BULK_LINK_ERRORS.CAMPAIGN_ERROR,
        });
        errorObject = { ...errorObject, ['campaign_id']: BULK_LINK_ERRORS.CAMPAIGN_ERROR };
      }
      if (key.includes('tags')) {
        notifications.show({
          color: 'red',
          message: BULK_LINK_ERRORS.TAG_ERROR,
        });
        errorObject = { ...errorObject, ['tags']: BULK_LINK_ERRORS.CAMPAIGN_ERROR };
      }
    });
    setLinkErrors(errorObject);
  };

  useEffect(() => {
    if (opened) {
      form.setValues({
        workspace_id: defaultWorkspace?._id,
        campaign_id: '',
        links: '',
        tags: [],
      });
      setLinkErrors({});
    }
  }, [opened]);
  //   if (!opened) return null;
  return (
    <>
      <Modal
        size={1000}
        opened={opened}
        onClose={onClose}
        title={
          <Text fw={700} fz={18}>
            Bulk Import Links
          </Text>
        }
      >
        <Box p={16}>
          <form onSubmit={form.onSubmit(handleFormSubmit)}>
            <Select
              data={getCampaignsData()}
              searchable
              label="Select Campaign"
              placeholder="Select your campaign"
              {...form.getInputProps('campaign_id')}
              mb={'md'}
              classNames={{
                input: linkErrors.campaign_id ? classes.errorTextareaWrapper : '', // Apply custom class to the input wrapper
                error: '', // Prevent Mantine UI from displaying its own error styles
              }}
            />
            <Textarea
              label="Links"
              placeholder="Enter the URL e.g https://techcrunch.com"
              minRows={10}
              maxRows={14}
              autosize
              {...form.getInputProps('links')}
              mb="md"
              classNames={{
                input: linkErrors?.url ? classes.errorTextareaWrapper : '', // Apply custom class to the input wrapper
                error: '', // Prevent Mantine UI from displaying its own error styles
              }}
            />

            <MultiSelectCreatable
              label="Tags"
              placeholder="Search or create a tag"
              dataItems={
                tags.length > 0
                  ? tags.map((tag: any) => ({
                      label: tag?.name,
                      value: tag?._id,
                    }))
                  : []
              }
              valueItems={form.values.tags}
              onChange={(value) => {
                // @ts-ignore
                form.setFieldValue('tags', value);
              }}
              onCreate={(item) => {
                // console.log(item);
                handleTagCreate(item.value);
              }}
              size={'sm'}
              error={linkErrors?.tags ? true : false}
            />
            <Flex mt={32} align={'center'} justify={'center'}>
              <Button disabled={loading} loading={loading} type="submit" mr={12}>
                Import
              </Button>
              <Button onClick={onClose} variant="outline" color="gray">
                Cancel
              </Button>
            </Flex>
          </form>
        </Box>
      </Modal>
    </>
  );
};
