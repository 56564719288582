import { Table, Text, Center } from '@mantine/core';

interface INoResultsDataTable {
  data: any[];
  colSpan: number;
  noResultsMessage?: string;
}

export const NoResultsDataTable = ({
  data,
  colSpan,
  noResultsMessage = 'No data available',
}: INoResultsDataTable) => {
  if (data.length === 0) {
    return (
      <Table.Tr>
        <Table.Td ta="center" colSpan={colSpan}>
          <Center style={{ width: '100%', height: '100px' }}>
            {' '}
            {/* Adjust dimensions as needed */}
            <Text size="sm" color="dimmed">
              {noResultsMessage}
            </Text>
          </Center>
        </Table.Td>
      </Table.Tr>
    );
  }

  return <Table>{/* Render table headers, rows, and columns here */}</Table>;
};
