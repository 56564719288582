import axios from './JWTService';

export class CustomDomainService {
  get = (workspace_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`workspace/${workspace_id}/custom_domain/get`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  delete = (
    workspace_id: string,
    id: string,
    type: 'workspace' | 'all_workspaces' | string = 'workspace'
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`workspace/${workspace_id}/custom_domain/delete/${id}/${type}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  create = (workspace_id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`workspace/${workspace_id}/custom_domain/create`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  update = (workspace_id: string, id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`workspace/${workspace_id}/custom_domain/update/${id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  checkConnectivity = (workspace_id: string, url: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`workspace/${workspace_id}/custom_domain/check_connectivity/${id}`, { url })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  dnsRecords = (workspace_id: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`workspace/${workspace_id}/custom_domain/dns_records/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  updateStatus = (workspace_id: string, id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`workspace/${workspace_id}/custom_domain/updateStatus/${id}`, {status: data})
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
