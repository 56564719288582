import { MultiSelectCreatable } from '@/components/MultiSelectCreatable/MultiSelectCreatable';
import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { useTagsStore } from '@/stores/useTagsStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { Grid } from '@mantine/core';

export const LinkCreateTagsSelection = () => {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [link, setLink] = useLinkSetupStore((state) => [state.link, state.setLink]);
  const [tags, createTag] = useTagsStore((state) => [state.tags, state.createTag]);
  const handleTagCreate = async (value: string) => {
    console.log(value);
    const resp = await createTag(defaultWorkspace?._id || '', {
      _id: null,
      name: value,
      workspace_id: defaultWorkspace?._id || '',
    });
    console.log(resp);
  };
  return (
    <>
      <Grid.Col span={6}>
        <MultiSelectCreatable
          label="Tags"
          placeholder="Search or create a tag"
          dataItems={
            tags.length > 0
              ? tags.map((tag: any) => ({
                  label: tag?.name,
                  value: tag?._id,
                }))
              : []
          }
          valueItems={link.tags}
          onChange={(value) => {
            setLink({ ...link, tags: value });
          }}
          onCreate={(item) => {
            console.log(item);
            handleTagCreate(item.value);
          }}
          size={'sm'}
        />
      </Grid.Col>
    </>
  );
};
