import { WhitelabelService } from '@/services/WhitelabelService';
import {
  Alert,
  Button,
  Center,
  Divider,
  Fieldset,
  Grid,
  Group,
  List,
  ListItem,
  Paper,
  PasswordInput,
  Select,
  TextInput,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useEffect } from 'react';
import { z } from 'zod';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';

const schema = z.object({
  from_email: z.string().email({ message: 'Invalid email' }),
  sender_name: z.string().min(2, { message: 'Sender name should be at least 2 characters long' }),
  smtp_host: z.string().min(2, { message: 'SMTP Host Address is required' }),
  smtp_port: z.string().min(2, { message: 'SMTP Port is required' }),
  smtp_username: z.string().min(2, { message: 'SMTP Username is required' }),
  smtp_password: z.string().min(2, { message: 'SMTP Password is required' }),
  smtp_encryption: z.string(),
});

export const WhitelabelEmailSettings = ({
  nextStep,
  prevStep,
}: {
  nextStep: () => void;
  prevStep: () => void;
}) => {
  const form = useForm({
    initialValues: {
      _id: '',
      from_email: '',
      sender_name: '',
      smtp_host: '',
      smtp_port: '',
      smtp_username: '',
      smtp_password: '',
      smtp_encryption: 'tls',
    },
    validate: zodResolver(schema),
  });

  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const handleNextStep = () => {
    form.validate();
    if (form.isValid()) {
      new WhitelabelService()
        .storeSMTP(defaultWorkspace?._id || '', form.values)
        .then((res) => {
          if (res.data.status) {
            notifications.show({
              message: 'SMTP settings saved successfully.',
              color: 'green',
            });
            const {
              _id,
              from_email,
              sender_name,
              smtp_host,
              smtp_port,
              smtp_username,
              smtp_password,
              smtp_encryption,
            } = res.data.data;
            form.setValues({
              _id,
              from_email,
              sender_name,
              smtp_host,
              smtp_port,
              smtp_username,
              smtp_password,
              smtp_encryption,
            });
            nextStep();
          }
        })
        .catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {
            notifications.show({
              message: err.response.data.message,
              color: 'red',
            });
          }
        });
    }
  };

  const fetchWhitelabel = async () => {
    await new WhitelabelService()
      .get(defaultWorkspace?._id || '')
      .then((res) => {
        if (res.data.status) {
          const {
            _id,
            from_email,
            sender_name,
            smtp_host,
            smtp_port,
            smtp_username,
            smtp_password,
            smtp_encryption,
          } = res.data.data;
          form.setValues({
            _id,
            from_email,
            sender_name,
            smtp_host,
            smtp_port,
            smtp_username,
            smtp_password,
            smtp_encryption,
          });
        }
      })
      .catch((err) => {});
  };

  const handleValidateSMTP = () => {
    form.validate();
    if (form.isValid()) {
      new WhitelabelService()
        .validateSMTP(defaultWorkspace?._id || '', form.values)
        .then((res) => {
          if (res.data.message) {
            notifications.show({
              message: res.data.message,
              color: 'green',
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {
            notifications.show({
              message: err.response.data.message,
              color: 'red',
            });
          }
        });
    }
  };

  useEffect(() => {
    fetchWhitelabel();
  }, []);

  return (
    <Paper py="md">
      <Alert color="primary">
        By using your own SMTP server, you can send emails from your own domain. All the emails from
        your whitelabel domain will be sent using your SMTP server.
      </Alert>
      <Grid py="md">
        <Grid.Col span={8}>
          <Fieldset legend="Email Settings">
            <Grid>
              <Grid.Col span={6}>
                <TextInput
                  label="From email"
                  placeholder="i.e support@youragencydomain.com"
                  {...form.getInputProps('from_email')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="Sender name"
                  placeholder="i.e Your Agency"
                  {...form.getInputProps('sender_name')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="SMTP Host Address"
                  placeholder="i.e smtp.postmarkapp.com"
                  {...form.getInputProps('smtp_host')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="SMTP Port"
                  placeholder="i.e 25, 2525, 587"
                  {...form.getInputProps('smtp_port')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="SMTP Username"
                  placeholder="Your SMTP Username"
                  {...form.getInputProps('smtp_username')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <PasswordInput
                  label="SMTP Password"
                  placeholder="Your SMTP Password"
                  {...form.getInputProps('smtp_password')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Select
                  label="Encryption"
                  data={[
                    {
                      value: 'tls',
                      label: 'TLS',
                    },
                    {
                      value: 'ssl',
                      label: 'SSL',
                    },
                  ]}
                  {...form.getInputProps('smtp_encryption')}
                />
              </Grid.Col>
            </Grid>
          </Fieldset>
        </Grid.Col>
        <Grid.Col span={4}>
          <Alert color="yellow" title="Steps to connect SMTP" mb={'md'}>
            <List>
              <ListItem>Ensure that your email account is enabled for SMTP.</ListItem>
              <ListItem>Ensure that 2FA is not enabled on your email account.</ListItem>
              <ListItem>
                If 2FA is enabled, please use app password instead of email password.
              </ListItem>
            </List>
            <Center py={12}>
              <Button variant="outline" onClick={handleValidateSMTP}>
                Test SMTP Connection
              </Button>
            </Center>
          </Alert>
        </Grid.Col>
      </Grid>

      <Divider my={'md'} />
      <Group justify="center" mt="xl">
        <Button variant="default" onClick={prevStep}>
          Back
        </Button>
        <Button onClick={handleNextStep}>Next</Button>
      </Group>
    </Paper>
  );
};
