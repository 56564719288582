import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';

export function IntegrationCallbackPage() {

  const navigate = useNavigate();

  useEffect(() => {
    // Read the query params
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const error = urlParams.get('error');
    const errorDescription = urlParams.get('error_description');

    if (error) {
      // Show error message
      notifications.show({
        color: 'red',
        message: `${error || 'Error: '} ${errorDescription ? errorDescription : ''}`,
      });
      console.error('Error connecting to Integration:', error, errorDescription);
    }

    // Read details from localStorage
    const integrationDetails = JSON.parse(localStorage.getItem('integrationDetails') || '{}');
    const workspaceId = integrationDetails?.workspaceId;
    const integrationName = integrationDetails?.integrationName;

    // Send user to integration page with the workspaceId and code
    navigate(`/workspace/${workspaceId}/integrations?code=${code}&integrationName=${integrationName}&state=${state}`);

  }, []); // This effect runs only once on component mount

  return <div>Loading...</div>; // Placeholder while redirecting
};
