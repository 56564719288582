import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex, Text } from '@mantine/core';
import classes from '../LinkCreationModal.module.css';

export const DeepLinkCheck = () => {
  const [link] = useLinkSetupStore((state) => [state.link]);
  const type = link.is_deep_link_allowed ? 'valid' : 'invalid';
  return (
    <Box
      className={!link.is_deep_link_enabled ? classes.disabled : ''}
      style={{
        opacity: link.is_deep_link_allowed ? 1 : 0.5,
      }}
    >
      <Flex align={'center'} justify={'center'}>
        <Box
          mt={64}
          style={{
            width: 200,
            height: 200,
            backgroundColor:
              type === 'valid' ? 'var(--mantine-color-green-1)' : 'var(--mantine-color-red-1)',
            borderRadius: '50%',
          }}
          h={200}
          w={200}
        >
          <Flex
            style={{
              width: '100%',
              height: '100%',
            }}
            align={'center'}
            justify={'center'}
            direction={'column'}
          >
            <FontAwesomeIcon
              icon={type === 'valid' ? faCheck : faTimes}
              style={{
                color:
                  type === 'valid' ? 'var(--mantine-color-green-8)' : 'var(--mantine-color-red-8)',
                marginBottom: 16,
              }}
              size="2x"
            />
            <Text c="gray" px={32} fw={500} fz={13} ta={'center'}>
              Deep Linking applied{' '}
              <span
                style={{
                  color:
                    type === 'valid'
                      ? 'var(--mantine-color-green-8)'
                      : 'var(--mantine-color-red-8)',
                  fontWeight: 700,
                }}
              >
                {type === 'valid' ? 'successfully' : 'unsuccessfully'}
              </span>{' '}
              on the link.
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
