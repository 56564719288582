import { useCampaignSetupStore } from '@/stores/useCampaignSetupStore';
import { useCustomDomainsStore } from '@/stores/useCustomDomainsStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import {
  Anchor,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  Radio,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { useUsermaven } from "@usermaven/react";

export const CampaignType = ({ nextStep, form }: { nextStep: () => void; form: any }) => {
  const [getConnectedCustomDomains] = useCustomDomainsStore((state) => [
    state.getConnectedCustomDomains,
  ]);
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [storeCampaign, createLoading] = useCampaignSetupStore((state) => [
    state.storeCampaign,
    state.createLoading,
  ]);
  const [ setPage ] = useCampaignsStore((state) => [ state.setPage]);
  const navigate = useNavigate();
  const connectedDomains = getConnectedCustomDomains();
  const defaultDomain = connectedDomains.find((domain) => domain.is_default) ?? null;
  const {track} = useUsermaven()

  useEffect(() => {
    if (form.values._id === null || form.values._id === "") {
      if (form?.values?.domain_id === null && defaultDomain !== null) {
        form.setFieldValue('domain_id', defaultDomain._id)
      }
    }
  }, [])
  
  const handleNext = () => {
    form.validate();
    if (!form.values.name) return;
    if (form.errors?.name) return;

    // const campaignType = form.values.campaign_type;
    // Campaign Type: CTA
    if (form.values.is_call_to_action_enabled && !form.values.is_custom_widget_enabled) {
      form.setFieldValue('type', 'button');
    } else if (
      !form.values.is_call_to_action_enabled &&
      !form.values.is_custom_widget_enabled &&
      form.values.is_retargeting_enabled
    ) {
      // if only retargeting is enabled
      form.setFieldValue('type', 'retargeting');
    } else if (form.values.is_custom_widget_enabled) {
      // if only third party is enabled
      form.setFieldValue('type', 'integration');
    } else {
      form.setFieldValue('type', 'no_cta');
    }
    nextStep();
  };

  const isOnlyShortenerCampaign = () => {
    return (
      !form.values.is_call_to_action_enabled &&
      !form.values.is_custom_widget_enabled &&
      !form.values.is_retargeting_enabled
    );
  };

  const handleSaveCampaign = async () => {
    form.validate();
    if (form.isValid()) {
      const res = await storeCampaign(defaultWorkspace?._id || '', form.values);
      if (res) {
        track('campaign_created', { campaign: form.values.name, type: 'simple_shortener' })
        setPage(1)
        navigate(`/campaigns/${defaultWorkspace?._id}`);
      }
    }
  };

  const handleDomainConfirmationChange = (value: string | null) => {
    if (form.values._id) {
      const newDomain = getConnectedCustomDomains().find((domain) => domain._id === value);
      const oldDomain = getConnectedCustomDomains().find(
        (domain) => domain._id === form.values.domain_id
        );
      modals.openConfirmModal({
        title: <Title order={5}>Are you sure?</Title>,
        children: (
          <>
            <Text size="sm">
              Your campaign links will be updated from{' '}
              {/* TODO: Trial user case domain to be updated here */}
              <strong>{oldDomain ? oldDomain?.url : defaultWorkspace?.agency?.is_whitelabel_enabled ? defaultWorkspace?.agency?.shortener_domain : 'https://replug.link/'}</strong> to{' '}
              <strong>{newDomain ? newDomain?.url : defaultWorkspace?.agency?.is_whitelabel_enabled ? defaultWorkspace?.agency?.shortener_domain : 'https://replug.link/'} </strong>
              as you have changed custom domain for your campaign.
            </Text>
          </>
        ),
        labels: { confirm: 'Yes, Update it.', cancel: 'No, Cancel' },
        confirmProps: { color: 'red' },
        onCancel: () => console.log('Cancel'),
        onConfirm: () => form.setFieldValue('domain_id', value),
      });
    } else {
      form.setFieldValue('domain_id', value);
    }
  };

  return (
    <>
      {' '}
      <Title order={5} px={24} py={16}>
        Campaign Type
      </Title>
      <Divider color="gray.2" />
      <Stack p={24}>
        <TextInput
          label="Name"
          variant="filled"
          placeholder="Give your campaign an easy name to remember."
          {...form.getInputProps('name')}
        />
        <Flex mb={-12}>
          <Text fw={600} fz={13}>
            Custom Domain
          </Text>
          <Flex
            justify={'end'}
            style={{
              flexGrow: 1,
            }}
          >
            <Anchor
              fz={13}
              fw={600}
              onClick={() => navigate(`/workspace/${defaultWorkspace?._id}/custom-domains`)}
            >
              + Connect
            </Anchor>
          </Flex>
        </Flex>
        <Select
          variant="filled"
          placeholder="Choose custom domain you want to associate links."
          data={getConnectedCustomDomains().map((domain) => ({
            label: domain.url,
            value: domain._id,
          }))}
          value={form.values.domain_id} // Set default value here
          onChange={(value) => {
            handleDomainConfirmationChange(value);
          }}
        />
        <Checkbox color="gray.5" label="Shortener" checked />
        <Checkbox
          label="Retargeting Pixel"
          checked={form.values.is_retargeting_enabled}
          {...form.getInputProps('is_retargeting_enabled')}
        />

        <Divider color="gray.2" my={4} />
        <Text fw={600}>Widgets</Text>
        <Radio
          label="Call-to-action (CTA)"
          checked={form.values.is_call_to_action_enabled}
          onClick={() => {
            // Directly toggle the 'cta' value based on its current state.
            const currentlyChecked = form.values.is_call_to_action_enabled;
            form.setFieldValue('is_call_to_action_enabled', !currentlyChecked);

            // If unselecting 'cta', do not automatically select 'third_party'.
            // Only explicitly set 'third_party' to false if 'cta' is being selected.
            if (!currentlyChecked) {
              form.setFieldValue('is_custom_widget_enabled', false);
            }
          }}
        />
        <Radio
          label="3rd Party/Custom Widgets"
          checked={form.values.is_custom_widget_enabled}
          onClick={() => {
            // Directly toggle the 'third_party' value based on its current state.
            const currentlyChecked = form.values.is_custom_widget_enabled;
            form.setFieldValue('is_custom_widget_enabled', !currentlyChecked);

            // If unselecting 'third_party', do not automatically select 'cta'.
            // Only explicitly set 'cta' to false if 'third_party' is being selected.
            if (!currentlyChecked) {
              form.setFieldValue('is_call_to_action_enabled', false);
            }
          }}
        />
        <Center>
          {isOnlyShortenerCampaign() ? (
            <>
              <Button
                loading={createLoading}
                disabled={createLoading}
                w={'200'}
                onClick={handleSaveCampaign}
              >
                Save Campaign
              </Button>
            </>
          ) : (
            <>
              <Button w={'200'} onClick={handleNext}>
                Next
              </Button>
            </>
          )}
        </Center>
      </Stack>
    </>
  );
};
