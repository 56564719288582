import { MultiSelectCreatable } from '@/components/MultiSelectCreatable/MultiSelectCreatable';
import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { useTagsStore } from '@/stores/useTagsStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { Box, Button, Flex, Modal, Select, Textarea, Title, Text, Group, Switch, Tooltip } from '@mantine/core';
import { useForm } from '@mantine/form';
import { zodResolver } from 'mantine-form-zod-resolver';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { useUserStore } from '@/stores/useUserStore';
import CSVButton from '@/components/CSVImport/CSVButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';

const csvImportLinksSchema = z.object({
  workspace_id: z.string(),
  campaign_id: z.string(),
  tags: z.array(z.string().nullable()),
  run_on_background: z.boolean(),
  allow_duplicate_slug: z.boolean(),
  discard_invalid_slug: z.boolean(),
  mail_iframe_disabled_links: z.boolean(),
});

export const CSVImportLinksModal = ({
  opened,
  onClose,
}: {
  opened: boolean;
  onClose: () => void;
}) => {
  const [campaigns, setCampaigns] = useCampaignsStore((state) => [
    state.campaigns,
    state.setCampaigns,
  ]);
  const [loading, setLoading] = useState(false);
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [tags, createTag] = useTagsStore((state) => [state.tags, state.createTag]);
  const [user] = useUserStore(state => [state.user]);
  const [disableSubmit, setDisableSubmit] = useState<boolean | undefined>(false)
  const [runOnBackground, setRunOnBackground] = useState<boolean | undefined>(false)
  const [allowDuplicateSlug, setAllowDuplicateSlug] = useState<boolean | undefined>(false)
  const [discardInvalidSlug, setDiscardInvalidSlug] = useState<boolean | undefined>(false)
  const [mailIframeDisabledLink, setMailIframeDisabledLink] = useState<boolean | undefined>(false)
  const [campaignId, setCampaignId] = useState<string | undefined>('')
  const [tagsArray, setTagsArray] = useState<string[]>([])
  const [backgroundJobFlag, setBackgroundJobFlag] = useState<boolean>(true);
  const [isCampaignCTA, setIsCampaignCTA] = useState<boolean>(false);
  const handleTagCreate = async (value: string) => {
    const resp = await createTag(defaultWorkspace?._id || '', {
      _id: null,
      name: value,
      workspace_id: defaultWorkspace?._id || '',
    });
  };
  const form = useForm({
    validate: zodResolver(csvImportLinksSchema),
    initialValues: {
      workspace_id: defaultWorkspace?._id,
      campaign_id: '',
      tags: [],
      run_on_background: false,
      allow_duplicate_slug: false,
      discard_invalid_slug: false,
      mail_iframe_disabled_links: false,
    },
  });

  const getCampaignsData = () => {
    const list = campaigns.map((campaign: any) => ({
      label: campaign?.name || 'No Campaign',
      value: campaign._id,
    }));
    if (!list.length) return [];
    return list;
  };

  const handleFormSubmit = async (values: any) => {
    setCampaignId(values.campaign_id)
    setTagsArray(values.tags)
    setRunOnBackground(values.run_on_background)
    setAllowDuplicateSlug(values.allow_duplicate_slug)
    setDiscardInvalidSlug(values.discard_invalid_slug)
    setMailIframeDisabledLink(values.mail_iframe_disabled_links)
    setDisableSubmit(false)
  };

  const handleCampaignChange = (campaign_id: any) => {
    const filteredCampaign = campaigns.find(item => item._id === campaign_id);
    if (filteredCampaign) {
      setIsCampaignCTA(filteredCampaign.is_call_to_action_enabled)
    }
  }

  useEffect(() => {
    if (opened) {
      form.setValues({
        workspace_id: defaultWorkspace?._id,
        campaign_id: '',
        tags: [],
        run_on_background: false,
        allow_duplicate_slug: false,
        discard_invalid_slug: false,
        mail_iframe_disabled_links: false,
      });
      setBackgroundJobFlag(true)
    }
  }, [opened]);

  useEffect(() => {
    setDisableSubmit(true)
    handleCampaignChange(form.values.campaign_id)
  }, [form.values.campaign_id, form.values.tags, form.values.run_on_background, form.values.allow_duplicate_slug, form.values.discard_invalid_slug, form.values.mail_iframe_disabled_links,])

  return (
    <>
      <Modal
        size={1000}
        opened={opened}
        onClose={onClose}
        title={
          <Text fw={700} fz={18} mt="20px">
            Import CSV
          </Text>
        }
        style={{ minHeight: '100vh !important' }}
      >
        <Box p={16}>
          <form onSubmit={form.onSubmit(handleFormSubmit)}>
            <Select
              data={getCampaignsData()}
              label="Select Campaign"
              placeholder="Select your campaign"
              {...form.getInputProps('campaign_id')}
              mb={'md'}
              searchable
            />
            <MultiSelectCreatable
              label="Tags"
              placeholder="Search or create a tag"
              dataItems={
                tags.length > 0
                  ? tags.map((tag: any) => ({
                    label: tag?.name,
                    value: tag?._id,
                  }))
                  : []
              }
              valueItems={form.values.tags}
              onChange={(value) => {
                // @ts-ignore
                form.setFieldValue('tags', value);
              }}
              onCreate={(item) => {
                handleTagCreate(item.value);
              }}
              size={'sm'}
            />
            <Flex justify="space-between" align="center" style={{ padding: '0px 10px', backgroundColor: '#f0f0f0', borderRadius: '48px', margin: '30px 0px 10px' }}>
              <p>
                <span style={{ padding: '0px 10px' }}> Run the import process in the background</span>
                <Tooltip
                  multiline
                  position="bottom"
                  label="After starting the import process, you won't be able to modify the duplicate and invalid slugs in real-time."
                >
                  <FontAwesomeIcon
                    style={{
                      color: 'var(--mantine-color-gray-6)',
                    }}
                    icon={faCircleExclamation}
                  />
                </Tooltip>
              </p>
              <Switch
                style={{
                  cursor: 'pointer',
                }}
                onLabel="ON"
                offLabel="OFF"
                checked={form.values.run_on_background}
                onChange={(value) => {
                  form.setFieldValue('run_on_background', value.target.checked)
                  setBackgroundJobFlag(!backgroundJobFlag)
                }}
                size="sm"
              />
              {/* <Tooltip label="lorem ipsum"></Tooltip> */}

            </Flex>
            <Flex
              justify="space-between"
              align="center"
              style={{
                padding: '0px 10px',
                backgroundColor: '#f0f0f0',
                borderRadius: '48px',
                margin: '10px 0px 10px 30px',
                opacity: backgroundJobFlag ? '0.5' : '1',
                pointerEvents: backgroundJobFlag ? 'none' : 'auto',
              }}
            >
              <p>
                <span style={{ padding: '0px 10px' }}> Assign new slug in case of a duplicate slug</span>
                <Tooltip
                  multiline
                  position="bottom"
                  label="Duplicate slugs shall be replaced with new slugs, discarded otherwise."
                >
                  <FontAwesomeIcon
                    style={{
                      color: 'var(--mantine-color-gray-6)',
                    }}
                    icon={faCircleExclamation}
                  />
                </Tooltip>
              </p>
              <Switch
                style={{
                  cursor: 'pointer',
                }}
                onLabel="ON"
                offLabel="OFF"
                checked={form.values.allow_duplicate_slug}
                onChange={(event) => {
                  if (!backgroundJobFlag) {
                    form.setFieldValue('allow_duplicate_slug', event.target.checked);
                  }
                }}
                size="sm"
                disabled={backgroundJobFlag}
              />
            </Flex>
            <Flex
              justify="space-between"
              align="center"
              style={{
                padding: '0px 10px',
                backgroundColor: '#f0f0f0',
                borderRadius: '48px',
                margin: '10px 0px 10px 30px',
                opacity: backgroundJobFlag ? '0.5' : '1',
                pointerEvents: backgroundJobFlag ? 'none' : 'auto',
              }}
            >
              <p>
                <span style={{ padding: '0px 10px' }}> Assign new slug in case of an invalid slug</span>
                <Tooltip
                  multiline
                  position="bottom"
                  label="Slugs that contain alphanumeric values including hyphen shall be replaced with a new slug, discarded otherwise."
                >
                  <FontAwesomeIcon
                    style={{
                      color: 'var(--mantine-color-gray-6)',
                    }}
                    icon={faCircleExclamation}
                  />
                </Tooltip>
              </p>
              <Switch
                style={{
                  cursor: 'pointer',
                }}
                onLabel="ON"
                offLabel="OFF"
                checked={form.values.discard_invalid_slug}
                onChange={(event) => {
                  if (!backgroundJobFlag) {
                    form.setFieldValue('discard_invalid_slug', event.target.checked);
                  }
                }}
                size="sm"
                disabled={backgroundJobFlag}
              />
            </Flex>
            {isCampaignCTA && <Flex
              justify="space-between"
              align="center"
              style={{
                padding: '0px 10px',
                backgroundColor: '#f0f0f0',
                borderRadius: '48px',
                margin: '10px 0px 10px 30px',
                opacity: backgroundJobFlag ? '0.5' : '1',
                pointerEvents: backgroundJobFlag ? 'none' : 'auto',
              }}
            >
              <p>
                <span style={{ padding: '0px 10px' }}> Notify iframe disabled links to the user.</span>
                <Tooltip
                  multiline
                  position="bottom"
                  label="Summary page will be applied to links that have iFrame issues and shall be notified via Email."
                >
                  <FontAwesomeIcon
                    style={{
                      color: 'var(--mantine-color-gray-6)',
                    }}
                    icon={faCircleExclamation}
                  />
                </Tooltip>
              </p>
              <Switch
                style={{
                  cursor: 'pointer',
                }}
                onLabel="ON"
                offLabel="OFF"
                checked={form.values.mail_iframe_disabled_links}
                onChange={(event) => {
                  if (!backgroundJobFlag) {
                    form.setFieldValue('mail_iframe_disabled_links', event.target.checked);
                  }
                }}
                size="sm"
                disabled={backgroundJobFlag}
              />
            </Flex>}
            <Flex mt={32} align={'center'} justify={'center'}>
              {disableSubmit && <Button disabled={loading} loading={loading} type="submit" mr={4}>
                Submit Form
              </Button>}
              {(campaignId && !disableSubmit) && <CSVButton workspace_id={defaultWorkspace?._id} user_id={user?._id} campaign_id={campaignId} tags={tagsArray} runOnBackground={runOnBackground} discardInvalidSlug={discardInvalidSlug} allowDuplicateSlug={allowDuplicateSlug} mailIframeDisabledLink={mailIframeDisabledLink} onClose={onClose} />}
              <Button onClick={onClose} variant="outline" color="gray">
                Cancel
              </Button>
            </Flex>
          </form>
        </Box>
      </Modal>
    </>
  );
};
