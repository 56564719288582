import {
  faBookmark,
  faCode,
  faCrosshairsSimple,
  faDivide,
  faEllipsis,
  faEllipsisVertical,
  faGear,
  faGlobe,
  faLink,
  faLocationPin,
  faNetworkWired,
  faShieldHalved,
  faTableLayout,
  faTags,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Center, Divider, Flex, Text, Title } from '@mantine/core';
import { NavLink, Outlet } from 'react-router-dom';
import classes from './WorkspaceSettings.module.css';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isUserCollaborator } from '@/utils/CommonUtility';
import { useUserStore } from '@/stores/useUserStore';

export function WorkspaceSettingsPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [user] = useUserStore((state) => [state.user]);

  const routes = () => {
    return [
      {
        path: `/workspace/${defaultWorkspace?._id}/basic`,
        label: 'Basic Settings',
        icon: faGear,
      },
      {
        path: `/workspace/${defaultWorkspace?._id}/custom-domains`,
        label: 'Custom domains',
        icon: faGlobe,
      },
      {
        path: `/workspace/${defaultWorkspace?._id}/team-members`,
        label: 'Team Members',
        icon: faUsers,
      },

      {
        path: `/workspace/${defaultWorkspace?._id}/integrations`,
        label: 'Integrations',
        icon: faNetworkWired,
      },
      {
        path: `/workspace/${defaultWorkspace?._id}/deep-linking`,
        label: 'Deep Linking',
        icon: faLink,
      },
      {
        path: 'divider',
        label: 'divider',
        icon: faDivide,
      },
    ];
  };

  const globalRoutes = [
    {
      path: `/workspace/${defaultWorkspace?._id}/white-label`,
      label: 'White-Label',
      icon: faBookmark,
    },
    {
      path: `/workspace/${defaultWorkspace?._id}/developer-apps`,
      label: 'Developer Apps',
      icon: faCode,
    },
  ];

  const miscRoutes = [
    {
      path: `/workspace/${defaultWorkspace?._id}/retargeting-pixels`,
      label: 'Retargeting Pixels',
      icon: faCrosshairsSimple,
    },
    {
      path: `/workspace/${defaultWorkspace?._id}/custom-widgets`,
      label: 'Custom Widgets',
      icon: faTableLayout,
    },
    {
      path: `/workspace/${defaultWorkspace?._id}/utm-tracking`,
      label: 'UTM Tracking',
      icon: faLocationPin,
    },
    {
      path: `/workspace/${defaultWorkspace?._id}/tags`,
      label: 'Tags',
      icon: faTags,
    },
    {
      path: `/workspace/${defaultWorkspace?._id}/ip_address`,
      label: 'Ip Addresses',
      icon: faShieldHalved,
    },
    {
      path: `/workspace/${defaultWorkspace?._id}/exclude-traffic`,
      label: 'Exclude Traffic',
      icon: faShieldHalved,
    },
    {
      path: `/workspace/${defaultWorkspace?._id}/miscellaneous`,
      label: 'Others',
      icon: faEllipsisVertical,
    },
  ];

  function renderItem(route: any, index: number, nested = false) {
    // console.log(route.label, index, 'render item function');
    switch (route.label) {
      case 'White-Label':
        if(defaultPermissions && !checkPermission(defaultPermissions, ['view_whitelabel'])){
          return '';
        }
        break;
      case 'Developer Apps':
        if(defaultPermissions && !checkPermission(defaultPermissions, ['view_apps'])){
          return '';
        }
        break;
      case 'Basic Settings':
        // if(defaultPermissions && !checkPermission(defaultPermissions, 'view_apps')){
        //   return '';
        // }
        break;
      case 'Custom domains':
        if(defaultPermissions && !checkPermission(defaultPermissions, ['view_cDomains'])){
          return '';
        }
        break;
      case 'Team Members':
        if(defaultPermissions && !checkPermission(defaultPermissions, ['view_team_member'])){
          return '';
        }
        break;
      case 'Integrations':
        if(defaultPermissions && !checkPermission(defaultPermissions, ['view_integrations'])){
          return '';
        }
        break;
      case 'Deep Linking':
        if(defaultPermissions && !checkPermission(defaultPermissions, ['view_deep_linking'])){
          return '';
        }
        break;
      case 'Retargeting Pixels':
        if(defaultPermissions && !checkPermission(defaultPermissions, ['view_pixels'])){
          return '';
        }
        break;
      case 'Custom Widgets':
        if(defaultPermissions && !checkPermission(defaultPermissions, ['view_widgets'])){
          return '';
        }
        break;
      case 'UTM Tracking':
        if(defaultPermissions && !checkPermission(defaultPermissions, ['view_utms'])){
          return '';
        }
        break;
      case 'Tags':
        if(defaultPermissions && !checkPermission(defaultPermissions, ['view_tags'])){
          return '';
        }
        break;
      case 'Ip Addresses':
        if(defaultPermissions && !checkPermission(defaultPermissions, ['view_ip'])){
          return '';
        }
        break;
      case 'Exclude Traffic':
        if(defaultPermissions && !checkPermission(defaultPermissions, ['view_exclude_traffic'])){
          return '';
        }
        break;
      case 'Others':
        if(defaultPermissions && !checkPermission(defaultPermissions, ['view_other_settings'])){
          return '';
        }
        break;
    }
    return (
      <NavLink
        key={index}
        className={({ isActive }) =>
          isActive ||
          // corner case condition.
          (window.location.href.endsWith('basic') &&
            route.path === `/workspace/${defaultWorkspace?._id}/basic`)
            ? `${classes.link} ${classes.linkActive}`
            : classes.link
        }
        to={route.path}
      >
        <Flex align={'center'} pl={nested ? 24 : 0}>
          <Box w={16} mr={12}>
            <Center>
              <FontAwesomeIcon color="gray.7" size="sm" icon={route.icon} />
            </Center>
          </Box>
          <Text fz="sm">{route.label}</Text>
        </Flex>
      </NavLink>
    );
  }

  return (
    <>
      <Flex
        style={{
          height: '100%',
          overflow: 'auto',
        }}
        mb={32}
      >
        <Flex w={'20%'}>
          <Box
            ml={16}
            style={{
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
              backgroundColor: 'var(--mantine-color-white)',
              borderRadius: '0.25rem',
              height: '100%',
              width: '100%',
              overflow: 'auto',
              position: 'sticky',
            }}
          >
            {defaultPermissions && checkPermission(defaultPermissions, ['view_whitelabel', 'view_apps']) &&  <Title p={16} order={6}>
              Global Settings
            </Title>}
            {defaultPermissions && checkPermission(defaultPermissions, ['view_whitelabel', 'view_apps']) && <Divider color="gray.2" mb={8} />}
            <Flex
              direction={'column'}
              style={{
                flexGrow: 1,
              }}
              pb={8}
            >
              {globalRoutes.map((route, index) => (
                <>{renderItem(route, index)}</>
              ))}
            </Flex>

            <Divider color="gray.2" />

            <Title p={16} order={6}>
              Workspace Settings
            </Title>
            <Divider color="gray.2" mb={8} />
            <Flex
              direction={'column'}
              style={{
                flexGrow: 1,
              }}
              pb={8}
            >
              {routes().map((route, index) => (
                <>
                  {route.path === 'divider' ? (
                    <Divider color="gray.2" />
                  ) : (
                    <>{renderItem(route, index)}</>
                  )}
                </>
              ))}
            </Flex>
            <Flex
              direction={'column'}
              style={{
                flexGrow: 1,
              }}
              pb={8}
              ml={16}
            >
              <Flex align={'center'}>
                <Box w={16} mr={12}>
                  <FontAwesomeIcon icon={faEllipsis} />
                </Box>
                <Text fz="sm">Miscellaneous</Text>
              </Flex>
            </Flex>

            <Flex
              direction={'column'}
              style={{
                flexGrow: 1,
              }}
            >
              {miscRoutes.map((route, index) => (
                <>
                  {route.path === 'divider' ? (
                    <Divider color="gray.2" />
                  ) : (
                    <>{renderItem(route, index, true)}</>
                  )}
                </>
              ))}
            </Flex>
          </Box>
        </Flex>
        <Flex
          w={'80%'}
          mb={64}
          style={{
            flexDirection: 'column',
            height: 'auto',
          }}
        >
          <Outlet />
        </Flex>
      </Flex>
    </>
  );
}
