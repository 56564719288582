import { usePlanStore } from '@/stores/usePlanStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { IPlan } from '@/types/plan';
import { IWorkspace } from '@/types/workspace';
import { setAuthTokenLocalStorage } from '@/utils/Storage';
import { Center, Container, Flex, Loader } from '@mantine/core';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const LogoutPage = () => {
  const navigate = useNavigate();

  const [setWorkspaces, setDefaultWorkspace] = useWorkspaceStore((state) => [
    state.setWorkspaces,
    state.setDefaultWorkspace,
  ]);
  const [setPlan, setPrimarySubscription] = usePlanStore((state) => [state.setPlan, state.setPrimarySubscription]);

  useEffect(() => {
    setAuthTokenLocalStorage('');
    setWorkspaces([]);
    setDefaultWorkspace({} as IWorkspace);
    setPlan({} as IPlan);
    setPrimarySubscription([]);
    navigate('/login');
  }, []);
  return (
    <Container>
      <Flex direction={'column'} justify={'center'} align={'center'}>
        <Loader size={'sm'} />
        <Center>Logging out...</Center>
      </Flex>
    </Container>
  );
};
