import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, Divider, Flex, Indicator, Popover, Text } from '@mantine/core';
import { useState } from 'react';

export function CampaignsMenu() {
  const [opened, setOpened] = useState(false);
  const [campaigns, selectedCampaigns, setSelectedCampaigns] = useCampaignsStore((state) => [
    state.campaigns,
    state.selectedCampaigns,
    state.setSelectedCampaigns,
  ]);
  const getMutatedCampaigns = () => {
    const filteredCampaigns = campaigns.map((campaign: any) => {
      return {
        label: campaign.name,
        value: campaign._id,
      };
    });
    return filteredCampaigns;
  };

  const handleCheckboxChange = (campaignId: string) => {
    const find = selectedCampaigns.find((campaign) => campaign === campaignId);
    if (find) {
      setSelectedCampaigns(selectedCampaigns.filter((campaign) => campaign !== campaignId));
    } else {
      setSelectedCampaigns([...selectedCampaigns, campaignId]);
    }
  };

  return (
    <>
      <Popover withArrow shadow="sm" opened={opened} onChange={setOpened}>
        <Popover.Target>
          <Indicator
            color="primary.4"
            inline
            disabled={selectedCampaigns.length === 0}
            size={'sm'}
            label={selectedCampaigns.length}
          >
            <Button
              variant="outline"
              color="gray"
              size="xs"
              onClick={() => setOpened(!opened)}
              rightSection={<FontAwesomeIcon icon={faChevronDown} />}
            >
              Filter by campaigns
            </Button>
          </Indicator>
        </Popover.Target>
        <Popover.Dropdown
          w={280}
          style={{
            maxHeight: '300px',
            overflow: 'auto',
          }}
        >
          {getMutatedCampaigns().length > 0 && (
            <>
              <Checkbox
                size="xs"
                pb={12}
                label="Select All"
                checked={selectedCampaigns.length === campaigns.length}
                onChange={() => {
                  if (selectedCampaigns.length === campaigns.length) {
                    setSelectedCampaigns([]);
                  } else {
                    setSelectedCampaigns(campaigns.map((campaign: any) => campaign._id));
                  }
                }}
              />
              <Divider color="gray.2" pb={8} />
            </>
          )}

          {getMutatedCampaigns().length === 0 && (
            <>
              <Text ta="center" py={8} size="sm">
                You do not have any campaigns created.
              </Text>
            </>
          )}
          {getMutatedCampaigns().map((item) => (
            <Flex
              key={item.value}
              align={'center'} // Make sure to have a unique key for each item
              fz={'xs'}
              style={{
                cursor: 'pointer',
              }}
              // onClick={(e) => {
              //   e.preventDefault();
              //   e.stopPropagation();
              //   handleCheckboxChange(item.value);
              // }}
              py={6}
            >
              <Checkbox
                mb={2}
                size="xs"
                styles={{
                  input: {
                    cursor: 'pointer',
                  },
                  label: {
                    cursor: 'pointer',
                  },
                }}
                label={item.label}
                checked={selectedCampaigns.includes(item.value)}
                onChange={(e) => {
                  // e.preventDefault();
                  // e.stopPropagation(); // Prevent event from closing the dropdown
                  handleCheckboxChange(item.value);
                }}
              />
            </Flex>
          ))}
        </Popover.Dropdown>
      </Popover>
    </>
  );
}
