import { useQRModalStore } from '@/stores/useQRModalStore';
import { appendQrParameter } from '@/utils/LinkUtility';
import { getQRAsCanvas, getQRAsSVGDataUri } from '@/utils/qr';
import { Button, Center, ColorInput, Flex, Group, Image, Modal, Title } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
export const QRCodeModal = () => {
  const [opened, setOpened, onClose, url, props, fgColor, bgColor, setFgColor, setBgColor] =
    useQRModalStore((state) => [
      state.isOpen,
      state.setIsOpen,
      state.onClose,
      state.url,
      state.props,
      state.fgColor,
      state.bgColor,
      state.setFgColor,
      state.setBgColor,
    ]);

  const anchorRef = useRef<HTMLAnchorElement>(null);
  const download = (url: string, extension: string) => {
    if (!anchorRef.current) return;
    anchorRef.current.href = url;
    anchorRef.current.download = `${props?.short_url}-qrcode.${extension}`;
    anchorRef.current.click();
  };

  if (url === '') return null;
  let finalUrl = '';

  if (url) {
    finalUrl = appendQrParameter(url);
  }

  return (
    <Modal
      zIndex={300}
      opened={opened}
      onClose={onClose}
      title={<Title order={5}>Download QR Code</Title>}
    >
      <Flex justify="center" align="center">
        <Image
          h={148}
          w={148}
          src={getQRAsSVGDataUri({ value: finalUrl, fgColor: fgColor, bgColor: bgColor })}
        />
      </Flex>
      <Center my="md">
        <Group>
          <ColorInput w={150} label="Background Color" value={bgColor} onChange={setBgColor} />
          <ColorInput w={150} label="Foreground Color" value={fgColor} onChange={setFgColor} />
        </Group>
      </Center>
      <Center my="md">
        <Group mt="md">
          <Button
            variant="outline"
            onClick={() => {
              download(
                getQRAsSVGDataUri({ value: finalUrl, fgColor: fgColor, bgColor: bgColor }),
                'svg'
              );
            }}
          >
            Download as SVG
          </Button>
          <Button
            variant="outline"
            onClick={async () => {
              download(
                (await getQRAsCanvas(
                  { value: finalUrl, fgColor: fgColor, bgColor: bgColor },
                  'image/png'
                )) as string,
                'png'
              );
            }}
          >
            Download as PNG
          </Button>
        </Group>

        <a className="hidden" download={`${props.short_url}-qrcode.svg`} ref={anchorRef} />
      </Center>
    </Modal>
  );
};
