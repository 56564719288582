import ReactEChartsCore from 'echarts-for-react/lib/core';
// @ts-ignore
import * as echarts from 'echarts/dist/echarts.esm';
import {
  Box,
  Divider,
  Flex,
  Grid,
  List,
  Loader,
  ScrollArea,
  SegmentedControl,
  Table,
  Text,
  rem,
} from '@mantine/core';
import { AnalyticsService } from '@/services/AnalyticsService';
import { useEffect, useState } from 'react';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { numberToCommas } from '@/utils/StringUtility';
import { useLocation } from 'react-router-dom';
import { NoResultsListTable } from '@/components/Menus/NoResultsListTable/NoResultsListTable';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';

export const AnalyticsByBrowser = () => {
  // Get Values from Query Params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fromDate =
    queryParams.get('from_date') ||
    format(startOfDay(subDays(new Date(), 30)), "yyyy-MM-dd'T'HH:mm:ss");
  const toDate =
    queryParams.get('to_date') || format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss");
  const campaignId = queryParams.get('campaign_id');
  const linkId = queryParams.get('link_id');

  const option = {
    title: {
      //   text: 'Nightingale Chart',
      //   subtext: 'Fake Data',
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)',
    },
    legend: {
      left: 'center',
      top: 'bottom',
      data: ['rose1', 'rose2', 'rose3', 'rose4', 'rose5', 'rose6', 'rose7', 'rose8'],
    },
    toolbox: {
      show: true,
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    series: [
      {
        name: 'Radius Mode',
        type: 'pie',
        radius: [30, 140],
        center: ['45%', '50%'],
        roseType: 'radius',
        itemStyle: {
          borderRadius: 5,
        },
        label: {
          show: false,
        },
        emphasis: {
          label: {
            show: true,
          },
        },
        data: [
          { value: 40, name: 'rose 1' },
          { value: 33, name: 'rose 2' },
          { value: 28, name: 'rose 3' },
          { value: 22, name: 'rose 4' },
          { value: 20, name: 'rose 5' },
          { value: 15, name: 'rose 6' },
          { value: 12, name: 'rose 7' },
          { value: 10, name: 'rose 8' },
        ],
      },
    ],
  };

  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [type, setType] = useState('country');

  const fetchBrowser = async () => {
    setLoading(true);
    await new AnalyticsService()
      .browser(defaultWorkspace?._id || '', campaignId, linkId, fromDate, toDate)
      .then((res) => {
        if (res.data.status && res.data.data.length > 0) {
          setList(res.data.data);
        } else {
          setList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  useEffect(() => {
    fetchBrowser();
  }, [campaignId, linkId, fromDate, toDate, defaultWorkspace?._id]);

  return (
    <>
      <Box
        bg={'white'}
        h={450}
        style={{
          boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
          border: '1px solid var(--mantine-color-gray-2)',
          borderRadius: 4,
        }}
      >
        <Flex align={'center'} p={12}>
          <Text
            style={{
              flexGrow: 1,
            }}
            fw={600}
          >
            By Browser
          </Text>
          <Flex>
            {/* <SegmentedControl
              size="xs"
              data={[
                { label: 'List', value: 'list' },
                { label: 'Graph', value: 'graph' },
              ]}
            /> */}
          </Flex>
        </Flex>
        <Divider />
        {/* <Box p={16} mt={32}>
          <ReactEChartsCore
            style={{ height: '300px' }}
            echarts={echarts}
            option={option}
            notMerge
          />
        </Box>
        <Flex mb={32} justify={'center'}>
          <List>
            <List.Item>Microsoft Edge</List.Item>
            <List.Item>Chrome</List.Item>
          </List>
        </Flex> */}
        {loading ? (
          <>
            <Flex mt={64} align={'center'} justify={'center'}>
              <Loader size="sm" />
            </Flex>
          </>
        ) : (
          <>
            {list.length === 0 ? (
              <Box pt={96}>
                <NoResultsListTable message={'No browser data available'} />
              </Box>
            ) : (
              <>
                <ScrollArea h={380} scrollbarSize={6}>
                  <Table
                    stickyHeader
                    striped
                    verticalSpacing={'xs'}
                    style={{
                      borderLeft: '1px solid var(--mantine-color-gray-2)',
                    }}
                  >
                    <Table.Thead h={4} fz="xs">
                      <Table.Tr>
                        <Table.Th w={'40%'}>
                          <></>
                        </Table.Th>
                        <Table.Th ta="center">Total Clicks</Table.Th>
                        <Table.Th ta="center">Unique Clicks</Table.Th>
                        <Table.Th ta="center">QR Scans</Table.Th>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      {list.map((item: any) => (
                        <Table.Tr>
                          <Table.Td w={'40%'}>{item.ua_family || 'Unknown'}</Table.Td>
                          <Table.Td ta="center">{numberToCommas(item.clicks)}</Table.Td>
                          <Table.Td ta="center">{numberToCommas(item.unique_clicks)}</Table.Td>
                          <Table.Td ta="center">{numberToCommas(item.qr_scans)}</Table.Td>
                        </Table.Tr>
                      ))}
                    </Table.Tbody>
                  </Table>
                </ScrollArea>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};
