import { PixelService } from '@/services/PixelService';
import { IPixel } from '@/types/pixels';
import { create } from 'zustand';

interface IPixelStore {
  pixels: IPixel[];
  setPixels: (utms: IPixel[]) => void;
  fetchPixels: (workspace_id: string) => void;
  setupModal: boolean;
  setSetupModal: (toggle: boolean) => void;
  id: string | null;
  setId: (id: string | null) => void;
}

export const usePixelsStore = create<IPixelStore>((set) => ({
  id: null,
  setId: (id) => {
    set({ id });
  },
  pixels: [],
  setPixels: (pixels) => {
    set({ pixels });
  },
  fetchPixels: (workspace_id) => {
    new PixelService().get(workspace_id).then((res) => {
      if (res.data.status) {
        set({ pixels: res.data.data });
      } else {
        set({ pixels: [] });
      }
    });
  },
  setupModal: false,
  setSetupModal: (toggle) => {
    set({ setupModal: toggle });
  },
}));
