
import { create } from 'zustand';
import { IPlan } from '@/types/plan';
import { PlanService } from '@/services/PlanService';


interface IPlanStore {
  plan: IPlan | null;
  primarySubscription: any;
  upgradeModalState: boolean;
  setPlan: (plan: IPlan | null) => void;
  setPrimarySubscription: (primarySubscription: any) => void;
  fetchPlan: (workspaceId: string, organizationId: string) => void;
  fetchPrimarySubscription: (workspaceId: string, organizationId: string) => void;
  setUpgradeModalState: (isOpen: boolean) => void;
}

export const usePlanStore = create<IPlanStore>((set) => ({
  plan: null,
  primarySubscription: [],
  upgradeModalState: false,
  setUpgradeModalState: (isOpen) => {
    set({ upgradeModalState: isOpen });
  },
  setPlan: (plan) => {
    set({ plan });
  },
  setPrimarySubscription: (primarySubscription: any) => {
    set({ primarySubscription });
  },
  fetchPlan: (workspaceId: string, organizationId: string) => {
    try {
      new PlanService().get(workspaceId, organizationId).then((response) => {
        console.log('Debug: Plan', response.data?.plan);

        set({ plan: response.data?.plan || null });
      });
    } catch (error) {
      console.log(error);
    }
  },
  fetchPrimarySubscription: (workspaceId: string, organizationId: string) => {
    try {
      new PlanService().getSubscriptions(workspaceId, organizationId).then((response) => {
        set({ primarySubscription: response.data?.primary_subscription || [] });
      });
    } catch (error) {
      console.log(error);
    }
  }}));
