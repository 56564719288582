import { WorkspaceMemberService } from '@/services/WorkspaceMemberService';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { Box, Button, Center, Modal, Radio, TextInput, Title, Group, Paper, Text } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useEffect } from 'react';
import { z } from 'zod';

interface ITeamMemberModalProps {
  isOpen: boolean;
  onClose: () => void;
  reload: () => void;
  member: any;
}

const schema = z.object({
  email: z.string().email('Invalid email'),
  role: z.string().min(2, { message: 'Please select a role.' }),
});

export const TeamMemberModal = ({ isOpen, onClose, member, reload }: ITeamMemberModalProps) => {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const form = useForm({
    initialValues: {
      _id: member ? member._id : '',
      email: member ? member.email : '',
      role: member ? member.role : 'administrator',
      workspace_id: defaultWorkspace?._id,
    },
    validate: zodResolver(schema),
  });

  const handleSubmit = () => {
    form.validate();
    if (form.isValid()) {
      new WorkspaceMemberService()
        .create(defaultWorkspace?._id || '', form.values)
        .then((res) => {
          if (res.data.status) {
            notifications.show({
              color: 'green',
              message: res.data.message,
            });
            reload();
            onClose();
            form.reset();
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 400) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          } else if (err.response.status === 422) {
            notifications.show({
              color: 'red',
              message: err?.response?.data?.errors?.email[0] ?? 'Something went wrong!',
            });
          } else {
            notifications.show({
              color: 'red',
              message: 'Something went wrong!',
            });
          }
        });
    }
  };

  useEffect(() => {
    console.log(member);
    form.setValues({
      _id: member ? member._id : '',
      email: member ? member.email : '',
      role: member ? member.role : 'administrator',
      workspace_id: defaultWorkspace?._id,
    });
  }, [member]);

  const handleClose = () => {
    onClose();
    form.reset(); // Reset the form when closing the modal
  };

  return (
    <>
      <Modal
        size={600}
        opened={isOpen}
        onClose={handleClose}
        title={<Title order={5}>Team Member</Title>}
      >
        <Box p={'md'}>
          <TextInput
            mb="md"
            label="Email Address"
            variant="filled"
            placeholder="i.e. john@email.com"
            {...form.getInputProps('email')}
          />
          <Radio.Group
            mb="md"
            label="Role"
            value={form.values.role}
            onChange={(value) => form.setFieldValue('role', value)}
            error={form.errors.role}
          >
            <Group style={{ flexDirection: 'column', width: '100%' }}>
              {[
                { value: 'administrator', label: 'Administrator', description: 'Full control over the workspace, including creating, editing, and deleting campaigns and links, and managing team members. No access to billing and white-label.' },
                { value: 'collaborator', label: 'Collaborator', description: 'Can create, edit, and delete their own campaigns and links. No access to team management, billing, or white-label.' },
                { value: 'guest', label: 'Guest', description: 'Read-only access to the workspace. No permissions to edit, save, export data, manage team members, or access billing.' },
              ].map((role) => (
                <div
                  key={role.value}
                  onClick={() => form.setFieldValue('role', role.value)}
                  style={{ width: '100%', cursor: 'pointer' }}
                >
                  <Paper withBorder p="sm" radius="md" style={{ width: '100%' }}>
                    <Radio
                      value={role.value}
                      label={role.label}
                      checked={form.values.role === role.value}
                      readOnly
                    />
                    <Text size="xs" color="dimmed" pt={10}>
                      {role.description}
                    </Text>
                  </Paper>
                </div>
              ))}
            </Group>
          </Radio.Group>

          <Center>
            <Button variant="outline" color="gray" onClick={onClose}>
              Cancel
            </Button>
            <Button ml={'sm'} color="primary" onClick={handleSubmit}>
              Save
            </Button>
          </Center>
        </Box>
      </Modal>
    </>
  );
};
