import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { useUTMStore } from '@/stores/useUTMStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import {
  Anchor,
  Box,
  Button,
  Center,
  Grid,
  Modal,
  NavLink,
  Switch,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
  Flex,
  Alert
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { LinksService } from '@/services/LinksService';
import { notifications } from '@mantine/notifications';
import { ABTesting } from './Components/ABTesting';
import { CustomizeFavicon } from './Components/CustomizeFavicon';
import { CustomizeLinkPreview } from './Components/CustomizeLinkPreview';
import { DeepLinkCheck } from './Components/DeepLinkCheck';
import { LinkCampaignSelection } from './Components/LinkCampaignSelection';
import { LinkExpiry } from './Components/LinkExpiry';
import { TrafficRouting } from './Components/TrafficRouting';
import { PasswordProtection } from './Components/PasswordProtection';
import { ShortenedURL } from './Components/ShortenedURL';
import { LinkCreateTagsSelection } from './LinkCreateTagsSelection';
import { ensureHttps } from '@/utils/LinkUtility';
import { usePlanStore } from '@/stores/usePlanStore';
import { findCampaignType, isCamapaignShortener } from '@/utils/CampaignUtility';
import { IconAlertCircle } from '@tabler/icons-react';

interface ICampaign {
  _id: string;
  name: string;
  is_call_to_action_enabled: boolean;
}

// Active feature tabs

const LINK_ACTIVE_FEATURE_TABS = {
  CUSTOMIZE_LINK_PREVIEW: {
    formProp: 'is_custom_link_preview_enabled',
    key: 'customizeLinkPreview',
    label: 'Customize Link Preview',
    content: <CustomizeLinkPreview />,
  },
  CUSTOMIZE_FAVICON: {
    formProp: 'is_custom_favicon_enabled',
    key: 'customizeFavicon',
    label: 'Customize Favicon',
    content: <CustomizeFavicon />,
  },
  PASSWORD_PROTECTION: {
    formProp: 'is_password_protected',
    key: 'passwordProtection',
    label: 'Password Protection',
    content: <PasswordProtection />,
  },
  LINK_EXPIRY: {
    formProp: 'is_link_expiry_enabled',
    key: 'linkExpiry',
    label: 'Link Expiry',
    content: <LinkExpiry />,
  },
  TRAFFIC_ROUTING_RULES: {
    formProp: 'is_routing_enabled',
    key: 'trafficRoutingRules',
    label: 'Traffic Routing Rules',
    content: <TrafficRouting />,
  },
  AB_TESTING: {
    formProp: 'is_ab_testing_enabled',
    key: 'abTesting',
    label: 'A/B Testing',
    content: <ABTesting />,
  },
  DEEP_LINKING: {
    formProp: 'is_deep_link_enabled',
    key: 'deepLinking',
    label: 'Deep Linking',
    content: <DeepLinkCheck />,
  },
};
export const LinkCreationModal = () => {
  const [updateLoader, setUpdateLoader] = useState(false);
  const [seePreview, setSeePreview] = useState(true);

  // Active feature tab state

  const [activeFeatureTab, setActiveFeatureTab] = useState(
    LINK_ACTIVE_FEATURE_TABS.CUSTOMIZE_LINK_PREVIEW.key
  );

  // Workspace store

  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  // Campaigns store

  const [campaigns, fetchCampaigns] = useCampaignsStore((state) => [state.campaigns, state.fetchCampaigns]);
  const [plan] = usePlanStore((state) => [state.plan]);
  const [isCampaignShortenerr, setIsCampaignShortener] = useState(true);

  // Link store

  const [link, setLink, opened, setOpened, resetLink] = useLinkSetupStore((state) => [
    state.link,
    state.setLink,
    state.openedModal,
    state.setOpenedModal,
    state.resetLink,
  ]);

  // UTM Modal for adding UTM parameters

  const [setUTMModalOpen, setUTMURL] = useUTMStore((state) => [
    state.setSetupModal,
    state.setUTMURL,
  ]);

  // mantine theme
  const theme = useMantineTheme();

  const isTabDisabled = (tabKey: string) => {
    // for basic plan users disable traffic routing, ab testing and deep linking features
    // for pro plan users disable deep linking feature
    if (plan?.name?.includes('basic') && ['trafficRoutingRules', 'abTesting', 'deepLinking'].includes(tabKey)) {
      return true;
    } else if (plan?.name?.includes('pro') && tabKey === 'deepLinking') {
      return true;
    }
    return false;
  };

  // handle deep linking state

  const handleIsDeepLinkAllowed = async () => {
    //check campaign type
    if (!isCampaignShortenerr) {
      notifications.show({
        color: 'red',
        message: 'You can not use deep linking with this campaign. It is supported with retargeting and shortener campaigns.',
      });
      return false;
    }
    //check from backend
    const res = await new LinksService()
      .isDeepLinkAllowed(defaultWorkspace?._id || '', link._id, link.url)
      .then((res) => {
        if (res.data.status && res.data.is_deep_link_allowed) {
          setLink({ ...link, is_deep_link_allowed: true, is_deep_link_enabled: true });
          return true;
        } else {
          notifications.show({
            color: 'red',
            message: 'Deep linking is not allowed for this link.',
          });
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
    return res;
  };

  // AB testing validation

  const handleABValidation = () => {
    // console.log('A/B validation.');

    //if traffic routing is enabled, disable ab testing
    if (link?.is_routing_enabled) {
      notifications.show({
        color: 'red',
        message: 'You can not use A/B Testing if Traffic Routing Rules are activated.',
      });
      return false;
    }

    // validate AB testing attributes weight
    if (link.ab_testing_attributes.length > 0) {
      const totalWeight = link.ab_testing_attributes.reduce((acc, curr) => acc + curr.weight, 0);
      if (totalWeight !== 100) {
        notifications.show({
          color: 'red',
          message: 'The total weight of A/B testing attributes should be 100.',
        });
        return false;
      }
    }

    // Make sure there is a URL present for A/B testing
    if (link.ab_testing_attributes.length > 0) {
      const hasNoURL = link.ab_testing_attributes.some(
        (attr) => !attr.url || attr.url.length === 0
      );

      if (hasNoURL) {
        notifications.show({
          color: 'red',
          message: 'Please make sure you have added URL.',
        });
        return false;
      }
      const urlPattern = new RegExp(
        `^(https?:\\/\\/)?` + // protocol
        `(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+` + // domain name
        `([a-z]{2,})` + // top-level domain
        `(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*` + // port and path
        `(\\?[;&a-z\\d%_.~+=-]*)?` + // query string
        `(\\#[-a-z\\d_]*)?$`, // fragment locator
        'i'
      );

      const hasInvalidURL = link.ab_testing_attributes.some((attr) => !urlPattern.test(attr.url));

      // console.log(hasInvalidURL, 'hasInvalidURL');

      if (hasInvalidURL) {
        notifications.show({
          color: 'red',
          message: 'Please make sure you have added a valid URL.',
        });
        return false;
      }
    }

    setLink({ ...link, is_ab_testing_enabled: true });
    return true;
  };

  const handleRoutingValidation = () => {

    //if ab testing is enabled, disable traffic routing
    if (link?.is_ab_testing_enabled) {
      notifications.show({
        color: 'red',
        message: 'You can not use Traffic Routing Rules if A/B Testing is activated.',
      });
      return false;
    }

    setLink({ ...link, is_routing_enabled: true });
    return true;
  }

  // Utility function to generate NavLink components

  const navLinks = Object.entries(LINK_ACTIVE_FEATURE_TABS).map(
    ([key, { key: tabKey, label, formProp }]) => {
      const isDisabled = isTabDisabled(tabKey);
      const navLinkContent = (
        <div style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}>
          <NavLink
            style={{
              borderLeft: '4px solid transparent',
              borderColor: activeFeatureTab === tabKey ? theme.colors.blue[7] : 'transparent',
            }}
            key={tabKey}
            py={12}
            disabled={isDisabled}
            onClick={() => setActiveFeatureTab(tabKey)}
            active={activeFeatureTab === tabKey}
            label={label}
            rightSection={
              <Switch
                size="xs"
                styles={{
                  track: {
                    cursor: 'pointer',
                  },
                }}
                checked={link[formProp]}
                value={link[formProp]}
                onChange={(e) => {
                  if (tabKey === 'deepLinking' && link[formProp] === false) {
                    handleIsDeepLinkAllowed();
                  } else if (tabKey === 'abTesting' && link[formProp] === false) {
                    handleABValidation();
                  } else if (tabKey === 'trafficRoutingRules' && link[formProp] === false) {
                    handleRoutingValidation();
                  } else {
                    setLink({ ...link, [formProp]: e.currentTarget.checked });
                  }
                }}
              />
            }
          />
          {/* {(!isCampaignShortenerr && tabKey === 'deepLinking') && (
            <Alert
              my={5}
              mx={10}
              px={10}
              color="yellow"
              icon={<IconAlertCircle size="1rem" />}
            >
              You cannot use deep linking with this campaign. It is only supported with retargeting and shortener campaigns.
            </Alert>
          )} */}

        </div>
      );

      return isDisabled ? (
        <Tooltip key={tabKey} label="Please upgrade your plan to enable this feature.">
          {navLinkContent}
        </Tooltip>
      ) : (
        navLinkContent
      );
    }
  );

  /**
   * Function to render the content of the active feature tab
   * @returns {JSX.Element} - The content of the active feature tab
   */
  const renderFeatureTab = () => {
    const findTabKey = Object.keys(LINK_ACTIVE_FEATURE_TABS).find(
      (key) =>
        LINK_ACTIVE_FEATURE_TABS[key as keyof typeof LINK_ACTIVE_FEATURE_TABS].key ===
        activeFeatureTab
    ) as keyof typeof LINK_ACTIVE_FEATURE_TABS; // Narrowing the type of findTabKey

    // Now TypeScript knows findTabKey is of a specific type (the keys of LINK_ACTIVE_FEATURE_TABS), or undefined.
    if (findTabKey) {
      return LINK_ACTIVE_FEATURE_TABS[findTabKey].content;
    }

    // Optionally handle the case where findTabKey is undefined, e.g., by returning a default or showing an error.
    return <></>;
  };

  const validateCustomizeLinkPreview = () => {
    if (
      !link.custom_link_preview_attributes.title ||
      !link.custom_link_preview_attributes.description ||
      !link.custom_link_preview_attributes.image
    ) {
      notifications.show({
        color: 'red',
        message: 'Please make sure you have added all the customize link preview details.',
      });
      return false;
    }
    return true;
  };

  const validateCustomizeFavicon = () => {
    if (!link.custom_favicon_attributes.url) {
      notifications.show({
        color: 'red',
        message: 'Please make sure you have added the favicon URL.',
      });
      return false;
    }

    return true;
  };

  // Password protection validation

  const handlePasswordValidation = () => {
    if (!link.password_attributes.password) {
      notifications.show({
        color: 'red',
        message: 'Please make sure you have added a password.',
      });
      return false;
    }

    return true;
  };

  const handleOnSave = async () => {
    // Validate A/B testing attributes
    if (link.is_ab_testing_enabled && !handleABValidation()) {
      return;
    }

    // Validate meta tags

    if (link.is_custom_link_preview_enabled && !validateCustomizeLinkPreview()) {
      return;
    }

    // Validate favicon URL

    if (link.is_custom_favicon_enabled && !validateCustomizeFavicon()) {
      return;
    }

    // Validate password protection

    if (link.is_password_protected && !handlePasswordValidation()) {
      return;
    }

    // Deep link validation

    if (link.is_deep_link_enabled) {
      const isDeepLinkAllowed = await handleIsDeepLinkAllowed();
      if (!isDeepLinkAllowed) {
        setLink({ ...link, is_deep_link_allowed: false });
        return;
      }
    }

    setUpdateLoader(true);
    await new LinksService()
      .update(defaultWorkspace?._id || '', link._id, link)
      .then((res) => {
        if (res.data.status) {
          // setOpened(false);
          notifications.show({
            color: 'green',
            message: res.data.message,
          });
          // resetLink();
          setSeePreview(true)
          setActiveFeatureTab(LINK_ACTIVE_FEATURE_TABS.CUSTOMIZE_LINK_PREVIEW.key);
        }
      })
      .catch((err) => {
        if (err.response.status === 400 || err.response.status === 422) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
        }
      });
    await new LinksService()
      .getById(defaultWorkspace?._id || '', link._id)
      .then((res) => {
        if (res.data.status) {
          setLink(res.data.data)
        }
      })
      .catch((err) => {
        if (err.response.status === 400 || err.response.status === 422 || err.response.status === 500) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
        }
      })
    setUpdateLoader(false);
  };

  const verifyIsDeepLinkingAllowed = async () => {
    if (link._id && link.is_deep_link_enabled) {
      const isDeepLinkAllowed = await handleIsDeepLinkAllowed();
      if (!isDeepLinkAllowed) {
        setLink({ ...link, is_deep_link_allowed: false });
        return;
      }
    }
  };

  const handleCampaignUpdate = (loading: boolean) => {
    setUpdateLoader(loading);
  }

  //is campaign cta and is iframe enabled
  const [isCampaignCTA, setIsCampaignCTA] = useState(false);
  const [isIframeEnabled, setIsIframeEnabled] = useState(false);

  // Fetch campaigns on component mount
  useEffect(() => {
    setActiveFeatureTab(LINK_ACTIVE_FEATURE_TABS.CUSTOMIZE_LINK_PREVIEW.key);
    verifyIsDeepLinkingAllowed();
    // Cleanup function
    return () => {
      resetLink();
    };
  }, []);

  useEffect(() => {
    // console.log('this is link from link creation modal', link);
    const selectedCampaign: ICampaign | undefined = campaigns.find(campaign => campaign._id === link.campaign_id)
    if (selectedCampaign?.is_call_to_action_enabled) {
      setIsCampaignCTA(true)
      if (link?.is_iframe_allowed) {
        setIsIframeEnabled(true)

      } else {
        setIsIframeEnabled(false)
      }
    } else {
      setIsCampaignCTA(false)
    }
    setIsCampaignShortener(isCamapaignShortener(link.campaign_id, campaigns))
  }, [link])

  useEffect(() => {
    if (!isCampaignShortenerr) {
      setLink({ ...link, is_deep_link_allowed: false, is_deep_link_enabled: false });
    }
  }, [isCampaignShortenerr])

  const previewUrl = `${link.domain_attributes.url}${link.short_url}?countClick=false`;

  return (
    <>
      <Modal
        styles={{
          header: {
            padding: 8,
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: theme.colors.gray[1],
          },
          content: {
            padding: 8,
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: theme.colors.gray[1],
          },
        }}
        title={
          <Text fw={700} pl={8}>
            Your Link
          </Text>
        }
        opened={opened}
        onClose={() => {
          setActiveFeatureTab(LINK_ACTIVE_FEATURE_TABS.CUSTOMIZE_LINK_PREVIEW.key);
          setOpened(false);
        }}
        size={840}
      >
        <Box py={8}>
          {/* <Text mb={4} fz={13} fw={500}>
            Original link
          </Text> */}
          <ShortenedURL />
          <TextInput
            mb={8}
            label={'Original link'}
            //   radius="xl"
            value={link.url}
            onChange={(event) => {
              let url = event.currentTarget.value;
              if (url.length > 0) {
                url = ensureHttps(url);
              }

              setLink({
                ...link,
                url,
              });
            }}
            placeholder="Enter URL to shorten"
            rightSectionWidth={link.url.length > 0 ? 80 : 36}
            rightSection={
              <>
                {link.url.length > 0 && (
                  <Anchor
                    onClick={() => {
                      setUTMURL(link.url);
                      setUTMModalOpen(true);
                    }}
                    size="xs"
                    fw="bold"
                    mr={12}
                  >
                    {' '}
                    + Add UTM
                  </Anchor>
                )}
              </>
            }
          />

          <Grid mt={12} mb={12}>
            <LinkCampaignSelection onCampaignUpdate={handleCampaignUpdate} />
            <LinkCreateTagsSelection />
          </Grid>
          {isCampaignCTA ? (
            isIframeEnabled ? (
              <Flex justify="flex-end">
                <Text size="sm">
                  CTA applied to your shortened link, <Anchor href={previewUrl} target="_blank">see preview.</Anchor>
                </Text>
              </Flex>
            ) : (
              <Flex justify="flex-end">
                <Text size="sm">
                  Summary page applied to your shortened link, <Anchor href={previewUrl} target="_blank">see preview.</Anchor>
                </Text>
              </Flex>
            )
          ) : null}
          <Text fw={700} fz={13} mb={12}>
            Features
          </Text>
          <Box
            bg={'white'}
            w={'100%'}
            style={{
              borderRadius: '0.5rem',
            }}
          >
            <Grid gutter={0}>
              <Grid.Col
                span={4}
                style={{
                  //   backgroundColor: 'var(--mantine-color-gray-1)',
                  borderRight: '1px solid var(--mantine-color-gray-3)',
                }}
              >
                {navLinks}
              </Grid.Col>
              <Grid.Col span={8}>{renderFeatureTab()}</Grid.Col>
            </Grid>
          </Box>
          <Center mt={16}>
            <Button loading={updateLoader} disabled={updateLoader} onClick={handleOnSave}>
              Save Shortened Link
            </Button>
          </Center>
        </Box>
      </Modal>
    </>
  );
};
