import axios from '@/services/JWTService';
import {
  Badge,
  Box,
  Center,
  Divider,
  Flex,
  Grid,
  Image,
  Modal,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useDebouncedValue, useDocumentTitle } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import classes from './DeepLink.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';

export function DeepLinkingPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  useDocumentTitle('Deep Linking | ' + defaultWorkspace?.agency?.name);

  const [opened, setOpened] = useState(false);
  const [selectedDeepLink, setSelectedDeepLink] = useState<any>(null);
  const [selectedDeepLinkBrand, setSelectedDeepLinkBrand] = useState<any>(null);
  const [search, setSearch] = useState('');
  const [debounced] = useDebouncedValue(search, 200);
  const [list, setList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('all');
  const fetchCategories = () => {
    axios
      .get(`workspace/${defaultWorkspace?._id}/deep_link/get/categories`)
      .then((res) => {
        if (res.data.status) {
          setCategories(res.data.data);
        }
      })
      .catch((err) => {});
  };

  const fetchDeepLinks = (category_id: string = 'all') => {
    axios
      .get(`workspace/${defaultWorkspace?._id}/deep_link/get/category/${category_id}`)
      .then((res) => {
        if (res.data.status) {
          setList(res.data.data);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    // Fetch data
    fetchCategories();
    fetchDeepLinks();
  }, []);

  return (
    <>
      <Modal
        size={680}
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
        title={
          selectedDeepLink ? (
            <>
              <Flex align={'center'}>
                <Image mr={16} src={selectedDeepLink?.image} fit={'contain'} h={24} w={24} />
                <Title order={5}>{selectedDeepLink?.name} | Deep Link Integration</Title>
              </Flex>
            </>
          ) : null
        }
      >
        {selectedDeepLink && (
          <Flex justify={'center'} align={'center'} direction={'column'}>
            <Text ta="center" fz={14} tt={'capitalize'} my={16}>
              {selectedDeepLink.description}
            </Text>
            <Text ta="center" fw={600} mb={16}>
              Deep Links supported for {selectedDeepLink?.name}.
            </Text>

            <Flex mb={12} wrap={'wrap'} justify={'center'}>
              {selectedDeepLink?.brands?.map((item: any) => (
                <>
                  <Badge
                    mb={8}
                    style={{
                      cursor: 'pointer',
                    }}
                    color={selectedDeepLinkBrand?.name === item.name ? 'primary' : 'gray.6'}
                    onClick={() => setSelectedDeepLinkBrand(item)}
                    mr={4}
                    size="md"
                  >
                    {item.name}
                  </Badge>
                </>
              ))}
            </Flex>
            <TextInput mb={16} w={380} label="URL Format" value={selectedDeepLinkBrand?.url} />
            <Text fw={600} mb={16}>
              OS Supported
            </Text>
            <Flex align={'center'} mb={16}>
              {selectedDeepLink.supported.ios && (
                <>
                  <FontAwesomeIcon
                    style={{
                      color: 'var(--mantine-color-gray-7)',
                      marginRight: 8,
                    }}
                    icon={faApple}
                  />
                  <Text mr={8} fz={14}>
                    Apple
                  </Text>
                </>
              )}
              {selectedDeepLink.supported.android && (
                <>
                  <FontAwesomeIcon
                    style={{
                      marginLeft: 8,
                      marginRight: 8,
                      color: 'var(--mantine-color-gray-7)',
                    }}
                    icon={faAndroid}
                  />
                  <Text fz={14}>Android</Text>
                </>
              )}
            </Flex>
          </Flex>
        )}

        {/* {JSON.stringify(selectedDeepLink)} */}
      </Modal>
      <Box
        style={{
          backgroundColor: 'var(--mantine-color-white)',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          height: '100%',
          flexGrow: 1,
        }}
        ml={16}
        mr={16}
      >
        <Grid p={16}>
          <Grid.Col span={5}>
            <></>
          </Grid.Col>
          <Grid.Col span={2}>
            <Title pt={2} order={5}>
              Deep Links
            </Title>
          </Grid.Col>
          <Grid.Col span={5}>
            <Flex justify="flex-end" align="center">
              <TextInput
                size="xs"
                placeholder="Search"
                value={search}
                onChange={(e) => {
                  setSearch(e.currentTarget.value);
                }}
              />
            </Flex>
          </Grid.Col>
        </Grid>
        <Divider color="gray.2" />
        <Box p={16}>
          <Flex align={'center'}>
            <Title order={5} mr={8}>
              Categories:
            </Title>
            <Box
              style={{
                cursor: 'pointer',
                borderRadius: '0.25rem',
              }}
              py={4}
              px={8}
              bg={category === 'all' ? 'primary' : 'gray.1'}
              c={category === 'all' ? 'white' : undefined}
              mr={12}
              onClick={() => {
                setCategory('all');
                fetchDeepLinks();
              }}
            >
              All
            </Box>
            {categories.map((item: any) => (
              <>
                <Box
                  py={4}
                  px={8}
                  bg={category === item._id ? 'primary' : 'gray.1'}
                  c={category === item._id ? 'white' : undefined}
                  mr={12}
                  onClick={() => {
                    setCategory(item._id);
                    fetchDeepLinks(item._id);
                  }}
                >
                  <Text fz={14}>{item.name}</Text>
                </Box>
              </>
            ))}
          </Flex>
        </Box>
        <Divider color="gray.2" />
        <Box p={16}>
          <Grid gutter={'md'}>
            {list
              .filter((i: any) => i.name.toLowerCase().includes(debounced))
              .map((item: any) => (
                <>
                  <Grid.Col span={3}>
                    <Box
                      className={classes.item}
                      h={100}
                      p={16}
                      onClick={() => {
                        setSelectedDeepLink(item);
                        if (item.brands.length >= 1) {
                          setSelectedDeepLinkBrand(item.brands[0]);
                        }

                        setOpened(true);
                      }}
                    >
                      <Flex align={'center'} justify={'center'}>
                        <Image mr={24} src={item.image} fit={'contain'} h={32} w={32} />
                        <Flex direction={'column'}>
                          <Text fz={14} fw={600} mb={8}>
                            {item.name}
                          </Text>
                          <Text fz={13}>{item.description}</Text>
                        </Flex>
                      </Flex>
                    </Box>
                  </Grid.Col>
                </>
              ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
