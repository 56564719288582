import { Center, Loader, Table } from '@mantine/core';

interface ILoaderDataTable {
  colSpan: number;
}

export const LoaderDataTable = ({ colSpan }: ILoaderDataTable) => {
  return (
    <Table.Tr>
      <Table.Td ta="center" colSpan={colSpan}>
        <Center style={{ width: '100%', height: '100px' }}>
          <Loader speed={1000} size="xs" />
        </Center>
      </Table.Td>
    </Table.Tr>
  );

  return <Table>{/* Render table headers, rows, and columns here */}</Table>;
};
