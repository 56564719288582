import axios from './JWTService';

export class PreviewService {
  link = (url: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/preview/link`, {
          url,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  rss = (url: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/preview/rss`, {
          url,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
