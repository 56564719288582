import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Button, Group, Menu, useMantineTheme } from '@mantine/core';
import classes from './SplitButton.module.css';
import { useState , useEffect} from 'react';
import { CreateLinkURLModal } from '../Modals/CreateLinkURLModal/CreateLinkURLModal';
import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { BulkImportLinksModal } from '../Modals/BulkImportLinksModal/BulkImportLinksModal';
import { CSVImportLinksModal } from '../Modals/CSVImportLinksModal/CSVImportLinksModal';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { useUserStore } from '@/stores/useUserStore';
import { any } from 'zod';

interface SplitButtonProps {
  createModalOpen: boolean;
  setCreateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function SplitButton({ createModalOpen, setCreateModalOpen }: SplitButtonProps) {
  const [createLinkModal, setCreateLinkModal] = useState(createModalOpen);
  const [bulkLinksModalOpened, setBulkLinksModalOpened] = useState(false);
  const [csvLinksModalOpened, setCSVLinksModalOpened] = useState(false);
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [user] = useUserStore(state => [state.user]);
  const [resetLink] = useLinkSetupStore((state) => [state.resetLink]);
  const onCreate = () => {
    resetLink();
    setCreateLinkModal(true);
  };
  const theme = useMantineTheme();
  useEffect(() => {
    if (createModalOpen) {
      setCreateLinkModal(true);
    }
  }, [createModalOpen]);
  
  return (
    <>
      <CreateLinkURLModal opened={createLinkModal} setOpened={setCreateLinkModal} />
      <BulkImportLinksModal
        opened={bulkLinksModalOpened}
        onClose={() => setBulkLinksModalOpened(!bulkLinksModalOpened)}
      />
      <CSVImportLinksModal
        opened={csvLinksModalOpened}
        onClose={() => setCSVLinksModalOpened(!csvLinksModalOpened)}
      />
      <Group wrap="nowrap" gap={0}>
        <Button size="xs" className={classes.button} onClick={onCreate}>
          Create new
        </Button>
        <Menu transitionProps={{ transition: 'pop' }} position="bottom-end" withinPortal>
          <Menu.Target>
            <ActionIcon
              variant="filled"
              color={theme.primaryColor}
              size={30}
              className={classes.menuControl}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: '0.75rem',
                }}
                icon={faChevronDown}
              />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item onClick={() => {
              setCSVLinksModalOpened(!csvLinksModalOpened);
            }}>
              Import CSV
            </Menu.Item>
            <Menu.Item onClick={() => {
              setBulkLinksModalOpened(!bulkLinksModalOpened);
            }}
            >
              Bulk Create
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </>
  );
}
