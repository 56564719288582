import { ILink } from '@/types/links';
import { IBioLink } from '@replug/utils';

export const getShortenedURL = (link: ILink | IBioLink) => {
  // Ensure that the domain URL is present and then remove any trailing slash.
  const domainURL = link?.domain_attributes?.url?.replace(/\/+$/, '');
  return `${domainURL}/${link.short_url}`;
};

// Utility function to ensure URLs use https://
export const ensureHttps = (url: string) => {
  // Check if the URL starts with http:// and replace it with https://
  if (url.startsWith('http://')) {
    return url.replace('http://', 'https://');
  }

  // If the URL starts with https://, return the original URL
  if (url.startsWith('https://')) {
    return url;
  }

  // If the URL does not include http:// or https://, prepend https://
  if (url !== '') {
    return `https://${url}`;
  }

  // Return an empty string if the URL is empty
  return '';
};

export const appendQrParameter = (string: string) => {
  if (string.includes('?')) {
    return `${string}&qr=true`;
  }
  return `${string}?qr=true`;
};
