import { getRelativeTime } from '@/utils/DateUtility';
import { Modal, Title, Text, Box, Table, Badge } from '@mantine/core';

export const ReportActivityModal = ({
  opened,
  onClose,
  data,
}: {
  opened: boolean;
  onClose: () => void;
  data: any;
}) => {
  return (
    <Modal
      size={740}
      opened={opened}
      onClose={onClose}
      title={<Title order={5}>Report Activity</Title>}
    >
      <Box p={16}>
        <Table striped>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Type</Table.Th>
              <Table.Th>Email</Table.Th>
              <Table.Th>Sent at</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {data.map((item: any) => (
              <Table.Tr>
                <Table.Td>
                  <Badge variant="outline">{item.type}</Badge>
                </Table.Td>
                <Table.Td>{item.email}</Table.Td>
                <Table.Td>
                  <Text fz={14}>
                    Report was generated and sent{' '}
                    <strong>{getRelativeTime(item.created_at)}</strong>.
                  </Text>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Box>
    </Modal>
  );
};
