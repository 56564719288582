import { CustomDomainService } from '@/services/CustomDomainService';
import { IDomain } from '@/types/domain';
import { create } from 'zustand';

interface ICustomDomainsStore {
  customDomains: IDomain[];
  setCustomDomains: (customDomains: IDomain[]) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  fetchCustomDomains: (workspaceId: string) => void;
  getConnectedCustomDomains: () => IDomain[];
}

export const useCustomDomainsStore = create<ICustomDomainsStore>((set, get) => ({
  customDomains: [],
  setCustomDomains: (customDomains: IDomain[]) => {
    set({ customDomains });
  },
  loading: false,
  setLoading: (loading: boolean) => {
    set({ loading });
  },
  fetchCustomDomains: async (workspaceId: string) => {
    set({ loading: true });
    // Fetch custom domains
    new CustomDomainService()
      .get(workspaceId)
      .then((res) => {
        if (res.data.status) {
          set({ customDomains: res.data.data });
        }
      })
      .catch((err) => {
        console.error(err);
      });

    set({ loading: false });
  },
  getConnectedCustomDomains: () => {
    const domains = get().customDomains;
    return domains.filter((domain) => domain.is_connected);
  },
}));
