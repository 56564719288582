import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Anchor, Box, Flex, Image, Stack, TextInput, Title, Tooltip } from '@mantine/core';
import { useEffect, useState } from 'react';
import ShortLinkImage from '../../assets/images/common/short-link.svg';
import classes from './dashboard.module.css';
import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { UTMPresetApplyOrCreate } from '@/components/Modals/UTMPresetModal/UTMPresetApplyOrCreate';
import { useUTMStore } from '@/stores/useUTMStore';
import { LinkCreationModal } from '@/components/Modals/LinkCreationModal/LinkCreationModal';
import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { ensureHttps } from '@/utils/LinkUtility';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isUserCollaborator } from '@/utils/CommonUtility';
import { useUserStore } from '@/stores/useUserStore';
import { useMediaQuery } from '@mantine/hooks';

const LINK_ERROR_STATES = {
  NO_LINK: 'NO_LINK',
  INVALID_LINK: 'INVALID_LINK',
};

export function DashboardLinkCreation() {
  const [linkError, setLinkError] = useState('false');
  const [isCreatingLink, setIsCreatingLink] = useState(false);
  const [url, setURL] = useState('');
  const isMobileOrTablet = useMediaQuery('(max-width: 1441px)');
  const [link, setLink, linkModalOpen, setLinkModalOpen, createLink, createLoader] =
    useLinkSetupStore((state) => [
      state.link,
      state.setLink,
      state.openedModal,
      state.setOpenedModal,
      state.createLink,
      state.createLoading,
    ]);
  const [isUTMModalOpen, setUTMModalOpen, UTMURL, setUTMURL] = useUTMStore((state) => [
    state.setupModal,
    state.setSetupModal,
    state.utmURL,
    state.setUTMURL,
  ]);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [user] = useUserStore((state) => [state.user]);
  const [campaigns] = useCampaignsStore((state) => [state.campaigns]);
  // Workspace store
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const handleCreateLink = async () => {
    if (!url) {
      setLinkError(LINK_ERROR_STATES.NO_LINK);
      return;
    }

    const defaultCampaign = campaigns.find((campaign: any) => campaign?.is_default);
    const created = await createLink(
      defaultWorkspace?._id || '',
      defaultCampaign?._id || '',
      !url.includes('http') && !url.includes('https') ? `https://${url}` : url
    );
    setIsCreatingLink(false);
    setLinkModalOpen(true);
  };

  // Custom onChange handler for the URL field
  const handleURLChange = (event: any) => {
    if (event.target.value) {
      setURL(ensureHttps(event.currentTarget.value)); // Update the form value
    } else {
      setURL(event.currentTarget.value);
    }
  };

  useEffect(() => {
    if (!linkModalOpen) {
      setLinkError('');
      setURL('');
    }
  }, [linkModalOpen]);

  return (
    <>
      <UTMPresetApplyOrCreate
        onChange={(u: string) => {
          console.log('dashboard change', u);
          setURL(u);
          setLink({
            ...link,
            url: u,
          });
        }}
      />
      <LinkCreationModal />
      <Box h={200} className={classes.dottedBorder}>
        <Flex
          align={isMobileOrTablet ? "flex-start" : "center"}
          direction={isMobileOrTablet ? 'column' : 'row'}
          h="100%"
          justify="center"
        >
          <Stack
            px={32}
            style={{
              flexGrow: 1,
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Title order={4}>Quickly Shorten your link</Title>
            {(defaultPermissions && checkPermission(defaultPermissions, ['add_link'], false)) ? <Tooltip label="You don't have permission to shorten links, please contact admin.">
              <TextInput
                autoComplete="off"
                disabled={true}
                error={
                  linkError === LINK_ERROR_STATES.NO_LINK ||
                  linkError === LINK_ERROR_STATES.INVALID_LINK
                }
                //   radius="xl"
                value={url}
                onChange={(event) => {
                  setLinkError('');
                  handleURLChange(event);
                  // setURL(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && !isCreatingLink) {
                    setIsCreatingLink(true);
                    setLinkError('');
                    handleCreateLink();
                  }
                }}
                placeholder="Enter URL to shorten"
                rightSectionWidth={url.length > 0 ? 112 : 36}
                rightSection={
                  <>
                    {url.length > 0 && (
                      <Anchor
                        onClick={() => {
                          setUTMURL(url);
                          setUTMModalOpen(true);
                        }}
                        size="xs"
                        fw="bold"
                        mr={12}
                      >
                        {' '}
                        + Add UTM
                      </Anchor>
                    )}

                    <ActionIcon
                      disabled={true}
                      loading={createLoader}
                      onClick={handleCreateLink}
                      size={32}
                      variant="filled"
                    >
                      <FontAwesomeIcon size="sm" icon={faArrowRight} />
                      {/* <IconArrowRight style={{ width: rem(18), height: rem(18) }} stroke={1.5} /> */}
                    </ActionIcon>
                  </>
                }
              />
            </Tooltip> : <TextInput
              autoComplete="off"
              disabled={false}
              error={
                linkError === LINK_ERROR_STATES.NO_LINK ||
                linkError === LINK_ERROR_STATES.INVALID_LINK
              }
              //   radius="xl"
              value={url}
              onChange={(event) => {
                setLinkError('');
                handleURLChange(event);
                // setURL(event.currentTarget.value);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && !isCreatingLink) {
                  setIsCreatingLink(true);
                  setLinkError('');
                  handleCreateLink();
                }
              }}
              placeholder="Enter URL to shorten"
              rightSectionWidth={url.length > 0 ? 112 : 36}
              rightSection={
                <>
                  {url.length > 0 && (
                    <Anchor
                      onClick={() => {
                        setUTMURL(url);
                        setUTMModalOpen(true);
                      }}
                      size="xs"
                      fw="bold"
                      mr={12}
                    >
                      {' '}
                      + Add UTM
                    </Anchor>
                  )}

                  <ActionIcon
                    disabled={false}
                    loading={createLoader}
                    onClick={handleCreateLink}
                    size={32}
                    variant="filled"
                  >
                    <FontAwesomeIcon size="sm" icon={faArrowRight} />
                    {/* <IconArrowRight style={{ width: rem(18), height: rem(18) }} stroke={1.5} /> */}
                  </ActionIcon>
                </>
              }
            />}
          </Stack>
          {!isMobileOrTablet && (
            <Flex>
              <Image mr={32} my={16} w={337} h={151} src={ShortLinkImage} />
            </Flex>
          )}
        </Flex>
      </Box>
    </>
  );
}
