export const numberToUnit = (value: number | undefined = 0) => {
  // console.debug(value)
  // Nine Zeroes for Billions
  return Math.abs(Number(value)) >= 1.0e9
    ? `${Number((Math.abs(Number(value)) / 1.0e9).toFixed(1))}B`
    : // Six Zeroes for Millions
      Math.abs(Number(value)) >= 1.0e6
      ? `${Number((Math.abs(Number(value)) / 1.0e6).toFixed(1))}M`
      : // Three Zeroes for Thousands
        Math.abs(Number(value)) >= 1.0e3
        ? `${Number((Math.abs(Number(value)) / 1.0e3).toFixed(1))}K`
        : Number(value) === value && value % 1 !== 0
          ? Math.abs(Number(value)).toFixed(2)
          : Math.abs(Number(value));
};

export const numberToCommas = (value: number | undefined = 0) => {
  if (!value) return 0;
  if (value === 0) return 0;
  try {
    //@ts-ignore
    value = parseInt(value);
  } catch (e) {
    // console.log(e);
  }
  if (typeof value === 'string' || typeof value === 'object') return value;
  return value.toLocaleString();
};
