import { UtilsService } from '@/services/UtilsService';
import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { faImage, faTimes, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Group, Text, rem, Image, Center, Button } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import classes from '../LinkCreationModal.module.css';

export const CustomizeFavicon = () => {
  const [link, setLink] = useLinkSetupStore((state) => [state.link, state.setLink]);
  const [loadingImage, setLoadingImage] = useState(false);

  const handleFileDrop = async (files: any) => {
    if (files && files.length > 0) {
      const formData = new FormData();
      formData.append('input_file', files[0]);
      setLoadingImage(true);
      await new UtilsService()
        .uploadImage(formData)
        .then((res) => {
          console.log(res.data);
          if (res.data.status) {
            setLink({
              ...link,
              custom_favicon_attributes: {
                ...link.custom_favicon_attributes,
                url: res.data.url,
              },
            });
          }
        })
        .catch((err) => {
          if ((err.response.status === 400 ||  err.response.status ===  422) && err?.response?.data?.message) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
          console.log(err);
        });

      setLoadingImage(false);
      // You can now use formData to send the file data to your server or perform any other actions.
    }
  };

  return (
    <Box p={16} className={!link.is_custom_favicon_enabled ? classes.disabled : ''}>
      <Text fw={600} fz={14}>
        Customize Favicon
      </Text>
      {link.custom_favicon_attributes.url && (
        <>
          <Center mt={12}>
            <Image
              src={link.custom_favicon_attributes.url || ''}
              alt="Favicon"
              h={16}
              w={16}
              mt={12}
              mb={12}
            />
          </Center>
        </>
      )}

      <Dropzone
        mt={12}
        onDrop={(files) => handleFileDrop(files)}
        onReject={(files) => console.log('rejected files', files)}
        maxSize={5 * 1024 ** 2}
        accept={IMAGE_MIME_TYPE}
      >
        <Group justify="center" gap="xl" mih={50} style={{ pointerEvents: 'none' }}>
          <Dropzone.Accept>
            <FontAwesomeIcon
              color="gray.7"
              size="sm"
              icon={faUpload}
              style={{
                width: rem(32),
                height: rem(32),
                color: 'var(--mantine-color-blue-6)',
              }}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <FontAwesomeIcon
              color="gray.7"
              size="sm"
              icon={faTimes}
              style={{
                width: rem(32),
                height: rem(32),
                color: 'var(--mantine-color-red-6)',
              }}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <FontAwesomeIcon
              color="gray.7"
              size="sm"
              icon={faImage}
              style={{
                width: rem(32),
                height: rem(32),
                color: 'var(--mantine-color-gray-4)',
              }}
            />
          </Dropzone.Idle>

          <div>
            <Text size="sm" inline>
              {link.custom_favicon_attributes.url ? 'Change' : 'Upload'} favicon image by clicking
              or dropping an image here.
            </Text>
          </div>
        </Group>
      </Dropzone>

      {link.custom_favicon_attributes.url && (
        <>
          <Center mt={'md'}>
            <Button
              variant="outline"
              color="red"
              size="xs"
              onClick={() => {
                setLink({
                  ...link,
                  custom_favicon_attributes: {
                    ...link.custom_favicon_attributes,
                    url: '',
                  },
                });
              }}
            >
              Remove Favicon
            </Button>
          </Center>
        </>
      )}
    </Box>
  );
};
