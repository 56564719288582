import { UTMService } from '@/services/UTMService';
import { useUTMStore } from '@/stores/useUTMStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { IUTM } from '@/types/utms';
import { Box, Button, Flex, Modal, TextInput, Title } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { z } from 'zod';

const schema = z.object({
  _id: z.string().nullable(),
  name: z.string().min(1, { message: 'Name should have at least 1 letter' }),
  utm_campaign: z.string().optional(),
  utm_medium: z.string().optional(),
  utm_source: z.string().optional(),
  utm_term: z.string().nullable(),
  utm_content: z.string().nullable(),
  workspace_id: z.string().nullable(),
});

export const UTMPresetModal = ({ id = null }: { id?: string | null }) => {
  const [opened, setOpened] = useUTMStore((state) => [state.setupModal, state.setSetupModal]);
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [UTMs, setUTMs] = useUTMStore((state) => [state.UTMs, state.setUTMs]);
  const [storeLoading, setStoreLoading] = useState(false);
  const form = useForm({
    initialValues: {
      _id: id,
      name: '',
      utm_campaign: '',
      utm_medium: '',
      utm_source: '',
      utm_term: '',
      utm_content: '',
      workspace_id: defaultWorkspace?._id,
    },
    validate: zodResolver(schema),
  });

  const handleFormSubmit = async (formValues: any) => {
    console.log(formValues);
    const keys = ['utm_campaign', 'utm_medium', 'utm_source', 'utm_term', 'utm_content'];
    const filtered = keys.filter((key) => formValues[key] !== '');
    if (filtered.length === 0) {
      notifications.show({
        color: 'red',
        message:
          'Please fill at least one of the UTMs: Campaign, Medium, or Source with at least 1 letter',
      });
      return;
    }
    setStoreLoading(true);
    if (form.values._id) {
      await new UTMService()
        .update(defaultWorkspace?._id || '', formValues)
        .then((res) => {
          if (res.data.status) {
            setOpened(false);
            const newList = UTMs.map((item) => {
              if (item._id === id) {
                return res.data.data;
              }
              return item;
            });
            setUTMs(newList);
            notifications.show({
              color: 'green',
              message: 'UTM preset has been updated.',
            });
            form.reset();
          } else {
            notifications.show({
              color: 'red',
              message: res.data.message,
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 400 ||  err.response.status ===  422) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
        });
    } else {
      await new UTMService()
        .create(defaultWorkspace?._id || '', formValues)
        .then((res) => {
          if (res.data.data) {
            setOpened(false);
            setUTMs([...UTMs, res.data.data]);
            notifications.show({
              color: 'green',
              message: 'UTM preset has been created.',
            });
            form.reset();
          } else {
            notifications.show({
              color: 'red',
              message: res.data.message,
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 400 ||  err.response.status ===  422) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
          console.log(err);
        });
    }
    setStoreLoading(false);
  };

  useEffect(() => {
    if (id) {
      const item = UTMs.find((utm: IUTM) => utm._id === id);
      if (item) {
        form.setValues({
          ...item,
          //@ts-ignore
          workspace_id: defaultWorkspace?._id || '',
        });
      }
    } else {
      form.reset();
      form.setFieldValue('workspace_id', defaultWorkspace?._id);
    }
  }, [id]);
  return (
    <>
      <Modal
        zIndex={300}
        size={'700'}
        opened={opened}
        onClose={() => setOpened(!opened)}
        title={<Title order={5}>{form.values._id ? 'Update' : 'Create'} UTM Preset</Title>}
      >
        <Box p={16}>
          {/* <Flex align={'center'} justify={'center'}>
            <FontAwesomeIcon
              style={{
                color: theme.colors.primary[9],
                marginBottom: 16,
              }}
              icon={faLocationPin}
              size={'2x'}
            />
          </Flex> */}
          <Box mx={32}>
            <form onSubmit={form.onSubmit(handleFormSubmit)}>
              <TextInput
                mb={12}
                label={'Name'}
                variant="filled"
                placeholder="Enter the name of the UTM preset."
                {...form.getInputProps('name')}
              />

              <TextInput
                mb={12}
                label={'UTM Campaign'}
                variant="filled"
                placeholder="Product, promo code or slogan (e.g. spring_sale)"
                {...form.getInputProps('utm_campaign')}
              />
              <TextInput
                mb={12}
                label={'UTM Medium'}
                variant="filled"
                placeholder="Marketing medium i.e email, social, cpc, banner"
                {...form.getInputProps('utm_medium')}
              />
              <TextInput
                mb={12}
                label={'UTM Source'}
                variant="filled"
                placeholder="Website or application (e.g. facebook, twitter, newsletter)"
                {...form.getInputProps('utm_source')}
              />

              <TextInput
                mb={12}
                label={'UTM Term'}
                variant="filled"
                placeholder="e.g Identify paid keywords"
                {...form.getInputProps('utm_term')}
              />

              <TextInput
                mb={12}
                label={'UTM Content'}
                variant="filled"
                placeholder="e.g Use to differentiate ads or links that point to the same URL, text ad copy, or image banner"
                {...form.getInputProps('utm_content')}
              />
              <Flex mt={32} align={'center'} justify={'center'}>
                <Button disabled={storeLoading} loading={storeLoading} type="submit" mr={12}>
                  {form.values._id ? 'Update' : 'Create'}
                </Button>
                <Button onClick={() => setOpened(false)} variant="outline" color="gray">
                  Cancel
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
