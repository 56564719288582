import { Box, Divider, Flex, Grid, Title, Text } from '@mantine/core';
import { Link, NavLink, Outlet } from 'react-router-dom';
import classes from './Account.module.css';
import { faCreditCard, faUser, faMoneyBill1Wave } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isUserCollaborator } from '@/utils/CommonUtility';
import { useUserStore } from '@/stores/useUserStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';

export function AccountSettingsPage() {
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [user] = useUserStore((state) => [state.user]);
  const routes = defaultPermissions && checkPermission(defaultPermissions, ['view_plan']) 
  ? [
      {
        path: '/account/profile',
        label: 'Profile',
        icon: faUser,
      },
      {
        path: '/account/billing',
        label: 'Plan and Billing',
        icon: faCreditCard,
      },
      // Only add "Refer and Earn" if whitelabeling is not enabled
      ...(!defaultWorkspace?.agency?.is_whitelabeled ? [
        {
          path: '/account/refer&earn',
          label: 'Refer and Earn',
          icon: faMoneyBill1Wave,
        }
      ] : [])
    ]
  : [
      {
        path: '/account/profile',
        label: 'Profile',
        icon: faUser,
      }
    ];
  return (
    <>
      <Flex
        style={{
          height: '94%',
        }}
      >
        <Flex w={'20%'}>
          <Box
            ml={16}
            style={{
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
              backgroundColor: 'var(--mantine-color-white)',
              borderRadius: '0.25rem',
              height: '100%',
              width: '100%',
            }}
          >
            <Title p={16} order={6}>
              Account Settings
            </Title>
            <Divider color="gray.2" mb={8} />
            <Flex
              direction={'column'}
              style={{
                flexGrow: 1,
              }}
              pb={8}
            >
              {routes.map((route, index) => (
                <>
                  <NavLink
                    key={index}
                    className={({ isActive }) =>
                      isActive ? `${classes.link} ${classes.linkActive}` : classes.link
                    }
                    to={route.path}
                  >
                    <Flex align={'center'}>
                      <Box w={16} mr={12}>
                        <FontAwesomeIcon size="sm" icon={route.icon} />
                      </Box>
                      <Text fz="sm">{route.label}</Text>
                    </Flex>
                  </NavLink>
                </>
              ))}
            </Flex>
          </Box>
        </Flex>
        <Flex w={'80%'}>
          <Outlet />
        </Flex>
      </Flex>
    </>
  );
}
