import axios from './JWTService';

export class PlanService {

  changeTrialPlan = (workspace_id: string, organization_id: string, plan_name: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`workspace/${workspace_id}/plan/change_trial_plan`, { organization_id, plan_name })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  get = (workspace_id: string, organization_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`workspace/${workspace_id}/plan/get?organization_id=${organization_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  getSubscriptions = (workspace_id: string, organization_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`workspace/${workspace_id}/plan/subscriptions?organization_id=${organization_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  cancelSubscription = (workspace_id: string, organization_id: string, subscription_id: string, reasons: string[]): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`workspace/${workspace_id}/plan/cancel_subscription`, { organization_id, subscription_id, reasons })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  updateSubscription = (workspace_id: string, organization_id: string, subscription_id: string, action: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`workspace/${workspace_id}/plan/update_subscription`, { organization_id, subscription_id, action })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  upgradePlan = (workspace_id: string, organization_id: string, plan_id: number, subscription_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`workspace/${workspace_id}/plan/upgrade_subscription`, { organization_id, plan_id, subscription_id })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  getInvoices = (workspace_id: string, organization_id: string, page: number, limit: number): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`workspace/${workspace_id}/plan/invoices?organization_id=${organization_id}&page=${page}&limit=${limit}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * Check limits for the selected plan before upgrading or downgrading the plan
   * @param workspace_id
   * @param organization_id
   * @param plan_name selected plan name
   */
  checkPlanLimits = (workspace_id: string, organization_id: string, plan_name: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`workspace/${workspace_id}/plan/check_limits?organization_id=${organization_id}&plan_name=${plan_name}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

}