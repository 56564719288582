import {
  DEFAULT_LINK_BLOCK_VALUES,
  DEFAULT_TEXT_BLOCK_VALUES,
  IBioLinkBlock,
  IBioTextBlock,
} from '@replug/utils';
import { create } from 'zustand';

interface IBioTextBlockStore {
  bioTextBlock: IBioTextBlock;
  setBioTextBlock: (bioTextBlock: IBioTextBlock) => void;
  loader: boolean;
  setLoader: (loader: boolean) => void;
  reset: () => void;
}

export const useBioTextBlockStore = create<IBioTextBlockStore>((set, get) => ({
  bioTextBlock: DEFAULT_TEXT_BLOCK_VALUES,
  setBioTextBlock: (bioTextBlock: IBioTextBlock) => {
    set({ bioTextBlock });
  },
  loader: false,
  setLoader: (loader: boolean) => {
    set({ loader });
  },
  reset: () => {
    set({ bioTextBlock: DEFAULT_TEXT_BLOCK_VALUES });
  },
}));
