import { usePlanStore } from "@/stores/usePlanStore";
import { Button, Center, Flex, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";

export const UpgradePlanMessage = () => {

  const navigate = useNavigate();
  const [setUpgradeModalState] = usePlanStore((state) => [state.setUpgradeModalState]);

  return (
    <>
      <Center mt={32}>
        <Text>
          Your current plan does not support this feature. Please upgrade your plan to
          enable this feature.
        </Text>
      </Center>
      <Flex mt={'xl'} justify={'center'}>
        <Button ml={'sm'} size="sm" variant="primary"
          onClick={() => {
            setUpgradeModalState(true);
            navigate('/account/billing');
          }}
        >
          Upgrade Plan
        </Button>
      </Flex>
    </>
  );
}
