import { LoaderDataTable } from '@/components/Menus/LoaderDataTable/LoaderDataTable';
import { NoResultsDataTable } from '@/components/Menus/NoResultsDataTable/NoResultsDataTable';
import { CustomWidgetModal } from '@/components/Modals/CustomWidgetModal/CustomWidgetModal';
import { CustomWidgetService } from '@/services/CustomWidgetService';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { IWidget } from '@/types/widgets';
import { getRelativeTime } from '@/utils/DateUtility';
import { faCircleExclamation, faPencil, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Table,
  Title,
  Text,
  ActionIcon,
  Space,
  Tooltip,
} from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isUserCollaborator } from '@/utils/CommonUtility';
import { useUserStore } from '@/stores/useUserStore';

export function CustomWidgetsPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  useDocumentTitle('Custom Widgets | ' + defaultWorkspace?.agency?.name);

  const [list, setList] = useState<IWidget | any>([]);
  const [opened, setOpened] = useState(false);
  const [widget, setWidget] = useState<IWidget | null>(null);
  const [loading, setLoading] = useState(false);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [user] = useUserStore((state) => [state.user]);
  const fetchCustomWidgets = async () => {
    setLoading(true);
    await new CustomWidgetService()
      .get(defaultWorkspace?._id || '')
      .then((res) => {
        if (res.data.status) {
          setList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  useEffect(() => {
    fetchCustomWidgets();
  }, []);

  const onStoreModal = async (storedWidget: IWidget) => {
    // find the widget if it exists

    const index = list.findIndex((item: IWidget) => item._id === storedWidget._id);
    if (index > -1) {
      // update the item in the list and then set the list
      const newList = [...list];
      newList[index] = storedWidget;
      setList(newList);
    } else {
      console.log('list of widgets', list, storedWidget);
      const newList = [...list, storedWidget];
      setList(newList);
    }
  };

  const handleWidgetDelete = async (id: string) => {
    await new CustomWidgetService()
      .delete(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          // Remove the item from the list
          const newList = list.filter((item: any) => item._id !== id);
          setList(newList);
          notifications.show({
            color: 'green',
            message: 'Custom Widget has been deleted.',
          });
        } else {
          notifications.show({
            color: 'red',
            message: 'Something went wrong. Please contact support',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   *  Function to confirm the deletion of a custom widget.
   * @param id - The id of the custom widgets to be deleted.
   */
  const confirmWidgetDelete = async (id: string) => {
    modals.openConfirmModal({
      title: <Title order={5}>Delete Custom Widget</Title>,
      children: (
        <>
          <Text size="sm">Are you sure? This process can not be undone.</Text>
        </>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleWidgetDelete(id),
    });
  };

  return (
    <>
      <CustomWidgetModal
        opened={opened}
        setOpened={setOpened}
        onChange={onStoreModal}
        widget={widget}
      />
      <Box
        style={{
          backgroundColor: 'var(--mantine-color-white)',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        }}
        ml={16}
        mr={16}
      >
        <Grid p={16}>
          <Grid.Col span={5}>
          {defaultPermissions && checkPermission(defaultPermissions, ['add_widgets']) && <Button
              size="xs"
              onClick={() => {
                setWidget(null);
                setOpened(true);
              }}
            >
              Add Custom Widget
            </Button>}
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex align={'center'}>
              <Title pt={2} order={5} mr={8}>
                Custom Widgets
              </Title>
              <Tooltip
                multiline
                w={450}
                fz="13"
                label="You can add 3rd party widgets here to use them in your campaigns. This adds infinite possibilities for you to use any type of custom or 3rd party widget you want.

For example, You can use optin forms from tools like Optinmonster, Sumo, Poptin, Convertful or even your own lead forms."
              >
                <FontAwesomeIcon
                  style={{
                    color: 'var(--mantine-color-gray-6)',
                  }}
                  icon={faCircleExclamation}
                />
              </Tooltip>
            </Flex>
          </Grid.Col>
          <Grid.Col span={5}>
            <></>
          </Grid.Col>
        </Grid>
        <Divider color="gray.2" />
        <Table stickyHeader verticalSpacing={'md'}>
          <Table.Thead h={20} bg={'gray.1'} c="gray.7" fz="xs">
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>Created at</Table.Th>
              {defaultPermissions && checkPermission(defaultPermissions, ['edit_widgets', 'delete_widgets']) && <Table.Th ta={'center'}>Actions</Table.Th>}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {loading ? (
              <>
                <LoaderDataTable colSpan={3} />
              </>
            ) : (
              <>
                {list.length === 0 && (
                  <>
                    <NoResultsDataTable
                      colSpan={3}
                      data={list}
                      noResultsMessage="You do not have any custom widgets"
                    />
                  </>
                )}
                {list.map((widgetItem: IWidget) => (
                  <>
                    <Table.Tr key={widgetItem._id}>
                      <Table.Td>
                        <Text pt={4} fz={13}>
                          {widgetItem.name}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Text fz={13}>{getRelativeTime(widgetItem.created_at)}</Text>
                      </Table.Td>

                      {defaultPermissions && checkPermission(defaultPermissions, ['edit_widgets', 'delete_widgets']) && isUserCollaborator(user, defaultWorkspace?._id, widgetItem, 'widget') && <Table.Td ta="center">
                        <Flex justify={'center'}>
                        {defaultPermissions && checkPermission(defaultPermissions, ['edit_widgets']) && <Tooltip label="Edit">
                            <ActionIcon
                              variant="subtle"
                              color="gray"
                              onClick={() => {
                                setWidget(widgetItem);
                                setOpened(true);
                              }}
                            >
                              <FontAwesomeIcon icon={faPencil} />
                            </ActionIcon>
                          </Tooltip>}
                          <Space w={8} />
                          {defaultPermissions && checkPermission(defaultPermissions, ['delete_widgets']) && <Tooltip label="Delete">
                            <ActionIcon
                              variant="subtle"
                              color="red"
                              onClick={() => confirmWidgetDelete(widgetItem?._id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </ActionIcon>
                          </Tooltip>}
                        </Flex>
                      </Table.Td>}
                    </Table.Tr>
                  </>
                ))}
              </>
            )}
          </Table.Tbody>
        </Table>
      </Box>
    </>
  );
}
