import { UpgradePlanMessage } from '@/components/Billing/UpgradePlanMessage';
import { TeamMemberModal } from '@/components/Modals/TeamMemberModal/TeamMemberModal';
import {
  APP_URL,
  WORKSPACE_MEMBER_ROLES,
  WORKSPACE_MEMBER_ROLE_COLORS,
} from '@/constants/Constants';
import { WorkspaceMemberService } from '@/services/WorkspaceMemberService';
import { usePlanStore } from '@/stores/usePlanStore';
import { useUserStore } from '@/stores/useUserStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { getRelativeTime } from '@/utils/DateUtility';
import {
  faArrowsRotate,
  faClipboard,
  faEllipsis,
  faPencil,
  faSend,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Center,
  Title,
  Avatar,
  Flex,
  Group,
  TextInput,
  ActionIcon,
  Text,
  Menu,
  Button,
  Divider,
  Grid,
  Table,
  Badge,
  Stack,
  Radio,
  Tooltip
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useClipboard, useDocumentTitle } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';

export function TeamMembersPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  useDocumentTitle('Team Members | ' + defaultWorkspace?.agency?.name);

  const [plan] = usePlanStore((state) => [state.plan]);
  const clipboard = useClipboard({ timeout: 500 });
  const [opened, setOpened] = useState(false);
  const [members, setMembers] = useState<any[]>([]);
  const [editMember, setEditMember] = useState<any | null>(null);
  const [user] = useUserStore((state) => [state.user]);
  const fetchMembers = async () => {
    await new WorkspaceMemberService()
      .get(defaultWorkspace?._id || '')
      .then((res) => {
        if (res.data.status) {
          console.log(res.data.status);
          setMembers(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (id: string, option: any) => {
    new WorkspaceMemberService()
      .delete(defaultWorkspace?._id || '', id, option)
      .then((res) => {
        if (res.data.status) {
          fetchMembers();
          notifications.show({
            color: 'green',
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteMemberModal = ({ id, onConfirm, role, state }: any) => {
    const [value, setValue] = useState<string | any>('move');

    const handleConfirm = () => {
      if (value) {
        onConfirm(value);
        modals.closeAll();
      } else {
        console.log('Select an option');
      }
    };
    
    if (role === 'guest' || state === 'invited') {
      return (
        <Stack>
          <Text size="sm">Are you sure? This process cannot be undone.</Text>
          <Group justify="right">
            <Button size="xs" color="red" onClick={handleConfirm}>Yes, Delete it!</Button>
            <Button size="xs" variant="light" onClick={() => modals.closeAll()}>No, Cancel!</Button>
          </Group>
        </Stack>
      )
    }
    return (
      <Stack>
        <Text size="sm">Are you sure? This process cannot be undone.</Text>
        <Radio.Group value={value} onChange={setValue}>
          <Flex align={'center'} >
            <Radio value="move" label="Migrate team member's data to my account" />
            <Tooltip
              position="bottom"
              multiline
              w={450}
              fz="xs"
              label="Campaigns, Links, RSS feeds, Bio Links, Tags, Custom Domains, Retargeting Pixels, Custom Widgets, UTMS, and BlackList IPs."
            >
              <FontAwesomeIcon
                style={{
                  color: 'var(--mantine-color-gray-6)',
                  padding: '0px 10px',
                  fontSize: '15px'
                }}
                icon={faCircleExclamation}
              />
            </Tooltip>
          </Flex>
          <Flex align={'center'}>
            <Radio value="delete" label="Permanently delete team member's data" />
            <Tooltip
              position="bottom"
              multiline
              w={450}
              fz="xs"
              label="Campaigns, Links, RSS feeds, Bio Links, Tags, Custom Domains, Retargeting Pixels, Custom Widgets, UTMS, and BlackList IPs."
            >
              <FontAwesomeIcon
                style={{
                  color: 'var(--mantine-color-gray-6)',
                  padding: '0px 10px',
                  fontSize: '15px'
                }}
                icon={faCircleExclamation}
              />
            </Tooltip>
          </Flex>
        </Radio.Group>
        <Group justify="right">
          <Button size="xs" color="red" onClick={handleConfirm}>Yes, Delete it!</Button>
          <Button size="xs" variant="light" onClick={() => modals.closeAll()}>No, Cancel!</Button>
        </Group>
      </Stack>
    );
  };

  const handleConfirmDelete = (id: any, role: any, state: any) => {
    modals.open({
      title: <Title order={5}>Delete Member</Title>,
      children: <DeleteMemberModal id={id} onConfirm={(option: any) => handleDelete(id, option)} role={role} state={state} />,
    });
  };

  const resendInvite = (id: string) => {
    new WorkspaceMemberService()
      .resendInvite(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          fetchMembers();
          notifications.show({
            color: 'green',
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTeamMemberRoleColor = (role: string) => {
    switch (role) {
      case WORKSPACE_MEMBER_ROLES.OWNER:
        return WORKSPACE_MEMBER_ROLE_COLORS.OWNER;
      case WORKSPACE_MEMBER_ROLES.ADMINISTRATOR:
        return WORKSPACE_MEMBER_ROLE_COLORS.ADMINISTRATOR;
      case WORKSPACE_MEMBER_ROLES.COLLABORATOR:
        return WORKSPACE_MEMBER_ROLE_COLORS.COLLABORATOR;
      default:
        return WORKSPACE_MEMBER_ROLE_COLORS.GUEST;
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  return (
    <>
      <TeamMemberModal
        isOpen={opened}
        onClose={() => {
          setEditMember(null);
          setOpened(false);
        }}
        member={editMember}
        reload={fetchMembers}
      />
      {!plan?.limits?.team_members ? (
        <UpgradePlanMessage />
      ) : (
      <Box
        style={{
          backgroundColor: 'var(--mantine-color-white)',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          height: '100%',
          flexGrow: 1,
        }}
        ml={16}
        mr={16}
      >
        <Grid p={16}>
          <Grid.Col span={5}>
            <Button size="xs" onClick={() => setOpened(true)}>
              Add Team Member
            </Button>
          </Grid.Col>
          <Grid.Col span={2}>
            <Title order={5}>Team Members</Title>
          </Grid.Col>
        </Grid>

        <Divider color="gray.2" />

        <Table stickyHeader verticalSpacing={'md'}>
          <Table.Thead h={20} bg={'gray.1'} c="gray.7" fz="xs">
            <Table.Tr>
              <Table.Th>Email Address</Table.Th>
              <Table.Th>Role</Table.Th>

              <Table.Th>Joined at</Table.Th>
              <Table.Th ta={'center'}>Actions</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {members.map((member, index) => (
              <>
                <Table.Tr key={index}>
                  <Table.Td>
                    <Flex align={'center'}>
                      {member?.user?.first_name && member?.user?.last_name ? (
                        <>
                          <Avatar size="sm" mr={8}>
                            {member?.user?.first_name[0]}
                            {member?.user?.last_name[0]}
                          </Avatar>
                        </>
                      ) : (
                        <Avatar size="sm" mr={8} />
                      )}
                      <Flex direction={'column'} ml={8}>
                        <Text fz={13}>{member?.user?.email}</Text>

                        <Text c="dimmed" fz={13}>
                          {member?.user?.first_name} {member?.user?.last_name}
                        </Text>

                        <Text fz={12} fw={500} tt="capitalize">
                          {member?.state}
                        </Text>
                      </Flex>
                    </Flex>
                  </Table.Td>
                  <Table.Td>
                    <Text fz={13}>
                      <Badge
                        color={getTeamMemberRoleColor(member?.role)}
                        radius={'sm'}
                        variant="outline"
                      >
                        {member?.role}
                      </Badge>
                    </Text>
                  </Table.Td>

                  <Table.Td>
                    <Text fz={13}>{getRelativeTime(member?.created_at)}</Text>
                  </Table.Td>

                  <Table.Td>
                    {member?.user?._id === user?._id ||
                      member?.role === WORKSPACE_MEMBER_ROLES.OWNER ? (
                      <></>
                    ) : (
                      <Menu withArrow withinPortal>
                        <Menu.Target>
                          <Center>
                            <ActionIcon c="gray" variant="subtle">
                              <FontAwesomeIcon icon={faEllipsis} />
                            </ActionIcon>
                          </Center>
                        </Menu.Target>
                        <Menu.Dropdown>
                          <Menu.Item
                            onClick={() => {
                              clipboard.copy(
                                `${APP_URL}/join-workspace/${member?.invitation_token}`
                              );
                              notifications.show({
                                color: 'blue',
                                message: 'Invite link copied.',
                              });
                            }}
                            leftSection={
                              <>
                                <FontAwesomeIcon icon={faClipboard} />
                              </>
                            }
                          >
                            Copy invite link
                          </Menu.Item>
                          <Menu.Item
                            onClick={() => resendInvite(member._id)}
                            leftSection={
                              <>
                                <FontAwesomeIcon icon={faArrowsRotate} />
                              </>
                            }
                          >
                            Resend invitation
                          </Menu.Item>
                          <Menu.Item
                            leftSection={
                              <>
                                <FontAwesomeIcon icon={faPencil} />
                              </>
                            }
                            onClick={() => {
                              setEditMember({
                                _id: member?._id,
                                email: member?.user?.email,
                                role: member?.role,
                              });
                              setOpened(true);
                            }}
                          >
                            Edit
                          </Menu.Item>
                          <Menu.Divider />
                          <Menu.Item
                            onClick={() => handleConfirmDelete(member?._id, member?.role, member?.state)}
                            leftSection={
                              <>
                                <FontAwesomeIcon icon={faTrash} />
                              </>
                            }
                            c={'red'}
                          >
                            Delete
                          </Menu.Item>
                        </Menu.Dropdown>
                      </Menu>
                    )}
                  </Table.Td>
                </Table.Tr>
              </>
            ))}
          </Table.Tbody>
        </Table>
      </Box>
      )}
    </>
  );
}