import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import MainLayout from './layouts/MainLayout/MainLayout';
import { AccountSettingsPage } from './pages/Account/AccountSettings.page';
import { BillingPage } from './pages/Account/Billing.page';
import { ReferAndEarnPage } from './pages/Account/ReferAndEarn.page';
import { ProfilePage } from './pages/Account/Profile/Profile.page';
import { AnalyticsPage } from './pages/Analytics/Analytics.page';
import { ForgotPasswordPage } from './pages/Auth/ForgotPassword.page';
import { LoginPage } from './pages/Auth/Login.page';
import { RegisterPage } from './pages/Auth/Register.page';
import { BioLinksPage } from './pages/BioLinks/BioLinks.page';
import { CampaignsPage } from './pages/Campaigns/Listing/Campaigns.page';
import { DashboardPage } from './pages/Dashboard/Dashboard.page';
import { HomePage } from './pages/Home.page';
import { LinksPage } from './pages/Links/Links.page';
import { ReportsPage } from './pages/Reports/Reports.page';
import { RSSFeedPage } from './pages/RSSFeed/RSSFeed.page';
import { WorkspaceSettingsPage } from './pages/WorkspaceSettings/WorkspaceSettings.page';
import { BasicSettingsPage } from './pages/WorkspaceSettings/BasicSettings.page';
import { TeamMembersPage } from './pages/WorkspaceSettings/TeamMembers.page';
import { CustomDomainsPage } from './pages/WorkspaceSettings/CustomDomains.page';
import { DeepLinkingPage } from './pages/WorkspaceSettings/DeepLinking.page';
import { DeveloeprAppsPage } from './pages/WorkspaceSettings/DeveloperApps.page';
import { RetargetingPixelsPage } from './pages/WorkspaceSettings/RetargetingPixels.page';
import { CustomWidgetsPage } from './pages/WorkspaceSettings/CustomWidgets.page';
import { UTMTrackingPage } from './pages/WorkspaceSettings/UTMTracking.page';
import { TagsPage } from './pages/WorkspaceSettings/Tags.page';
import { IpAddressPage } from './pages/WorkspaceSettings/IpAddresses.page';
import { ExcludeTrafficPage } from './pages/WorkspaceSettings/ExcludeTraffic.page';
import { MiscellaneousPage } from './pages/WorkspaceSettings/Miscellaneous.page';
import { IntegrationsPage } from './pages/WorkspaceSettings/Integrations.page';
import { CampaignsSetupPage } from './pages/Campaigns/Setup/CampaignsSetup.page';
import { EmailVerificationPage } from './pages/Auth/EmailVerification.page';
import { EmailChangeVerificationPage } from './pages/Auth/EmailChangeVerification.page';
import { LogoutPage } from './pages/Auth/Logout.page';
import { AnalyticsShortLinksPage } from './pages/Analytics/AnalyticsShortLinks.page';
import { AnalyticsBioLinksPage } from './pages/Analytics/AnalyticsBioLinksPage';
import { WhitelabelPage } from './pages/GlobalSettings/Whitelabel.page';
import { JoinWorkspacePage } from './pages/Auth/JoinWorkspace.page';
import { ResetPasswordPage } from './pages/Auth/ResetPassword.page';
import { BioLinksSetupPage } from './pages/BioLinks/BioLinksSetup.page';
import { IntegrationCallbackPage } from './pages/WorkspaceSettings/IntegrationCallbackPage';
import { DeveloperAppAuthorizationPage } from './pages/WorkspaceSettings/DeveloperAppAuthorization.page';
import { GoogleCallback } from '@/components/Auth/GoogleCallback';

// import { AnalyticsListViewPage } from './pages/Analytics/AnalyticsListView.page';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/register',
    element: <RegisterPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/verification',
    element: <EmailVerificationPage />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordPage />,
  },
  {
    path: '/reset-password/:token',
    element: <ResetPasswordPage />,
  },
  {
    path: '/logout',
    element: <LogoutPage />,
  },
  // redirect of oauth2 integrations callback
  {
    path: '/integration/connect/:code?',
    element: (
      <MainLayout header={false}>
        <IntegrationCallbackPage />
      </MainLayout>
    )
  },

  // developer apps authroization
  {
    path: '/oauth/authorize/:client_id?',
    element: (
      <MainLayout header={false}>
        <DeveloperAppAuthorizationPage />
      </MainLayout>
    )
  },

  {
    path: '/join-workspace/:token',
    element: <JoinWorkspacePage />,
  },
  {
    path: '/dashboard/:workspaceId?',
    element: (
      <MainLayout>
        <DashboardPage />
      </MainLayout>
    ),
  },
  {
    path: '/links/:workspaceId?',
    element: (
      <MainLayout>
        <LinksPage />
      </MainLayout>
    ),
  },
  {
    path: '/campaigns/:workspaceId?',
    element: (
      <MainLayout>
        <CampaignsPage />
      </MainLayout>
    ),
  },
  {
    path: '/campaigns/:workspaceId?/setup/:campaignId?',
    element: (
      <MainLayout header={false}>
        <CampaignsSetupPage />
      </MainLayout>
    ),
  },
  {
    path: '/rss/:workspaceId?',
    element: (
      <MainLayout>
        <RSSFeedPage />
      </MainLayout>
    ),
  },
  {
    path: '/bio-links/:workspaceId?',
    element: (
      <MainLayout>
        <BioLinksPage />
      </MainLayout>
    ),
  },
  {
    path: '/bio-links/:workspaceId?/setup/:id?',
    element: (
      <MainLayout header={false}>
        <BioLinksSetupPage />
      </MainLayout>
    ),
  },
  {
    path: '/reports/:workspaceId?',
    element: (
      <MainLayout>
        <ReportsPage />
      </MainLayout>
    ),
  },
  {
    path: '/analytics/:workspaceId?',
    element: (
      <MainLayout>
        <AnalyticsPage />
      </MainLayout>
    ),
    children: [
      {
        path: 'short-links',
        element: <AnalyticsShortLinksPage />,
      },
      {
        path: 'bio-links',
        element: <AnalyticsBioLinksPage />,
      },
    ],
  },
  {
    path: '/account',
    element: (
      <MainLayout>
        <AccountSettingsPage />
      </MainLayout>
    ),
    children: [
      {
        path: 'profile',
        element: <ProfilePage />,
      },
      {
        path: 'billing',
        element: <BillingPage />,
      },
      {
        path: 'refer&earn',
        element: <ReferAndEarnPage />,
      },
    ],
  },
  {
    path: '/workspace/:workspaceId?',
    element: (
      <MainLayout>
        <WorkspaceSettingsPage />
      </MainLayout>
    ),
    children: [
      {
        path: 'basic/',
        element: <BasicSettingsPage />,
      },
      {
        path: 'team-members',
        element: <TeamMembersPage />,
      },
      {
        path: 'custom-domains',
        element: <CustomDomainsPage />,
      },
      {
        path: 'integrations/?',
        element: <IntegrationsPage />,
      },
      {
        path: 'deep-linking',
        element: <DeepLinkingPage />,
      },
      {
        path: 'developer-apps',
        element: <DeveloeprAppsPage />,
      },
      {
        path: 'retargeting-pixels',
        element: <RetargetingPixelsPage />,
      },
      {
        path: 'custom-widgets',
        element: <CustomWidgetsPage />,
      },
      {
        path: 'utm-tracking',
        element: <UTMTrackingPage />,
      },
      {
        path: 'tags',
        element: <TagsPage />,
      },
      {
        path: 'ip_address',
        element: <IpAddressPage />,
      },
      {
        path: 'exclude-traffic',
        element: <ExcludeTrafficPage />,
      },
      {
        path: 'miscellaneous',
        element: <MiscellaneousPage />,
      },
      {
        path: 'white-label',
        element: <WhitelabelPage />,
      },
    ],
  },
  {
    path: '/verify-email/:token/:newEmail',
    element: <EmailChangeVerificationPage />,
  },
  {
    path: '/auth/google/callback',
    element: <GoogleCallback />,
  },
]);

export function Router() {
  return <RouterProvider router={router} />;
}

