import { PlanService } from "@/services/PlanService";
import { usePlanStore } from "@/stores/usePlanStore";
import { useWorkspaceStore } from "@/stores/useWorkspaceStore";
import { Button, Center, Flex, Text, Title } from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { useEffect, useState } from "react";

export const PausedPlanMessage = () => {

  const [loader , setLoader] = useState(false);
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [fetchPrimarySubscription, primarySubscription] = usePlanStore((state) => [state.fetchPrimarySubscription, state.primarySubscription]);


  const handleResumeSubscription = () => {
    modals.openConfirmModal({
      title: <Title order={5}>Resume Subscription</Title>,
      children: (
        <>
          <Text size="sm">Are you sure? This process can not be undone.</Text>
        </>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => updateSubscription(),
    });
  };

  const updateSubscription = () => {
    if (!defaultWorkspace?._id || !defaultWorkspace?.organization_id || !primarySubscription.subscription_id) {
      return;
    }

    setLoader(true);
    new PlanService().updateSubscription(defaultWorkspace._id, defaultWorkspace.organization_id, primarySubscription.subscription_id, 'resume').then((response) => {
      if (response.data?.status) {
        notifications.show({
          color: 'green',
          message: 'Subscription has been updated successfully.',
        });
        setTimeout(() => {
          setLoader(false);
          window.location.reload();
        }, 2000);
      } else {
        notifications.show({
          color: 'red',
          message: response.data?.message || "Something went wrong. Please try again later.",
        });
      }
    }).catch((error: any) => {
      console.error('Error updating subscription', error)
      notifications.show({
        color: 'red',
        message: error?.response?.data?.message || "Something went wrong. Please try again later.",
      });
    }).finally(() => setLoader(false));
  }

  useEffect(() => {
    if(defaultWorkspace?._id && defaultWorkspace?.organization_id) {
      fetchPrimarySubscription(defaultWorkspace._id, defaultWorkspace.organization_id);
    }
  }, [defaultWorkspace]);


  return (
    <Flex direction={'column'} justify={'center'} align={'center'}>
        <Text my={'xl'}>
          Your current plan is paused. Please resume your plan to continue using the Replug or contact at <a href="mailto:support@replug.io">support@replug.io</a>
          
        </Text>
        {loader ? (
          <Button ml={'sm'} size="sm" variant="primary" disabled>Updating...</Button>
        ) : (
          <Button ml={'sm'} size="sm" variant="primary" onClick={() => handleResumeSubscription()}>Resume Plan</Button>
        )}
    </Flex>
  );
}
