import { DEFAULT_CAMPAIGN_VALUES } from '@/constants/Constants';
import { CampaignsService } from '@/services/CampaignsService';
import { ICampaign } from '@/types/campaigns';
import { notifications } from '@mantine/notifications';
import { create } from 'zustand';
import { useCampaignsStore } from '@/stores/useCampaignsStore';

interface ICampaignSetupStore {
  campaign: ICampaign | null;
  setCampaign: (campaign: ICampaign | null) => void;
  campaignSetupToggle: boolean;
  setCampaignSetupToggle: (toggle: boolean) => void;
  storeCampaign: (workspaceId: string, campaign: any) => Promise<boolean>;
  createLoading: boolean;
  setCreateLoading: (loading: boolean) => void;
}

export const useCampaignSetupStore = create<ICampaignSetupStore>((set) => ({
  //@ts-ignore
  campaign: DEFAULT_CAMPAIGN_VALUES,
  setCampaign: (campaign) => {
    set({ campaign });
  },
  campaignSetupToggle: false,
  setCampaignSetupToggle: (toggle) => {
    set({ campaignSetupToggle: toggle });
  },
  storeCampaign: async (workspaceId: string , campaign: any) => {
    set({ createLoading: true });
    const resp = await new CampaignsService()
      .store(workspaceId, campaign)
      .then((res) => {
        if (res.data.status) {
          notifications.show({
            color: 'green',
            message: 'Campaign created successfully',
          });
        }
        useCampaignsStore.getState().fetchCampaigns(workspaceId);
        return true;
      })
      .catch((err) => {
        if (err.response.status === 400 ||  err.response.status ===  422) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
        }
        return false;
      })
      .finally(() => {
        set({ createLoading: false });
      });
    console.log(resp);
    return resp;
  },

  createLoading: false,
  setCreateLoading: (loading) => {
    set({ createLoading: loading });
  },
}));
