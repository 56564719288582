import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { Flex, SegmentedControl, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

export function AnalyticsPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [value, setValue] = useState(
    window.location.href.includes('/bio-links') ? 'bio-links' : 'short-links'
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (value === 'short-links') {
      // check if the url already has the value of the selected tab
      if (window.location.href.includes('short-links')) {
        return;
      }
      navigate(`/analytics/${defaultWorkspace?._id}/short-links`);
    } else {
      if (window.location.href.includes('bio-links')) {
        return;
      }
      navigate(`/analytics/${defaultWorkspace?._id}/bio-links`);
    }
  }, [value]);
  return (
    <>
      <Flex direction={'column'} mx="lg" mt="sm">
        <Flex mb={'md'}>
          <Flex
            style={{
              flexGrow: 1,
            }}
          >
            <Text size="xl" fw="bold" mr={'xs'}>
              Analytics {value === 'short-links' ? 'Short Links' : 'Bio Links'}
            </Text>
          </Flex>

          <Flex justify={'end'}>
            <SegmentedControl
              size="xs"
              w={230}
              color="primary"
              data={[
                { label: 'Short Links', value: 'short-links' },
                { label: 'Bio Links', value: 'bio-links' },
              ]}
              value={value}
              onChange={setValue}
            />
          </Flex>
        </Flex>{' '}
        <Outlet />
      </Flex>
    </>
  );
}
