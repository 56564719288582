import { z } from 'zod';

const fileSizeLimit = 10 * 1024 * 1024; // 10MB
const allowedFileTypes = ['image/jpeg', 'image/png'];
const allowedIconTypes = [
  'image/x-icon',
  'image/vnd.microsoft.icon',
  'image/ico',
  'image/jpeg',
  'image/png',
];

export const fileSchema = z
  .instanceof(File)
  .refine((file) => allowedFileTypes.includes(file.type), {
    message: 'Invalid file type. Only JPEG and PNG are allowed.',
  })
  .refine((file) => file.size <= fileSizeLimit, {
    message: `File size must be less than ${fileSizeLimit / 1024 / 1024}MB.`,
  });

export const iconSchema = z
  .instanceof(File)
  .refine((file) => allowedIconTypes.includes(file.type), {
    message: 'Invalid file type. Only JPEG, PNG and ICO file are allowed.',
  })
  .refine((file) => file.size <= fileSizeLimit, {
    message: `File size must be less than ${fileSizeLimit / 1024 / 1024}MB.`,
  });
