import { UserService } from '@/services/UserService';
import { Box, Button, PasswordInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';

export function ProfileChangePassword() {
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      current_password: '',
      confirm_new_password: '',
      new_password: '',
    },

    validate: {
      current_password: (value) =>
        value.trim().length > 0 ? null : 'Current password is required',
      confirm_new_password: (value) =>
        value.trim().length > 0 ? null : 'New password is required',
      new_password: (value) => (value.trim().length > 0 ? null : 'Confirm password is required'),
    },
  });

  const changePassword = async (formValues: any) => {
    if (formValues.new_password.length < 8) {
      notifications.show({
        color: 'red',
        message: 'Your new password length should be greater than 8 characters',
      });
      return;
    }
    if (formValues.new_password !== formValues.confirm_new_password) {
      notifications.show({
        color: 'red',
        message: 'Your new password and confirm password does not match.',
      });
      return;
    }

    setLoading(true);
    await new UserService()
      .updatePassword(formValues)
      .then((res) => {
        notifications.show({
          color: res.data.status ? 'green' : 'red',
          message: res.data.message,
        });
      })
      .catch((err) => {
        notifications.show({
          color: 'red',
          message: err.response.data.message,
        });
      });

    setLoading(false);
  };

  return (
    <>
      <Box ml={16}>
        <form onSubmit={form.onSubmit(changePassword)}>
          <PasswordInput
            mb={12}
            label="Current Password"
            placeholder="Current password"
            variant="filled"
            {...form.getInputProps('current_password')}
          />
          <PasswordInput
            mb={12}
            label="New Password"
            placeholder="New Password"
            variant="filled"
            {...form.getInputProps('new_password')}
          />
          <PasswordInput
            mb={12}
            label="Confirm Password"
            placeholder="Confirm Password"
            variant="filled"
            {...form.getInputProps('confirm_new_password')}
          />
          <Button loading={loading} disabled={loading} fullWidth type="submit">
            Change Password
          </Button>
        </form>
      </Box>
    </>
  );
}
