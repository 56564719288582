import { UTMService } from '@/services/UTMService';
import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { useUTMStore } from '@/stores/useUTMStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { addUtmParametersToUrl } from '@/utils/UTMUtility';
import {
  Box,
  Button,
  Center,
  Flex,
  Modal,
  TextInput,
  Title,
  Text,
  Select,
  Anchor,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { z } from 'zod';

// Define the base schema without the UTMs validation
const schema = z.object({
  _id: z.string().nullable(),
  name: z.string().min(1, { message: 'Name should have at least 1 letter' }),
  utm_campaign: z.string().optional(),
  utm_medium: z.string().optional(),
  utm_source: z.string().optional(),
  utm_term: z.string().nullable(),
  utm_content: z.string().nullable(),
  workspace_id: z.string().nullable(),
});

/**
 * This component is responsible for applying or creating UTM parameters to the link. It is used in the LinkSetup and during the CampaignSetup page.
 */
export const UTMPresetApplyOrCreate = ({ onChange }: { onChange: (value: string) => void }) => {
  const [create, setCreate] = useState(false);
  const [selected, setSelected] = useState<string | null>(null);
  const [opened, setOpened, url, setURL] = useUTMStore((state) => [
    state.setupModal,
    state.setSetupModal,
    state.utmURL,
    state.setUTMURL,
  ]);
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [UTMs, setUTMs] = useUTMStore((state) => [state.UTMs, state.setUTMs]);
  const [storeLoading, setStoreLoading] = useState(false);
  const form = useForm({
    initialValues: {
      _id: '',
      name: '',
      utm_campaign: '',
      utm_medium: '',
      utm_source: '',
      utm_term: '',
      utm_content: '',
      workspace_id: defaultWorkspace?._id,
    },
    validate: zodResolver(schema),
  });

  /**
   * This function is responsible for applying the UTM parameters to the link.
   * @param formValues Mantine form values
   */
  const handleUTMApply = (formValues: any) => {
    let originalURL = url;

    // if url does not have http or https, add it
    if (!originalURL.includes('http://') && !originalURL.includes('https://')) {
      originalURL = `https://${originalURL}`;
    }

    const finalURL = addUtmParametersToUrl(originalURL, formValues);
    // setLink({ ...link, url });
    setURL(finalURL);
    setOpened(false);
    onChange(finalURL);
  };

  /**
   * 1. This function applies the UTM parameters to the link and closes the modal.
   * 2. If it is a new UTM parameter, it first stores and then apply it.
   * @param formValues Form values
   * @returns void
   */
  const handleFormSubmission = async (formValues: any) => {
    const keys = ['utm_campaign', 'utm_medium', 'utm_source', 'utm_term', 'utm_content'];
    const filtered = keys.filter((key) => formValues[key] !== '');
    if (filtered.length === 0) {
      notifications.show({
        color: 'red',
        message:
          'Please fill at least one of the UTMs: Campaign, Medium, or Source with at least 1 letter.',
      });
      return;
    }

    if (selected) {
      handleUTMApply(formValues);
      return;
    }

    setStoreLoading(true);

    await new UTMService()
      .create(defaultWorkspace?._id || '', formValues)
      .then((res) => {
        if (res.data.data) {
          setOpened(false);
          setUTMs([...UTMs, res.data.data]);
          notifications.show({
            color: 'green',
            message: 'UTM preset has been created and applied.',
          });
          handleUTMApply(formValues);
          form.reset();
        } else {
          notifications.show({
            color: 'red',
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 400 ||  err.response.status ===  422) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
        }
        console.log(err);
      });
    setStoreLoading(false);
    setOpened(false);
  };

  useEffect(() => {
    form.reset();
    setSelected(null);
    form.setFieldValue('workspace_id', defaultWorkspace?._id);
  }, [opened]);

  useEffect(() => {
    if (selected) {
      const utmItem = UTMs.find((utm) => utm._id === selected);
      if (utmItem) {
        form.setFieldValue('name', utmItem.name);
        form.setFieldValue('utm_campaign', utmItem.utm_campaign);
        form.setFieldValue('utm_medium', utmItem.utm_medium);
        form.setFieldValue('utm_source', utmItem.utm_source);
        form.setFieldValue('utm_term', utmItem.utm_term);
        form.setFieldValue('utm_content', utmItem.utm_content);
      }
    } else {
      form.reset();
      form.setFieldValue('workspace_id', defaultWorkspace?._id);
    }
  }, [selected]);

  return (
    <>
      <Modal
        zIndex={300}
        size={'700'}
        opened={opened}
        onClose={() => setOpened(!opened)}
        title={<Title order={5}>Add UTM Parameters</Title>}
      >
        <Box p={16}>
          {/* <Flex align={'center'} justify={'center'}>
            <FontAwesomeIcon
              style={{
                color: theme.colors.primary[9],
                marginBottom: 16,
              }}
              icon={faLocationPin}
              size={'2x'}
            />
          </Flex> */}

          <Box mx={'md'}>
            <Flex align={'center'}>
              <Text
                style={{
                  flexGrow: 1,
                }}
                size="sm"
                mb={4}
                fz={13}
                fw={500}
              >
                UTM Preset
              </Text>
            </Flex>

            <Select
              mb={16}
              value={selected}
              data={UTMs.map((utm) => ({ value: utm._id, label: utm.name }))}
              placeholder="Select UTM preset"
              onChange={(value) => setSelected(value)}
            />

            {!create && (
              <Center>
                <Anchor
                  underline="always"
                  fw={600}
                  size="sm"
                  onClick={() => {
                    setCreate(true);
                    setSelected(null);
                  }}
                >
                  Or create new UTM
                </Anchor>
              </Center>
            )}
          </Box>
          <form onSubmit={form.onSubmit(handleFormSubmission)}>
            {(create || selected) && (
              <>
                <Box mx={16}>
                  {!selected && (
                    <>
                      <TextInput
                        mb={12}
                        label={'Name'}
                        variant="filled"
                        placeholder="Enter the name of the UTM preset"
                        {...form.getInputProps('name')}
                      />
                    </>
                  )}

                  <TextInput
                    mb={12}
                    label={'UTM Campaign'}
                    variant="filled"
                    placeholder="Product, promo code or slogan (e.g. spring_sale)"
                    {...form.getInputProps('utm_campaign')}
                  />
                  <TextInput
                    mb={12}
                    label={'UTM Medium'}
                    variant="filled"
                    placeholder="Marketing medium e.g email, social, cpc, banner"
                    {...form.getInputProps('utm_medium')}
                  />
                  <TextInput
                    mb={12}
                    label={'UTM Source'}
                    variant="filled"
                    placeholder="Website or application (e.g. Facebook, Twitter(X), Newsletter)"
                    {...form.getInputProps('utm_source')}
                  />

                  <TextInput
                    mb={12}
                    label={'UTM Term'}
                    variant="filled"
                    placeholder="e.g Identify paid keywords"
                    {...form.getInputProps('utm_term')}
                  />

                  <TextInput
                    mb={12}
                    label={'UTM Content'}
                    variant="filled"
                    placeholder="e.g Use to differentiate ads or links that point to the same URL, text ad copy, or image banner"
                    {...form.getInputProps('utm_content')}
                  />
                  <Flex mt={32} align={'center'} justify={'center'}>
                    <Button
                      disabled={storeLoading}
                      loading={storeLoading}
                      mr={12}
                      type="submit"
                    >
                      Apply
                    </Button>
                    <Button onClick={() => setOpened(false)} variant="outline" color="gray">
                      Cancel
                    </Button>
                  </Flex>
                </Box>
              </>
            )}
          </form>
        </Box>
      </Modal>
    </>
  );
};
