import axios from './JWTService';

export class PixelService {
  get = (workspace_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`workspace/${workspace_id}/retargeting_pixel/get`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  delete = (workspace_id: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`workspace/${workspace_id}/retargeting_pixel/delete/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  create = (workspace_id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`workspace/${workspace_id}/retargeting_pixel/create`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  update = (workspace_id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`workspace/${workspace_id}/retargeting_pixel/update/${data._id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
