import {
  Anchor,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Image,
  PinInput,
  Text,
  Title,
} from '@mantine/core';
import { useUserStore } from '@/stores/useUserStore';
import { useEffect, useState } from 'react';
import { UserService } from '@/services/UserService';
import { useNavigate , useParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { getAppLogo } from '@/utils/CommonUtility';

export const EmailChangeVerificationPage = () => {
  const [emailVerifyingLoader, setEmailVerifyingLoader] = useState(false);
  const [message, setMessage] = useState('Please wait ...');
  const [user, setUser] = useUserStore((state) => [state.user, state.setUser]);
  const navigate = useNavigate();
  const { token , newEmail} = useParams();
  const handleVerification = async () => {
    setEmailVerifyingLoader(true);
    await new UserService()
      .verifyNewEmail(token, newEmail)
      .then((response) => {
        if (response.data.status) {
          setMessage('Email varification successfully.')
          navigate('/account/profile');
        }
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 404) {
          setMessage('Email verification unsuccessful.')
        }
      });
    setEmailVerifyingLoader(false);
  };

  const fetchUser = async () => {
    await new UserService()
      .getProfile()
      .then((response) => {
        if (response.data.status) {
          setUser(response.data.data.user);
          return true;
        }
        return false;
      })
      .catch((error) => {
        console.debug(error);
        if (error.response.status === 401) {
          const redirect = window.location.pathname + window.location.search;
          navigate('/login?redirect=' + redirect);
        }
        return false;
      });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      handleVerification()
    }
  }, [user])
  

  return (
    <>
      <Container>
        <Flex direction={'column'}>
          <Box
            mt={16}
            p={24}
            px={32}
            bg="primary.9"
            style={{
              borderRadius: '0.25rem',
            }}
          >
            <Center>
              <Image w={122} h={36} src={getAppLogo()} />
            </Center>
          </Box>
        </Flex>
        <Flex direction={'column'} align={'center'} py={32}>
          <Title order={2} mb={32}>
            {message}
          </Title>
        </Flex>
      </Container>
    </>
  );
};
