import { usePermissionsStore } from "@/stores/usePermissionsStore";
import { usePlanStore } from "@/stores/usePlanStore";
import { useWorkspaceStore } from "@/stores/useWorkspaceStore";
import { checkPermission } from "@/utils/CommonUtility";
import { Flex } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { PlansModal } from "../Modals/PlansModal/PlansModal";
import { PausedPlanMessage } from "./PausedPlanMessage";

export const DisabledAccountMessage = () => {

  const navigate = useNavigate();
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [plan] = usePlanStore((state) => [state.plan]);

  const isTrialExpired = (defaultWorkspace?.organization?.status === 'trial_expired' || (plan?.type_of == 'trial' && plan?.trial_ends_at < new Date().toISOString()));
  const isAccountPaused = defaultWorkspace?.organization?.status === 'paused';

  const logout = () => {
    navigate('/logout')
  }

  const getTrialExpiredMessage = () => {
    return 'Your trial has expired. Please upgrade your plan to continue using Replug.';
  }

  const getSubscriptionMessage = () => {
    return `Your subscription has been ${defaultWorkspace?.organization?.status}. Please upgrade your plan to continue using Replug.`;
  }

  return (
    !checkPermission(defaultPermissions ?? [], ['edit_plan']) ? (
      <Flex
        justify={'center'}
        align={'center'}
        mt={30}
      >
        Your account has been {defaultWorkspace?.organization?.status
        }. Please contact the account owner to upgrade the plan.
      </Flex>
    ) : isTrialExpired ? (
      <Flex
        justify={'center'}
        align={'center'}
        mt={30}
      >
        {getTrialExpiredMessage()}
        <PlansModal
          disabledAccountMessage={getTrialExpiredMessage()}
          isOpen={true}
          primarySubscription={plan}
          showTrialPlans={false}
          currentPlanId={plan?.paddle_id}
          onClose={() => {
            logout();
          }}
          fetchSubscriptions={() => { }}
        />
      </Flex>
    ) : isAccountPaused ? (
      <Flex
        justify={'center'}
        align={'center'}
        mt={30}
      >
        <PausedPlanMessage />
      </Flex>
    ) : (
      <Flex
        justify={'center'}
        align={'center'}
        mt={30}
      >
        {getSubscriptionMessage()}
        <PlansModal
          disabledAccountMessage={getSubscriptionMessage()}
          isOpen={true}
          primarySubscription={plan}
          showTrialPlans={false}
          currentPlanId={plan?.paddle_id}
          onClose={() => {
            logout();
          }}
          fetchSubscriptions={() => window.location.reload()}
        />
      </Flex>
    )
  );
}
