import { DEFAULT_CAMPAIGN_VALUES } from '@/constants/Constants';
import { CampaignsService } from '@/services/CampaignsService';
import { useCampaignSetupStore } from '@/stores/useCampaignSetupStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import {
  ActionIcon,
  Box,
  Center,
  CloseButton,
  Flex,
  Grid,
  Group,
  Loader,
  ScrollArea,
  Stepper,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import { CallToActionCustomization } from './CallToActionCustomization';
import { CallToActionMessage } from './CallToActionMessage';
import { CallToActionType } from './CallToActionType';
import { CampaignPreview } from './CampaignPreview';
import { CampaignRetargetingPixels } from './CampaignRetargetingPixels';
import { CampaignSetupWidgets } from './CampaignSetupWidgets';
import { CampaignType } from './CampaignType';
import { useDocumentTitle } from '@mantine/hooks';
import { IntegrationServie } from '@/services/IntegrationService';
import { notifications } from '@mantine/notifications';
import { useCampaignsStore } from '@/stores/useCampaignsStore';

const schema = z.object({
  workspace_id: z.string(),
  name: z.string().min(2, { message: 'Campaign name should be at least 2 characters long' }),
});

export const CampaignsSetupPage = () => {
  useDocumentTitle('Campaign Setup');
  const { campaignId } = useParams();
  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(true);
  const [integrationsList, setIntegrationsList] = useState<any>([]);
  const nextStep = () => setActive((current) => (current < 5 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
  const [campaign, setCampaign] = useCampaignSetupStore((state) => [
    state.campaign,
    state.setCampaign,
  ]);
  const [ setPage ] = useCampaignsStore((state) => [ state.setPage]);
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const form = useForm({
    initialValues: { ...campaign, workspace_id: defaultWorkspace?._id },
    validate: {
      name: (value) => {
        if (!value) return 'Please enter campaign name';
        if (value && value.length < 2) {
          return 'Campaign name should be at least 2 characters long';
        }
        return false;
      },
      // @ts-ignore
      'call_to_action.message': (value, values) => {
        if (values.is_call_to_action_enabled && !value) {
          return 'Please enter call to action message';
        }
        return false;
      },
      // @ts-ignore
      'call_to_action.message_headline': (value, values) => {
        if (values.is_call_to_action_enabled && !value) {
          return 'Please enter call to action message headline';
        }
        return false;
      },
      // @ts-ignore
      'call_to_action.button_text': (value, values) => {
        if (values.is_call_to_action_enabled && !value) {
          return 'Please enter call to action button text';
        }
        return false;
      },
      // @ts-ignore
      'call_to_action.button_url': (value, values) => {
        if (values.is_call_to_action_enabled && values.call_to_action.type !== 'form' && !value) {
          return 'Please enter call to action button URL';
        }
        if (values.call_to_action.type == 'form' && !value && values.call_to_action.redirect_after_submission) {
          return 'Please enter call to action button URL';
        }
        return false;
      },
    },
  });

  const shouldShowCampaignType =
    form.values.is_custom_widget_enabled ||
    form.values.is_call_to_action_enabled ||
    form.values.is_retargeting_enabled;
  const steps = [
    ...(shouldShowCampaignType
      ? [
          {
            label: 'Campaign Type',
            condition: true,
            content: <CampaignType form={form} nextStep={nextStep} />,
          },
        ]
      : []),
    // {
    //   label: 'Campaign Type',
    //   condition: true,
    //   content: <CampaignType form={form} nextStep={nextStep} />,
    // },
    {
      label: 'Widgets',
      condition: form.values.is_custom_widget_enabled,
      content: <CampaignSetupWidgets form={form} nextStep={nextStep} />,
    },
    {
      label: 'Call-to-action',
      condition: form.values.is_call_to_action_enabled,
      content: (
        <CallToActionType form={form} integrationsList={integrationsList} nextStep={nextStep} />
      ),
    },
    {
      label: 'Message',
      condition: form.values.is_call_to_action_enabled,
      content: <CallToActionMessage form={form} nextStep={nextStep} />,
    },
    {
      label: 'Customization',
      condition: form.values.is_call_to_action_enabled,
      content: <CallToActionCustomization form={form} nextStep={nextStep} />,
    },
    {
      label: 'Retargeting Pixels',
      condition: form.values.is_retargeting_enabled,
      content: (
        <>
          <CampaignRetargetingPixels form={form} nextStep={nextStep} />
        </>
      ),
    },
  ];

  // Filter steps based on their condition to get only the active ones
  const filteredSteps = steps.filter((step) => step.condition);

  const navigate = useNavigate();

  const fetchCampaign = async () => {
    setLoading(true);
    await new CampaignsService()
      .getById(defaultWorkspace?._id || '', campaignId || '')
      .then((res) => {
        if (res.data.status) {
          setCampaign(res.data.data);
          form.setValues({ ...res.data.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const sortList = (list: any) => {
    return list.sort((a: any, b: any) => {
      if (a.is_connected && !b.is_connected) {
        return -1;
      }
      if (!a.is_connected && b.is_connected) {
        return 1;
      }
      return 0;
    });
  };

  /**
   * Get available integrations from the API
   * Uses the IntegrationService to fetch the available integrations
   */
  const getIntegrationsList = async () => {
    await new IntegrationServie()
      .getList(defaultWorkspace?._id || '')
      .then((res) => {
        if (res.data.status) {
          setIntegrationsList(sortList(res.data?.data || []));
        }
      })
      .catch((err) => {
        console.error(err);
        notifications.show({
          color: 'red',
          message: err.response?.data?.message || 'Error fetching integrations',
        });
      });
  };

  useEffect(() => {
    getIntegrationsList();
    if (campaignId) {
      fetchCampaign();
    } else {
      // @ts-ignore
      form.setValues({
        ...DEFAULT_CAMPAIGN_VALUES,
        workspace_id: defaultWorkspace?._id,
      });
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <Flex align={'center'} justify={'center'} pt={32}>
        <Center>
          <Loader size={'sm'} />
        </Center>
      </Flex>
    );
  }

  return (
    <Box>
      <Flex justify={'flex-end'} mr={16}>
        <Group style={{ width: '100%', padding: 16 }}>
          <Title order={4} ta="center" style={{ flex: 1 }}>
            Campaign Setup
          </Title>
          <ActionIcon
            color="gray"
            variant="outline"
            onClick={() => {
              setPage(1)
              navigate(`/campaigns/${defaultWorkspace?._id}`);
            }}
          >
            <CloseButton size={'md'} />
          </ActionIcon>
        </Group>
      </Flex>
      <Box
        px={32}
        py={8}
        w={1000}
        style={{
          margin: '0 auto',
        }}
      >
        <Stepper mb={16} size="xs" active={active} onStepClick={setActive}>
          {filteredSteps.map((step, index) => (
            <Stepper.Step
              styles={{
                stepIcon: {
                  color:
                    index <= active ? 'var(--mantine-color-blue)' : 'var(--mantine-color-gray-7)',
                  borderColor: index > active ? '1px solid var(--mantine-color-dimmed)' : undefined,
                },
              }}
              key={index}
              label={step.label}
            >
              <></>
            </Stepper.Step>
          ))}
        </Stepper>
      </Box>

      <Grid gutter={'lg'} px={32}>
        <Grid.Col span={4}>
          <Box
            h={'100%'}
            bg="white"
            style={{
              borderRadius: '0.5rem',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
            }}
          >
            <ScrollArea h={'75vh'}>
              {filteredSteps[active] ? (
                <>{filteredSteps[active].content}</>
              ) : (
                <CampaignType form={form} nextStep={nextStep} />
              )}
            </ScrollArea>
          </Box>
        </Grid.Col>
        <Grid.Col span={8}>
          <CampaignPreview form={form} />
        </Grid.Col>
      </Grid>
    </Box>
  );
};
