import React, { useState, useEffect } from 'react';
import { Text, Flex, Popover, TextInput, Button } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import {
  IconFlag2Filled,
  IconPhoneCall,
  IconBrowserPlus,
  IconBrandWindows,
  IconCurrentLocation,
  IconCalendar,
  IconCalendarWeek,
} from '@tabler/icons-react';
import { CustomMultiSelect } from './CustomMultiSelect';
import {
  ALL_COUNTRIES,
  DEVICES,
  BROWSERS,
  OPERATING_SYSTEMS,
  DAYS_OF_WEEK,
  DUMMY_IPS,
} from '@/constants/Constants';
import styles from './TrafficRouting.module.css'
import { IpAddressCreateTagsSelection } from './IpAddressCreateTagsSelection';
import { isSameDay } from 'date-fns';


export const TrafficRoutingCondition = ({
  form,
  ruleIndex,
  blockIndex,
  conditionIndex,
  type,
}: {
  form: any;
  ruleIndex: any;
  blockIndex: any;
  conditionIndex: any;
  type: any;
}) => {
  // Variables
  const [popoverOpened, setPopoverOpened] = useState(false);
  let data: any = [];
  const getNestedValue2 = (obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };
  const conditionPath = `rules.${ruleIndex}.blocks.${blockIndex}.conditions.${conditionIndex}.filterValue`;
  const conditionPathForError = `rules.${ruleIndex}.blocks.${blockIndex}.conditions.${conditionIndex}`;
  const conditionError = getNestedValue2(form.errors, conditionPathForError);
  const today = new Date();

  switch (type) {
    case 'Country':
      data = ALL_COUNTRIES;
      break;
    case 'Device':
      data = DEVICES;
      break;
    case 'Browser':
      data = BROWSERS;
      break;
    case 'OS':
      data = OPERATING_SYSTEMS;
      break;
    case 'DaysOfWeek':
      data = DAYS_OF_WEEK;
      break;
    case 'IP':
      data = DUMMY_IPS;
      break;
  }

  // Functions
  const handleDateChange = (date: any) => {
    setNestedValue(form.values, conditionPath, date);
    form.setFieldValue(conditionPath, date);
  };

  const setNestedValue = (obj: any, path: any, value: any) => {
    const keys = path.split('.');
    let current = obj;
    for (let i = 0; i < keys.length - 1; i++) {
      if (!current[keys[i]]) {
        current[keys[i]] = {};
      }
      current = current[keys[i]];
    }
    current[keys[keys.length - 1]] = value;
  };

  const getNestedValue = (obj: any, path: any) => {
    const keys = path.split('.');
    let current = obj;
    for (let i = 0; i < keys.length; i++) {
      if (!current[keys[i]]) {
        return undefined;
      }
      current = current[keys[i]];
    }
    return current;
  };

  const handleIpChange = (value: any) => {
    setNestedValue(form.values, conditionPath, value);
    form.setFieldValue(conditionPath, value);
  };

  const conditionValue = getNestedValue(form.values, conditionPath);

  return (
    <div>
      <Flex align="left" direction="row" wrap="nowrap" rowGap="xs" columnGap="xs" gap="xs">
        <Text size="md" fw={300} fz={14} my={5}>
          {conditionIndex > 0 ? 'And' : 'When'} {getIcon(type)} {type} is{' '}
        </Text>
        {
          type === 'Date' ? (
            <Popover
              opened={popoverOpened}
              onClose={() => setPopoverOpened(false)}
              position="bottom"
              withArrow
            >
              <Popover.Target>
                <TextInput
                  onClick={() => setPopoverOpened(true)}
                  placeholder="Pick a date"
                  // value={(conditionValue && conditionValue.length > 0) ? new Date(conditionValue[0]).toLocaleDateString() : ''}
                  value={
                    conditionValue && conditionValue.length > 0
                      ? conditionValue.length === 1
                        ? new Date(conditionValue[0]).toLocaleDateString()
                        : `${new Date(conditionValue[0]).toLocaleDateString()} and ${conditionValue.length - 1} more`
                      : ''
                  }
                  readOnly
                  styles={{
                    input: {
                      backgroundColor: 'transparent',
                      borderColor: 'transparent',
                      color: '#003aa9', // Change text color here
                      '&::placeholder': {
                        color: '#003aa9', // Change placeholder color here
                      },
                      '&:focus': {
                        borderColor: 'transparent',
                        boxShadow: 'none',
                      },
                    },
                  }}
                />
              </Popover.Target>
              <Popover.Dropdown>
                <DatePicker
                  //@ts-ignore
                  allowDeselect
                  type="multiple"
                  value={conditionValue ? conditionValue.map((date: string) => new Date(date)) : []}
                  onChange={(dates) => {
                    const selectedDates = dates.map((date) => {
                      const year = date.getFullYear();
                      const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two digits
                      const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
                      return `${year}-${month}-${day}`;
                    });
                    handleDateChange(selectedDates);
                  }}
                  renderDay={(date) => {
                    const isToday = isSameDay(date, today);
                    const isSelected = conditionValue && conditionValue.some((selectedDate: any) => isSameDay(new Date(selectedDate), date));
                    return (
                      <div
                        style={{
                          backgroundColor: isSelected ? '#003aa9' : isToday ? '#5b97f3' : undefined,
                          padding: '9px 14px',
                          borderRadius: '5px',
                          color: isSelected ? '#fff' : '#000',
                        }}
                      >
                        {date.getDate()}
                      </div>
                    );
                  }}
                />
                <Button mt="xs" fullWidth onClick={() => setPopoverOpened(false)}>
                  Close
                </Button>
              </Popover.Dropdown>
            </Popover>
          ) : type === 'IP' ? <IpAddressCreateTagsSelection form={form} path={conditionPath} onChange={handleIpChange} /> : (
            <CustomMultiSelect form={form} path={conditionPath} data={data} />
          )}
      </Flex>
      {conditionError && (
        <Text color="#F03E3E" size="sm">
          <small>{conditionError}</small>
        </Text>
      )}
    </div>
  );
};

const getIcon = (type: any) => {
  switch (type) {
    case 'Country':
      return <IconFlag2Filled size={13} style={{ color: '#003aa9' }} />;
    case 'Device':
      return <IconPhoneCall size={13} style={{ color: '#003aa9' }} />;
    case 'Browser':
      return <IconBrowserPlus size={13} style={{ color: '#003aa9' }} />;
    case 'OS':
      return <IconBrandWindows size={13} style={{ color: '#003aa9' }} />;
    case 'Date':
      return <IconCalendar size={13} style={{ color: '#003aa9' }} />;
    case 'DaysOfWeek':
      return <IconCalendarWeek size={13} style={{ color: '#003aa9' }} />;
    case 'IP':
      return <IconCurrentLocation size={13} style={{ color: '#003aa9' }} />;
    default:
      return null;
  }
};

