import { create } from 'zustand';

interface ILinksStore {
  search: string;
  setSearch: (search: string) => void;
  page: number;
  setPage: (page: number) => void;
  sort: string;
  setSort: (sort: string) => void;
  date: {
    label: string;
    value: string;
  };
  setDate: (date: { label: string; value: string }) => void;
  archive: boolean;
  setArchive: (archive: boolean) => void;
  tags: string[];
  setTags: (tags: string[]) => void;
  campaigns: string[];
  setCampaigns: (campaigns: string[]) => void;
  lastPage: number;
  setLastPage: (lastPage: number) => void;
  totalResults: number;
  setTotalResults: (totalResults: number) => void;
}

export const useLinksStore = create<ILinksStore>((set) => ({
  search: '',
  setSearch: (search) => {
    set({ search });
  },
  page: 1,
  setPage: (page) => {
    set({ page });
  },
  sort: 'created_desc',
  setSort: (sort) => {
    set({ sort });
  },
  date: {
    label: ' All Time',
    value: '',
  },
  setDate: (date) => {
    set({ date });
  },
  archive: false,
  setArchive: (archive) => {
    set({ archive });
  },
  tags: [],
  setTags: (tags) => {
    set({ tags });
  },
  campaigns: [],
  setCampaigns: (campaigns) => {
    set({ campaigns });
  },
  lastPage: 1,
  setLastPage: (lastPage) => {
    set({ lastPage });
  },
  totalResults: 20,
  setTotalResults: (totalResults) => {
    set({ totalResults });
  },
}));
