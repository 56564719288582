import React, { useState, useRef, useEffect } from 'react';
import { MultiSelectCreatable } from '@/components/MultiSelectCreatable/MultiSelectCreatable';
import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { Grid, Text, Box } from '@mantine/core';
import { useIpAddressStore } from '@/stores/useIpAddressStore';
import { notifications } from '@mantine/notifications';
import { Form } from '@mantine/form';

interface IpAddressCreateTagsSelectionProps {
  form: any;
  path: string;
  onChange: (value: string[]) => void;
}

export const IpAddressCreateTagsSelection: React.FC<IpAddressCreateTagsSelectionProps> = ({ form, path, onChange }) => {
  const [selectOpened, setSelectOpened] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [link, setLink] = useLinkSetupStore((state) => [state.link, state.setLink]);
  const [ipAddresses, createIpAddress] = useIpAddressStore((state) => [state.ipAddresses, state.createIpAddress]);

  const handleIpAddressCreate = async (value: string) => {
    const resp: any = await createIpAddress(defaultWorkspace?._id || '', {
      _id: null,
      ip_address: value,
      workspace_id: defaultWorkspace?._id || '',
    });
    if (resp.response.status === 422 || 400) {
      if (resp.response?.data?.errors['ip_address'] && resp.response.data.errors['ip_address'].length > 0) {
        resp.response.data.errors['ip_address'].forEach((error: string) => {
          notifications.show({
            color: 'red',
            message: error,
          });
        });
      }
    }
  };

  const handleValueChange = (value: string[]) => {
    onChange(value);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setSelectOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const selectedValues: any = form.getInputProps(path).value || [];
  const selectedCount: any = selectedValues.length;
  const displayValue: any =
    selectedCount > 1
      ? 
      //@ts-ignore
      `${ipAddresses.find((item: any) => item._id === selectedValues[0])?.ip_address} and ${selectedCount - 1} others` : selectedCount === 1
      ? 
      //@ts-ignore
      ipAddresses.find((item: any) => item._id === selectedValues[0])?.ip_address : 'Choose a value';

  return (
    <Grid.Col span={6}>
      <Box ref={dropdownRef}>
        {selectOpened ? (
          <MultiSelectCreatable
            placeholder="Search or create an IP address"
            dataItems={
              ipAddresses.length > 0
                ? ipAddresses.map((address: any) => ({
                    label: address?.ip_address,
                    value: address?._id,
                  }))
                : []
            }
            valueItems={selectedValues}
            onChange={handleValueChange}
            onCreate={(item) => {
              handleIpAddressCreate(item.value);
            }}
            size="sm"
          />
        ) : (
          <Box onClick={() => setSelectOpened(true)} style={{ cursor: 'pointer' }}>
            <Text size="sm" mt="6" color={selectedCount > 0 ? '#003aa9' : 'black'}>
              {displayValue}
            </Text>
          </Box>
        )}
      </Box>
    </Grid.Col>
  );
};
