import axios from './JWTService';

export class TagsService {
  getAll = (workspace_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`workspace/${workspace_id}/tag/get/all`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  bulkDelete = (workspace_id: string, tag_ids: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`workspace/${workspace_id}/tag/bulk_delete`, {
          data: {
            tag_ids,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  create = (workspace_id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`workspace/${workspace_id}/tag/create`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  update = (workspace_id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`workspace/${workspace_id}/tag/update/${data._id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
