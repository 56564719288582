import axios from './JWTService';

export class UtilsService {
  uploadImage = (formData: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post('/file/image/upload', formData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
