import { faPencil, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Table,
  Title,
  Text,
  ActionIcon,
  Space,
  Tooltip,
} from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { LoaderDataTable } from '@/components/Menus/LoaderDataTable/LoaderDataTable';
import { NoResultsDataTable } from '@/components/Menus/NoResultsDataTable/NoResultsDataTable';
import { DeveloperAppService } from '@/services/DeveloperAppsService';
import { DeveloperAppModal } from '@/components/Modals/DeveloperAppModal/DeveloperAppModal';
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { usePlanStore } from '@/stores/usePlanStore';
import { UpgradePlanMessage } from '@/components/Billing/UpgradePlanMessage';

export function DeveloeprAppsPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  useDocumentTitle('Developer Apps | ' + defaultWorkspace?.agency?.name);

  const [plan] = usePlanStore((state) => [state.plan]);

  const [apps, setApps] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const [opened, setOpened] = useState(false);

  const [editApp, setEditApp] = useState<any | null>(null);

  /**
   * Fetch developer apps from the API.
   * Uses the DeveloperAppService to fetch the apps.
   */
  const fetchApps = async () => {
    setLoading(true);
    await new DeveloperAppService()
      .get(defaultWorkspace?._id || '')
      .then((res) => {
        if (res.data) {
          console.log('developers apps', res.data);
          setApps(res.data);
          // setLastPage(res.data.data.last_page);
        }
      })
      .catch((res) => {
        console.log(res);
      });
    setLoading(false);
  };


  const handleConfirmDelete = (id: string) => {
    modals.openConfirmModal({
      title: <Title order={5}>Delete Developer App</Title>,
      children: (
        <>
          <Text size="sm">Are you sure? This process can not be undone.</Text>
        </>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => deleteApp(id),
    });
  };

  /**
   * Delete a developer app
   * Uses the DeveloperAppService to delete the app
   * @param id - The ID of the app to delete
   */
  const deleteApp = async (id: string) => {
    await new DeveloperAppService()
      .delete(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data?.status) {
          notifications.show({
            color: 'green',
            message: 'App deleted successfully',
          });
          fetchApps();
        }
      })
      .catch((res) => {
        console.log(res);
        notifications.show({
          color: 'red',
          message: 'Error deleting app',
        });
      });
  };

  // on URL parameters and component lifecycle
  useEffect(() => {
    fetchApps();
    // Dependency array
  }, []);
  return (
    <>
      <DeveloperAppModal
        isOpen={opened}
        onClose={() => {
          setEditApp(null);
          setOpened(false);
        }}
        app={editApp}
        reload={fetchApps}
      />

      {!plan?.limits?.api_access ? (
        <UpgradePlanMessage />
      ) : (
          <Box
            style={{
              backgroundColor: 'var(--mantine-color-white)',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
              height: '100%',
              flexGrow: 1,
            }}
            ml={16}
            mr={16}
          >
            <Grid p={16}>
              <Grid.Col span={5}>
                <Button size="xs" onClick={() => setOpened(true)}>New Application</Button>
              </Grid.Col>
              <Grid.Col span={2}>
                <Title pt={2} order={5}>
                  Your Applications
                </Title>
              </Grid.Col>
              <Grid.Col span={5}>
                <></>
              </Grid.Col>
            </Grid>
            <Divider color="gray.2" />
            <Table stickyHeader verticalSpacing={'md'}>
              <Table.Thead h={20} bg={'gray.1'} c="gray.7" fz="xs">
                <Table.Tr>
                  <Table.Th>Name</Table.Th>
                  <Table.Th>Client ID</Table.Th>
                  <Table.Th>Client Secret</Table.Th>
                  <Table.Th ta={'center'}>Actions</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {loading ? (
                  <>
                    <LoaderDataTable colSpan={4} />
                  </>
                ) : (
                  <>
                    <NoResultsDataTable
                      colSpan={4}
                      data={apps}
                      noResultsMessage="No developer app found"
                    />
                  {apps.map((item, index) => (
                    <Table.Tr key={index}>
                      <Table.Td>
                        <Text pt={4} fz={13}>
                          {item.name}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Text fz={13}>{item._id}</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text fz={13}>{item.secret}</Text>
                      </Table.Td>

                      <Table.Td ta="center">
                        <Flex justify={'center'}>
                          {/* <Tooltip label="Edit">
                          <ActionIcon variant="subtle" color="gray">
                            <FontAwesomeIcon icon={faPencil}
                              onClick={() => {
                                setEditApp(item);
                                setOpened(true);
                              }}
                            />
                          </ActionIcon>
                        </Tooltip> */}
                          <Space w={8} />
                          <Tooltip label="Delete">
                            <ActionIcon variant="subtle" color="red">
                              <FontAwesomeIcon icon={faTrash}
                                onClick={() => handleConfirmDelete(item._id)}
                              />
                            </ActionIcon>
                          </Tooltip>
                        </Flex>
                      </Table.Td>
                    </Table.Tr>
                  ))}
                  </>
                )}
              </Table.Tbody>
            </Table>
          </Box>
      )}
    </>
  );
}
