import { Box, Button, Modal, Text, Group, Input, Center, Select, MultiSelect, TextInput, CloseIcon } from '@mantine/core';
import { IconLink, IconCross } from '@tabler/icons-react';
import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { TrafficRoutingCondition } from './Components/TrafficRoutingCondition';
import { TrafficRoutingFilter } from './Components/TrafficRoutingFilter';
import { TrafficRoutingBlock } from './Components/TrafficRoutingBlock';
import { useState, useEffect } from 'react'
import { useForm, hasLength } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { DEFAULT_ROUTING_RULES } from '@/constants/Constants';

export const TrafficRoutingModal = ({ opened, onClose, ruleIndex }: { opened: boolean; onClose: () => void; ruleIndex: any }) => {

  //variables
  const [link, setLink, routingRules, setRoutingRules] = useLinkSetupStore((state) => [state.link, state.setLink, state.routingRules, state.setRoutingRules]);
  const [selectedValue, setSelectedValue] = useState(null);

  // Custom validation function
  const validateForm = (values: any) => {
    const errors: any = {};
    values.rules.forEach((rule: any, ruleIndex: any) => {
      if (!rule.destination) {
        if (!errors.rules) errors.rules = [];
        if (!errors.rules[ruleIndex]) errors.rules[ruleIndex] = {};
        errors.rules[ruleIndex].destination = 'Destination is required';
      }
      if (!errors.rules) errors.rules = [];
      if (!errors.rules[ruleIndex]) errors.rules[ruleIndex] = { blocks: [] };
      if (!errors.rules[ruleIndex].blocks) errors.rules[ruleIndex].blocks = [];
      rule.blocks.forEach((block: any, blockIndex: any) => {
        let blockErrors: any = {};
        if (block.conditions.length === 0) {
          blockErrors = 'Block cannot be empty';
        } else {
          const conditionTypes = new Set();
          block.conditions.forEach((condition: any, conditionIndex: any) => {
            if (!condition.filterValue || condition.filterValue.length === 0) {
              if (!blockErrors.conditions) blockErrors.conditions = [];
              blockErrors.conditions[conditionIndex] = 'Condition cannot be empty';
            }
            if (conditionTypes.has(condition.filterType)) {
              if (!blockErrors.conditions) blockErrors.conditions = [];
              blockErrors.conditions[conditionIndex] = 'Duplicate condition type';
            } else {
              conditionTypes.add(condition.filterType);
            }
          });
        }
        if (Object.keys(blockErrors).length > 0) {
          errors.rules[ruleIndex].blocks[blockIndex] = blockErrors;
        }
      });
      if (errors.rules[ruleIndex].blocks.length === 0) {
        delete errors.rules[ruleIndex].blocks;
      }
    });
    if (Object.keys(errors.rules).length === 0) {
      delete errors.rules;
    }
    if (Object.keys(errors.rules[0]).length === 0) {
      return;
    }
    return errors;
  };

  const form = useForm({
    initialValues: DEFAULT_ROUTING_RULES,
    validate: validateForm
  });

  //functions
  const addBlock = () => {
    form.insertListItem('rules.0.blocks', {
      conditions: [],
    });
  };

  const removeBlock = (ruleIndex: any, blockIndex: any) => {
    form.removeListItem(`rules.${ruleIndex}.blocks`, blockIndex);
  };

  const addCondition = (blockIndex: any, filterType: any) => {
    const conditions = form.values.rules[0].blocks[blockIndex].conditions;
    const existingCondition = conditions.find((condition: any) => condition.filterType === filterType);
    if (!existingCondition) {
      form.insertListItem(`rules.0.blocks.${blockIndex}.conditions`, {
        filterType,
        filterValue: [],
      });
    } else {
      showNotification({
        title: 'Error',
        message: 'This filter type already exists in the block',
        color: 'red',
      });
    }
  };

  const removeCondition = (ruleIndex: any, blockIndex: any, conditionIndex: any) => {
    form.removeListItem(`rules.${ruleIndex}.blocks.${blockIndex}.conditions`, conditionIndex);
    // Remove the corresponding error for the condition
    const errors : any = { ...form.errors };
    if (errors.rules && errors.rules[ruleIndex] && errors.rules[ruleIndex].blocks && errors.rules[ruleIndex].blocks[blockIndex] && errors.rules[ruleIndex].blocks[blockIndex].conditions) {
      errors.rules[ruleIndex].blocks[blockIndex].conditions.splice(conditionIndex, 1);
      // If there are no more errors in the conditions array, delete it
      if (errors.rules[ruleIndex].blocks[blockIndex].conditions.length === 0) {
        delete errors.rules[ruleIndex].blocks[blockIndex].conditions;
      }
      // If there are no more errors in the block, delete it
      if (Object.keys(errors.rules[ruleIndex].blocks[blockIndex]).length === 0) {
        delete errors.rules[ruleIndex].blocks[blockIndex];
      }
      // If there are no more errors in the rule, delete it
      if (Object.keys(errors.rules[ruleIndex]).length === 0) {
        delete errors.rules[ruleIndex];
      }
      // If there are no more rules with errors, delete the rules property
      if (errors.rules.length === 0) {
        delete errors.rules;
      }
    }
    // Update the form errors
    form.setErrors(errors);
  };

  const handleFilterTypeChange = (blockIndex: any, value: any) => {
    setSelectedValue(null);
    addCondition(blockIndex, value);
  };

  const handleSubmit = (values: any) => {
    const updatedRoutingAttributes = [...link.routing_attributes];
    if (ruleIndex === null) {
      updatedRoutingAttributes.push(values.rules);
    } else {
      updatedRoutingAttributes[ruleIndex] = values.rules;
    }
    setLink({ ...link, routing_attributes: updatedRoutingAttributes });
    form.reset();
    onClose();
    showNotification({
      title: 'Success',
      message: 'Routing rule saved successfully',
      color: 'blue',
    });
  };

  const onClosingModal = () => {
    form.reset();
    onClose();
  }

  useEffect(() => {
    if (ruleIndex !== null) {
      form.setValues({ rules: link.routing_attributes[ruleIndex] });
    } else {
      form.reset();
    }
  }, [ruleIndex, link.routing_attributes]);

  return (
    <Modal
      size={840}
      opened={opened}
      onClose={onClose}
      title={
        <Text fw={600} fz={14} mt="20px">
          {ruleIndex === null ? 'Create a routing rule' : 'Edit routing rule'}
        </Text>
      }
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Box p={20} style={{ minHeight: '75vh' }}>
          <Box
            style={{
              border: '1px solid #ccc',
              padding: '20px',
              borderRadius: '5px',
              backgroundColor: '#f9f9f9',
              marginBottom: '20px',
            }}
          >
            {form.values.rules.map((rule: any, ruleIndex: any) => (
              <div key={ruleIndex}>
                {rule.blocks.map((block: any, blockIndex: any) => (
                  <div>
                    <TrafficRoutingBlock
                      key={blockIndex}
                      form={form}
                      ruleIndex={ruleIndex}
                      blockIndex={blockIndex}
                      block={block}
                      selectedValue={selectedValue}
                      handleFilterTypeChange={handleFilterTypeChange}
                      removeCondition={removeCondition}
                      removeBlock={removeBlock}
                      blockCount={rule.blocks.length}
                    />
                    {blockIndex !== rule.blocks.length - 1 ? <Text size="md" fw={400} mt={12} ml={8} fz={14}> Otherwise </Text> : ''}
                  </div>
                ))}
                <Button
                  variant="subtle"
                  color="primary"
                  size="xs"
                  onClick={addBlock}
                  type="button"
                >
                  + Add another block
                </Button>
                <Group
                  style={{
                    padding: '10px',
                    borderRadius: '5px',
                    backgroundColor: '#f5f5f5',
                    marginBottom: '20px',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <Text size="md" fw={400} mt={12} ml={8} fz={14}>
                    Then
                  </Text>
                  <div style={{ flexGrow: 1, marginLeft: '10px' }}>
                    <TextInput
                      placeholder="Enter Rule Destination"
                      leftSection={<IconLink size={16} />}
                      styles={{
                        input: {
                          borderTop: 'none',
                          borderRight: 'none',
                          borderLeft: 'none',
                          borderBottom: '1px solid #ccc',
                          borderRadius: '0px',
                          backgroundColor: 'transparent',
                        }
                      }}
                      {...form.getInputProps(`rules.${ruleIndex}.destination`)}
                      //@ts-ignore
                      error={form.errors.rules?.[ruleIndex]?.destination}
                    />
                  </div>
                </Group>
              </div>
            ))}
          </Box>
        </Box>
        <Center mt={16}>
          <Button variant="filled" mr={10} type="submit">
            Save
          </Button>
          <Button variant="light" color="gray" onClick={onClosingModal}>
            Close
          </Button>
        </Center>
      </form>
    </Modal>
  );
};