import { DEFAULT_LINK_VALUES } from '@/constants/Constants';
import { LinksService } from '@/services/LinksService';
import { ILink } from '@/types/links';
import { notifications } from '@mantine/notifications';
import { create } from 'zustand';

interface ILinkSetupStore {
  link: ILink;
  setLink: (link: ILink | any) => void;
  linkSetupToggle: boolean;
  setLinkSetupToggle: (toggle: boolean) => void;
  createLoading: boolean;
  setCreateLoading: (loading: boolean) => void;
  openedModal: boolean;
  setOpenedModal: (opened: boolean) => void;
  createLink: (workspaceId: string, campaignId: string, url: string) => void;
  editShortenURL: (workspace_id: string, id: string, url: string) => any | boolean;
  resetLink: () => void;
  updateLink: () => void;
  isSlugEditable: boolean;
  setIsSlugEditable: (editable: boolean) => void;
  bulkCreateLinks: (workspace_id: string, campaignId: string, urls: string[], tags: string[]) => void;
  routingRules: string[] | any;
  setRoutingRules: (routingRules: string[]) => void;
}

export const useLinkSetupStore = create<ILinkSetupStore>((set, get) => ({
  //@ts-ignore
  link: DEFAULT_LINK_VALUES,
  setLink: (link) => {
    set({ link });
  },
  linkSetupToggle: false,
  setLinkSetupToggle: (toggle) => {
    set({ linkSetupToggle: toggle });
  },

  createLoading: false,
  setCreateLoading: (loading) => {
    set({ createLoading: loading });
  },
  openedModal: false,
  setOpenedModal: (opened) => {
    set({ openedModal: opened });
  },

  createLink: async (workspaceId: string, campaignId: string, url: string) => {
    set({ createLoading: true });
    const resp = await new LinksService()
      .create(workspaceId, 
      {
        url,
        campaign_id: campaignId,
      })
      .then((res) => {
        if (res.data.status) {
          set({
            link: res.data.data,
          });
        }
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });

    set({ createLoading: false });
    console.log(resp);
    return resp;
  },
  editShortenURL: async (workspace_id: string, id: string, short_url: string) => {
    const resp = await new LinksService()
      .editShortenURL( workspace_id,{
        id,
        short_url,
      })
      .then((res) => {
        if (res.data.status) {
          set({
            link: {
              ...get().link,
              short_url,
              short_url_lowercase: short_url.toLowerCase(),
            },
          });
          notifications.show({
            color: 'green',
            message: res.data.message,
          });
        }
        return true;
      })
      .catch((err) => {
        if (err.response.status === 400) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
        }
        if (err.response.status === 422) {
          if (err?.response?.data?.errors?.short_url?.length > 0) {
            err.response.data.errors.short_url.forEach((item: object, key : number) => {
              notifications.show({
                color: 'red',
                message: err.response.data.errors.short_url[key],
              });
            })
          } else {
            notifications.show({
              color: 'red',
              message: 'Something went wrong!',
            });
          }
        }
        return false;
      });
    return resp;
  },
  resetLink: () => {
    //@ts-ignore
    set({ link: DEFAULT_LINK_VALUES });
  },
  isSlugEditable: false,
  setIsSlugEditable: (editable) => {
    set({ isSlugEditable: editable });
  },
  bulkCreateLinks: async (workspace_id: string, campaignId: string, urls: string[], tags: string[]) => {
    const resp = await new LinksService()
      .bulkCreate(workspace_id, {
        urls: urls,
        tags: tags,
        campaign_id: campaignId,
      })
      .then((res) => {
        return true;
      })
      .catch((err) => {
        return !err?.response?.data?.status && err?.response?.data?.errors;
      });
    return resp;
  },
  routingRules: [],
  setRoutingRules: (routingRules) => {
    set({ routingRules });
  },
}));
