import { COLOR_PAELLETES } from '@/constants/Constants';
import { Stack, Text, Flex, Box, Group } from '@mantine/core';

export const ColorPaellete = ({
  handleSetColors,
  p,
}: {
  handleSetColors: (palette: any) => void;
  p: number;
}) => {
  return (
    <>
      <Stack p={p}>
        <Text size="sm" fw={500}>
          {' '}
          Choose a color paellete
        </Text>
        <Flex wrap={'wrap'}>
          {COLOR_PAELLETES.map((palette) => (
            <>
              <Group
                onClick={() => handleSetColors(palette)}
                mr={12}
                mb={12}
                p={4}
                style={{
                  borderRadius: 4,
                  cursor: 'pointer',

                  border: '1px solid var(--mantine-color-gray-3)',
                }}
              >
                <Flex direction={'row'}>
                  <Box
                    w={20}
                    h={20}
                    style={{
                      backgroundColor: palette.buttonBackgroundColor,
                    }}
                  >
                    <></>
                  </Box>
                  <Box
                    w={20}
                    h={20}
                    style={{
                      backgroundColor: palette.textColor,
                    }}
                  >
                    <></>
                  </Box>
                  <Box
                    w={20}
                    h={20}
                    style={{
                      textColor: palette.textColor,
                      buttonColor: palette.buttonBackgroundColor,
                      buttonTextColor: palette.buttonTextColor,
                      backgroundColor: palette.backgroundColor,
                    }}
                  >
                    <></>
                  </Box>
                </Flex>
              </Group>
            </>
          ))}
        </Flex>
      </Stack>
    </>
  );
};
