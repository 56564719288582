import { fileSchema, iconSchema } from '@/constants/validations';
import { UtilsService } from '@/services/UtilsService';
import { WhitelabelService } from '@/services/WhitelabelService';
import { ensureHttps } from '@/utils/LinkUtility';
import {
  Button,
  Center,
  Fieldset,
  FileInput,
  Flex,
  Grid,
  Group,
  Image,
  Paper,
  TextInput,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';

const DEFAULT_WHITELABEL_GENERAL_SETTINGS = {
  _id: '',
  logo: '',
  favicon: '',
  agency_name: '',
  privacy_policy: '',
  terms_of_service: '',
};

const schema = z.object({
  _id: z.string().nullable(),
  agency_name: z.string().min(2, { message: 'Name should have at least 2 letters' }),
  privacy_policy: z.string().url({ message: 'Invalid URL' }),
  terms_of_service: z.string().url({ message: 'Invalid URL' }),
  logo: z.string().min(1, { message: 'Logo is required' }),
  favicon: z.string().min(1, { message: 'Favicon is required' }),
});

export const WhitelabelGeneralSettings = ({ nextStep }: { nextStep: () => void }) => {
  const [logoFileError, setLogoFileError] = useState('');
  const [faviconFileError, setFaviconFileError] = useState('');
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  const form = useForm({
    initialValues: DEFAULT_WHITELABEL_GENERAL_SETTINGS,
    validate: zodResolver(schema),
  });
  const handleSaveGeneralSettings = async () => {
    new WhitelabelService()
      .store(defaultWorkspace?._id || '', form.values)
      .then((res) => {
        if (res.data.status) {
          notifications.show({
            color: 'green',
            message: 'General settings saved successfully.',
          });
          const { _id, agency_name, logo, favicon, privacy_policy, terms_of_service } =
            res.data.data;
          form.setValues({
            _id,
            agency_name,
            logo,
            favicon,
            privacy_policy,
            terms_of_service,
          });
          nextStep();
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
        }
      });
  };

  const handleLogoFileChange = async (file: File | null) => {
    if (file) {
      //@ts-ignore
      const res = await fileSchema.safeParseAsync(file).then(({ success, error }) => {
        if (!success && error) {
          setLogoFileError(error.errors.map((e) => e.message).join(', '));
          return false;
        }
        setLogoFileError('');
        return true;
      });

      if (res) {
        // Create FormData and append the file
        const formData = new FormData();
        formData.append('input_file', file);
        await new UtilsService()
          .uploadImage(formData)
          .then((response) => {
            console.log(response.data);
            if (response.data.status) {
              form.setFieldValue('logo', response.data.url);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  const handleFaviconChange = async (file: File | null) => {
    if (file) {
      //@ts-ignore
      const res = await iconSchema.safeParseAsync(file).then(({ success, error }) => {
        if (!success && error) {
          setFaviconFileError(error.errors.map((e) => e.message).join(', '));
          return false;
        }
        setFaviconFileError('');
        return true;
      });

      if (res) {
        // Create FormData and append the file
        const formData = new FormData();
        formData.append('input_file', file);
        await new UtilsService()
          .uploadImage(formData)
          .then((response) => {
            console.log(response.data);
            if (response.data.status) {
              form.setFieldValue('favicon', response.data.url);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const handleNextStep = () => {
    form.validate();
    if (!form.isValid()) {
      return '';
    }
    handleSaveGeneralSettings();
    return '';
  };

  const fetchWhitelabel = async () => {
    await new WhitelabelService()
      .get(defaultWorkspace?._id || '')
      .then((res) => {
        if (res.data.status) {
          const { _id, agency_name, logo, favicon, privacy_policy, terms_of_service } =
            res.data.data;
          form.setValues({
            _id,
            agency_name,
            logo,
            favicon,
            privacy_policy,
            terms_of_service,
          });
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchWhitelabel();
  }, []);

  return (
    <Paper py={16}>
      <Fieldset legend="General Settings">
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              label="Agency name"
              description="Your agency name will be used across application as branding."
              placeholder='e.g. "Your Agency Name"'
              required
              {...form.getInputProps('agency_name')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <></>
          </Grid.Col>
          <Grid.Col span={6}>
            <FileInput
              label="Logo"
              description="Your logo image should be in  (.png, .jpg, .jpeg) format. Recommended size is 250x150 px."
              placeholder="Upload your logo"
              onChange={handleLogoFileChange}
              required
              error={logoFileError || form.errors.logo}
            />
            {form.values.logo && (
              <Flex mt={'md'} direction={'column'}>
                <Center>
                  {' '}
                  <Image src={form.values.logo} w={250} h={150} fit="contain" />
                </Center>
                <Flex justify={'center'}>
                  <Button
                    color="red"
                    variant="subtle"
                    mt="md"
                    size={'sm'}
                    onClick={() => form.setFieldValue('logo', '')}
                  >
                    Remove Image
                  </Button>
                </Flex>
              </Flex>
            )}
          </Grid.Col>
          <Grid.Col span={6}>
            <FileInput
              label="Favicon"
              description="Your favicon image dimension should be 32x32 px."
              placeholder="Upload your favicon"
              onChange={handleFaviconChange}
              required
              error={faviconFileError || form.errors.favicon}
            />
            {form.values.favicon && (
              <Flex mt={'md'} direction="column">
                <Center>
                  <Image src={form.values.favicon} w={32} h={32} fit="contain" />
                </Center>
                <Flex justify={'center'}>
                  <Button
                    variant="subtle"
                    color="red"
                    mt="md"
                    size={'sm'}
                    onClick={() => form.setFieldValue('favicon', '')}
                  >
                    Remove Image
                  </Button>
                </Flex>
              </Flex>
            )}
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Privacy Policy URL"
              description="Privacy Policy URL will be used on the sign up page."
              placeholder='e.g. "https://youragency.com/privacy-policy"'
              value={form.values.privacy_policy}
              required
              onChange={(e) => {
                const value = e.currentTarget.value;
                form.setFieldValue('privacy_policy', ensureHttps(value));
              }}
              error={form.errors.privacy_policy}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Terms of service URL"
              description="Terms of service URL will be used on the sign up page."
              placeholder='e.g. "https://youragency.com/terms-and-conditions"'
              value={form.values.terms_of_service}
              required
              onChange={(e) => {
                const value = e.currentTarget.value;
                console.log(ensureHttps(value));
                form.setFieldValue('terms_of_service', ensureHttps(value));
              }}
              error={form.errors.terms_of_service}
            />
          </Grid.Col>
        </Grid>
      </Fieldset>
      <Group justify="center" mt="xl">
        <Button onClick={handleNextStep}>Next step</Button>
      </Group>
    </Paper>
  );
};
