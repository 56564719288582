import {
  Fieldset,
  Flex,
  Text,
  Title,
  Group,
  Button,
  Divider,
  ColorInput,
  Box,
} from '@mantine/core';
import classes from '../../BioLinks.module.css';

const BOX_SHADOW_VALUE = '0px 4px 6px rgba(0, 0, 0, 0.3)';
export const BioAppearance = ({ form }: { form: any }) => {
  const isVariantActive = (variant: string, radius: string, boxShadow: string = '') => {
    if (form.values.theme === undefined) {
      return false;
    }
    if (
      form.values.theme.button?.variant === variant &&
      form.values.theme.button.radius === radius &&
      form.values.theme.button.box_shadow === boxShadow
    ) {
      return true;
    }
    return false;
  };

  const handleChange = (variant: string, radius: string, boxShadow: string) => {
    form.setFieldValue('theme.button.variant', variant);
    form.setFieldValue('theme.button.radius', radius);
    form.setFieldValue('theme.button.box_shadow', boxShadow);
  };
  return (
    <>
      {/* {JSON.stringify(form.values.theme)} */}

      <Fieldset>
        <Flex>
          <Title ta="left" order={4} mb={'md'}>
            Select Button Style
          </Title>
        </Flex>
        <Group>
          <Box
            p={'sm'}
            onClick={() => {
              handleChange('filled', 'sm', '');
            }}
            className={isVariantActive('filled', 'sm', '') ? classes.buttonVariantActive : ''}
          >
            <Button w={200} variant="filled" color="black"></Button>
          </Box>
          <Box
            onClick={() => {
              handleChange('filled', 'md', '');
            }}
            p={'sm'}
            className={isVariantActive('filled', 'md', '') ? classes.buttonVariantActive : ''}
          >
            <Button w={200} variant="filled" radius={'md'} color="black"></Button>
          </Box>
          <Box
            onClick={() => {
              handleChange('filled', 'xl', '');
            }}
            p={'sm'}
            className={isVariantActive('filled', 'xl', '') ? classes.buttonVariantActive : ''}
          >
            <Button w={200} variant="filled" radius={'xl'} color="black"></Button>
          </Box>

          <Box
            p={'sm'}
            onClick={() => {
              handleChange('outline', 'sm', '');
            }}
            className={isVariantActive('outline', 'sm', '') ? classes.buttonVariantActive : ''}
          >
            <Button w={200} variant="outline" color="black"></Button>
          </Box>
          <Box
            p={'sm'}
            onClick={() => {
              handleChange('outline', 'md', '');
            }}
            className={isVariantActive('outline', 'md', '') ? classes.buttonVariantActive : ''}
          >
            <Button w={200} variant="outline" radius={'md'} color="black"></Button>
          </Box>
          <Box
            p={'sm'}
            onClick={() => {
              handleChange('outline', 'xl', '');
            }}
            className={isVariantActive('outline', 'xl', '') ? classes.buttonVariantActive : ''}
          >
            <Button w={200} variant="outline" radius={'xl'} color="black"></Button>
          </Box>

          <Box
            p={'sm'}
            onClick={() => {
              handleChange('outline', 'sm', BOX_SHADOW_VALUE);
            }}
            className={
              isVariantActive('outline', 'sm', BOX_SHADOW_VALUE) ? classes.buttonVariantActive : ''
            }
          >
            <Button
              style={{
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
              }}
              w={200}
              variant="outline"
              color="gray.4"
            ></Button>
          </Box>
          <Box
            p={'sm'}
            onClick={() => {
              handleChange('outline', 'md', BOX_SHADOW_VALUE);
            }}
            className={
              isVariantActive('outline', 'md', BOX_SHADOW_VALUE) ? classes.buttonVariantActive : ''
            }
          >
            <Button
              style={{
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
              }}
              w={200}
              variant="outline"
              radius={'md'}
              color="gray.4"
            ></Button>
          </Box>
          <Box
            p={'sm'}
            onClick={() => {
              handleChange('outline', 'xl', BOX_SHADOW_VALUE);
            }}
            className={
              isVariantActive('outline', 'xl', BOX_SHADOW_VALUE) ? classes.buttonVariantActive : ''
            }
          >
            <Button
              style={{
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
              }}
              w={200}
              variant="outline"
              radius={'xl'}
              color="gray.4"
            ></Button>
          </Box>
        </Group>
      </Fieldset>

      <Divider my={'md'} />
      <Fieldset>
        <Title ta="left" order={4} mb={'md'}>
          Customize Colors
        </Title>
        <Group>
          <ColorInput
            w={200}
            label="Background"
            mr={8}
            {...form.getInputProps('theme.background_color')}
          />
          <ColorInput
            w={200}
            label="Button background"
            mr={8}
            {...form.getInputProps('theme.button.background_color')}
          />
          <ColorInput
            w={200}
            label="Button text"
            mr={8}
            {...form.getInputProps('theme.button.color')}
          />
          <ColorInput w={200} label="Text Color" mr={8} {...form.getInputProps('theme.color')} />
          {/* <ColorInput w={200} label="Page title" mr={8} />
          <ColorInput w={200} label="Page subtitle" /> */}
        </Group>
      </Fieldset>
    </>
  );
};
