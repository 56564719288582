import { IpAddressService } from '@/services/IpAddressService';
import { create } from 'zustand';

interface IIpAddressStore {
  ipAddresses: string[];
  setIpAddress: (tags: string[]) => void;
  selectedIpAddresses: string[];
  setSelectedIpAddresses: (tags: string[]) => void;
  fetchIpAddresses: (workspace_id: string) => void;
  createIpAddress: (workspaceId: string, payload: { _id: null | string; ip_address: string; workspace_id: string }) => void;
}

export const useIpAddressStore = create<IIpAddressStore>((set, get) => ({
  ipAddresses: [],
  setIpAddress: (ipAddresses) => {
    set({ ipAddresses });
  },
  selectedIpAddresses: [],
  setSelectedIpAddresses: (ipAddress) => {
    set({ selectedIpAddresses: ipAddress });
  },
  fetchIpAddresses: (workspace_id) => {
    new IpAddressService()
      .getAll(workspace_id)
      .then((res) => {
        if (res.data.status) {
          set({ ipAddresses: res.data.data });
        } else {
          set({ ipAddresses: [] });
        }
      })
      .catch((err) => {
        console.log(err);
        set({ ipAddresses: [] });
      });
  },
  createIpAddress: async (workspaceId, payload) => {
    const resp = await new IpAddressService()
      .create(workspaceId, payload)
      .then((res) => {
        console.log('createIpAddress', res.data);
        if (res.data.status) {
          const prevTags = get().ipAddresses;
          prevTags.push(res.data.data);
          set({ ipAddresses: prevTags });
          return res.data.data;
        }
        return false;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
    return resp;
  },
}));
