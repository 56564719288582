import { ICampaignAgreement } from '@/types/campaigns';
import { Button } from '@mantine/core';
import { CTAAgreementBox } from './CTAAgreementBox';

export const CTAAgreementListing = ({ form }: { form: any }) => {
  const handleAddAgreement = () => {
    const agreements = form.values.call_to_action?.agreements;
    const totalAgreements = agreements.length;
    agreements.push({
      id: Math.random(),
      text: '<p>I accept the <a href="">Terms</a> and the <a href="">Privacy Policy</a>.</p>',
      toggle: true,
      name: `Agreement Checkbox ${totalAgreements}`,
      optional_field: false,
      editName: false,
    });
    form.setFieldValue('call_to_action.agreements', agreements);
  };
  if (form.values.call_to_action.type !== 'form') {
    return <></>;
  }
  return (
    <>
      {form.values.call_to_action.agreements.map((agreement: ICampaignAgreement, index: number) => (
        <CTAAgreementBox agreement={agreement} form={form} index={index} />
      ))}
      <Button variant="outline" color="primary" fullWidth onClick={handleAddAgreement}>
        Add Checkbox
      </Button>
    </>
  );
};
