import { useEffect, useState } from 'react';
import { Box, Button, Center, Divider, Flex, Group, Pagination, Table, Text } from '@mantine/core';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { PlanService } from '@/services/PlanService';
import getSymbolFromCurrency from 'currency-symbol-map';

export function PaymentHistory() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([] as any[]);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const limit = 10;

  const fetchInvoices = (workspace_id: string, organization_id: string) => {
    setLoading(true);
    new PlanService().getInvoices(workspace_id, organization_id, page, limit).then((response) => {
      if (response?.data?.invoices) {
        setInvoices(response.data.invoices)
        setTotalResults(response.data?.total || 0)
      }
    }).catch((error) => {
      console.error("Error fetching invoices", error)
    }).finally(() => {
      setLoading(false);
    });

  }

  useEffect(() => {
    if (defaultWorkspace?.organization_id) {
      fetchInvoices(defaultWorkspace._id, defaultWorkspace.organization_id);
    }
  }, [defaultWorkspace?._id, page]);

  return (
    <>
      <Flex h={70} p={16} align={'center'}>
        <Text size="sm" fw={700}>
          Payment History
        </Text>
      </Flex>
      <Divider color={'gray.3'} />
      {loading ? (
        <Flex h={200} align={'center'} justify={'center'}>
          <Text size="sm">Loading...</Text>
        </Flex>
      ) : (
        <>
          {invoices?.length === 0 && (
            <Flex h={200} align={'center'} justify={'center'}>
              <Text size="sm">No payment history found</Text>
            </Flex>
          )}
          {invoices?.length > 0 && (
            <Table stickyHeader verticalSpacing={'md'}>
              <Table.Thead h={20} bg={'gray.1'} c="gray.7" fz="xs">
                <Table.Tr>
                  <Table.Th>Order ID</Table.Th>
                    <Table.Th>Plan/Product</Table.Th>
                    <Table.Th>Amount</Table.Th>
                    <Table.Th>Date</Table.Th>
                    <Table.Th ta={'center'}>Actions</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {invoices.map((item, index) => (
                    <>
                      <Table.Tr key={index}>
                        <Table.Td>
                          <Text pt={4} fz={13}>
                          {item.order_id}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Text fz={13}>{item.plan_name || item.product_name}</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text fw="bold" fz={13}>{getSymbolFromCurrency(item.currency) + item.amount}</Text>
                      </Table.Td>
                      <Table.Td>
                        <Text fz={13}>{item.date}</Text>
                      </Table.Td>
                      <Table.Td>
                        <Center>
                          <Button
                            size="xs"
                            variant={'subtle'}
                            color="primary.3"
                            onClick={() => window.open(item.receipt_url)}
                          >
                            View Reciept
                          </Button>
                        </Center>
                      </Table.Td>
                    </Table.Tr>
                  </>
                ))}
                </Table.Tbody>
              </Table>
          )}
        </>
      )}
      <Box mx={'sm'} mb={'xl'} pb={'lg'} mt={'sm'}>
        <Group justify="space-between">
          <Pagination value={page} onChange={setPage} size="xs" total={Math.ceil(totalResults / limit)} />
        </Group>
      </Box>
      <Divider c={'gray.3'} />
    </>
  );
}
