import { useTagsStore } from '@/stores/useTagsStore';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, Divider, Flex, Indicator, Popover, Text } from '@mantine/core';
import { useState } from 'react';

export function TagsMenu() {
  const [opened, setOpened] = useState(false);
  const [tags, selectedTags, setSelectedTags] = useTagsStore((state) => [
    state.tags,
    state.selectedTags,
    state.setSelectedTags,
  ]);
  const getMutatedTags = () => {
    const filteredTags = tags.map((tag: any) => {
      return {
        label: tag.name,
        value: tag._id,
      };
    });
    return filteredTags;
  };

  const handleCheckboxChange = (tagId: string) => {
    const find = selectedTags.find((tag) => tag === tagId);
    if (find) {
      setSelectedTags(selectedTags.filter((tag) => tag !== tagId));
    } else {
      setSelectedTags([...selectedTags, tagId]);
    }
  };

  return (
    <>
      <Popover withArrow shadow="sm" opened={opened} onChange={setOpened}>
        <Popover.Target>
          <Indicator
            color="primary.4"
            inline
            disabled={selectedTags.length === 0}
            size={'sm'}
            label={selectedTags.length}
          >
            <Button
              variant="outline"
              color="gray"
              size="xs"
              onClick={() => setOpened(!opened)}
              rightSection={<FontAwesomeIcon icon={faChevronDown} />}
            >
              Filter by tags
            </Button>
          </Indicator>
        </Popover.Target>
        <Popover.Dropdown
          w={280}
          style={{
            maxHeight: '300px',
            overflow: 'auto',
          }}
        >
          {getMutatedTags().length > 0 && (
            <>
              <Checkbox
                size="xs"
                pb={12}
                label="Select All"
                checked={selectedTags.length === tags.length}
                onChange={() => {
                  if (selectedTags.length === tags.length) {
                    setSelectedTags([]);
                  } else {
                    setSelectedTags(tags.map((tag: any) => tag._id));
                  }
                }}
              />
              <Divider color="gray.2" pb={8} />
            </>
          )}

          {getMutatedTags().length === 0 && (
            <>
              <Text ta="center" py={8} size="sm">
                You do not have any tags created.
              </Text>
            </>
          )}
          {getMutatedTags().map((item) => (
            <Flex
              key={item.value}
              align={'center'} // Make sure to have a unique key for each item
              fz={'xs'}
              style={{
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleCheckboxChange(item.value);
              }}
              py={6}
            >
              <Checkbox
                size={'xs'}
                label={item.label}
                checked={selectedTags.includes(item.value)}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation(); // Prevent event from closing the dropdown
                  handleCheckboxChange(item.value);
                }}
              />
            </Flex>
          ))}
        </Popover.Dropdown>
      </Popover>
    </>
  );
}
