import { PixelService } from '@/services/PixelService';
import { UTMService } from '@/services/UTMService';
import { usePixelsStore } from '@/stores/usePixelsStore';
import { useUTMStore } from '@/stores/useUTMStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { IPixel } from '@/types/pixels';
import { IUTM } from '@/types/utms';
import {
  Anchor,
  Box,
  Button,
  Flex,
  Modal,
  Radio,
  Select,
  Text,
  TextInput,
  Textarea,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { z } from 'zod';

const schema = z.object({
  _id: z.string().nullable(),
  key: z.string().nullable(), // Used for the Google Pixel.
  name: z.string().nullable(),
  pixel: z.string().min(1, { message: 'Pixel should have at least 1 letter' }),
  type: z.string().min(1, { message: 'Type should have at least 1 letter' }),
  workspace_id: z.string().nullable(),
});

export const RetargetingPixelModal = ({ id = null }: { id?: string | null }) => {
  const [opened, setOpened] = usePixelsStore((state) => [state.setupModal, state.setSetupModal]);
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [pixels, setPixels] = usePixelsStore((state) => [state.pixels, state.setPixels]);
  const [storeLoading, setStoreLoading] = useState(false);
  const form = useForm({
    initialValues: {
      _id: id,
      name: '',
      pixel: '',
      type: '',
      key: '',
      workspace_id: defaultWorkspace?._id,
    },
    validate: zodResolver(schema),
  });

  const handleFormSubmit = async (formValues: any) => {
    console.log(formValues);

    // Validate the key if the type is Google.
    if (formValues.type === 'google' && !formValues.key) {
      notifications.show({
        color: 'red',
        message: 'Please select a Google Pixel Type',
      });
      return;
    }

    // set the key to null in case if the type is not Google.
    if (formValues.type !== 'google' && formValues.key) {
      formValues.key = '';
    }

    setStoreLoading(true);

    // Update case
    if (id) {
      await new PixelService()
        .update(defaultWorkspace?._id || '', formValues)
        .then((res) => {
          if (res.data.status) {
            setOpened(false);
            const newList = pixels.map((item) => {
              if (item._id === id) {
                return res.data.data;
              }
              return item;
            });
            setPixels(newList);
            notifications.show({
              color: 'green',
              message: 'Pixel has been updated.',
            });
            form.reset();
            setOpened(false);
          } else {
            notifications.show({
              color: 'red',
              message: res.data.message,
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 400 ||  err.response.status ===  422) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
        });
    } else {
      // Create new pixel
      await new PixelService()
        .create(defaultWorkspace?._id || '', formValues)
        .then((res) => {
          if (res.data.status) {
            setOpened(false);

            setPixels([...pixels, res.data.data]);
            notifications.show({
              color: 'green',
              message: 'Pixel has been created.',
            });
            form.reset();
            setOpened(false);
          } else {
            notifications.show({
              color: 'red',
              message: res.data.message,
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 400 ||  err.response.status ===  422) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
        });
    }
    setStoreLoading(false);
  };

  useEffect(() => {
    console.log('pixel id', id);
    if (id) {
      const item = pixels.find((pixel: IPixel) => pixel._id === id);
      if (item) {
        form.setValues({
          ...item,
          workspace_id: defaultWorkspace?._id,
        });
      }
    } else {
      console.log('resetting form');
      form.reset();
      form.setFieldValue('workspace_id', defaultWorkspace?._id);
    }
  }, [id]);

  const handleInfoTooltip = (type: string) => {
    let message = '';
    let link = '';
    switch (type) {
      case 'facebook':
        message = 'How to find Facebook Pixel ID?';
        link = 'https://docs.replug.io/article/147-how-to-find-facebook-pixel-id';
        break;
      case 'google':
        message = 'How to find Google Ads Pixel ID?';
        link = 'https://docs.replug.io/article/177-how-to-find-my-google-adwords-pixel-id';
        break;
      case 'twitter':
        message = 'How to find Twitter Pixel ID?';
        link = 'https://docs.replug.io/article/148-how-to-find-my-twitter-pixel-id';
        break;
      case 'pinterest':
        message = 'How to find Pinterest Pixel ID?';
        link = 'https://docs.replug.io/article/176-how-to-find-my-pinterest-pixel-id';
        break;
      case 'linkedin':
        message = 'How to find LinkedIn Pixel ID?';
        link = 'https://docs.replug.io/article/174-how-to-find-my-linkedin-pixel-id';
        break;
      case 'quora':
        message = 'How to find Quora Pixel ID?';
        link = 'https://docs.replug.io/article/175-how-to-find-my-quora-pixel-id';
        break;
      default:
        message = '';
        link = '';
    }
    if (!message) return <></>;
    return (
      <>
        <Flex justify={'flex-end'}>
          <Anchor size="sm" fw={500} href={link} target="_blank">
            {message}
          </Anchor>
        </Flex>
      </>
    );
  };
  return (
    <>
      <Modal
        size={'700'}
        opened={opened}
        onClose={() => setOpened(!opened)}
        title={<Title order={5}>{id ? 'Update' : 'Create'} Retargeting Pixel</Title>}
      >
        <Box p={16}>
          {/* <Flex align={'center'} justify={'center'}>
            <FontAwesomeIcon
              style={{
                color: theme.colors.primary[9],
                marginBottom: 16,
              }}
              icon={faLocationPin}
              size={'2x'}
            />
          </Flex> */}

          <Box mx={32}>
            <form onSubmit={form.onSubmit(handleFormSubmit)}>
              <TextInput
                mb={12}
                label={'Name'}
                variant="filled"
                placeholder="e.g. Facebook Pixel"
                {...form.getInputProps('name')}
              />
              <Select
                placeholder="Select Pixel Type"
                data={[
                  {
                    label: 'Meta (Facebook) Pixel',
                    value: 'facebook',
                  },
                  {
                    label: 'Google',
                    value: 'google',
                  },

                  {
                    label: 'Twitter Pixel',
                    value: 'twitter',
                  },
                  {
                    label: 'LinkedIn Pixel',
                    value: 'linkedin',
                  },
                  {
                    label: 'Pinterest Pixel',
                    value: 'pinterest',
                  },
                  {
                    label: 'TikTok Pixel',
                    value: 'tiktok',
                  },
                  {
                    label: 'Quora Pixel',
                    value: 'quora',
                  },
                  {
                    label: 'Custom',
                    value: 'custom',
                  },
                ]}
                mb={12}
                label={'Network/Type'}
                variant="filled"
                {...form.getInputProps('type')}
              />

              {form.values.type === 'google' && (
                <>
                  <Text fz={13} mb={12} fw={500}>
                    Google Pixel Type
                  </Text>
                  <Radio.Group
                    mb={8}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                    {...form.getInputProps('key')}
                  >
                    <Flex justify={'space-evenly'}>
                      <Radio mr={12} size="xs" value="AW" label="Google Adwords" />
                      <Radio mr={12} size="xs" value="G" label="GA4" />
                      <Radio mr={12} size="xs" value="UA" label="Universal Analytics" />
                      <Radio size="xs" value="GTM" label="Google Tag Manager" />
                    </Flex>
                  </Radio.Group>
                </>
              )}
              {form.values.type === 'custom' ? (
                <>
                  <Textarea
                    {...form.getInputProps('pixel')}
                    label={'Custom Pixel'}
                    variant="filled"
                    placeholder="e.g. <script>...</script>"
                  />
                </>
              ) : (
                <>
                  <TextInput
                    mb={12}
                    label={'Pixel ID'}
                    variant="filled"
                    placeholder="e.g. 123456789"
                    leftSection={
                      form.values.type === 'google' ? (
                        <>
                          <Text ml={24} mr={24} fz={13} fw={500} c={'primary'}>
                            {form.values.key}-
                          </Text>
                        </>
                      ) : undefined
                    }
                    {...form.getInputProps('pixel')}
                  />
                </>
              )}
              {handleInfoTooltip(form.values.type)}

              <Flex mt={32} align={'center'} justify={'center'}>
                <Button disabled={storeLoading} loading={storeLoading} type="submit" mr={12}>
                  {form.values._id ? 'Update' : 'Create'}
                </Button>
                <Button onClick={() => setOpened(false)} variant="outline" color="gray">
                  Cancel
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
