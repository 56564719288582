import axios from './JWTService';

const prefix = `/integrations`;
export class IntegrationServie {

  getList = (workspace_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`workspace/${workspace_id}/integrations/list`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  getConnected = (workspace_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`workspace/${workspace_id}/integrations/connected`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  connect = (workspace_id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`workspace/${workspace_id}/integrations/connect`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  disconnect = (workspace_id: string, integration_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`workspace/${workspace_id}/integrations/${integration_id}/disconnect`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  getIntegrations = (workspace_id: string, name: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`workspace/${workspace_id}/integrations/${name}/connected`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * Get the lists for the given workspace
   * @param workspace_id
   * @param integration_id 
   * @returns 
   */
  getLists = (workspace_id: string, integration_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`workspace/${workspace_id}/integrations/${integration_id}/lists`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  }

}