import { LoaderDataTable } from '@/components/Menus/LoaderDataTable/LoaderDataTable';
import { NoResultsDataTable } from '@/components/Menus/NoResultsDataTable/NoResultsDataTable';
import { ExcludeTrafficService } from '@/services/ExcludeTrafficService';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { getRelativeTime } from '@/utils/DateUtility';
import { faCircleExclamation, faPencil, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Table,
  Title,
  Text,
  ActionIcon,
  Space,
  Tooltip,
  Popover,
  Group,
  TextInput,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useDocumentTitle } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isUserCollaborator } from '@/utils/CommonUtility';
import { useUserStore } from '@/stores/useUserStore';

const schema = z.object({
  _id: z.string().nullable(),
  workspace_id: z.string(),
  ip_address: z.string().ip().min(7, { message: 'Invalid IP Address' }),
});

export function ExcludeTrafficPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  useDocumentTitle('Exclude Traffic | ' + defaultWorkspace?.agency?.name);

  const [list, setList] = useState([]);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(true);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [user] = useUserStore((state) => [state.user]);
  const [editMode, setEditMode] = useState(false);

  const form = useForm({
    initialValues: {
      _id: null,
      workspace_id: defaultWorkspace?._id,
      ip_address: '',
    },
    validate: zodResolver(schema),
  });

  const fetchList = async () => {
    setLoading(true);
    await new ExcludeTrafficService()
      .get(defaultWorkspace?._id || '')
      .then((res) => {
        if (res.data.status) {
          setList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const handleCreate = async (formValues: any) => {
    await new ExcludeTrafficService()
      .store(defaultWorkspace?._id || '', formValues)
      .then((res) => {
        if (res.data.status) {
          form.reset();
          setOpened(false);
          fetchList();
        }
      })
      .catch((err) => {
        if (err.response.status === 400 ||  err.response.status ===  422) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
        }
        console.log(err);
      });
  };

  const handleUpdate = async (formValues : any) => {
    await new ExcludeTrafficService()
      .update(defaultWorkspace?._id || '', formValues?._id, formValues)
      .then((res) => {
        if (res.data.status) {
          form.reset();
          setOpened(false);
          setEditMode(false);
          fetchList();
          notifications.show({
            color: 'green',
            message: 'Your IP address has been updated successfully.',
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 400 ||  err.response.status ===  422) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
        }
        console.log(err);
      });
  };

  const handleDelete = async (id : any) => {
    await new ExcludeTrafficService()
      .delete(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          const newList = list.filter((item: any) => item._id !== id);
          setList(newList);
          notifications.show({
            color: 'green',
            message: 'Your IP address has been deleted.',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const confirmDeleteIP = (id : any) => {
    modals.openConfirmModal({
      title: <Title order={5}>Delete IP address</Title>,
      children: (
        <>
          <Text size="sm">Are you sure? This process can not be undone.</Text>
        </>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleDelete(id),
    });
  };

  const handleEdit = (item : any) => {
    form.setValues({
      _id: item._id,
      workspace_id: item.workspace_id,
      ip_address: item.ip_address,
    });
    setEditMode(true);
    setOpened(true);
  };

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <>
      <Box
        style={{
          backgroundColor: 'var(--mantine-color-white)',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          height: '100%',
          flexGrow: 1,
        }}
        ml={16}
        mr={16}
      >
        <Grid p={16}>
          <Grid.Col span={5}>
            <Popover
              shadow="sm"
              opened={opened}
              onClose={() => setOpened(false)}
              position="bottom"
              withArrow
            >
              {defaultPermissions && checkPermission(defaultPermissions, ['add_exclude_traffic']) && (
                <Popover.Target>
                  <Button size="xs" onClick={() => setOpened((o) => !o)}>
                    {editMode ? 'Edit IP Address' : 'Add IP Address'}
                  </Button>
                </Popover.Target>
              )}
              <Popover.Dropdown>
                <form
                  onSubmit={form.onSubmit((values) =>
                    editMode ? handleUpdate(values) : handleCreate(values)
                  )}
                >
                  <Group>
                    <TextInput
                      size="xs"
                      {...form.getInputProps('ip_address')}
                      placeholder="IP Address"
                    />
                    <Button loading={loading} disabled={loading} size="xs" type="submit">
                      {editMode ? 'Update' : 'Create'}
                    </Button>
                  </Group>
                </form>
              </Popover.Dropdown>
            </Popover>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex align={'center'}>
              <Title pt={2} order={5} mr={8}>
                Exclude Traffic
              </Title>
              <Tooltip
                multiline
                w={450}
                fz="13"
                label="The visits coming from blocked IPs are simply excluded from the statistics, our engine continues to serve tracking links and their options also to visits coming from blocked IPs, with the only difference that nothing will be displayed on the statistics."
              >
                <FontAwesomeIcon
                  style={{
                    color: 'var(--mantine-color-gray-6)',
                  }}
                  icon={faCircleExclamation}
                />
              </Tooltip>
            </Flex>
          </Grid.Col>
          <Grid.Col span={5}>
            <></>
          </Grid.Col>
        </Grid>
        <Divider color="gray.2" />
        <Table stickyHeader verticalSpacing={'md'}>
          <Table.Thead h={20} bg={'gray.1'} c="gray.7" fz="xs">
            <Table.Tr>
              <Table.Th>IP Address</Table.Th>
              <Table.Th>Created at</Table.Th>
              {defaultPermissions && checkPermission(defaultPermissions, ['edit_exclude_traffic', 'delete_exclude_traffic']) && (
                <Table.Th ta={'center'}>Actions</Table.Th>
              )}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {loading ? (
              <LoaderDataTable colSpan={3} />
            ) : (
              <>
                {list.length === 0 ? (
                  <NoResultsDataTable
                    colSpan={3}
                    noResultsMessage={'You have not added any IP address for exclusion.'}
                    data={list}
                  />
                ) : (
                  list.map((item : any, index : any) => (
                    <Table.Tr key={index}>
                      <Table.Td>
                        <Text pt={4} fz={13}>
                          {item.ip_address}
                        </Text>
                      </Table.Td>
                      <Table.Td>
                        <Text fz={13}>{getRelativeTime(item.created_at)}</Text>
                      </Table.Td>
                      {defaultPermissions &&
                        checkPermission(defaultPermissions, ['edit_exclude_traffic', 'delete_exclude_traffic']) &&
                        isUserCollaborator(user, defaultWorkspace?._id, item, 'exclude_traffic') && (
                          <Table.Td ta="center">
                            <Flex justify={'center'}>
                              <Tooltip label="Edit">
                                <ActionIcon
                                  variant="subtle"
                                  color="gray"
                                  onClick={() => handleEdit(item)}
                                >
                                  <FontAwesomeIcon icon={faPencil} />
                                </ActionIcon>
                              </Tooltip>
                              <Space w={8} />
                              {defaultPermissions && checkPermission(defaultPermissions, ['delete_exclude_traffic']) && (
                                <Tooltip label="Delete" onClick={() => confirmDeleteIP(item._id)}>
                                  <ActionIcon variant="subtle" color="red">
                                    <FontAwesomeIcon icon={faTrash} />
                                  </ActionIcon>
                                </Tooltip>
                              )}
                            </Flex>
                          </Table.Td>
                        )}
                    </Table.Tr>
                  ))
                )}
              </>
            )}
          </Table.Tbody>
        </Table>
      </Box>
    </>
  );
}
