import { Flex, List, Text } from '@mantine/core';
import classes from '../../BioLinks.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBlock,
  faGear,
  faLink,
  faPencil,
  faPlay,
  faRss,
  faShareNodes,
  faText,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { BIO_SECTION_NAMES } from '@replug/utils';

export const BioNavMenu = ({
  filteredSection,
  handleSectionChange,
}: {
  filteredSection: any;
  handleSectionChange: any;
}) => {
  return (
    <>
      {' '}
      <List listStyleType="none" fz={13}>
        <List.Item
          ta="center"
          mb={12}
          onClick={() => {
            handleSectionChange(BIO_SECTION_NAMES.BRANDING);
          }}
        >
          <Flex
            className={`${classes.navItem} ${filteredSection && filteredSection.value === BIO_SECTION_NAMES.BRANDING ? classes.active : ''}`}
            direction={'column'}
            justify={'center'}
            align={'center'}
          >
            <FontAwesomeIcon icon={faUser} />
            <Text mt={4} fz={11}>
              Branding
            </Text>
          </Flex>
        </List.Item>
        <List.Item
          ta="center"
          mb={12}
          onClick={() => {
            handleSectionChange('');
          }}
        >
          <Flex
            className={`${classes.navItem} ${!filteredSection ? classes.active : ''}`}
            direction={'column'}
            justify={'center'}
            align={'center'}
          >
            <FontAwesomeIcon icon={faPencil} />
            <Text mt={4} fz={11}>
              Content
            </Text>
          </Flex>
        </List.Item>

        <List.Item
          ta="center"
          mb={12}
          onClick={() => {
            handleSectionChange(BIO_SECTION_NAMES.SETTINGS);
          }}
        >
          <Flex
            className={`${classes.navItem} ${filteredSection && filteredSection.value === BIO_SECTION_NAMES.SETTINGS ? classes.active : ''}`}
            direction={'column'}
            justify={'center'}
            align={'center'}
          >
            <FontAwesomeIcon icon={faGear} />
            <Text mt={4} fz={11}>
              Settings
            </Text>
          </Flex>
        </List.Item>
      </List>
    </>
  );
};
