import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Menu } from '@mantine/core';
import { useState } from 'react';

interface ITotalResultsMenu {
  value: number;
  onChange: (value: number) => void;
}
export function TotalResultsMenu({ value, onChange }: ITotalResultsMenu) {
  const [label, setLabel] = useState(value);

  return (
    <>
      <Menu>
        <Menu.Target>
          <Button
            variant="outline"
            color="gray"
            size="xs"
            rightSection={<FontAwesomeIcon icon={faChevronDown} />}
          >
            {label} Results
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          {[20, 50, 100].map((item) => (
            <Menu.Item
              key={`${item}-results`}
              onClick={() => {
                setLabel(item);
                onChange(item);
              }}
            >
              {item} Results
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </>
  );
}
