import { Box, Button, Flex, Title } from '@mantine/core';
import classes from '../../BioLinks.module.css';
import { IThemeCard, createContrastGradientBackground } from '@replug/utils';
import { useBioLinkSetupStore } from '@/stores/Bio/useBioLinkSetupStore';
import { useEffect, useState } from 'react';

interface IBioThemeCard {
  theme: IThemeCard;
  onClick: (theme: IThemeCard) => void;
  currentTheme: any;  // Add this prop
}

export const BioThemeCard = ({ theme, onClick, currentTheme }: IBioThemeCard) => {
  const [bioLink] = useBioLinkSetupStore((state) => [
    state.bioLink
  ]);
  const [matches, setMatches] = useState<boolean>(false);

  const compareThemes = (theme1: any, theme2: any): boolean => {
    const results: boolean[] = [];

    // Compare background colors
    if (theme1?.backgroundColor && theme2?.background_color) {
      results.push(theme1.backgroundColor === theme2.background_color);
    }

    // Compare text colors
    if (theme1?.textColor && theme2?.color) {
      results.push(theme1.textColor === theme2.color);
    }

    // Compare button properties
    const button1 = theme1?.buttonStyles;
    const button2 = theme2?.button;

    if (button1 && button2) {
      // Compare button variant
      if (button1?.variant && button2?.variant) {
        results.push(button1.variant === button2.variant);
      }

      // Compare button colors
      if (button1?.color && button2?.color) {
        results.push(button1.color === button2.color);
      }

      // Compare button background colors
      if (button1?.backgroundColor && button2?.background_color) {
        results.push(button1.backgroundColor === button2.background_color);
      }

      // Compare button radius
      if (button1?.radius && button2?.radius) {
        results.push(button1.radius === button2.radius);
      }
    }

    return results.length > 0 && results.every(result => result === true);
  };

  useEffect(() => {
    if (currentTheme) {
      const isMatching = compareThemes(theme, currentTheme);
      setMatches(isMatching);
    }
  }, [theme, currentTheme]);
  return (
    <Box
      className={classes.bioTheme}
      bg={
        theme.backgroundStyle === 'gradient'
          ? createContrastGradientBackground(theme.backgroundColor, 300)
          : theme.backgroundColor
      }
      style={{
        borderColor: matches ? '#000' : undefined,
        borderWidth: matches ? '2px' : undefined,
        borderStyle: matches ? 'solid' : undefined,
      }}
      onClick={() => {
        onClick(theme);
      }}
    >
      <Flex direction={'column'} justify={'center'} h={'100%'} align={'center'}>
        {[0, 1, 2].map((i) => (
          <Button
            key={i}
            radius={theme.buttonStyles.radius || 'md'}
            style={{
              backgroundColor: theme.buttonStyles.backgroundColor,
              color: theme.buttonStyles.color,
              border: theme.buttonStyles.border,
              boxShadow: theme.buttonStyles?.boxShadow
                ? theme.buttonStyles.boxShadow
                : undefined,
            }}
            w={140}
            h={32}
            variant={theme.buttonStyles.variant}
            my={'sm'}
          ></Button>
        ))}
      </Flex>
    </Box>
  );
};
