import {
  Anchor,
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Paper,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';

import classes from './auth.module.css';
import { AuthCarousel } from '@/components/Auth/AuthCarousel';
import { UserService } from '@/services/UserService';
import { useState } from 'react';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import { getAppLogo } from '@/utils/CommonUtility';

export function ForgotPasswordPage() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const form = useForm({
    initialValues: {
      email: '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const handleForgotPassword = async (formValues: any) => {
    setLoading(true);
    const updatedFormValues = {
      ...formValues,
      domain: window.location.hostname,
    };
    await axios
      .post('/auth/recover_password', updatedFormValues)
      .then((res) => {
        if (res.data.status) {
          notifications.show({
            color: 'green',
            message: res.data.message,
          });
        } else {
          notifications.show({
            color: 'red',
            message: res.data.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.message) {
          notifications.show({
            color: 'red',
            message: error.response.data.message,
          });
        }
      });

    setLoading(false);
  };

  return (
    <Paper
      style={{
        backgroundColor: '#f2f6f7',
      }}
    >
      <Grid>
        <Grid.Col
          span={3.5}
          style={{
            height: '100%',
          }}
        >
          <Flex mt={16} mx={16} direction="column" className={classes.authContainer}>
            <Box className={classes.authLogoContainer}>
              <Image w={122} h={36} src={getAppLogo()} />
            </Box>
            <Stack h="100%" justify="space-between">
              <Box p={16} px={32}>
                <Title order={4} ta="center" py={16}>
                  Forgot Password
                </Title>
                <Text size="sm" ta="center" mb={16}>
                  Enter your email address and we will send you a link to reset your password.
                </Text>
                <form onSubmit={form.onSubmit(handleForgotPassword)}>
                  <TextInput
                    mb={32}
                    label="Email"
                    placeholder="e.g john.carl@email.com"
                    {...form.getInputProps('email')}
                  />

                  <Box
                    mb={16}
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Button loading={loading} disabled={loading} type="submit" w={160}>
                      Reset Password
                    </Button>
                  </Box>

                  <Text pb={8} size="sm" ta="center">
                    <Anchor size="xs" onClick={() => navigate('/login')} fw="bold">
                      Take me back to login
                    </Anchor>
                  </Text>
                </form>
              </Box>
            </Stack>
          </Flex>
        </Grid.Col>
        <Grid.Col span={8.5}>
          <AuthCarousel />
        </Grid.Col>
      </Grid>
    </Paper>
  );
}
