import { ICampaignAgreement } from '@/types/campaigns';
import { faCaretDown, faCaretUp, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Box, Collapse, Flex, Stack, Switch, TextInput, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { RichTextEditor } from '@mantine/tiptap';
import Highlight from '@tiptap/extension-highlight';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

export const CTAAgreementBox = ({
  agreement,
  form,
  index,
}: {
  agreement: ICampaignAgreement;
  form: any;
  index: number;
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      //   Superscript,
      //   SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content: agreement.text || '<p></p>',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    onUpdate: ({ editor }: any) => {
      form.setFieldValue(`call_to_action.agreements.${index}.text`, editor.getHTML());
    },
  });

  const [opened, { toggle }] = useDisclosure(true);


  const handleRemoveAgreement = (index: number) => {
        const agreements = form.values.call_to_action?.agreements || [];
        agreements.splice(index, 1);
        form.setFieldValue('call_to_action.agreements', agreements);
    };

  return (
    <>
      <Box
        bg="gray.1"
        style={{
          borderRadius: 8,
        }}
      >
        <Stack p={16}>
          <Flex align={'center'}>
            <TextInput
              size="xs"
              placeholder="Checkbox Name"
              {...form.getInputProps(`call_to_action.agreements.${index}.name`)}
            />
            <Flex
              align={'center'}
              justify={'end'}
              style={{
                flexGrow: 1,
              }}
            >
              <Tooltip label="Delete">
                <ActionIcon
                  variant="subtle"
                  color="red"
                  size="sm"
                  onClick={() => {
                    handleRemoveAgreement(index);
                  }}
                >
                  <FontAwesomeIcon size="sm" icon={faTrash} />
                </ActionIcon>
              </Tooltip>

              <Tooltip label="Collapse">
                <ActionIcon ml={8} variant="subtle" color="gray" size="sm" onClick={toggle}>
                  <FontAwesomeIcon size="sm" icon={opened ? faCaretDown : faCaretUp} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          </Flex>

          <Collapse in={opened} transitionDuration={100}>
            <RichTextEditor fz={13} editor={editor}>
              <RichTextEditor.Toolbar sticky stickyOffset={60}>
                <RichTextEditor.ControlsGroup fz={11}>
                  <RichTextEditor.Bold />
                  <RichTextEditor.Italic />
                  <RichTextEditor.Underline />
                  <RichTextEditor.Strikethrough />
                  <RichTextEditor.ClearFormatting />
                  <RichTextEditor.BulletList />
                  <RichTextEditor.OrderedList />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Link />
                  <RichTextEditor.Unlink />
                </RichTextEditor.ControlsGroup>
              </RichTextEditor.Toolbar>

              <RichTextEditor.Content
                {...form.getInputProps(`call_to_action.agreements.${index}.text`)}
              />
            </RichTextEditor>
          </Collapse>
          <Switch
            size="xs"
            checked={form.values.call_to_action.agreements[index].optional_field}
            fw={500}
            {...form.getInputProps(`call_to_action.agreements.${index}.optional_field`)}
            label="Optional field"
          />
        </Stack>
      </Box>
    </>
  );
};
