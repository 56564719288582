import { UserService } from '@/services/UserService';
import { Container, Flex, Loader, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { setAuthTokenLocalStorage } from '@/utils/Storage';

export function HomePage() {
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchProfile = async () => {
    const res = await new UserService()
      .getProfile()
      .then((response) => {
        console.log(response);
        if (response.data.status) {
          return response.data.data.user;
        }
        return false;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });

    if (!res) {
      navigate('/login');
    } else if (res.email_verified_at === null) {
      navigate('/verification');
    } else {
      navigate('/dashboard/');
    }
    setLoading(false);
  };

  const handleLoginFromQueryParam = async () => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    if (token) {
      try {
        // Save the token
        setAuthTokenLocalStorage(token);
      } catch (error) {
        console.error('Login via token failed:', error);
      }
    }
  };

  useEffect(() => {
    const performLoginAndFetch = async () => {
      await handleLoginFromQueryParam();
      await fetchProfile();
    };
    performLoginAndFetch();
  });

  if (isLoading) {
    return (
      <Container h={600}>
        <Flex h={'100%'} align={'center'} justify={'center'}>
          <Flex align={'center'} direction={'column'}>
            <Loader />
            <Text mt={16} size="sm">
              Loading...
            </Text>
          </Flex>
        </Flex>
      </Container>
    );
  }
}
