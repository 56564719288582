import { SHORTENER_CUSTOM_DOMAIN } from '@/constants/Constants';
import { CustomDomainService } from '@/services/CustomDomainService';
import { WhitelabelService } from '@/services/WhitelabelService';
import { useCustomDomainsStore } from '@/stores/useCustomDomainsStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { IDomain } from '@/types/domain';
import { ensureHttps } from '@/utils/LinkUtility';
import {
  faExclamation,
  faExclamationCircle,
  faLightbulb,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Anchor,
  Divider,
  Flex,
  Modal,
  TextInput,
  Title,
  Text,
  Table,
  Button,
  Alert,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useDebouncedValue } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { z } from 'zod';

const schema = z.object({
  _id: z.optional(z.string()).nullable(),
  url: z.string().min(1).url(),
  '404_redirect_url': z.optional(z.string()).nullable(),
  branded_domain_url: z.optional(z.string()).nullable(),
});

export const CustomDomainModal = ({
  opened,
  setOpened,
  domain,
}: {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  domain?: IDomain | null;
}) => {
  const [domainHost, setDomainHost] = useState('@');

  const [customDomains, setCustomDomains] = useCustomDomainsStore((state) => [
    state.customDomains,
    state.setCustomDomains,
  ]);

  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [loader, setLoader] = useState(false);
  const DEFAULT_VALUES = {
    url: '',
    '404_redirect_url': '',
    branded_domain_url: '',
    workspace_id: defaultWorkspace?._id || '',
  };

  const form = useForm({
    initialValues: domain || DEFAULT_VALUES,
    validate: zodResolver(schema),
  });

  const [debounced] = useDebouncedValue(form.values.url, 500);
  const handleCustomDomainSubmit = async (values: any) => {
    setLoader(true);
    console.log(values);
    const fieldsToUpdate = ['url', 'branded_domain_url', '404_redirect_url'];

    fieldsToUpdate.forEach((field) => {
      if (values[field]) {
        const updatedUrl = ensureHttps(values[field]);
        form.setFieldValue(field, updatedUrl);
        values[field] = updatedUrl;
      }
    });
    if (values._id) {
      // Update custom domain

      await new CustomDomainService()
        .update(defaultWorkspace?._id || '', values._id, values)
        .then((res) => {
          if (res.data.status) {
            setOpened(false);
            const newList = customDomains.map((item: IDomain) => {
              if (item._id === values._id) {
                return res.data.data;
              }
              return item;
            });
            setCustomDomains(newList);
            notifications.show({
              color: 'green',
              message: 'Custom Domain has been updated successfully.',
            });
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.response.status === 422 || err.response.status === 400) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
        });
    } else {
      // Create custom domain

      await new CustomDomainService()
        .create(defaultWorkspace?._id || '', values)
        .then((res) => {
          if (res.data.status) {
            setOpened(false);
            setCustomDomains([...customDomains, res.data.data]);
            notifications.show({
              color: 'green',
              message: 'Custom Domain has been created successfully.',
            });
          }
        })
        .catch((err) => {
          console.log(err.response.status);
          if (err.response.status === 422 || err.response.status === 400) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
        });
    }
    setLoader(false);
  };

  const findDomainHost = (domain: string) => {
    new WhitelabelService()
      .findHost(defaultWorkspace?._id || '', domain)
      .then((res) => {
        if (res.data.status) {
          setDomainHost(res.data.data ? res.data.data : '@');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log(domain);
    if (domain) {
      form.setValues(domain);
    } else {
      form.setValues(DEFAULT_VALUES);
    }
  }, [domain]);

  useEffect(() => {
    if (debounced) {
      findDomainHost(form.values.url);
    }
  }, [debounced]);
  return (
    <>
      <Modal
        size={700}
        title={<Title order={5}>Connect your custom domain</Title>}
        opened={opened}
        onClose={() => setOpened(false)}
        styles={{
          body: {
            padding: 16,
            paddingLeft: 32,
            paddingRight: 32,
          },
        }}
      >
        {domain?._id && !domain.is_connected && (
          <>
            {' '}
            <Alert
              mb={12}
              variant="light"
              color="yellow"
              title="DNS not yet verified"
              icon={<FontAwesomeIcon icon={faExclamationCircle} />}
            >
              <Text fz={13}>
                DNS propagation can take up-to 24 hours. Please make sure you have pointed the DNS
                records in the DNS configuration section. We'll also send you an email once your
                domain is verified.
              </Text>
            </Alert>
          </>
        )}
        {!defaultWorkspace?.agency?.is_whitelabeled && (
          <Flex justify={'end'} w={'100%'}>
            <Anchor
              fw={500}
              underline="always"
              size="sm"
              href="https://docs.replug.io/article/164-custom-domain-setup"
              target="_blank"
            >
              How to add DNS records for your custom domain?
            </Anchor>
          </Flex>
        )}

        <form onSubmit={form.onSubmit(handleCustomDomainSubmit)}>
          <TextInput
            variant="filled"
            label="Domain URL"
            placeholder="Your branded domain i.e. https://track.yourdomain.com"
            value={form.values.url}
            onChange={(e) => {
              let value = e.currentTarget.value;
              if (value) {
                form.setFieldValue('url', ensureHttps(e.currentTarget.value));
              } else {
                form.setFieldValue('url', '');
              }
            }}
          />
          <TextInput
            variant="filled"
            description={
              "When a user opens a URL that doesn't exist, they will be redirected to this URL."
            }
            label="404 Redirect URL"
            placeholder="Redirect URL for 404 pages"
            mt={16}
            value={form.values['404_redirect_url']}
            onChange={(e) => {
              let value = e.currentTarget.value;
              if (value) {
                form.setFieldValue('404_redirect_url', ensureHttps(e.currentTarget.value));
              } else {
                form.setFieldValue('404_redirect_url', '');
              }
            }}
          />

          <TextInput
            variant="filled"
            description={
              'Users will be redirected to this URL when they open your root address of branded domain.'
            }
            label="Branded domain"
            placeholder="Your main website URL i.e. https://yourdomain.com"
            mt={16}
            value={form.values['branded_domain_url']}
            onChange={(e) => {
              let value = e.currentTarget.value;
              if (value) {
                form.setFieldValue('branded_domain_url', ensureHttps(e.currentTarget.value));
              } else {
                form.setFieldValue('branded_domain_url', '');
              }
            }}
          />

          <Divider mt={16} mb={16} color="gray.2" />

          <Text fw={600} mb={8}>
            DNS Configuration
          </Text>

          <Table mb={16}>
            <Table.Thead h={20} bg={'gray.1'} c="gray.7" fz="xs">
              <Table.Tr>
                <Table.Th>Record Type</Table.Th>
                <Table.Th>Hostname</Table.Th>
                <Table.Th>Points to</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td>CNAME</Table.Td>
                <Table.Td>{domainHost}</Table.Td>
                <Table.Td>{SHORTENER_CUSTOM_DOMAIN}</Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>

          <Alert color="yellow" icon={<FontAwesomeIcon icon={faLightbulb} />}>
            <Text fz={13}>
              You'll need to access your domain hosting service to configure the domain. Copy Host
              and Value for record and add them to your domain manager.
              {!defaultWorkspace?.agency?.is_whitelabeled && (
                <span>
                  {' '}Refer to{' '}
                  <Anchor fz={'13'} href="https://docs.replug.io/article/164-custom-domain-setup" target="_blank" c="primary" fw={'600'}>
                    this article
                  </Anchor>{' '}
                  to learn more.
                </span>
              )}
              <br />
              <br />
              DNS Configuration Changes can take up to <strong>24 hours to propagate</strong> fully.
              If you're using Cloudflare, ensure that the Cloudflare Proxy option is disabled.
            </Text>
          </Alert>

          <Flex justify={'end'}>
            <Button mt={'md'} type="submit" loading={loader} disabled={loader}>
              Save Custom Domain
            </Button>
          </Flex>
        </form>
      </Modal>
    </>
  );
};
