import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Badge, Center, Divider, Flex, Menu, Table, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import getSymbolFromCurrency from 'currency-symbol-map';

interface ISubscriptionsListingProps {
  loading: boolean;
  subscriptions: any[];
  updateSubscription: (subscription: any, action: string) => void;
  cancelSubscription: (subscription: any) => void;
}

export function SubscriptionsListing({ subscriptions, loading, cancelSubscription, updateSubscription }: ISubscriptionsListingProps) {

  const openPaddleUrl = (url: string) => {
    // @ts-ignore
    Paddle.Checkout.open({
      override: url,
      successCallback: function (date: any) {
        console.log('successCallback', date);
        notifications.show({
          message: 'Subscription updated successfully',
          color: 'green',
        });
      },
    })
  }


  return (
    <>
      <Flex h={70} p={16} align={'center'}>
        <Text size="sm" fw={700}>
          Subscriptions
        </Text>
      </Flex>
      <Divider color={'gray.3'} />

      {loading ? (
        <Flex h={200} align={'center'} justify={'center'}>
          <Text size="sm">Loading...</Text>
        </Flex>
      ) : (
        <>
            {subscriptions?.length === 0 && (
              <Flex h={200} align={'center'} justify={'center'}>
                <Text size="sm">No subscriptions found</Text>
              </Flex>
            )}
            {subscriptions?.length > 0 && (
              <Table stickyHeader verticalSpacing={'md'}>
                <Table.Thead h={20} bg={'gray.1'} c="gray.7" fz="xs">
                  <Table.Tr>
                    <Table.Th>Subscription ID</Table.Th>
                    <Table.Th>Plan</Table.Th>
                    <Table.Th>Next Payment</Table.Th>
                    <Table.Th>Status</Table.Th>
                    <Table.Th ta={'center'}>Actions</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {subscriptions.map((item, index) => (
                    <>
                      <Table.Tr key={index}>
                        <Table.Td>
                          <Text pt={4} fz={13}>
                            {item.subscription_id}
                          </Text>
                        </Table.Td>
                        <Table.Td>
                          <Text fz={13}>{item.plan_name}</Text>
                        </Table.Td>
                        <Table.Td>
                          {item.status === 'active' && item.next_bill_date ? (
                            <Text fz={13}>
                              <Text fw="bold" size='xs'>
                                {getSymbolFromCurrency(item.currency) + item.next_payment_amount || '-'}
                              </Text>{item.next_bill_date}</Text>
                          ) : (
                            <Text fz={13}>N/A</Text>
                          )}
                        </Table.Td>
                        <Table.Td>
                          {item.status === 'active' ? (
                            <Badge color="green">Active</Badge>
                          ) : item.status === 'deleted' ? (
                            <Badge color="red">Deleted</Badge>
                          ) : item.status === 'past_due' ? (
                            <Badge color="red">Past Due</Badge>
                          ) : item.status === 'paused' ? (
                            <Badge color="gray">Paused</Badge>
                          ) : (
                            <Badge color="blue">{item.status}</Badge>
                          )}
                        </Table.Td>
                        <Table.Td>
                          {item.status !== 'deleted' && (
                            <Menu withArrow withinPortal>
                              <Menu.Target>
                                <Center>
                                  <ActionIcon c="gray" variant="subtle">
                                    <FontAwesomeIcon icon={faEllipsis} />
                                  </ActionIcon>
                                </Center>
                              </Menu.Target>
                              <Menu.Dropdown w="140">
                                <Menu.Item onClick={() => openPaddleUrl(item.update_url)}>Update</Menu.Item>
                                {item.status === 'paused' && (
                                  <Menu.Item onClick={() => updateSubscription(item, 'resume')}>Resume</Menu.Item>
                                )}
                                {item.status === 'active' && (
                                  <>
                                    <Menu.Item onClick={() => updateSubscription(item, 'pause')}>Pause</Menu.Item>
                                    <Menu.Divider />
                                    <Menu.Item c={'red'} onClick={() => cancelSubscription(item)}>Cancel</Menu.Item>
                                  </>
                                )}
                              </Menu.Dropdown>
                            </Menu>
                          )}
                        </Table.Td>
                      </Table.Tr >
                    </>
                  ))}
                </Table.Tbody>
              </Table >
            )
            }
        </>
      )}
      <Divider c={'gray.3'} />
    </>
  );
}
