import { MantineColorsTuple, createTheme } from '@mantine/core';

const primary: MantineColorsTuple = [
  '#e6f2ff',
  '#d0e0ff',
  '#a1bdfa',
  '#6f99f4',
  '#457af0',
  '#2967ee',
  '#185ded',
  '#054dd4',
  '#0045be',
  '#003aa9',
];
// [

//   '#ebf4ff',
//   '#d4e5fa',
//   '#a3c7f6',
//   '#72aaf5',
//   '#4b90f4',
//   '#3880f3',
//   '#2d77f4',
//   '#2366da',
//   '#195ac3',
//   '#014eac',
// ];

export const theme = createTheme({
  fontFamily: "'Inter',sans-serif",
  autoContrast: true,
  primaryColor: 'primary',
  primaryShade: 9,
  colors: {
    primary,
  },

  /** Put your mantine theme override here */
});
