import { UTMService } from '@/services/UTMService';
import { IUTM } from '@/types/utms';
import { create } from 'zustand';

interface iUTMStore {
  UTMs: IUTM[];
  setUTMs: (utms: IUTM[]) => void;
  fetchUTMs: (workspace_id: string) => void;
  setupModal: boolean;
  setSetupModal: (toggle: boolean) => void;
  id: string | null;
  setId: (id: string | null) => void;
  utmURL: string;
  setUTMURL: (url: string) => void;
}

export const useUTMStore = create<iUTMStore>((set) => ({
  id: null,
  setId: (id) => {
    set({ id });
  },
  UTMs: [],
  setUTMs: (UTMs) => {
    set({ UTMs });
  },
  fetchUTMs: (workspace_id) => {
    new UTMService().get(workspace_id).then((res) => {
      if (res.data.status) {
        set({ UTMs: res.data.data });
      } else {
        set({ UTMs: [] });
      }
    });
  },
  setupModal: false,
  setSetupModal: (toggle) => {
    set({ setupModal: toggle });
  },
  utmURL: '',
  setUTMURL: (url) => {
    set({ utmURL: url });
  },
}));
