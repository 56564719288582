import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex, Text } from '@mantine/core';

export const NoResultsListTable = ({ message = 'No data available' }: { message: string }) => {
  return (
    <>
      <Flex mt={64} justify={'center'} align={'center'} direction={'column'}>
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          size="2x"
          style={{ color: 'var(--mantine-color-gray-5)', marginBottom: 9 }}
        />
        <Text ta="center" fz={14} c="dimmed">
          {message}
        </Text>
      </Flex>
    </>
  );
};
