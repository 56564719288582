import { CallToActionTypeEnum, CallToActionVariantsEnum } from '@replug/utils';

export const APP_ENVIRONMENT = import.meta.env.VITE_APP_ENVIRONMENT ?? 'dev';

const isWhitelabelDomain = !window.location.hostname.includes('.replug.io') && !window.location.hostname.includes('localhost');

// TODO: Need to update old whitelabel ingress to use whitelabel domain for backend api
// export const API_URL = isWhitelabelDomain ? (window.location.origin + '/backend/api') : (import.meta.env.VITE_BACKEND_API_URL
//   ? import.meta.env.VITE_BACKEND_API_URL
//   : 'http://localhost:80/api');

export const API_URL = import.meta.env.VITE_BACKEND_API_URL ? import.meta.env.VITE_BACKEND_API_URL : 'http://localhost:80/api';
  
export const APP_URL = isWhitelabelDomain ? window.location.origin : (import.meta.env.VITE_APP_URL
  ? import.meta.env.VITE_APP_URL
  : 'http://localhost:5173');


export const CSV_LICENSE_KEY = import.meta.env.VITE_CSV_LICENSE_KEY ?? 'a6YPsJ3ipwIzTvIzCwmeyEooSRjsVU';
export const CSV_USER_ID = import.meta.env.VITE_CSV_USER_ID ?? 'default123';

export const SHORTENER_CUSTOM_DOMAIN = import.meta.env.VITE_SHORTENER_CUSTOM_DOMAIN ?? "dev.rplg.co"
export const WHITELABEL_SHORTENER_DOMAIN = import.meta.env.VITE_WHITELABEL_SHORTENER_DOMAIN ?? "whitelabel-shortener-dev.replug.io"
export const WHITELABEL_APP_DOMAIN = import.meta.env.VITE_WHITELABEL_APP_DOMAIN ?? "whitelabel-dev.replug.io"

export const PADDLE_VENDOR_ID = parseInt(import.meta.env.VITE_PADDLE_VENDOR_ID ?? '4086');
export const RSS_URL = import.meta.env.VITE_RSS_URL ?? "https://dev-rss.replug.io/rss"

export const REPLUG_AUTH_TOKEN = 'replug_auth_token';
export const REPLUG_LINKS_TOTAL_RESULTS = 20;
export const REPLUG_TERMS_URL = 'https://replug.io/terms'
export const REPLUG_PRIVACY_URL = 'https://replug.io/privacy'

export const USERMAVEN_PROJECT_ID = import.meta.env.VITE_USERMAVEN_PROJECT_ID ?? 'UM8Fm4BG1W';
export const USERMAVEN_TRACKING_HOST = import.meta.env.VITE_USERMAVEN_TRACKING_HOST ?? 'https://um.replug.io';

export const CALL_TO_ACTION_OPTIONS = [
  { label: 'Button', value: CallToActionTypeEnum.Button },
  { label: 'Link', value: CallToActionTypeEnum.Link },

  { label: 'Form', value: CallToActionTypeEnum.Form },
];

export const CAMPAIGN_OPTIONS = [
  { label: 'Social Classic', value: CallToActionVariantsEnum.SocialClassic },
  { label: 'Social Modern', value: CallToActionVariantsEnum.SocialModern },
  { label: 'Banner', value: CallToActionVariantsEnum.Banner },
  { label: 'Exit Intent', value: CallToActionVariantsEnum.ExitIntent },
  { label: 'Popup', value: CallToActionVariantsEnum.Popup },
  { label: 'Scroll Box', value: CallToActionVariantsEnum.ScrollBox },
  { label: 'Bridge Page', value: CallToActionVariantsEnum.BridgePage },
];

export const EMAIL_PROVIDER_OPTIONS = [
  { label: 'Mailchimp', value: 'mailchimp' },
  { label: 'ConvertKit', value: 'convertkit' },
  { label: 'Aweber', value: 'aweber' },
  { label: 'ActiveCampaign', value: 'activecampaign' },
  { label: 'GetResponse', value: 'getresponse' },
  { label: 'MailerLite', value: 'mailerlite' },
  { label: 'SendFox', value: 'sendfox' },
  { label: 'Sendinblue', value: 'sendinblue' },
];

export const COLOR_PAELLETES = [
  {
    name: 'Classic Elegance',
    backgroundColor: '#F3F4F6',
    textColor: '#1A1C1E',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#337FBD',
  },
  {
    name: 'Soft & Serene',
    backgroundColor: '#FAFAFA',
    textColor: '#344054',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#62B0E8',
  },
  {
    name: 'Vibrant Sunset',
    backgroundColor: '#FFF5EB',
    textColor: '#503E2C',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#FFC6FF',
  },
  {
    name: 'Earthy Harmony',
    backgroundColor: '#F7F6F2',
    textColor: '#3E4C59',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#A8BDAF',
  },
  {
    name: 'Modern Minimalist',
    backgroundColor: '#FFFFFF',
    textColor: '#000000',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#000000',
  },
  {
    name: 'Lush Garden',
    backgroundColor: '#E3F9E5',
    textColor: '#2E4739',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#57CC99',
  },
  {
    name: 'Ocean Breeze',
    backgroundColor: '#E0FFFF',
    textColor: '#305065',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#3CAEA3',
  },
  {
    name: 'Desert Spice',
    backgroundColor: '#FFF8E6',
    textColor: '#8C6B4D',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#FFA07A',
  },
  {
    name: 'Royal Elegance',
    backgroundColor: '#F4EFEF',
    textColor: '#463F3A',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#9368B7',
  },
  {
    name: 'Sunset Glow',
    backgroundColor: '#FFF0EA',
    textColor: '#5C3A4D',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#FF7E67',
  },
  {
    name: 'Midnight Blue',
    backgroundColor: '#2c3e50',
    textColor: '#ecf0f1',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#3498db',
  },
  {
    name: 'Autumn Spice',
    backgroundColor: '#fdf2e9',
    textColor: '#5d473a',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#e67e22',
  },
  {
    name: 'Cool Mint',
    backgroundColor: '#e8f8f5',
    textColor: '#16a085',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#1abc9c',
  },
  {
    name: 'Lavender Dream',
    backgroundColor: '#f4e8f4',
    textColor: '#6c567b',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#9b59b6',
  },
  {
    name: 'Sunshine Joy',
    backgroundColor: '#fff9db',
    textColor: '#f39c12',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#f1c40f',
  },
  {
    name: 'Crisp Winter',
    backgroundColor: '#e6f0fa',
    textColor: '#2c3e50',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#5dade2',
  },
  {
    name: 'Forest Whisper',
    backgroundColor: '#e9f7ef',
    textColor: '#1e8449',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#52be80',
  },
  {
    name: 'Berry Blush',
    backgroundColor: '#f8e6e9',
    textColor: '#943126',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#c0392b',
  },
  {
    name: 'Golden Sands',
    backgroundColor: '#faf4e1',
    textColor: '#a68d60',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#f0b27a',
  },
  {
    name: 'Twilight Spark',
    backgroundColor: '#eaecee',
    textColor: '#34495e',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#5d6d7e',
  },
];

export const DEFAULT_CAMPAIGN_VALUES = {
  _id: '',
  name: '',
  type: 'no_cta',
  is_default: false,
  workspace_id: '',
  user_id: '',
  domain_id: null,
  retargeting_ids: [],
  custom_widget_ids: [],
  is_retargeting_enabled: false,
  is_custom_widget_enabled: false,
  is_call_to_action_enabled: false,
  call_to_action: {
    button_url: '',
    button_text: 'Call to action',
    message: 'Your message goes here',
    message_headline: 'Your headline goes here',
    image: null,
    variant: 'social',
    type: 'button',
    background_color: '#FFFFFF',
    text_color: '#333',
    background_image_exit_intent: null,
    exit_intent_opacity: 0.5,
    button_background_color: '#003aa9',
    button_text_color: '#F5F5DC',
    position: 'left',
    branding_logo: true,
    circle: true,
    sound: true,
    link_color: '#003aa9',
    redirect_after_submission: false,
    email_service_tags: [],
    agreements: [],
    trigger_type: 'time',
    trigger_seconds: 5,
    trigger_scroll_value: 0,
    trigger_scroll_type: 'px',
    email_service: null,
    email_service_id: null,
    email_list: null,
    name_field: false,
  },
};

export const DEFAULT_LINK_VALUES = {
  _id: '',
  campaign_id: null,
  user_id: null,
  workspace_id: null,
  created_from: null,
  url: '',
  redirected_url: null,
  short_url: '',
  short_url_lowercase: '',
  qr_code_url: '',
  domain: null,
  domain_attributes: {
    url: null,
    scheme: null,
    netloc: null,
    registered_domain: null,
  },
  tags: [],
  status: true,
  link_preview_attributes: {
    twitter: {
      title: null,
      description: null,
      image: null,
      url: null,
      site: null,
      card: null,
      website: null,
    },
    facebook: {
      title: null,
      description: null,
      image: null,
      url: null,
      website: null,
      type: null,
      sitename: null,
      locale: null,
    },
    open_graph: {
      title: null,
      description: null,
      image: null,
    },
    favicon_url: null,
  },
  is_iframe_allowed: true,
  is_deep_link_enabled: false,
  is_deep_link_allowed: false,
  is_link_disabled: false,
  is_custom_link_preview_enabled: false,
  is_custom_favicon_enabled: false,
  is_link_expiry_enabled: false,
  is_password_protected: false,
  is_ab_testing_enabled: false,
  is_routing_enabled: false,
  custom_link_preview_attributes: {
    title: null,
    description: null,
    image: null,
  },
  custom_favicon_attributes: {
    url: null,
  },
  link_expiry_attributes: {
    expiry_date: null,
  },
  password_attributes: {
    password: null,
  },
  ab_testing_attributes: [],
  routing_attributes: [],
  deep_link_attributes: [],

  // error handling for AB Testing
  ab_testing_error: '',
};

export const CAMPAIGN_COLUMNS = [
  // Define your columns
  { accessor: 'name', label: 'Campaign Name', ta: 'left', is_disabled: true },
  { accessor: 'cta_type', label: 'CTA Type', ta: 'left' },
  { accessor: 'campaign_type', label: 'Campaign Type', ta: 'left' },
  { accessor: 'headline', label: 'Headline', ta: 'left' },
  { accessor: 'total_links', label: 'Total Links', ta: 'center' },
  { accessor: 'total_clicks', label: 'Total Clicks', ta: 'center' },
  { accessor: 'unique_clicks', label: 'Unique Clicks', ta: 'center' },
  { accessor: 'qr_scans', label: 'QR Scans', ta: 'center' },
  { accessor: 'actions', label: 'Actions', ta: 'center', is_disabled: true },
];

export const WORKSPACE_MEMBER_STATES = {
  INVITED: 'invited',
  JOINED: 'joined',
};

export const WORKSPACE_MEMBER_ROLES = {
  ADMINISTRATOR: 'administrator',
  OWNER: 'owner',
  COLLABORATOR: 'collaborator',
  GUEST: 'guest',
};

export const WORKSPACE_MEMBER_ROLE_COLORS = {
  ADMINISTRATOR: 'grape',
  OWNER: 'primary',
  COLLABORATOR: 'orange',
  GUEST: 'yellow',
};

export const ALL_COUNTRIES = [
  { value: 'afghanistan', label: 'Afghanistan' },
  { value: 'albania', label: 'Albania' },
  { value: 'algeria', label: 'Algeria' },
  { value: 'andorra', label: 'Andorra' },
  { value: 'angola', label: 'Angola' },
  { value: 'antigua-and-barbuda', label: 'Antigua and Barbuda' },
  { value: 'argentina', label: 'Argentina' },
  { value: 'armenia', label: 'Armenia' },
  { value: 'australia', label: 'Australia' },
  { value: 'austria', label: 'Austria' },
  { value: 'azerbaijan', label: 'Azerbaijan' },
  { value: 'bahamas', label: 'Bahamas' },
  { value: 'bahrain', label: 'Bahrain' },
  { value: 'bangladesh', label: 'Bangladesh' },
  { value: 'barbados', label: 'Barbados' },
  { value: 'belarus', label: 'Belarus' },
  { value: 'belgium', label: 'Belgium' },
  { value: 'belize', label: 'Belize' },
  { value: 'benin', label: 'Benin' },
  { value: 'bhutan', label: 'Bhutan' },
  { value: 'bolivia', label: 'Bolivia' },
  { value: 'bosnia-and-herzegovina', label: 'Bosnia and Herzegovina' },
  { value: 'botswana', label: 'Botswana' },
  { value: 'brazil', label: 'Brazil' },
  { value: 'brunei', label: 'Brunei' },
  { value: 'bulgaria', label: 'Bulgaria' },
  { value: 'burkina-faso', label: 'Burkina Faso' },
  { value: 'burundi', label: 'Burundi' },
  { value: 'cabo-verde', label: 'Cabo Verde' },
  { value: 'cambodia', label: 'Cambodia' },
  { value: 'cameroon', label: 'Cameroon' },
  { value: 'canada', label: 'Canada' },
  { value: 'central-african-republic', label: 'Central African Republic' },
  { value: 'chad', label: 'Chad' },
  { value: 'chile', label: 'Chile' },
  { value: 'china', label: 'China' },
  { value: 'colombia', label: 'Colombia' },
  { value: 'comoros', label: 'Comoros' },
  { value: 'congo', label: 'Congo' },
  { value: 'costa-rica', label: 'Costa Rica' },
  { value: 'croatia', label: 'Croatia' },
  { value: 'cuba', label: 'Cuba' },
  { value: 'cyprus', label: 'Cyprus' },
  { value: 'czech-republic', label: 'Czech Republic' },
  { value: 'denmark', label: 'Denmark' },
  { value: 'djibouti', label: 'Djibouti' },
  { value: 'dominica', label: 'Dominica' },
  { value: 'dominican-republic', label: 'Dominican Republic' },
  { value: 'ecuador', label: 'Ecuador' },
  { value: 'egypt', label: 'Egypt' },
  { value: 'el-salvador', label: 'El Salvador' },
  { value: 'equatorial-guinea', label: 'Equatorial Guinea' },
  { value: 'eritrea', label: 'Eritrea' },
  { value: 'estonia', label: 'Estonia' },
  { value: 'eswatini', label: 'Eswatini' },
  { value: 'ethiopia', label: 'Ethiopia' },
  { value: 'fiji', label: 'Fiji' },
  { value: 'finland', label: 'Finland' },
  { value: 'france', label: 'France' },
  { value: 'gabon', label: 'Gabon' },
  { value: 'gambia', label: 'Gambia' },
  { value: 'georgia', label: 'Georgia' },
  { value: 'germany', label: 'Germany' },
  { value: 'ghana', label: 'Ghana' },
  { value: 'greece', label: 'Greece' },
  { value: 'grenada', label: 'Grenada' },
  { value: 'guatemala', label: 'Guatemala' },
  { value: 'guinea', label: 'Guinea' },
  { value: 'guinea-bissau', label: 'Guinea-Bissau' },
  { value: 'guyana', label: 'Guyana' },
  { value: 'haiti', label: 'Haiti' },
  { value: 'honduras', label: 'Honduras' },
  { value: 'hungary', label: 'Hungary' },
  { value: 'iceland', label: 'Iceland' },
  { value: 'india', label: 'India' },
  { value: 'indonesia', label: 'Indonesia' },
  { value: 'iran', label: 'Iran' },
  { value: 'iraq', label: 'Iraq' },
  { value: 'ireland', label: 'Ireland' },
  { value: 'israel', label: 'Israel' },
  { value: 'italy', label: 'Italy' },
  { value: 'jamaica', label: 'Jamaica' },
  { value: 'japan', label: 'Japan' },
  { value: 'jordan', label: 'Jordan' },
  { value: 'kazakhstan', label: 'Kazakhstan' },
  { value: 'kenya', label: 'Kenya' },
  { value: 'kiribati', label: 'Kiribati' },
  { value: 'north-korea', label: 'North Korea' },
  { value: 'south-korea', label: 'South Korea' },
  { value: 'kuwait', label: 'Kuwait' },
  { value: 'kyrgyzstan', label: 'Kyrgyzstan' },
  { value: 'laos', label: 'Laos' },
  { value: 'latvia', label: 'Latvia' },
  { value: 'lebanon', label: 'Lebanon' },
  { value: 'lesotho', label: 'Lesotho' },
  { value: 'liberia', label: 'Liberia' },
  { value: 'libya', label: 'Libya' },
  { value: 'liechtenstein', label: 'Liechtenstein' },
  { value: 'lithuania', label: 'Lithuania' },
  { value: 'luxembourg', label: 'Luxembourg' },
  { value: 'madagascar', label: 'Madagascar' },
  { value: 'malawi', label: 'Malawi' },
  { value: 'malaysia', label: 'Malaysia' },
  { value: 'maldives', label: 'Maldives' },
  { value: 'mali', label: 'Mali' },
  { value: 'malta', label: 'Malta' },
  { value: 'marshall-islands', label: 'Marshall Islands' },
  { value: 'mauritania', label: 'Mauritania' },
  { value: 'mauritius', label: 'Mauritius' },
  { value: 'mexico', label: 'Mexico' },
  { value: 'micronesia', label: 'Micronesia' },
  { value: 'moldova', label: 'Moldova' },
  { value: 'monaco', label: 'Monaco' },
  { value: 'mongolia', label: 'Mongolia' },
  { value: 'montenegro', label: 'Montenegro' },
  { value: 'morocco', label: 'Morocco' },
  { value: 'mozambique', label: 'Mozambique' },
  { value: 'myanmar', label: 'Myanmar' },
  { value: 'namibia', label: 'Namibia' },
  { value: 'nauru', label: 'Nauru' },
  { value: 'nepal', label: 'Nepal' },
  { value: 'netherlands', label: 'Netherlands' },
  { value: 'new-zealand', label: 'New Zealand' },
  { value: 'nicaragua', label: 'Nicaragua' },
  { value: 'niger', label: 'Niger' },
  { value: 'nigeria', label: 'Nigeria' },
  { value: 'north-macedonia', label: 'North Macedonia' },
  { value: 'norway', label: 'Norway' },
  { value: 'oman', label: 'Oman' },
  { value: 'pakistan', label: 'Pakistan' },
  { value: 'palau', label: 'Palau' },
  { value: 'palestine', label: 'Palestine' },
  { value: 'panama', label: 'Panama' },
  { value: 'papua-new-guinea', label: 'Papua New Guinea' },
  { value: 'paraguay', label: 'Paraguay' },
  { value: 'peru', label: 'Peru' },
  { value: 'philippines', label: 'Philippines' },
  { value: 'poland', label: 'Poland' },
  { value: 'portugal', label: 'Portugal' },
  { value: 'qatar', label: 'Qatar' },
  { value: 'romania', label: 'Romania' },
  { value: 'russia', label: 'Russia' },
  { value: 'rwanda', label: 'Rwanda' },
  { value: 'saint-kitts-and-nevis', label: 'Saint Kitts and Nevis' },
  { value: 'saint-lucia', label: 'Saint Lucia' },
  { value: 'saint-vincent-and-the-grenadines', label: 'Saint Vincent and the Grenadines' },
  { value: 'samoa', label: 'Samoa' },
  { value: 'san-marino', label: 'San Marino' },
  { value: 'sao-tome-and-principe', label: 'Sao Tome and Principe' },
  { value: 'saudi-arabia', label: 'Saudi Arabia' },
  { value: 'senegal', label: 'Senegal' },
  { value: 'serbia', label: 'Serbia' },
  { value: 'seychelles', label: 'Seychelles' },
  { value: 'sierra-leone', label: 'Sierra Leone' },
  { value: 'singapore', label: 'Singapore' },
  { value: 'slovakia', label: 'Slovakia' },
  { value: 'slovenia', label: 'Slovenia' },
  { value: 'solomon-islands', label: 'Solomon Islands' },
  { value: 'somalia', label: 'Somalia' },
  { value: 'south-africa', label: 'South Africa' },
  { value: 'south-sudan', label: 'South Sudan' },
  { value: 'spain', label: 'Spain' },
  { value: 'sri-lanka', label: 'Sri Lanka' },
  { value: 'sudan', label: 'Sudan' },
  { value: 'suriname', label: 'Suriname' },
  { value: 'sweden', label: 'Sweden' },
  { value: 'switzerland', label: 'Switzerland' },
  { value: 'syria', label: 'Syria' },
  { value: 'taiwan', label: 'Taiwan' },
  { value: 'tajikistan', label: 'Tajikistan' },
  { value: 'tanzania', label: 'Tanzania' },
  { value: 'thailand', label: 'Thailand' },
  { value: 'timor-leste', label: 'Timor-Leste' },
  { value: 'togo', label: 'Togo' },
  { value: 'tonga', label: 'Tonga' },
  { value: 'trinidad-and-tobago', label: 'Trinidad and Tobago' },
  { value: 'tunisia', label: 'Tunisia' },
  { value: 'turkey', label: 'Turkey' },
  { value: 'turkmenistan', label: 'Turkmenistan' },
  { value: 'tuvalu', label: 'Tuvalu' },
  { value: 'uganda', label: 'Uganda' },
  { value: 'ukraine', label: 'Ukraine' },
  { value: 'united-arab-emirates', label: 'United Arab Emirates' },
  { value: 'united-kingdom', label: 'United Kingdom' },
  { value: 'united-states', label: 'United States' },
  { value: 'uruguay', label: 'Uruguay' },
  { value: 'uzbekistan', label: 'Uzbekistan' },
  { value: 'vanuatu', label: 'Vanuatu' },
  { value: 'vatican-city', label: 'Vatican City' },
  { value: 'venezuela', label: 'Venezuela' },
  { value: 'vietnam', label: 'Vietnam' },
  { value: 'yemen', label: 'Yemen' },
  { value: 'zambia', label: 'Zambia' },
  { value: 'zimbabwe', label: 'Zimbabwe' },
];

export const DEVICES = [
  { value: 'laptops', label: 'Laptops' },
  { value: 'smart_phones', label: 'Smart Phones' },
  { value: 'game_consoles', label: 'Game Consoles' },
  { value: 'smart_tv', label: 'Smart TV' },
  { value: 'unrecognized', label: 'Unrecognized' },
];

export const BROWSERS = [
  { value: 'edge', label: 'Microsoft Edge' },
  { value: 'internet explorer', label: 'Internet Explorer' },
  { value: 'chrome', label: 'Chrome' },
  { value: 'firefox', label: 'Firefox' },
  { value: 'safari', label: 'Safari' },
  { value: 'opera', label: 'Opera' },
  { value: 'polaris', label: 'Polaris' },
  { value: 'netscape', label: 'Netscape' },
];

export const OPERATING_SYSTEMS = [
  { value: 'android', label: 'Android' },
  { value: 'fire_os', label: 'Fire OS' },
  { value: 'ios', label: 'iOS' },
  { value: 'linux', label: 'Linux' },
  { value: 'mac_os', label: 'Mac OS' },
  { value: 'windows', label: 'Windows' },
];

export const DAYS_OF_WEEK = [
  { value: 'sunday', label: 'Sunday' },
  { value: 'monday', label: 'Monday' },
  { value: 'tuesday', label: 'Tuesday' },
  { value: 'wednesday', label: 'Wednesday' },
  { value: 'thursday', label: 'Thursday' },
  { value: 'friday', label: 'Friday' },
  { value: 'saturday', label: 'Saturday' },
];

export const DUMMY_IPS = [
  { value: '192.168.1.1', label: '192.168.1.1' },
  { value: '10.0.0.1', label: '10.0.0.1' },
  { value: '172.16.0.1', label: '172.16.0.1' },
  { value: '192.0.2.1', label: '192.0.2.1' },
  { value: '198.51.100.1', label: '198.51.100.1' },
  { value: '203.0.113.1', label: '203.0.113.1' },
];

export const DEFAULT_ROUTING_RULES = {
  rules: [
    {
      destination: '',
      blocks: [
        {
          conditions: [],
        },
      ],
    },
  ],
};

export const PADDLE_PLAN_IDS = {
  'sandbox': {
    'basic-monthly': 69597,
    'professional-monthly': 69596,
    'agency-monthly': 69598,
    'agency-monthly-20-workspaces': 72619,
    'agency-monthly-50-workspaces': 72620,
    'agency-monthly-100-workspaces': 72621,
    // Yearly Plans
    'basic-yearly': 69599,
    'professional-yearly': 69601,
    'agency-yearly': 69600,
    'agency-yearly-20-workspaces': 72622,
    'agency-yearly-50-workspaces': 72623,
    'agency-yearly-100-workspaces': 72624,

  },
  'production': {
    'basic-monthly': 563085,
    'professional-monthly': 563086,
    'agency-monthly': 563087,
    'agency-monthly-20-workspaces': 899644,
    'agency-monthly-50-workspaces': 899646,
    'agency-monthly-100-workspaces': 899648,
    // Yearly Plans
    'basic-yearly': 563088,
    'professional-yearly': 563089,
    'agency-yearly': 563090,
    'agency-yearly-20-workspaces': 899645,
    'agency-yearly-50-workspaces': 899647,
    'agency-yearly-100-workspaces': 899649,
  }
}

