import { useState } from 'react';
import { Modal, Button, Checkbox, Group, Stack, Text, Textarea, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';

interface ICancelSubscriptionModalProps {
  opened: boolean;
  onClose: () => void;
  onConfirm: (reasons: string[]) => void;
}

export const CancelSubscriptionModal = ({ opened, onClose, onConfirm }: ICancelSubscriptionModalProps) => {

  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const [otherReason, setOtherReason] = useState('');

  const reasons = [
    "I didn't understand how to use the product",
    "I missed certain features",
    "I'm using a better product",
    "I don't have time to organize everything from the beginning",
    "I don't have a need to use it at this moment",
    "It is expensive",
    "Other"
  ];

  const handleReasonToggle = (reason: string) => {
    setSelectedReasons(prevReasons =>
      prevReasons.includes(reason)
        ? prevReasons.filter(r => r !== reason)
        : [...prevReasons, reason]
    );
  };

  const handleSubmit = () => {
    if (selectedReasons.length === 0) {
      notifications.show({
        color: 'red',
        message: 'Please select at least one reason to cancel subscription',
      });
      return;
    }

    const reasonsToSubmit = selectedReasons.includes('Other')
      ? [...selectedReasons.filter(r => r !== 'Other'), otherReason]
      : selectedReasons;

    onConfirm(reasonsToSubmit);
  };

  return (
    <Modal opened={opened} onClose={onClose}
      title={<Title order={5}>Cancel Subscription</Title>}
      size="md">
      <Text size="sm" mb="md">
        We're sorry to see you go! Our support team will continue to cancel your
        subscription soon. We'd appreciate it if you would take a moment to let us
        know why you are cancelling.
      </Text>
      <Stack>
        {reasons.map((r) => (
          <Checkbox
            key={r}
            label={r}
            checked={selectedReasons.includes(r)}
            onChange={() => handleReasonToggle(r)}
          />
        ))}
        {selectedReasons.includes('Other') && (
          <Textarea
            placeholder="Please specify the reason for cancellation"
            minLength={100}
            autosize={true}
            minRows={3}
            value={otherReason}
            onChange={(event) => setOtherReason(event.currentTarget.value)}
            error={otherReason.length < 100 ? "Minimum 100 characters required" : null}
          />
        )}
      </Stack>
      <Group mt="md" justify='end'>
        <Button onClick={onClose} variant="subtle">Cancel</Button>
        <Button
          onClick={handleSubmit}
          disabled={selectedReasons.includes('Other') && otherReason.length < 100}
        >
          Submit
        </Button>
      </Group>
    </Modal>
  );
};