import { BioLinkService } from '@/services/BioLinkService';
import { notifications } from '@mantine/notifications';
import { DEFUALT_BIO_LINK_VALUES, IBioLink } from '@replug/utils';
import { create } from 'zustand';

interface IBioLinkSetupStore {
  bioLink: IBioLink | null;
  setBioLink: (bioLink: IBioLink | null) => void;
  loader: boolean;
  setLoader: (loader: boolean) => void;
  create: (workspace_id: any, values: any) => any;
  update: (workspace_id:any, values: any) => void;
}

export const useBioLinkSetupStore = create<IBioLinkSetupStore>((set, get) => ({
  bioLink: DEFUALT_BIO_LINK_VALUES,
  setBioLink: (bioLink: IBioLink | null) => {
    set({ bioLink });
  },
  loader: false,
  setLoader: (loader: boolean) => {
    set({ loader });
  },
  create: async (workspace_id, values) => {
    set({ loader: true });
    const res = await new BioLinkService()
      .create(workspace_id, values)
      .then((response) => {
        if (response.data.status) {
          set({ bioLink: response.data.data });
          notifications.show({
            title: 'Success',
            message: response.data.message,
            color: 'green',
          });
        }
        return response.data;
      })
      .catch((error) => {
          notifications.show({
            title: 'Error',
            message: error.response?.data?.message,
            color: 'red',
          });

        return false;
      });
    set({ loader: false });
    return res;
  },

  update: async (workspace_id, values) => {
    set({ loader: true });
    await new BioLinkService()
      .update(workspace_id, values)
      .then((response) => {
        if (response.data.status) {
          set({ bioLink: response.data.data });
          notifications.show({
            message: 'Your bio link has been updated successfully',
            color: 'green',
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 422 || error.response.status === 400) {
          notifications.show({
            title: 'Error',
            message: error.response.data.message,
            color: 'red',
          });
        }
      });
    set({ loader: false });
  },
}));
