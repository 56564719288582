import React, { useState, useEffect } from 'react';
// @ts-ignore
import Confetti from 'react-confetti';

export const ConfettiComponent = () => {
    const [isConfetti, setIsConfetti] = useState(false);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {

        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });

        const handleResize = () => {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        const timer = setTimeout(() => {
            setIsConfetti(true);
        }, 2000);

        return () => {
            window.removeEventListener('resize', handleResize);
            clearTimeout(timer);
        };
    }, []);

    return (
        <div style={{ position: 'absolute', zIndex: 9999, top: 0, left: 0, right: 0, bottom: 0, pointerEvents: 'none' }}>
            {isConfetti && (
                <Confetti
                    width={dimensions.width}
                    height={dimensions.height}
                    numberOfPieces={1000}
                    gravity={0.1}
                    recycle={false}
                    onConfettiComplete={() => setIsConfetti(false)}
                />
            )}
        </div>
    );
};
