import { CustomWidgetService } from '@/services/CustomWidgetService';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { IWidget } from '@/types/widgets';
import { html } from '@codemirror/lang-html';
import { javascript } from '@codemirror/lang-javascript';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Modal,
  NumberInput,
  Select,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import CodeMirror from '@uiw/react-codemirror';
import { useEffect, useState } from 'react';
import { z } from 'zod';

const schema = z.object({
  _id: z.string().nullable(),
  name: z.string().nullable(),
  js: z.string().nullable(),
  html: z.string().nullable(),
  trigger_type: z.string(),
  trigger_seconds: z.number().nullable(),
  position: z.string().nullable(),
  workspace_id: z.string().nullable(),
});

export const CustomWidgetModal = ({
  id = null,
  opened,
  setOpened,
  onChange,
  widget,
}: {
  id?: string | null;
  opened: boolean;
  widget?: IWidget | null;
  setOpened: (value: boolean) => void;
  onChange?: (value: IWidget) => void;
}) => {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  const [storeLoading, setStoreLoading] = useState(false);
  const theme = useMantineTheme();
  const form = useForm({
    initialValues: {
      _id: id,
      name: '',
      js: '',
      html: '',
      trigger_type: '',
      trigger_seconds: 0,
      position: 'bottom_left',
      workspace_id: defaultWorkspace?._id,
    },
    validate: zodResolver(schema),
  });
  const handleCodeChange = (name: any) => (value: any) => {
    form.setFieldValue(name, value);
  };

  const handleFormSubmit = async (formValues: any) => {
    console.log(formValues);
    const keys = ['html', 'js'];
    const filtered = keys.filter((key) => formValues[key] !== '');
    if (filtered.length === 0) {
      notifications.show({
        color: 'red',
        message: 'Please fill at least one field.',
      });
      return;
    }
    if (!formValues.trigger_type) {
      form.setFieldError('trigger_type', 'Please select a trigger type');
      return;
    }
    setStoreLoading(true);

    if (formValues._id) {
      await new CustomWidgetService()
        .update(defaultWorkspace?._id || '', formValues)
        .then((res) => {
          if (res.data.status) {
            notifications.show({
              color: 'green',
              message: `Custom Widget has been ${
                form.values._id ? 'updated' : 'created'
              } successfully.`,
            });
            setOpened(false);
            onChange && onChange(res.data.data);
          } else {
            notifications.show({
              color: 'red',
              message: res.data.message,
            });
          }
        })
        .catch((err) => {
          if (err.response.status) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
          console.log(err);
        });
    } else {
      await new CustomWidgetService()
        .create(defaultWorkspace?._id || '', formValues)
        .then((res) => {
          if (res.data.status) {
            notifications.show({
              color: 'green',
              message: `Custom Widget has been ${
                form.values._id ? 'updated' : 'created'
              } successfully.`,
            });
            setOpened(false);
            onChange && onChange(res.data.data);
          } else {
            notifications.show({
              color: 'red',
              message: res.data.message,
            });
          }
        })
        .catch((err) => {
          if (err.response.status) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
        });
    }

    setStoreLoading(false);
  };

  useEffect(() => {
    if (widget) {
      form.setValues({
        ...widget,
        js: widget.js || '',
        html: widget.html || '',
        workspace_id: defaultWorkspace?._id,
      });
    } else {
      form.reset();
      form.setFieldValue('workspace_id', defaultWorkspace?._id);
    }
  }, [widget, opened]);
  return (
    <>
      <Modal
        size={'700'}
        opened={opened}
        onClose={() => setOpened(!opened)}
        title={<Title order={5}>{form.values._id ? 'Update' : 'Create'} Custom Widget</Title>}
      >
        <Box p={16}>
          {/* <Flex align={'center'} justify={'center'}>
            <FontAwesomeIcon
              style={{
                color: theme.colors.primary[9],
                marginBottom: 16,
              }}
              icon={faLocationPin}
              size={'2x'}
            />
          </Flex> */}

          <Box mx={32}>
            <form onSubmit={form.onSubmit(handleFormSubmit)}>
              <TextInput
                mb={16}
                label={'Name'}
                variant="filled"
                placeholder="e.g. Live Chat Widget"
                {...form.getInputProps('name')}
              />

              <CodeMirror
                style={{
                  marginBottom: 16,
                  fontSize: 13,
                  borderRadius: 4,
                }}
                value={form.values.html}
                extensions={[html()]}
                height="140px"
                onChange={handleCodeChange('html')}
                placeholder={'<div>Your HTML Code</div>'}
              />

              <CodeMirror
                style={{
                  marginBottom: 16,
                  fontSize: 13,
                  borderRadius: 4,
                }}
                value={form.values.js}
                extensions={[javascript()]}
                onChange={handleCodeChange('js')}
                height="140px"
                placeholder={'<script>Your Javasacript Code</script>'}
              />

              <Box
                p={16}
                bg="gray.1"
                style={{
                  borderRadius: 4,
                }}
              >
                <Flex align={'center'}>
                  <Text
                    fz={14}
                    style={{
                      flexGrow: 1,
                    }}
                  >
                    <strong>When</strong> should it appear?
                  </Text>
                  <Flex justify={'flex-end'} align={'center'}>
                    <Checkbox
                      size="xs"
                      mr={12}
                      label="On exit-intent"
                      value={'exit_intent'}
                      onChange={(event) => {
                        console.log(event.target.checked);
                        if (event.target.checked) {
                          form.setFieldValue('trigger_type', event.target.value);
                        } else {
                          form.setFieldValue('trigger_type', '');
                        }
                      }}
                      checked={form.values.trigger_type === 'exit_intent'}
                      // value={'exit_intent'}
                      // {...form.getInputProps('trigger_type')}
                    />
                    <Checkbox
                      error={form.errors.trigger_type}
                      size="xs"
                      label="After 'X' seconds"
                      value={'time'}
                      onChange={(event) => {
                        console.log(event.target.checked);
                        if (event.target.checked) {
                          form.setFieldValue('trigger_type', event.target.value);
                        } else {
                          form.setFieldValue('trigger_type', '');
                        }
                      }}
                      checked={form.values.trigger_type === 'time'}
                    />
                    {form.values.trigger_type === 'time' && (
                      <>
                        <NumberInput
                          ml={12}
                          size="xs"
                          w={40}
                          placeholder="Seconds"
                          disabled={form.values.trigger_type !== 'time'}
                          {...form.getInputProps('trigger_seconds')}
                        />
                      </>
                    )}
                  </Flex>
                </Flex>

                <Divider color="gray.2" mb={16} mt={16} />

                <Flex align={'center'}>
                  <Text
                    fz={14}
                    style={{
                      flexGrow: 1,
                    }}
                  >
                    <strong>Where</strong> should it appear?
                  </Text>
                  <Flex justify={'flex-end'} align={'center'}>
                    <Select
                      size="xs"
                      w={140}
                      placeholder="Select Position"
                      data={[
                        {
                          label: 'Bottom Left',
                          value: 'bottom_left',
                        },

                        {
                          label: 'Bottom Right',
                          value: 'bottom_right',
                        },

                        {
                          label: 'Middle',
                          value: 'middle',
                        },

                        {
                          label: 'Top Left',
                          value: 'top_left',
                        },

                        {
                          label: 'Top Right',
                          value: 'top_right',
                        },
                      ]}
                      {...form.getInputProps('position')}
                    />
                  </Flex>
                </Flex>
              </Box>

              <Flex mt={32} align={'center'} justify={'center'}>
                <Button disabled={storeLoading} loading={storeLoading} type="submit" mr={12}>
                  {form.values._id ? 'Update' : 'Create'}
                </Button>
                <Button onClick={() => setOpened(false)} variant="outline" color="gray">
                  Cancel
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
