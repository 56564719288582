import React, { useState, useEffect } from "react";
import { Container, Flex, Text, Anchor, CloseButton } from "@mantine/core";
import { useWorkspaceStore } from "@/stores/useWorkspaceStore";

export const DiscountBanner = () => {
    const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const bannerDismissed = localStorage.getItem('discountBannerDismissed');
        setIsVisible(bannerDismissed !== 'true');
    }, []);

    const handleDismiss = () => {
        setIsVisible(false);
        localStorage.setItem('discountBannerDismissed', 'true');
    };

    if (!isVisible) return null;

    return (
        <div style={{ padding: "8px 16px", backgroundColor: "black", position: "relative" }}>
            <Container size="xl">
                <Flex
                    direction={{ base: "column", md: "row" }}
                    align="center"
                    justify="center"
                    wrap="wrap"
                >
                    <Flex align="center" justify="center">
                        <Text
                            color="white"
                            size="lg"
                            fw="500"
                            style={{ marginLeft: "8px", marginRight: "8px" }}
                        >
                            {!defaultWorkspace?.agency?.is_whitelabel_enabled ? "🎉 Unveiling Replug 2.0: Explore the brand new interface, features and latest updates today. " : "🎉 Explore the brand new interface, features and latest updates today. "}
                            <Anchor target="_blank" style={{ color: '#3a93ff', fontWeight: 500 }} href={'https://updates.replug.io/announcements'}>
                                Discover what's new!
                            </Anchor>
                        </Text>
                    </Flex>
                </Flex>
            </Container>
            <CloseButton
                style={{
                    position: "absolute",
                    top: "50%",
                    right: "16px",
                    transform: "translateY(-50%)",
                }}
                onClick={handleDismiss}
                aria-label="Close banner"
            />
        </div>
    );
};