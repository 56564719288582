import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { Box, PasswordInput, Text } from '@mantine/core';
import classes from '../LinkCreationModal.module.css';
export const PasswordProtection = () => {
  const [link, setLink] = useLinkSetupStore((state) => [state.link, state.setLink]);
  return (
    <Box p={16} className={!link.is_password_protected ? classes.disabled : ''}>
      <Text fw={600} fz={14}>
        Password Protection
      </Text>
      <PasswordInput
        label="Your link password"
        value={link.password_attributes.password || ''}
        onChange={(event) => {
          setLink({
            ...link,
            password_attributes: {
              password: event.currentTarget.value,
            },
          });
        }}
        variant="filled"
        mt={12}
        placeholder="Password"
        rightSectionPointerEvents={!link.is_password_protected ? 'none' : 'auto'}
      />
    </Box>
  );
};
