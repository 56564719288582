import axios from './JWTService';

export class LinksService {
  get = (
    workspace_id: string,
    campaigns: any,
    tags: any,
    page: number = 1,
    search: string = '',
    sort: string = '',
    limit: number = 10,
    archive: boolean = false,
    date: string
  ): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `workspace/${workspace_id}/link/get/all?page=${page}&search=${search}&sort_by=${sort}&limit=${limit}&campaigns=${campaigns}&tags=${tags}&archive=${archive}&date=${date}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  getById = (workspace_id: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`workspace/${workspace_id}/link/get/individual/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  create = (workspace_id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`workspace/${workspace_id}/link/create`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  editShortenURL = (workspace_id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`workspace/${workspace_id}/link/edit_shorten_url`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  changeStatus = (workspace_id: string, id: string, status: boolean): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`workspace/${workspace_id}/link/change_status/${id}`, {
          status: !status,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  delete = (workspace_id: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`workspace/${workspace_id}/link/delete/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  archive = (workspace_id: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`workspace/${workspace_id}/link/archive/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  unarchive = (workspace_id: string, id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`workspace/${workspace_id}/link/unarchive/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  update = (workspace_id: string, id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`workspace/${workspace_id}/link/update/${id}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  updateTags = (workspace_id: string, id: string, tag_ids: string[]): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`workspace/${workspace_id}/link/update_tags/${id}`, { tag_ids })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  updateCampaign = (workspace_id: string, id: string, campaign_id: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`workspace/${workspace_id}/link/update_campaign/${id}`, { campaign_id })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  isDeepLinkAllowed = (workspace_id: string, id: string, url: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`workspace/${workspace_id}/deep_link/is_allowed`, {
          id,
          url,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  export = (workspace_id: string, ids: string[], email_addresses: string[]): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`workspace/${workspace_id}/link/export`, {
          campaign_ids: ids,
          email_addresses,
          workspace_id: workspace_id
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  bulkCreate = (workspace_id: string, data: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`workspace/${workspace_id}/link/bulk_create`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error)
        });
    });
  };
}
